import TokenModel from '../Models/TokenModel';
import GlobalStateModel from '../Models/GlobalStateModel';
import { ResultModel } from '../Models/ResultModel';
import TrainingPlanUnitStandardModel from '../Models/TrainingPlanUnitStandardModel';
import TrainingPlanUnitStandard from './TrainingPlanUnitStandard';
import ComponentHelperBase, { IUSCheckedEventArg } from '../ComponentHelpers/ComponentHelperBase';
import _ from 'underscore';
import Result from './Result';
import CommentForm from './CommentForm';
import { TrainingPlanCommentModel } from '../Models/TrainingPlanCommentModel';
import { UnitStandardModel } from '../Models/UnitStandardModel';
import ResultForm from "./ResultForm";
import { ItpuCreate, ItpuUpdate } from "../Common/TrainingPlanUnitStandardManager";
import TrainingPlanUnitStandardManager from "../Common/TrainingPlanUnitStandardManager";
import CrossCreditSearchHelper from './CrossCreditSearchHelper';


const AssessmentType = {
    "Practical": 1,
    "Theory": 2,
    "Combined": 3
};

const AssessedBy = {
    "Assessor": 1,
    "TrainingProvider": 2
};


export default class CrossCreditProgramUnitStandardHelper extends ComponentHelperBase {




    electiveSectionId: string | undefined;
    _trainingPlanUnitStandardM: TrainingPlanUnitStandardModel | null;
    comment: TrainingPlanCommentModel | undefined;
    _trainingPlanUnitStandard: TrainingPlanUnitStandard | undefined;
    _globalStateModel: GlobalStateModel;
    unitStandard: UnitStandardModel | undefined;
    _resultHelper: Result | undefined;
    _isChecked: boolean;
    _iselectedInOtherSection: boolean;
    resultForm: ResultForm | undefined;
    commentForm: CommentForm | undefined;
    _searchHelper: CrossCreditSearchHelper;
    constructor(result: ResultModel, trainingPlanUnitStandard: TrainingPlanUnitStandardModel | null, electiveSectionId: string, globalStateModel: GlobalStateModel) {
        super();
        this._result = result;
        this._trainingPlanUnitStandardM = trainingPlanUnitStandard;
        this.electiveSectionId = electiveSectionId;
        this._globalStateModel = globalStateModel;
        this._isChecked = false;
        this._iselectedInOtherSection = false;
        this._searchHelper = new CrossCreditSearchHelper(this.electiveSectionId, this._globalStateModel, trainingPlanUnitStandard);
    }

    async build() {

        let trainingPlanCommentModel = new TrainingPlanCommentModel(this._globalStateModel.tokenModel as TokenModel)
        let _unitStandard = new UnitStandardModel(this._globalStateModel.tokenModel as TokenModel);

        if (this._trainingPlanUnitStandardM) {
            this._trainingPlanUnitStandard = new TrainingPlanUnitStandard(this._trainingPlanUnitStandardM);

            // set the comment
            var tpusId = this._trainingPlanUnitStandard._tpuModel.tims_trainingplanunitstandardid as string;
            var comment = trainingPlanCommentModel.retrieveByTrainingPlanUnitStandardId(tpusId);

            if (comment !== undefined)
                this.comment = comment as TrainingPlanCommentModel;
        }

        // need to get the latest unit standard here for the framework element
        // populate unit standard from the result or the trianing plan unit standard
        var unitStandardId;
        if (!this._result?.tims_resultid) {
            unitStandardId = this._trainingPlanUnitStandard?._tpuModel._tims_unitstandardid_value as string;
        } else {
            unitStandardId = this._result._tims_unitstandardid_value as string;
        }

        var unitStandard = await _unitStandard.retrieveUnitStandard(unitStandardId, true);
        this.unitStandard = unitStandard;
        this._resultHelper = new Result(this._result as ResultModel);
        this._isChecked = this.hasTrainingPlanUnitStandard;
        this.resultForm = new ResultForm(this._resultHelper, this._trainingPlanUnitStandard);
        this.commentForm = new CommentForm(this);

        if (this.unitStandard != undefined && this._globalStateModel.trainingPlanObj != undefined) {
            var selectedUnitStandards = await _unitStandard.checkIfSelectedInOtherSections(this.unitStandard?.tims_frameworkelementcode as string, this._globalStateModel.trainingPlanObj?.tims_trainingplanid as string, this.unitStandard?.tims_unitstandardid as string) as UnitStandardModel[];
            this._iselectedInOtherSection = selectedUnitStandards.length > 0 && !this.hasTrainingPlanUnitStandard;
        }

    }
    get resultHelper() {
        return this._resultHelper as Result;
    }

    get searchHelper() {
        return this._searchHelper as CrossCreditSearchHelper;
    }

    // When selecting, delselect from the other sections
    deselectFrameworkElementCode(option: IUSCheckedEventArg) {
        if (option.frameworkElementCode == this.unitStandard?.tims_frameworkelementcode &&
            this.electiveSectionId != option.electiveSectionId && !this.isDirty) {
            this.onCheckboxChanged(false);
        }
    };

    // When deselecting, select from the other sections
    selectFrameworkElementCode(option: IUSCheckedEventArg) {

        if (option.frameworkElementCode == this.unitStandard?.tims_frameworkelementcode &&
            this.electiveSectionId != option.electiveSectionId && this.isDirty) {
            this.onCheckboxChanged(true);
        }
    };

    // When selecting, delselect from the other sections
    checkedEvent(value: any) {

    };

    onCheckboxChanged(isChecked: boolean) {
        this._isChecked = isChecked;
    }


    get isChecked() {
        return this._isChecked;
    }

    get isSelected() {
        if (this.isDirty)
            return this.isChecked;

        return this.hasTrainingPlanUnitStandard;
    }

    get resourceStatus() {

        var tpus = this._trainingPlanUnitStandard;
        if (tpus && tpus.resourceorderstatus) {
            return tpus.resourceorderstatus;
        }
        return -1;
    };

    get isSelectedOtherSection() {
        return this._iselectedInOtherSection;

    };

    // Cannot make changes if result is acheved after training plan
    // start date and is itr registered
    get isITRLocked() {
        var dateOfTrainingPlanStart = this._globalStateModel.trainingPlanObj?.tims_datetrainingplanstarted as string;
        var itrRegistrationStatus = this._globalStateModel.trainingPlanObj?.tims_itrregistrationstatus;

        // if has result and is itr registered
        if (this._result && this._result.tims_dateofachievement && itrRegistrationStatus &&
            (itrRegistrationStatus == 2 || itrRegistrationStatus == 3)) {

            var startDate = new Date(dateOfTrainingPlanStart);
            startDate.setHours(0);
            startDate.setMinutes(0);
            startDate.setSeconds(0);

            var achievementDate = new Date(this._result.tims_dateofachievement);

            if (achievementDate >= startDate && this.hasTrainingPlanUnitStandard) {

                return true;
            }
        }

        return false;
    };

    sync() {
        if (this.isChecked && this.isDirty) {
            this.create();
            this.update();
        } else if (!this.isChecked && this.isDirty) {
            this.delete();
            this.updatePre();
        }
    };

    create() {

        // sometimes result doesn't seem to have assessed by and assessment type
        var assessedBy = this._result?.tims_assessedby !== undefined ?
            this._result?.tims_assessedby : AssessedBy.TrainingProvider;
        var assessmentType = this._result?.tims_assessmenttype !== undefined ?
            this._result?.tims_assessmenttype : AssessmentType.Theory;

        var tpus = {
            //trainingplanid: trainingPlanId,
            unitstandardid: this.unitStandard?.tims_unitstandardid,
            assessmenttype: assessmentType,
            assessedby: assessedBy,
            electivesectionid: this.electiveSectionId
        } as ItpuCreate;
        let tpuModel = new TrainingPlanUnitStandardModel(this._globalStateModel.tokenModel as TokenModel);
        let seqNum = tpuModel.getMaxSeqNum();
        if (!this.isSelectedOtherSection) {
            TrainingPlanUnitStandardManager.createTrainingPlanUnitStandard(tpus, seqNum);
        }
    };

    delete() {
        if (!this.isSelectedOtherSection) {
            return TrainingPlanUnitStandardManager.deleteTrainingPlanUnitStandard(this._trainingPlanUnitStandard?._tpuModel as TrainingPlanUnitStandardModel);
        }




    };

    update() {
        if (this._result && this._result.tims_dateofachievement && this.isSelectedOtherSection) {
            var tpus = {
                programmeunitstandardid: null,
                unitstandardid: this.unitStandard?.tims_unitstandardid,
                electivesectionid: this.electiveSectionId
            } as ItpuUpdate;
            return TrainingPlanUnitStandardManager.updateTrainingPlanUnitStandard(tpus);
        }
    };

    updatePre() {

        if (this._result && this._result.tims_dateofachievement) {
            return TrainingPlanUnitStandardManager.updatePreTrainingPlanUnitStandard(this._trainingPlanUnitStandard?._tpuModel as TrainingPlanUnitStandardModel);
        }
    };

    _trainingPlanUnitStandardCreated(tpus: TrainingPlanUnitStandardModel) {
        if (this._trainingPlanUnitStandard !== null)
            return;

        var trainingPlanCode = tpus.unitStandard.tims_frameworkelementcode
        if (this.unitStandard?.tims_frameworkelementcode == trainingPlanCode && this.electiveSectionId == tpus._tims_electivesectionid_value) {
            this._trainingPlanUnitStandard = new TrainingPlanUnitStandard(tpus)
        }
    };

    get hasTrainingPlanUnitStandard() {
        return this._trainingPlanUnitStandard != undefined;
    };

    get isDirty() {
        var hasTPUS = this.hasTrainingPlanUnitStandard;
        return (this.isChecked && !hasTPUS) || (!this.isChecked && hasTPUS);
    };

    get assessmentType() {
        if (this._trainingPlanUnitStandard != null)
            if (this._trainingPlanUnitStandard._tpuModel.tims_trainingplanunitstandardid) {
                return {
                    1: "Practical",
                    2: "Theory",
                    3: "Combined"
                }[this._trainingPlanUnitStandard._tpuModel.tims_assessmenttype as number];
            }

        var result = this._result;
        if (result === null)
            return "Practical";

        if (result?.tims_assessmenttype)
            return {
                1: "Practical",
                2: "Theory",
                3: "Combined"
            }[this._result?.tims_assessmenttype as number];

        // for some reason, some of the training plans didn't assessment type....
        if (result?.tims_datetheorypassed && result.tims_datepracticalpassed)
            return "Combined";

        if (result?.tims_datetheorypassed)
            return "Theory";

        return "Practical";
    };

    get hasPrerequisites() {
        if (this._trainingPlanUnitStandard != null)
            return this._trainingPlanUnitStandard._tpuModel.tims_prerequisite != undefined && this._trainingPlanUnitStandard._tpuModel.tims_prerequisite;
        else 
            return false;
    }

    get isAchieved() {
        var result = this._resultHelper;
        return result !== null && result?.isAchieved() as boolean;
    };

    get isAchievedPendingPreReq() {
        var result = this._resultHelper;
        if (result != null)
            return result?.isAchievedPendingPreReq();
        else
            return false;
    };

    get isPartiallyAchieved() {
        var result = this._resultHelper;
        return result !== null && result?.isPartiallyAchieved();
    };

    get credits() {
        return this.unitStandard?.tims_credits;
    };

    get isExempt() {

        var tpus = this._trainingPlanUnitStandard;
        if (tpus && tpus._tpuModel.tims_isexempt) {
            return true;
        } else {
            return false;
        }
    };

    get hasResource() {
        var tpus = this._trainingPlanUnitStandard;
        if (tpus && tpus._tpuModel !== undefined) {
            return tpus._tpuModel.uspunitstandardid !== undefined;
        }
        else return false;
    };
}