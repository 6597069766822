import { ContactModel } from './ContactModel';
import { Serializable } from './Serializable';
import TokenModel from "./TokenModel"

const entityName = "tims_unitstandards";

var cache = [] as any[];
var cacheAssessorUnitstandard = [] as any[];
export class UnitStandardModel extends Serializable {

    constructor(tokenModel: TokenModel) {
        super(tokenModel);
    }

    tims_name: string | undefined;
    tims_version: number | undefined;
    tims_unitstandardtitle: string | undefined
    statuscode: number | undefined;
    tims_level: number | undefined;
    tims_frameworkelementcode: string | undefined;
    tims_credits: number | undefined;
    _tims_frameworkelementid_value: string | undefined;
    tims_unitstandardid: string | undefined;
    tims_dateofexpiry:string | undefined

    attrs = [
        'tims_name',
        'tims_version',
        'tims_unitstandardtitle',
        'statuscode',
        'tims_level',
        'tims_frameworkelementcode',
        'tims_credits',
        'tims_frameworkelementid',
        'tims_unitstandardid',
        'tims_dateofexpiry'
    ] as any[];

    createColumnSet() {
        return "<attribute name='" + this.attrs.join("' /><attribute name='") + "' />";
    }

    clearCache() {
        cache = [];
        cacheAssessorUnitstandard = [];
    }

    async initCache(programmeId: string, traineeId: string) {

        // intialises cache with unit standards related to programme
        // and unit standards that are related to the trainee
        var fetch = [
            "<fetch version='1.0' output-format='xml-platform' mapping='logical' distinct='true'>",
            "  <entity name='tims_unitstandard'>",
            this.createColumnSet(),
            "    <order attribute='tims_frameworkelementcode' descending='true' />",
            "    <link-entity name='tims_programmeunitstandard' from='tims_unitstandardid' to='tims_unitstandardid' alias='ac'>",
            "      <filter type='and'>",
            "        <condition attribute='tims_programmeid' operator='eq' value='#PROGRAMMEID#' />",
            "      </filter>",
            "    </link-entity>",
            "  </entity>",
            "</fetch>"
        ].join('').replace("#PROGRAMMEID#", programmeId);

        let response = await this.d365Helper.ExecuteFetch(entityName, fetch, true);
        if (response != undefined)
            cache = response;

        // intialises cache with unit standards related to programme
        // and unit standards that are related to the trainee
        if (traineeId != undefined) {
            var fetch2 = ["<fetch version='1.0' output-format='xml-platform' mapping='logical' distinct='true'>",
                "  <entity name='tims_unitstandard'>",
                this.createColumnSet(),
                "    <link-entity name='tims_result' from='tims_unitstandardid' to='tims_unitstandardid' alias='ac'>",
                "      <filter type='and'>",
                "        <condition attribute='tims_contacttraineeid' operator='eq' value='#TRAINEEID#' />",
                "      </filter>",
                "    </link-entity>",
                "  </entity>",
                "</fetch>"
            ].join('').replace("#TRAINEEID#", traineeId);

            let response2 = await this.d365Helper.ExecuteFetch(entityName, fetch2, true);
            if (response2 != undefined)
                cache.push(response2);
        }
    }

    async checkIfSelectedInOtherSections(frameworkElementCode: string, trainingplaId: string, unitstandardId: string) {

        var fetch = ["<fetch distinct='true' >"
            , "<entity name='tims_unitstandard'>"
            , "<attribute name='tims_unitstandardid'/>"
            , "<attribute name='tims_name'/>"
            , "<filter type='and'>"
            , "<condition attribute='tims_frameworkelementcode' operator= 'eq' value='" + frameworkElementCode + "' />"
            , "</filter>"
            , "<link-entity name ='tims_trainingplanunitstandard' from='tims_unitstandardid' to ='tims_unitstandardid' link-type='inner'>"
            , "<filter>"
            , "<condition attribute='tims_trainingplanid' operator ='eq' value = '" + trainingplaId + "'/>"
            , "<condition attribute='statecode' operator ='eq' value = '0'/>"
            , "</filter>"
            , "</link-entity>"
            , "</entity>"
            , "</fetch>"];

        let response = await this.d365Helper.ExecuteFetch(entityName, fetch.join(""), false);
        return response;
    }
    async retrieveUnitStandard(unitStandardId: string, async: boolean) {

        if (async)
            return this.retrieveUnitStandardAsync(unitStandardId);
    }

    // retrieves programme unit standards, unit standard, and framework element
    async retrieveUnitStandardNonAsync(unitStandardId: string) {
        var us = cache.find(elem => elem.id == unitStandardId);
        if (us) {
            return us;
        } else {
            var unitStandard;
            var fetch = [
                "<fetch version='1.0' output-format='xml-platform' mapping='logical' distinct='true'>",
                "  <entity name='tims_unitstandard'>",
                this.createColumnSet(),
                "    <order attribute='tims_frameworkelementcode' descending='true' />",
                "      <filter type='and'>",
                "        <condition attribute='tims_unitstandardid' operator='eq' value='#UNITSTANDARDID#' />",
                "      </filter>",
                "  </entity>",
                "</fetch>"
            ].join('').replace("#UNITSTANDARDID#", unitStandardId);

            let response = await this.d365Helper.ExecuteFetch(entityName, fetch, true);
            if (response.length > 0)
                unitStandard = response[0];

            return unitStandard;
        }
    }

    async retrieveUnitStandardAsync(unitStandardId: string) {

        var us = cache.find(elem => elem.id == unitStandardId);

        if (us) {
            return us;
        } else {
            var unitStandard;
            var fetch = [
                "<fetch version='1.0' output-format='xml-platform' mapping='logical' distinct='true'>",
                "  <entity name='tims_unitstandard'>",
                this.createColumnSet(),
                "    <order attribute='tims_frameworkelementcode' descending='true' />",
                "      <filter type='and'>",
                "        <condition attribute='tims_unitstandardid' operator='eq' value='#UNITSTANDARDID#' />",
                "      </filter>",
                "  </entity>",
                "</fetch>"
            ].join('').replace("#UNITSTANDARDID#", unitStandardId);

            let response = await this.d365Helper.ExecuteFetch(entityName, fetch, true);
            if (response.length > 0)
                unitStandard = response[0];

            cache.push(unitStandard);

            return unitStandard;
        }
    }

    async retrieveUnitStandardsFilteredFE(searchString: string, filter: string) {


        var fetch = [
            "<fetch version='1.0' output-format='xml-platform' mapping='logical' distinct='true' page='1' count='20'>",
            "  <entity name='tims_unitstandard'>",
            this.createColumnSet(),
            "    <order attribute='tims_frameworkelementcode' descending='true' />",
            "    <filter type='and'>",
            "      <condition attribute='tims_name' operator='like' value='%#UNITSTANDARDSEARCH#%' />",
            "      <filter type='or'>",
            "        <condition attribute='statuscode' operator='eq' value='3' />",
            "        <condition attribute='statuscode' operator='eq' value='4' />",
            "      </filter>",
            "    </filter>",
            "    <link-entity name='tims_frameworkelement' from='tims_frameworkelementid' to='tims_frameworkelementid' alias='frameworkelement'>",
            "       <filter type='or'>",
            filter,
            "       </filter>",
            "    </link-entity>",
            "  </entity>",
            "</fetch>"
        ].join('').replace("#UNITSTANDARDSEARCH#", searchString);

        return await this.d365Helper.ExecuteFetch(entityName, fetch, true);
    }

    MapLevels(assessorLevel: number, unitStandardLevel: number) {
        switch (assessorLevel) {
            case 1:
                return unitStandardLevel >= 1;
                break;
            case 2:
                return unitStandardLevel === 1;
                break;
            case 3:
                return unitStandardLevel <= 2;
                break;
            case 4:
                return unitStandardLevel === 2;
                break;
            case 5:
                return unitStandardLevel >= 2;
                break;
            case 6:
                return unitStandardLevel <= 3;
                break;
            case 7:
                return unitStandardLevel === 3;
                break;
            case 8:
                return unitStandardLevel >= 3;
                break;
            case 9:
                return unitStandardLevel <= 4;
                break;
            case 10:
                return unitStandardLevel >= 4;
                break;
            case 11:
                return unitStandardLevel <= 5;
                break;
            case 12:
                return unitStandardLevel >= 5;
                break;
            case 13:
                return unitStandardLevel <= 6;
                break;
            case 14:
                return unitStandardLevel >= 6;
                break;
        }
    }

    async initialiseAssessorUnitStandards(assessor: ContactModel, fitlerCriteria: string[]) {
        if (assessor.contactid == null || assessor.contactid == "")
            return;

        var fetch = [
            "<fetch version='1.0' output-format='xml-platform' mapping='logical' distinct='true'>",
            "   <entity name='tims_unitstandard'>",
            "       <attribute name='tims_unitstandardid'/>",
            "       <link-entity name='tims_unitstandard' to='tims_frameworkelementcode' from='tims_frameworkelementcode'>",
            "           <link-entity name='tims_industrylevel_unitstandard' to='tims_unitstandardid' from='tims_unitstandardid'>",
            "               <link-entity name='tims_contact_industrylevel' intersect='true' visible='false' to='tims_industrylevelid' from='tims_industrylevelid' >",
            "                   <filter type='and' >",
            "                       <condition attribute='contactid' uitype='contact' value='#ASSESSORID#' operator='eq' />",
            "                   </filter>",
            "               </link-entity>",
            "           </link-entity>",
            "      </link-entity>",
            "   </entity>",
            "</fetch>"
        ].join('').replace('#ASSESSORID#', assessor.contactid);
        cacheAssessorUnitstandard = await this.d365Helper.ExecuteFetch(entityName, fetch, true);

        var fetch1 = [
            "<fetch version='1.0' output-format='xml-platform' mapping='logical' distinct='false'>",
            "  <entity name='tims_unitstandard'>",
            "       <attribute name='tims_name'/>",
            "       <attribute name='tims_unitstandardid'/>",
            "       <attribute name='tims_level' />",
            "       <link-entity name='tims_frameworkelement' from='tims_frameworkelementid' to='tims_frameworkelementid' alias='frameworkelement'>",
            "          <filter type='or'>",
            fitlerCriteria[0],
            "          </filter>",
            "           <link-entity name='tims_assessorframeworklevel' from='tims_frameworkelementid' to='tims_frameworkelementid' visible='false' intersect='true' alias='frameworklevel'>",
            "               <attribute name='tims_level' />",
            "                   <filter type='and'>",
            "                       <condition attribute='tims_contactassessorid' operator='eq' value='#ASSESSORID#' />",
            "                       <condition attribute='tims_includeexclude' operator='ne' value='100000001' />",
            "                   </filter>",
            "            </link-entity>",
            "       </link-entity>",
            "  </entity>",
            "</fetch>"
        ].join('').replace('#ASSESSORID#', assessor.contactid);

        let resp = await this.d365Helper.ExecuteFetch(entityName, fetch1, true);

        resp.forEach(elem => {
            var frameworkElementLevel = elem["frameworklevel.tims_level"] != null ? elem["frameworklevel.tims_level"] : null;
            var unitstandardLevel = elem.tims_level;
            if (frameworkElementLevel == null || this.MapLevels(parseInt(frameworkElementLevel), unitstandardLevel != null ? parseInt(unitstandardLevel) : unitstandardLevel)) {
                cacheAssessorUnitstandard.push(elem);
            }
        });

        var fetch2 = [
            "<fetch version='1.0' output-format='xml-platform' mapping='logical' distinct='false'>",
            "  <entity name='tims_unitstandard'>",
            "       <attribute name='tims_unitstandardid'/>",
            "       <attribute name='tims_frameworkelementcode'/>",
            "          <filter type='or'>",
            fitlerCriteria[1],
            "          </filter>",
            "  </entity>",
            "</fetch>"
        ].join('').replace('#ASSESSORID#', assessor.contactid);

        var excludedUnits = [
            "<fetch version='1.0' output-format='xml-platform' mapping='logical' distinct='false'>",
            "   <entity name='tims_unitstandard'>",
            "   <attribute name = 'tims_frameworkelementcode'/>",
            "   <attribute name='tims_unitstandardid' />",
            "   <link-entity name='tims_frameworkelement' from='tims_frameworkelementcode' to='tims_frameworkelementcode' alias='frameworkelement'>",
            "      <link-entity name='tims_assessorframeworklevel' from='tims_frameworkelementid' to='tims_frameworkelementid' visible='false' alias='frameworklevel'>",
            "           <filter type='and'>",
            "               <condition attribute='tims_contactassessorid' operator='eq' value='#ASSESSORID#' />",
            "               <condition attribute='tims_includeexclude' operator='eq' value='100000001' />",
            "           </filter>",
            "      </link-entity>",
            "    </link-entity>",
            " </entity>",
            "</fetch>"
        ].join('').replace("#ASSESSORID#", assessor.contactid)

        let excludedUnitsResp = await this.d365Helper.ExecuteFetch(entityName, excludedUnits, true);

        let resp2 = await this.d365Helper.ExecuteFetch(entityName, fetch2, true);
        resp2.forEach(val => {
            cacheAssessorUnitstandard.push(val);
        });
        
        // If assessor scope marked as excluded, do not add to assessor units cache
        cacheAssessorUnitstandard = cacheAssessorUnitstandard.filter(e => {
            return excludedUnitsResp.find(item => item.tims_unitstandardid == e.tims_unitstandardid) == undefined
        })
    }



    isAssessorHasAccessToUnitStandard(unitStandardId: string) {

        let us = cacheAssessorUnitstandard.find(item => item.tims_unitstandardid == unitStandardId);
        if (us != undefined) {
            //console.log("Contact Unitstandard Cache Hit");
            return true;
        }

        return false;
    }

}