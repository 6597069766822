import * as React from "react";
import { Accordion } from "./Accordion";
import { GridControl } from "./GridControl";
import GlobalState from "../GlobalState";
import Loader from "react-loader-spinner";
import { Stack } from "@fluentui/react";
import CompulsarySectionHelper from "../ComponentHelpers/CompulsarySectionHelper";
import Result from "../ComponentHelpers/Result";
import { MasterControlContext, MasterControlContextWarnings } from "./MasterControl";
import { PreviewControlContext } from "./PreviewControl";
import Common from "../Common/Common";
import { SummaryObject } from "./ElectiveGroupControl";
import ProgrammeUnitStandard from "../ComponentHelpers/ProgrammeUnitStandard";
import { Header } from "./Header";

interface CompSecState {
    compSectionHelp: CompulsarySectionHelper,
    showLoader: boolean
}

export const compulsarySectionContext = React.createContext({});
export const CompulsarySection: React.FunctionComponent<{}> = () => {
    const globalState = React.useContext(GlobalState);

    const ref = React.useRef(true);
    const masterControlDispatcher = React.useContext(MasterControlContext);
    const previewControlDispatcher = React.useContext(PreviewControlContext);
    const masterControlWarningDispatcher = React.useContext(MasterControlContextWarnings);

    const compSecSectionReducer = (state: CompSecState, action: any) => {

        switch (action.type) {

            case "CREATE_RESULT":
                {
                    let row = state.compSectionHelp.rows.find(e => e.unitStandard?.tims_unitstandardid == action.obj.unitStandardId)
                    if(row){
                        row._resultHelper = new Result(action.obj.createdResult);
                        if (row.resultForm != undefined)
                        row.resultForm.result = row._resultHelper;   
                    }
                    return { compSectionHelp: state.compSectionHelp, showLoader: false } as CompSecState;
                }

            case "UPDATE_RESULT":
                {
                    let row = state.compSectionHelp.rows.find(e => e.unitStandard?.tims_unitstandardid == action.obj.unitStandardId)
                    if(row){
                        row._resultHelper = new Result(action.obj.updatedResult);
                        if (row.resultForm != undefined)
                        row.resultForm.result = row._resultHelper;
                    }
                    return { compSectionHelp: state.compSectionHelp, showLoader: false } as CompSecState;
                }
            case "CREATE_COMMENT":
                {
                    let row = state.compSectionHelp.rows.find(e => e.unitStandard?.tims_unitstandardid == action.obj.unitStandardId)
                    if(row) row.comment = action.obj.commentObj;

                    return { compSectionHelp: state.compSectionHelp, showLoader: false } as CompSecState;
                }

            case "UPDATE_RESOURCE":
                {
                    let row = state.compSectionHelp.rows.find(e => e.unitStandard?.tims_unitstandardid == action.obj.unitStandardId)
                    if(row){
                        if (row._trainingPlanUnitStandard != undefined)
                        row._trainingPlanUnitStandard._tpuModel.tims_resourceorderstatus = action.obj.status;
                    }

                    return { compSectionHelp: state.compSectionHelp, showLoader: false } as CompSecState;
                }

            case "INIT":
                return { compSectionHelp: action.obj.compSectionHelp, showLoader: false } as CompSecState;
            default:
                break;
        }

        return state;
    }

    const getDispatcher = () => {
        if (typeof masterControlDispatcher == "function")
            return masterControlDispatcher;
        else if (typeof previewControlDispatcher == "function")
            return previewControlDispatcher;
    }

    const getWarningsDispatcher = () => {
        if (typeof masterControlWarningDispatcher == "function")
            return masterControlWarningDispatcher;
        else
            return undefined;

    }
    React.useEffect(() => {
        const initializeDataLoad = async () => {

            var complusarySectionHelp = new CompulsarySectionHelper(globalState.programmeObj?.tims_programmeid as string, globalState);
            await complusarySectionHelp.build();

            if (!ref.current)
                return;
            dispatch({ type: "INIT", obj: { compSectionHelp: complusarySectionHelp, showLoader: false } as CompSecState });
            ref.current = false
        }
        initializeDataLoad();

        return () => {

        };

    }, []);

    const [compSecState, dispatch] = React.useReducer(compSecSectionReducer, { compSectionHelp: { rows: [] as ProgrammeUnitStandard[] }, showLoader: true } as CompSecState);

    React.useEffect(() => {
        let dispatcher = getWarningsDispatcher();
        let prerequisiteWarnings = [];
        let expiringThisYearWarnings = []
        if (compSecState.compSectionHelp.rows != null) {
            for (let index = 0; index < compSecState.compSectionHelp.rows.length; index++) {
                const element = compSecState.compSectionHelp.rows[index];
                if(element._trainingPlanUnitStandard?.isExpiringThisYear){
                    expiringThisYearWarnings.push(`Unit ${element.unitStandard?.tims_frameworkelementcode} is expiring this year.` )
                }
                if (element.isAchievedPendingPreReq) {
                    if (element.preReqUnitstandard != undefined)
                        //prerequisiteWarnings.push(`Prerequisites ${element.preReqUnitstandard.map(item => item.tims_frameworkelementcode).join(",")} exist for Unit Standard ${element.unitStandard?.tims_frameworkelementcode} result marked with Asterix. Learner will not be able to complete without meeting prerequisite Unit Standard requirements.`);
                        prerequisiteWarnings.push(`Unit ${element.unitStandard?.tims_frameworkelementcode} has prerequisites(s); ${element.preReqUnitstandard.map(item => item.tims_frameworkelementcode).join(",")}. The result is marked with an Asterix (*) and will not be fully achieved until all prerequisite units are completed. `);
                    // else
                    //     prerequisiteWarnings.push(`Prerequisites exist for Unit Standard ${element.unitStandard?.tims_frameworkelementcode} result marked with Asterix. Learner will not be able to complete without meeting prerequisite Unit Standard requirements.`);
                }
                else if (element.hasPrerequisites && !element.isAchieved && !element.isAchievedPendingPreReq) {
                    if (element.preReqUnitstandard != undefined)
                        //prerequisiteWarnings.push(`Prerequisites ${element.preReqUnitstandard.map(item => item.tims_frameworkelementcode).join(",")} exist for Programme US ID ` + element.unitStandard?.tims_frameworkelementcode);
                        prerequisiteWarnings.push(`Unit ${element.unitStandard?.tims_frameworkelementcode} has prerequisite(s); ${element.preReqUnitstandard.map(item => item.tims_frameworkelementcode).join(",")}`);
                    // else
                    //     prerequisiteWarnings.push("Prerequisites exist for Programme US ID " + element.unitStandard?.tims_frameworkelementcode);
                }

            }
            if (dispatcher != undefined)
                dispatcher({ type: "UPDATE_WARNINGS", obj: { warnings: prerequisiteWarnings, sectionID: "COMPULSARY_SECTION" } });
        }
    }, [compSecState.compSectionHelp.rows.length, compSecState.compSectionHelp.rows.filter(item => item.isAchievedPendingPreReq).length]);

    React.useEffect(() => {
        let dispatcher = getDispatcher();
        if (dispatcher != undefined && compSecState.compSectionHelp.selectedCredits != undefined)
            dispatcher({
                type: "INIT_SUMMARY", obj: {
                    selected: compSecState.compSectionHelp.selectedCredits,
                    achieved: compSecState.compSectionHelp.achievedCredits,
                    remaining: compSecState.compSectionHelp._remainingCredits, id: Common.newGuid(),
                    // Sending selected credits at level on programme rules
                    selectedMin:compSecState.compSectionHelp.getCreditCountByLevel(globalState.programmeObj?.tims_minimumlevel ?? 0),
                    selectedMax:compSecState.compSectionHelp.getCreditCountByLevel(globalState.programmeObj?.tims_maximumlevel ?? 0),
                } as SummaryObject
            });
    }, [compSecState.compSectionHelp.selectedCredits]);

    const getProgressBarValue = () => {
        let percentage = compSecState.compSectionHelp.achievedCredits / compSecState.compSectionHelp.selectedCredits;
        let isNan = Number.isNaN(percentage)
        if (isNan)
            return 0;
        return percentage;
    }

    const createHeader = () => {
        return (
            <Header
                showRules = {globalState.showSectionRules ?? true}
                name = "Compulsory section"
                isDisabled = {false}
                isCompliant = {true}
                progress = {
                    {
                        achieved:compSecState.compSectionHelp.achievedCredits,
                        remaining:compSecState.compSectionHelp._remainingCredits
                    }
                }
                credits={
                    {
                        minAllowed: undefined,
                        maxAllowed: undefined,
                        selected:compSecState.compSectionHelp.selectedCredits
                    }
                }

            >
            </Header>
      
        );
    }


    return (
        <Stack style={{ paddingBottom: "10px" }}>
            {compSecState.showLoader ?
                <Stack style={{ display: "block", marginLeft: "auto", marginRight: "auto", textAlign: "center" }}>
                    <Loader
                        type="TailSpin"
                        color="#00BFFF"
                        height={100}
                        width={100}
                        timeout={60000} //3 secs
                    />
                </Stack> : compSecState.compSectionHelp.programmeUnitStandards.length > 0 ?
                    <compulsarySectionContext.Provider value={dispatch}>
                        <Accordion HeaderElem={createHeader()} isSuperParentAccordion={false}>
                            <GridControl
                                rows={compSecState.compSectionHelp.programmeUnitStandards} key={globalState.programmeObj?.tims_programmeid} componentHelper={compSecState.compSectionHelp} template={compSecState.compSectionHelp.templateName(globalState.isEditMode as boolean)}
                            ></GridControl>
                        </Accordion>
                    </compulsarySectionContext.Provider> : <div></div>}
        </Stack>
    );

}
