import { Serializable } from './Serializable';
import TokenModel from "./TokenModel"
import { ContactRole } from "../Common/Enum";

const entityName = "appointments";
var cachedAssessor = {} as any;

export class AppointmentModel extends Serializable {

    constructor(tokenModel: TokenModel) {
        super(tokenModel);
    }

    async createAppointment(learnerName: string, learnerId: string, scheduledStart: Date, scheduledEnd: Date, trainingPlanOwnerId: string, appointmentDescription: string) {
        var entity = {
            "regardingobjectid_contact@odata.bind": "/contacts(" + learnerId + ")",
            "appointment_activity_parties": [
                {
                    "partyid_systemuser@odata.bind": "/systemusers(" + trainingPlanOwnerId + ")",
                    "participationtypemask": 7   ///For organizer
                }
            ],
            "scheduledstart": scheduledStart,
            "scheduledend": scheduledEnd,
            "subject": "Progress visit - " + learnerName,
            "ownerid@odata.bind": "/systemusers(" + trainingPlanOwnerId + ")",
            "description": appointmentDescription
        };

        let resp = await this.d365Helper.Create(entityName, entity, ["activityid"]);
        return resp;
    }

}