import { ContactModel } from './ContactModel';
import { Serializable } from './Serializable';
import TokenModel from "./TokenModel"

const entityName = "accounts";
export class AccountModel extends Serializable {
    name: string | undefined;
    _tims_primarycontactid_value: string | undefined;



    constructor(tokenModel: TokenModel) {
        super(tokenModel);
    }


    get contact() {
        let contact = new ContactModel(this._tokenModel);
        contact.contactid = this._tims_primarycontactid_value;
        for (const key in this) {
            if (Object.prototype.hasOwnProperty.call(this, key)) {
                if (key.includes("primarycontact.")) {
                    let propName = key.split(".")[1];
                    //@ts-ignore
                    contact[propName] = this[key];
                }

            }
        }
        return contact;
    }

    async retrieveAccount(accountId: string) {
        let fetchXml = `<fetch>
                        <entity name="account">
                            <attribute name="name" />
                            <filter>
                                <condition attribute="accountid" operator="eq" value="`+ accountId + `" />
                            </filter>
                        <link-entity name="contact" from="contactid" to="primarycontactid" link-type="outer" alias="primarycontact">
                            <attribute name="emailaddress1" />
                            <attribute name="fullname" />
                            <attribute name="contactid" />
                        </link-entity>
                </entity>
            </fetch>`;

        let account = await this.d365Helper.ExecuteFetch(entityName, fetchXml, false);
        return this.createAccModel(account);
    }

    createAccModel(accounts: any[]) {
        let accArr = [] as AccountModel[];
        if (accounts != undefined) {
            for (let index = 0; index < accounts.length; index++) {
                const element = accounts[index];
                let acc = new AccountModel(this._tokenModel);
                acc.fillFromJSON(JSON.stringify(element));
                accArr.push(acc);
            }
        }
        return accArr;
    }
}