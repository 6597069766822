import { Serializable } from './Serializable';
import TokenModel from "./TokenModel"

const entityName = "tims_electivesections"

export default class ElectiveSectionModel extends Serializable {
    /**
     *
     */
    constructor(tokenModel: TokenModel) {
        super(tokenModel);

    }

    statecode: number | undefined;
    statuscode: number | undefined;
    tims_allowonlyachievedunitstandards: number | undefined;
    _tims_electivegroupid_value: string | undefined;
    tims_electivesectionid: string | undefined;
    tims_level: number | undefined;
    tims_maximumcredits: number | undefined;
    tims_maximumitems: number | undefined;
    tims_maximumlevel: number | undefined;
    tims_minimumcredits: number | undefined;
    tims_minimumcreditsonlevel: number | undefined;
    tims_maximumcreditsonlevel: number | undefined
    tims_minimumitems: number | undefined;
    tims_name: string | undefined;
    _tims_programmeid_value: string | undefined;
    tims_ruledescription: string | undefined;
    tims_ruletype: number | undefined;
    tims_sortorder: number | undefined; 
    tims_listitemdefinition: string | undefined;


    async retrieveElectiveSectionsForProgramme(programmeId: string) {

        return await this.d365Helper.RetrieveMultipleByOrder(entityName, this.createColumnArray(this), "_tims_programmeid_value eq '" + programmeId + "' and _tims_electivegroupid_value eq null", "tims_sortorder asc");
    }

    async retrieveElectiveSectionsForGroup(electiveGroupId: string) {
        return await this.d365Helper.RetrieveMultipleByOrder(entityName, this.createColumnArray(this), "_tims_electivegroupid_value eq '" + electiveGroupId + "'", "tims_sortorder asc");
    }

}