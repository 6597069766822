import * as React from "react";
import { Accordion } from "./Accordion";
import GlobalState from "../GlobalState";
import Loader from "react-loader-spinner";
import { Stack, Stylesheet } from "@fluentui/react";
import { electiveSectionContext, ElectiveSectionControl } from "../Controls/ElectiveSectionControl";
import ElectiveGroupModel from "../Models/ElectiveGroupModel";
import ElectiveSectionModel from "../Models/ElectiveSectionModel";
import ComponentHelperBase from "../ComponentHelpers/ComponentHelperBase";
import ElectiveGroupHelper from "../ComponentHelpers/ElectiveGroupHelper";
import { Header } from "./Header";

export interface ElecGrpState {
    showLoader: boolean,
    selected: number,
    achieved: number,
    remaining: number
}

export interface ElectiveGroupCom {
    electiveGroupMdl: ElectiveGroupModel,
    compHelpers: ComponentHelperBase[],
    childElecGrp: ElectiveGroupCom[],
    summaryObj: SummaryObject,
    electiveGrpHelp: ElectiveGroupHelper
}

export interface SummaryObject {
    selected: number,
    selectedMin: number,
    selectedMax: number,
    achieved: number,
    remaining: number,
    selectedItems:number
    id: string
    
}


interface ElecGrpProp {
    elecGrpModel: ElectiveGroupCom,
    isTopLevel: boolean,
    summaryObj: SummaryObject
}

export const electiveGroupContext = React.createContext({});

export const ElectiveGroupControl: React.FunctionComponent<ElecGrpProp> = (props) => {
    //     static contextType = GlobalState;
    //     summaryObj: SummaryObject[];

    const electiveGroupReducer = (state: any, action: any) => {
        return { Test: "" };
    }


    const [summaryState, setSummaryState] = React.useState({} as SummaryObject);
    const globalContext = React.useContext(GlobalState);
    const [showLoader, setShowLoader] = React.useState(true);
    const [electiveGroupState, dispatch] = React.useReducer(electiveGroupReducer, { Test: "" });

    const isDisabled = (props.elecGrpModel.electiveGrpHelp.isDisabled && props.summaryObj.selectedItems == 0)

    React.useEffect(() => {
        setShowLoader(false);
    }, [props.summaryObj.selectedItems]);

    const isCompliant = (elecGrp: ElectiveGroupCom): boolean => {
        if(isDisabled) return true
        
        if (!elecGrp.electiveGrpHelp.isCompliant) return false;

        let isChildElecCompliant = elecGrp.compHelpers.every((val, index, arr) => { return val.isCompliant; });
        if (isChildElecCompliant)
            return elecGrp.childElecGrp.every((val, index, arr) => { return isCompliant(val); });
        else
            return false;
    }

    const createHeader = () => {
        const electiveGroupModel = props.elecGrpModel.electiveGroupMdl;
        const isParent = props.elecGrpModel.electiveGrpHelp.isParent

        let minSections = isParent ? props.elecGrpModel.electiveGroupMdl.tims_minimumelectivegroups : electiveGroupModel.tims_minimumelectivesections
        let maxSections = isParent ? electiveGroupModel.tims_maximumelectivegroups : electiveGroupModel.tims_maximumelectivesections


        return (  
            <Header
            showRules = {globalContext.showSectionRules ?? true}
            name = {props.elecGrpModel.electiveGroupMdl.tims_name ?? ''} 
            isCompliant = {isCompliant(props.elecGrpModel)}
            isDisabled = {isDisabled}
            progress = {
                {
                    achieved: props.summaryObj.achieved, 
                    remaining: props.summaryObj.remaining
                }
            }
            credits = {
                {
                    minAllowed: props.elecGrpModel.electiveGroupMdl.tims_minimumcredits, 
                    maxAllowed:props.elecGrpModel.electiveGroupMdl.tims_maximumcredits, 
                    selected:props.summaryObj.selected
                }
            }
            sections = {
                {
                    isParent: props.elecGrpModel.electiveGrpHelp.isParent,
                    selected: props.summaryObj.selectedItems,
                    minAllowed: minSections,
                    maxAllowed: maxSections
                }
            }
            levels={
                {
                    minAllowed:props.elecGrpModel.electiveGroupMdl.tims_minimumcreditsonlevel,
                    maxAllowed:props.elecGrpModel.electiveGroupMdl.tims_maximumcreditsonlevel,
                    minAllowedLevel:props.elecGrpModel.electiveGroupMdl.tims_level,
                    maxAllowedLevel:props.elecGrpModel.electiveGroupMdl.tims_maximumlevel,
                    selectedAtMinLevel:props.elecGrpModel.electiveGrpHelp.selectedMinCredits,
                    selectedAtMaxLevel:props.elecGrpModel.electiveGrpHelp.selectedMaxCredits
                }
            }

            >
            </Header> 
        );
    }

    return (
        <Stack style={{ paddingBottom: "10px" }}>
            {showLoader ?
                <Stack style={{ display: "block", marginLeft: "auto", marginRight: "auto", textAlign: "center" }}>
                    <Loader
                        type="TailSpin"
                        color="#00BFFF"
                        height={100}
                        width={100}
                        timeout={10000} //3 secs 
                    />
                </Stack> :
                <electiveSectionContext.Provider value={dispatch}>
                    <Accordion HeaderElem={createHeader()} isSuperParentAccordion={false}>
                        {
                            props.elecGrpModel.compHelpers.map((data, index) => (
                                <ElectiveSectionControl key={data._electiveSectionM?.tims_electivesectionid} electiveSection={data._electiveSectionM as ElectiveSectionModel} isUnderElectiveGroup={true} electiveSecHelp={data} ></ElectiveSectionControl>
                            ))
                        }
                        {
                            props.elecGrpModel.childElecGrp.map((elecGrp, index) => (
                                <ElectiveGroupControl key={elecGrp.electiveGroupMdl.tims_electivegroupid} elecGrpModel={elecGrp} isTopLevel={false} summaryObj={elecGrp.summaryObj}></ElectiveGroupControl>
                            ))
                        }
                    </Accordion>
                </electiveSectionContext.Provider>}
        </Stack>
    );

}
