import * as React from "react";
import TokenModel from "../Models/TokenModel";
import GlobalState from "../GlobalState";
import Loader from "react-loader-spinner";
import { Stack, elementContains } from "@fluentui/react";
import ElectiveGroupModel from "../Models/ElectiveGroupModel";
import { ProgrammeModel } from '../Models/ProgrammeModel';
import { ElectiveGroupControl } from "../Controls/ElectiveGroupControl";
import { ElectiveGroupCom, SummaryObject } from "../Controls/ElectiveGroupControl";
import ElectiveSectionModel from "../Models/ElectiveSectionModel";
import ComponentHelperBase, { IUSCheckedEventArg } from "../ComponentHelpers/ComponentHelperBase";
import ProgramElectiveSectionHelper from "../ComponentHelpers/ProgramElectiveSectionHelper";
import CrossCreditElectiveSectionHelper from "../ComponentHelpers/CrossCreditElectiveSectionHelper";
import Emitter from "../Common/EventEmitter";
import { Events } from "../Common/Enum";
import Result from "../ComponentHelpers/Result";
import { PostSaveResponse } from "../Common/TrainingPlanUnitStandardManager";
import TrainingPlanUnitStandard from "../ComponentHelpers/TrainingPlanUnitStandard";
import "../assets/css/PreviewControl.css";
import CrossCreditProgramUnitStandardHelper from "../ComponentHelpers/CrossCreditProgramUnitStandardHelper";
import GlobalStateModel from "../Models/GlobalStateModel";
import ElectiveGroupHelper from "../ComponentHelpers/ElectiveGroupHelper";


interface ElecGrpState {
    electiveGroups: ElectiveGroupModel[]
    elecGrpsCom: ElectiveGroupCom[]
}

export const electiveGroupSectionContext = React.createContext({});


export const ElectiveGroupSection: React.FunctionComponent<{}> = () => {
    const globalContext = React.useContext(GlobalState);
    const [showLoader, setShowLoader] = React.useState(true);
    const ref = React.useRef(true);
    var masterPromArr: any[];

    const findChangedRow = (elecGrpsCom: ElectiveGroupCom[], action: any): boolean => {

        let isSelectedOtherSection = false;
        for (let index = 0; index < elecGrpsCom.length; index++) {
            const item = elecGrpsCom[index];

            let elecSec = item.compHelpers.find((innerItem) => { return innerItem._electiveSectionM?.tims_electivesectionid == action.obj.electiveSectionId });
            if (elecSec != undefined) {
                let elecSecRow = elecSec.rows.find((innerItem) => {
                    return innerItem.unitStandard?.tims_unitstandardid == action.obj.unitstandardId;
                });
                if (elecSecRow != undefined) {
                    elecSecRow.onCheckboxChanged(action.obj.checked);
                    isSelectedOtherSection = elecSecRow.isSelectedOtherSection;
                    return isSelectedOtherSection;
                }
            }
            if (item.childElecGrp.length > 0)
                isSelectedOtherSection =  findChangedRow(item.childElecGrp, action);
        }
        return isSelectedOtherSection;
    }

    const refreshOtherRows = (elecGrpsCom: ElectiveGroupCom[], action: any) => {
        for (let index = 0; index < elecGrpsCom.length; index++) {
            const item = elecGrpsCom[index];
            let elecSec = item.compHelpers.find((innerItem) => {
                return innerItem.rows.find((row) => { return row.unitStandard?.tims_frameworkelementcode == action.obj.frameworkElementCode && !row.isSelectedOtherSection }) && innerItem._electiveSectionM?.tims_electivesectionid != action.obj.electiveSectionId;
            });
            if (elecSec != undefined) {
                {
                    let rowToChange = elecSec.rows.find((row) => { return row.unitStandard?.tims_frameworkelementcode == action.obj.frameworkElementCode && !row.isSelectedOtherSection });
                    if (action.obj.checked)
                        rowToChange?.deselectFrameworkElementCode({ electiveSectionId: action.obj.electiveSectionId, frameworkElementCode: action.obj.frameworkElementCode } as IUSCheckedEventArg);
                    else
                        rowToChange?.selectFrameworkElementCode({ electiveSectionId: action.obj.electiveSectionId, frameworkElementCode: action.obj.frameworkElementCode } as IUSCheckedEventArg);

                    break;
                }
            } else
                refreshOtherRows(item.childElecGrp, action);
        }
    }

    const setDirtyAndCompliantInContext = (isDirty: Boolean, isCompliant: boolean) => {
        let globalContextTmp = {
            ...globalContext,
            isCompliant: { ...globalContext.isCompliant, isElecGrpCompliant: isCompliant },
            isDirty: {
                isElecGrpSectionDirty: isDirty, isPrgrmElecSec: globalContext.isDirty?.isPrgrmElecSec
            }
        } as GlobalStateModel;
        globalContext.setGlobalState(globalContextTmp);
    }

    const checkForDirty = (elecGrpsCom: ElectiveGroupCom[]) => {
        let isDirty = false
        for (let index = 0; index < elecGrpsCom.length; index++) {
            const element = elecGrpsCom[index];
            for (let innerIndex = 0; innerIndex < element.compHelpers.length; innerIndex++) {
                const innerElement = element.compHelpers[innerIndex];
                if (innerElement.rows.some(item => item.isDirty)) isDirty = true
            }
            if(checkForDirty(element.childElecGrp)) isDirty = true
        }
        return isDirty;
    }

    const checkForCompliant = (elecGrpsCom: ElectiveGroupCom[]) => {
        let isCompliant = true
        for (let index = 0; index < elecGrpsCom.length; index++) {
            const element = elecGrpsCom[index];
            if (!element.electiveGrpHelp.isCompliant) {
                isCompliant = false;  // Check if child group is compliant
            }

            for (let innerIndex = 0; innerIndex < element.compHelpers.length; innerIndex++) {
                const innerElement = element.compHelpers[innerIndex];
                if (!innerElement.isCompliant) isCompliant = false // check if child elective section is compliant
            }
            if(element.childElecGrp.length > 0){
                if(!checkForCompliant(element.childElecGrp)) isCompliant = false; 
            }
        }
        
        return isCompliant;
    }

  


    React.useEffect(() => {
        const initializeDataLoad = async () => {
            masterPromArr = [];
            let tokenModel = globalContext.tokenModel as TokenModel;
            var electiveGroupModel = new ElectiveGroupModel(tokenModel);
            let programmeId = (globalContext.programmeObj as ProgrammeModel).tims_programmeid as string;
            let electiveGroups = await electiveGroupModel.retrieveParentElectiveGroups(programmeId) as ElectiveGroupModel[];
            let electiveGroupHelper;

            let elecGrpComMdsl = [] as ElectiveGroupCom[];
            let emptyObject = { achieved: 0, remaining: 0, selected: 0, id: "" } as SummaryObject;
            for (let index = 0; index < electiveGroups.length; index++) {
                let elecGrpModel = electiveGroups[index];
                let childElectiveGroups = await electiveGroupModel.retrieveChildElectiveGroups(elecGrpModel.tims_electivegroupid as string) as ElectiveGroupModel[];
                let componentHelpers = await getElectiveSectionHelpers(elecGrpModel);
                let childElectiveGroupsCom = [] as ElectiveGroupCom[];
                let childElectiveGroupHelpers = []
                for (let index = 0; index < childElectiveGroups.length; index++) {
                    let nestedElecGrp = childElectiveGroups[index];
                    let nestedHelpers = await getElectiveSectionHelpers(nestedElecGrp);
                    electiveGroupHelper = new ElectiveGroupHelper(globalContext, nestedElecGrp, [], nestedHelpers)
                    let elecGrpCom = { childElectiveGroupHelpers: [], electiveGrpHelp: electiveGroupHelper, compHelpers: nestedHelpers, electiveGroupMdl: nestedElecGrp, childElecGrp: [], summaryObj: emptyObject } as ElectiveGroupCom;
                    childElectiveGroupsCom.push(elecGrpCom)
                    childElectiveGroupHelpers.push(electiveGroupHelper)
                }
                electiveGroupHelper = new ElectiveGroupHelper(globalContext, elecGrpModel, childElectiveGroupHelpers, componentHelpers)
                let elecGrpComm = { electiveGrpHelp: electiveGroupHelper, electiveGroupMdl: elecGrpModel, compHelpers: componentHelpers, childElecGrp: childElectiveGroupsCom, summaryObj: emptyObject };
                elecGrpComMdsl.push(elecGrpComm);
            }

            await Promise.all(masterPromArr);
            if (!ref.current)
                return;
            calculateSummaryInBulk(elecGrpComMdsl);
            disableAllSectionsIfRequired(elecGrpComMdsl, false)
            dispatch({ type: "INIT", obj: { electiveGroups: electiveGroups, elecGrpsCom: elecGrpComMdsl } as ElecGrpState });

            Emitter.on(Events.UnitStandardSelectionChangedInPrgElecSec, (arg: IUSCheckedEventArg) => {
                dispatch({ type: "CHECKBOX_CHANGED_PRG_ELEC", obj: arg });
            });

            Emitter.on(Events.SaveTrainingPlan, () => {
                dispatch({ type: "SAVE_TP" });
            });

            Emitter.on(Events.POSTSaveTrainingPlan, (arg: PostSaveResponse[]) => {
                dispatch({ type: "POST_SAVE", obj: arg });
            });


            setShowLoader(false);
            ref.current = false;
        }

        initializeDataLoad();
        return () => {
            Emitter.off(Events.UnitStandardSelectionChangedInPrgElecSec, () => { });
            Emitter.off(Events.SaveTrainingPlan, () => { });
            Emitter.off(Events.POSTSaveTrainingPlan, () => { });
        };
    }, []);

    const calculateSummaryInBulk = (elecGrpComs: ElectiveGroupCom[]) => {
        elecGrpComs.forEach((item) => { item.summaryObj = calculateSummary(item); calculateSummaryInBulk(item.childElecGrp); });
    }

    const disableAllSectionsIfRequired = (elecGrpComs: ElectiveGroupCom[], isParentDisabled: boolean) => {
        elecGrpComs.forEach(elecGrp => { 
            disableChildSections(elecGrp)
        });
    }

    const disableChildSections = (elecGrp: ElectiveGroupCom) => {  

        let isDisabled = elecGrp.electiveGrpHelp.isDisabled || elecGrp.electiveGrpHelp.isSectionLimitReached 
        for (const value of elecGrp.compHelpers) if(!value.isSelected) value.setDisabled(isDisabled) 

        for (const value of elecGrp.childElecGrp){
            if(!value.electiveGrpHelp.isSelected) value.electiveGrpHelp.setDisabled(isDisabled)
            disableChildSections(value) 
        }
    }


    const getElectiveSectionHelpers = async (elecSecGrp: ElectiveGroupModel) => {

        let tokenModel = globalContext.tokenModel as TokenModel;
        let elecSecModel = new ElectiveSectionModel(tokenModel);
        let electiveSectionsForGroup = await elecSecModel.retrieveElectiveSectionsForGroup(elecSecGrp.tims_electivegroupid as string) as ElectiveSectionModel[];

        let componentHelpers = [] as ComponentHelperBase[];
        for (let index = 0; index < electiveSectionsForGroup.length; index++) {
            const electiveSection = electiveSectionsForGroup[index];

            if (electiveSection.tims_ruletype != 3) {
                //ProgramElectiveSection
                let programElectiveSectionHelper = new ProgramElectiveSectionHelper(globalContext, electiveSection);
                programElectiveSectionHelper.setEditMode(globalContext.isEditMode as boolean);
                masterPromArr.push(programElectiveSectionHelper.build());
                componentHelpers.push(programElectiveSectionHelper);

            } else {
                //CrossCreditElectiveSection
                let crossCreditElectiveSectionHelper = new CrossCreditElectiveSectionHelper(globalContext, electiveSection);
                crossCreditElectiveSectionHelper.setEditMode(globalContext.isEditMode as boolean);

                if (!globalContext.isPreviewModeOnly)
                    masterPromArr.push(crossCreditElectiveSectionHelper.build());
                else
                    masterPromArr.push(crossCreditElectiveSectionHelper.previewBuild());

                componentHelpers.push(crossCreditElectiveSectionHelper);
            }
        }

        return componentHelpers;
    }


    const calculateSummary = (elecGrpCom: ElectiveGroupCom) => {
        
        let selected = elecGrpCom.compHelpers.reduce((prev, currObj) => prev + currObj.selectedCredits, 0);
        let achieved = elecGrpCom.compHelpers.reduce((prev, currObj) => prev + currObj.achievedCredits, 0);
        let remaining = elecGrpCom.compHelpers.reduce((prev, currObj) => prev + currObj._remainingCredits, 0);
        // Counting elective sections that have more than 1 selected programme unit standard
        let selectedItems = elecGrpCom.compHelpers.reduce((prev, currentObj) => (currentObj.isSelected ? prev + 1: prev), 0)

        for (let index = 0; index < elecGrpCom.childElecGrp.length; index++) {
            let elecGrpComSing = elecGrpCom.childElecGrp[index];
            let summaryObj = calculateSummary(elecGrpComSing);
            selected += summaryObj.selected;
            achieved += summaryObj.achieved;
            remaining += summaryObj.remaining;
            // If child elective group has at least one elective section selected, we can mark it as "selected"
            if(summaryObj.selectedItems >= 1) selectedItems += 1 
        }        
        return { selected: selected, achieved: achieved, remaining: remaining, selectedItems:selectedItems } as SummaryObject;

    }

    const electiveGroupSectionReducer = (state: ElecGrpState, action: any) => {
        switch (action.type) {
            case "CHECKBOX_CHANGED":
                let isSelectedInOtherSection = findChangedRow(state.elecGrpsCom, action);

                if (isSelectedInOtherSection) {
                    refreshOtherRows(state.elecGrpsCom, action);
                    setTimeout(() => { Emitter.emit(Events.UnitStandardSelectionChangedInElecGrps, action.obj); }, 100);

                }
                calculateSummaryInBulk(state.elecGrpsCom);
                disableAllSectionsIfRequired(state.elecGrpsCom, false)
                
                let isDirty = checkForDirty(state.elecGrpsCom);
                let isCompliant = checkForCompliant(state.elecGrpsCom);
                if (globalContext.isDirty?.isElecGrpSectionDirty != isDirty || globalContext.isCompliant?.isElecGrpCompliant != isCompliant)
                    setDirtyAndCompliantInContext(isDirty, isCompliant);

                return { elecGrpsCom: state.elecGrpsCom, electiveGroups: state.electiveGroups } as ElecGrpState;

            case "ROW_ADDED":
                let elecSecAdd = findElecSec(action.obj.electiveSectionId, state.elecGrpsCom);
                if (elecSecAdd != undefined) {

                    //Get the result
                    const { newCrossCreditUSHelper, electiveSectionId, newUnit } = action.obj;
                    let newCrossCreditUSHelperRow = newCrossCreditUSHelper as CrossCreditProgramUnitStandardHelper;
                    //All this state is impure so this is a hack to fix the double invokation 
                    //We check it the TPUS has already been pushed onto the array.
                    const found = elecSecAdd.rows.find(elm => elm._trainingPlanUnitStandard?._tpuModel.tims_trainingplanunitstandardid === newUnit.tims_trainingplanunitstandardid)
                    if (!found) {
                        elecSecAdd.rows.push(newCrossCreditUSHelperRow);
                    }
                    return { elecGrpsCom: state.elecGrpsCom, electiveGroups: state.electiveGroups } as ElecGrpState;
                }

                break;
            case "ROW_REMOVED":
                var newState = { ...state };
                let elecSecRemove = findElecSec(action.obj.electiveSectionId, newState.elecGrpsCom);
                if (elecSecRemove != undefined) {

                    const { tpusId, electiveSectionId } = action.obj;

                    var found = elecSecRemove.rows.find(elm => elm._trainingPlanUnitStandard?._tpuModel.tims_trainingplanunitstandardid === tpusId);
                    if (found) {
                        elecSecRemove.rows.splice(elecSecRemove.rows.indexOf(found), 1);
                    }

                    return newState as ElecGrpState;
                }

                break;
            case "CREATE_RESULT":
                let elecSec = findElecSec(action.obj.electiveSectionId, state.elecGrpsCom);
                if (elecSec != undefined) {
                    let row = elecSec.rows.find(e => e.unitStandard?.tims_unitstandardid == action.obj.unitStandardId)
                    if(row){
                        row._resultHelper = new Result(action.obj.createdResult);
                        if (row.resultForm != undefined)
                        row.resultForm.result = row._resultHelper;
                    }
                }
                return { elecGrpsCom: state.elecGrpsCom, electiveGroups: state.electiveGroups } as ElecGrpState;
            case "UPDATE_RESULT":
                {
                    let elecSec = findElecSec(action.obj.electiveSectionId, state.elecGrpsCom);
                    if (elecSec != undefined) {
                        let row = elecSec.rows.find(e => e.unitStandard?.tims_unitstandardid == action.obj.unitStandardId)
                        if(row) {
                            row._resultHelper = new Result(action.obj.updatedResult)
                            if (row.resultForm != undefined)
                            row.resultForm.result = row._resultHelper;
                        }
                    }
                    return { elecGrpsCom: state.elecGrpsCom, electiveGroups: state.electiveGroups } as ElecGrpState;
                }
            case "CREATE_COMMENT":
                let elecSecCmmnt = findElecSec(action.obj.electiveSectionId, state.elecGrpsCom);
                if (elecSecCmmnt != undefined) {
                    let row = elecSecCmmnt.rows.find(e => e.unitStandard?.tims_unitstandardid == action.obj.unitStandardId)
                    if(row) row.comment = action.obj.commentObj;
                }
                return { elecGrpsCom: state.elecGrpsCom, electiveGroups: state.electiveGroups } as ElecGrpState;
            case "PICKALIST_SELECTION_CHANGED":
                {
                    
                    pickAListSelecttionChanged(state.elecGrpsCom, action);

                    calculateSummaryInBulk(state.elecGrpsCom)
                    disableAllSectionsIfRequired(state.elecGrpsCom, false)
                    
                    let isDirty = checkForDirty(state.elecGrpsCom);
                    let isCompliant = checkForCompliant(state.elecGrpsCom);
                    if (globalContext.isDirty?.isElecGrpSectionDirty != isDirty || globalContext.isCompliant?.isElecGrpCompliant != isCompliant)
                        setDirtyAndCompliantInContext(isDirty, isCompliant);

                    return { elecGrpsCom: state.elecGrpsCom, electiveGroups: state.electiveGroups } as ElecGrpState;
                }
            case "UPDATE_RESOURCE":
                let elecSecRes = findElecSec(action.obj.electiveSectionId, state.elecGrpsCom);
                if (elecSecRes != undefined) {
                    let row = elecSecRes.rows.find(e => e.unitStandard?.tims_unitstandardid == action.obj.unitStandardId)
                    if(row){
                        if (row._trainingPlanUnitStandard != undefined)
                        row._trainingPlanUnitStandard._tpuModel.tims_resourceorderstatus = action.obj.status;
                    }
                }
                return { elecGrpsCom: state.elecGrpsCom, electiveGroups: state.electiveGroups } as ElecGrpState;

            case "CHECKBOX_CHANGED_PRG_ELEC"://When unitstandard is checked/unchecked in programelectivesection
                refreshOtherRows(state.elecGrpsCom, action);
                calculateSummaryInBulk(state.elecGrpsCom);
                return { elecGrpsCom: state.elecGrpsCom, electiveGroups: state.electiveGroups } as ElecGrpState;

            case "POST_SAVE":
                let postSaveResp = action.obj as PostSaveResponse[];
                postSaveResponse(state.elecGrpsCom, postSaveResp);
                return { elecGrpsCom: state.elecGrpsCom, electiveGroups: state.electiveGroups } as ElecGrpState;
            case "SAVE_TP":
                preSave(state.elecGrpsCom);
                return state;
            case "INIT":
                let initIsDirty = checkForDirty(action.obj.elecGrpsCom);
                let initIsCompliant = checkForCompliant(action.obj.elecGrpsCom);
                if (globalContext.isDirty?.isElecGrpSectionDirty != initIsDirty || globalContext.isCompliant?.isElecGrpCompliant != initIsCompliant)
                    setDirtyAndCompliantInContext(initIsDirty, initIsCompliant);
    
                return { elecGrpsCom: action.obj.elecGrpsCom, electiveGroups: action.obj.electiveGroups } as ElecGrpState;
            default:
                break;
        }
        return state;
    }



    const preSave = (elecGrpsCom: ElectiveGroupCom[]) => {
        for (let index = 0; index < elecGrpsCom.length; index++) {
            const element = elecGrpsCom[index];
            for (let innerIndex = 0; innerIndex < element.compHelpers.length; innerIndex++) {
                const innerElement = element.compHelpers[innerIndex];
                innerElement.rows.forEach(val => val.sync());
            }
            preSave(element.childElecGrp);
        }

    }

    const postSaveResponse = (elecGrpsCom: ElectiveGroupCom[], response: PostSaveResponse[]) => {

        for (let index = 0; index < elecGrpsCom.length; index++) {
            const element = elecGrpsCom[index];
            for (let innerIndex = 0; innerIndex < element.compHelpers.length; innerIndex++) {
                const innerElement = element.compHelpers[innerIndex];

                let rowsToUnselect = innerElement.rows.filter(item => item.isDirty && !item.isChecked);
                rowsToUnselect.forEach(item => item._trainingPlanUnitStandard = undefined);


                let respForElecSec = response.filter(resp => resp.electivesectionid == innerElement._electiveSectionM?.tims_electivesectionid);
                for (let index = 0; index < respForElecSec.length; index++) {
                    const element = respForElecSec[index];
                    let row = innerElement.rows.find(row => row.unitStandard?.tims_unitstandardid == element?.unitstandardid && row.isDirty && row.isChecked);
                    if (row != undefined) {
                        row._trainingPlanUnitStandard = new TrainingPlanUnitStandard(element.trainingPlanUnitStandard)
                    }
                }
            }
            postSaveResponse(element.childElecGrp, response);
        }
    }

    const findElecSec = (electiveSectionID: string, elecGrpsCom: ElectiveGroupCom[]): ComponentHelperBase | undefined => {
        let elecSec;
        for (let index = 0; index < elecGrpsCom.length; index++) {
            const item = elecGrpsCom[index];
            elecSec = item.compHelpers.find(innerItem => innerItem._electiveSectionM?.tims_electivesectionid == electiveSectionID);
            if (elecSec != undefined){
                return elecSec;
            }
            else
                elecSec = findElecSec(electiveSectionID, item.childElecGrp);
        }
        return elecSec
    }

    const pickAListSelecttionChanged = (elecGrpsCom: ElectiveGroupCom[], action: any) => {

        for (let index = 0; index < elecGrpsCom.length; index++) {
            const item = elecGrpsCom[index];
            let elecSec = item.compHelpers.find(innerItem => innerItem._electiveSectionM?.tims_electivesectionid == action.obj.electiveSectionId);
            if (elecSec != undefined) {
                if (elecSec instanceof ProgramElectiveSectionHelper) {
                    let prgElecSecHelper = elecSec as ProgramElectiveSectionHelper;
                    prgElecSecHelper.selectList(action.obj.value);
                }
            } else
                pickAListSelecttionChanged(item.childElecGrp, action);
        }

    }

    const [electiveGroupSecState, dispatch] = React.useReducer(electiveGroupSectionReducer, { elecGrpsCom: [], electiveGroups: [] } as ElecGrpState);

    return (
        <Stack>
            {showLoader ?
                <Stack style={{ display: "block", marginLeft: "auto", marginRight: "auto", textAlign: "center" }}>
                    <Loader
                        type="TailSpin"
                        color="#00BFFF"
                        height={100}
                        width={100}
                        timeout={60000} //3 secs 
                    />
                </Stack> :
                <electiveGroupSectionContext.Provider value={dispatch}>
                    {electiveGroupSecState.elecGrpsCom.map((data: any, index: any) => (
                        <ElectiveGroupControl key={data.electiveGroupMdl.tims_electivegroupid + 'TEST'} elecGrpModel={data} summaryObj={data.summaryObj} isTopLevel={true}></ElectiveGroupControl>
                    ))}
                </electiveGroupSectionContext.Provider>
            }   
        </Stack>
    );
}
