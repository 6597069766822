import * as React from "react";

export const DragHandleCell = ({ className, style, id, title, onClick }: any) => {
  return (
    <td className={className} style={style} id={id} title={title} onClick={onClick}>
      <span
        className="k-icon k-i-reorder"
        style={{ cursor: "move" }}
        data-drag-handle={true}
      />
    </td>
  );
};