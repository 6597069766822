import * as React from "react";
import GlobalState from "../GlobalState";
import { Accordion } from "./Accordion";
import { Stack } from "@fluentui/react";
import { GridControl } from "./GridControl";
import Loader from "react-loader-spinner";
import { ProgressIndicator } from "@fluentui/react/lib/ProgressIndicator";
import ElectiveSectionModel from "../Models/ElectiveSectionModel";
import ComponentHelperBase from "../ComponentHelpers/ComponentHelperBase";
import Common from "../Common/Common";
import check from "../assets/images/check.png";
import cross from "../assets/images/cross.png";
import disabled from "../assets/images/disabled.svg"
import { IMessagebarObjectWarning, MasterControlContext, MasterControlContextWarnings } from "./MasterControl";
import { SummaryObject } from "./ElectiveGroupControl";
import { PreviewControlContext } from "./PreviewControl";
import { Col, Container, Row, Table } from "react-bootstrap";
import "../assets/css/ElectiveSectionControl.css";
import Emitter from "../Common/EventEmitter";
import { Events } from '../Common/Enum';
import ProgramElectiveSectionHelper from "../ComponentHelpers/ProgramElectiveSectionHelper";
import { Header } from "./Header";

interface ElecSecProp {
    electiveSection: ElectiveSectionModel,
    electiveSecHelp: ComponentHelperBase,
    isUnderElectiveGroup: boolean
}

export const electiveSectionContext = React.createContext({});

export const ElectiveSectionControl: React.FunctionComponent<ElecSecProp> = (props) => {
    const ref = React.useRef(true);


    const electiveSectionReducer = (state: any, action: any) => {
        return { Test: "" };
    }

    const usePrevious = (value: any) => {
        const ref = React.useRef();
        React.useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const [showLoader, setShowLoader] = React.useState(true);
    const globalContext = React.useContext(GlobalState);
    const [electiveSectionState, dispatch] = React.useReducer(electiveSectionReducer, { Test: "" });
    const masterControlDispatcher = React.useContext(MasterControlContext);
    const previewControlDispatcher = React.useContext(PreviewControlContext);
    const masterControlWarningDispatcher = React.useContext(MasterControlContextWarnings);
    const previousSummary = usePrevious( { 
        selectedMin: props.electiveSecHelp.getCreditCountByLevel(globalContext.programmeObj?.tims_minimumlevel ?? 0), 
        selectedMax: props.electiveSecHelp.getCreditCountByLevel(globalContext.programmeObj?.tims_maximumlevel ?? 0), 
        selected: props.electiveSecHelp.selectedCredits, 
        achieved: props.electiveSecHelp.achievedCredits, 
        remaining: props.electiveSecHelp._remainingCredits 
    } as SummaryObject);
    

    React.useEffect(() => {
        if(!ref.current) return;
        setShowLoader(false);
        let dispatcher = getDispatcher();
        if (dispatcher != undefined)
            dispatcher({
                type: "INIT_SUMMARY", obj: {  
                    selected: props.electiveSecHelp.selectedCredits, 
                    achieved: props.electiveSecHelp.achievedCredits, 
                    remaining: props.electiveSecHelp._remainingCredits, id: Common.newGuid(),
                    
                    // Sending selected credits at level on programme rules
                    selectedMin: props.electiveSecHelp.getCreditCountByLevel(globalContext.programmeObj?.tims_minimumlevel ?? 0), 
                    selectedMax: props.electiveSecHelp.getCreditCountByLevel(globalContext.programmeObj?.tims_maximumlevel ?? 0),
                } as SummaryObject
            });
            ref.current = false

    }, []);

    const getDispatcher = () => {

        if (typeof masterControlDispatcher == "function")
            return masterControlDispatcher;
        else if (typeof previewControlDispatcher == "function")
            return previewControlDispatcher;

    }

    const getWarningsDispatcher = () => {
        if (typeof masterControlWarningDispatcher == "function")
            return masterControlWarningDispatcher;
        else
            return undefined;

    }

    const getStatusImage = (electiveSecHelp: ProgramElectiveSectionHelper) => { 
        let complianceImage = electiveSecHelp.isCompliant ? check : cross 
        return electiveSecHelp.isDisabled ? disabled : complianceImage
    }


    React.useEffect(() => {
        let dispatcher = getWarningsDispatcher();
        let pus = props.electiveSecHelp.rows.filter(item => item.hasPrerequisites || item.isAchievedPendingPreReq);
        let prerequisiteWarnings = [];
        for (let index = 0; index < pus.length; index++) {
            let element = pus[index];

            if (element.isAchievedPendingPreReq) {
                if (element.preReqUnitstandard != undefined)
                    //prerequisiteWarnings.push(`Prerequisites ${element.preReqUnitstandard.map(item => item.tims_frameworkelementcode).join(",")} exist for Unit Standard ${element.unitStandard?.tims_frameworkelementcode} result marked with Asterix. Learner will not be able to complete without meeting prerequisite Unit Standard requirements.`);
                    prerequisiteWarnings.push(`Unit ${element.unitStandard?.tims_frameworkelementcode} has prerequisites(s); ${element.preReqUnitstandard.map(item => item.tims_frameworkelementcode).join(",")}. The result is marked with an Asterix (*) and will not be fully achieved until all prerequisite units are completed. `);
                //else
                //  prerequisiteWarnings.push(`Prerequisites exist for Unit Standard ${element.unitStandard?.tims_frameworkelementcode} result marked with Asterix. Learner will not be able to complete without meeting prerequisite Unit Standard requirements.`);
            }
            else if (element.isSelected && element.hasPrerequisites && !element.isAchieved && !element.isAchievedPendingPreReq) {
                if (element.preReqUnitstandard != undefined)
                    //prerequisiteWarnings.push(`Prerequisites ${element.preReqUnitstandard.map(item => item.tims_frameworkelementcode).join(",")} exist for Programme US ID ` + element.unitStandard?.tims_frameworkelementcode);
                    prerequisiteWarnings.push(`Unit ${element.unitStandard?.tims_frameworkelementcode} has prerequisite(s); ${element.preReqUnitstandard.map(item => item.tims_frameworkelementcode).join(",")}`);
                //else
                // prerequisiteWarnings.push(`Prerequisites exist for Programme US ID ` + element.unitStandard?.tims_frameworkelementcode);
            }
        }
        if (dispatcher != undefined)
            dispatcher({ type: "UPDATE_WARNINGS", obj: { warnings: prerequisiteWarnings, sectionID: props.electiveSection.tims_electivesectionid } });

    }, [props.electiveSecHelp.rows.filter(item => item.isSelected).length,
    props.electiveSecHelp.rows.filter(item => item.isAchievedPendingPreReq).length]);


    React.useEffect(() => {
        if (previousSummary != undefined) {
            let previousSummaryObj = previousSummary as unknown as SummaryObject
            let newSelectedCredits = props.electiveSecHelp.selectedCredits - previousSummaryObj.selected;
            let newAcheivedCredits = props.electiveSecHelp.achievedCredits - previousSummaryObj.achieved;
            let newRemainingCredits = props.electiveSecHelp._remainingCredits - previousSummaryObj.remaining;
            let newMinSelectedCredits = props.electiveSecHelp.getCreditCountByLevel(globalContext.programmeObj?.tims_minimumlevel ?? 0) - previousSummaryObj.selectedMin
            let newMaxSelectedCredits = props.electiveSecHelp.getCreditCountByLevel(globalContext.programmeObj?.tims_maximumlevel ?? 0) - previousSummaryObj.selectedMax
            let newSummaryObj = { selected: Math.abs(newSelectedCredits), achieved: Math.abs(newAcheivedCredits), remaining: Math.abs(newRemainingCredits), selectedMin: Math.abs(newMinSelectedCredits), selectedMax: Math.abs(newMaxSelectedCredits)} as SummaryObject;
            let dispatcher = getDispatcher();
            if (newSelectedCredits < 0) {
                if (dispatcher != undefined)
                    dispatcher({
                        type: "UPDATE_SUMMARY", obj: { checked: false, summaryObj: newSummaryObj }
                    });

                //unselected scenario
            } else {
                if (dispatcher != undefined)
                    dispatcher({
                        type: "UPDATE_SUMMARY", obj: { checked: true, summaryObj: newSummaryObj }
                    });

                //selected scenario
            }
        }
    }, [props.electiveSecHelp.selectedCredits]);

    const createHeader = () => {
        return (
            <Header
                showRules={globalContext.showSectionRules ?? true}
                name={props.electiveSection.tims_name ?? ""}
                isDisabled = {props.electiveSecHelp.isDisabled}
                isCompliant = {props.electiveSecHelp.isCompliant}
                progress={
                    {
                        achieved:props.electiveSecHelp.achievedCredits,
                        remaining:props.electiveSecHelp._remainingCredits
                    }
                }
                credits = {
                    {
                        minAllowed:props.electiveSection.tims_minimumcredits,
                        maxAllowed:props.electiveSection.tims_maximumcredits,
                        selected:props.electiveSecHelp.selectedCredits
                    }
                }
                units = {
                    {
                        minAllowed:props.electiveSection.tims_minimumitems,
                        maxAllowed:props.electiveSection.tims_maximumitems,
                        selected:props.electiveSecHelp.selectedUnits
                    }
                }
                levels = { 
                    {
                        minAllowed:props.electiveSection.tims_minimumcreditsonlevel,
                        maxAllowed:props.electiveSection.tims_maximumcreditsonlevel,
                        minAllowedLevel:props.electiveSection.tims_level,
                        maxAllowedLevel:props.electiveSection.tims_maximumlevel,
                        selectedAtMinLevel:props.electiveSecHelp.selectedMinimumCredits,
                        selectedAtMaxLevel:props.electiveSecHelp.selectedMaximumCredits
                    }
                }
                >
            </Header>
        );
    }
    return (
        <Stack style={{ paddingBottom: "10px" }}>
            {showLoader ?
                <Stack style={{ display: "block", marginLeft: "auto", marginRight: "auto", textAlign: "center" }}>
                    <Loader
                        type="TailSpin"
                        color="#00BFFF"
                        height={100}
                        width={100}
                        timeout={10000} //3 secs 
                    />
                </Stack> :
                <electiveSectionContext.Provider value={dispatch}>
                    <Accordion HeaderElem={createHeader()} isSuperParentAccordion={false}>
                        <GridControl
                            key={props.electiveSection.tims_electivesectionid} rows={props.electiveSecHelp.rows} template={props.electiveSecHelp.templateName(globalContext.isEditMode as boolean)} componentHelper={props.electiveSecHelp}
                        ></GridControl>
                    </Accordion>
                </electiveSectionContext.Provider>
            }
        </Stack >
    );
}


