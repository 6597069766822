import { Serializable } from './Serializable';
import TokenModel from "./TokenModel"

const entityName = "tims_trainingcontracts";
export class TrainingContractModel extends Serializable {
    statuscode: string | undefined;
    _tims_accountworkplaceid_value: string | undefined;

    constructor(tokenModel: TokenModel) {
        super(tokenModel);
    }

    async retrieveTrainingContract(trainingContractID: string) {

        var trainingContract;
        trainingContract = await this.d365Helper.Retrieve(entityName, trainingContractID, this.createColumnArray(this));
        return trainingContract;
    }
}