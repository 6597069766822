import { Serializable } from './Serializable';
import TokenModel from "./TokenModel"

const entityName = "tims_templateunitstandards";
export class TemplateUnitStandardModel extends Serializable {

    constructor(tokenModel: TokenModel) {
        super(tokenModel);
    }


    _tims_electivesectionid_value: string | undefined;
    tims_name: string | undefined;
    _tims_programmeid_value: string | undefined;
    _tims_programmetemplateid_value: string | undefined;
    tims_templateunitstandardid: string | undefined;
    _tims_unitstandardid_value: string | undefined;



    async getTemplateUnitStandards(templateId: string) {
        var criteria = "_tims_programmetemplateid_value  eq '" + templateId + "'";
        return await this.d365Helper.RetrieveMultiple(entityName, this.createColumnArray(this), criteria);
    }

    async createTemplateUnitStandard(options: any) {

        var templateUS = {
            "tims_electivesectionid@odata.bind": "/tims_electivesections(" + options.electivesectionid + ")",
            "tims_programmeid@odata.bind": "/tims_programmes(" + options.programmeid + ")",
            "tims_programmetemplateid@odata.bind": "/tims_programmetemplates(" + options.programmetemplateid + ")",
            "tims_unitstandardid@odata.bind": "/tims_unitstandards(" + options.unitstandardid + ")"
        } as any;

        return await this.d365Helper.Create(entityName, templateUS, ["tims_templateunitstandardid"]);
    }

}