import TokenModel from '../Models/TokenModel';
import CacheManager from "../Common/CacheManager";

enum RequestType {
    Retrieve = 1,
    Update = 2,
    Fetch = 3,
    Delete = 4,
    Create = 5,
    Action = 6
}

export interface ExpandData {
    entityName: string;
    columnset: string[];
    filter: string | undefined,
    orderby?:string | undefined
}
export class D365Helper {
    /**
     *
     */


    Token: TokenModel;
    constructor(token: TokenModel) {
        this.Token = token;
    }

    private getRequestOptions(requestType: RequestType, entity: any): RequestInit {
        var requestOptions = {} as RequestInit;
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + this.Token.access_token);
        switch (requestType) {
            case RequestType.Retrieve:
                myHeaders.append("Prefer", "odata.include-annotations=\"OData.Community.Display.V1.FormattedValue\"");
                requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow'
                };
                break;
            case RequestType.Update:
                let raw = JSON.stringify(entity);
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append("MSCRMCallerID", this.Token.userId as string);
                requestOptions = {
                    method: 'PATCH',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
                break;
            case RequestType.Create:
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append("Prefer", "return=representation");
                myHeaders.append("MSCRMCallerID", this.Token.userId as string);
                let rawForCreate = JSON.stringify(entity);
                requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: rawForCreate,
                    redirect: 'follow'
                };
                break;
            case RequestType.Delete:
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append("MSCRMCallerID", this.Token.userId as string);
                requestOptions = {
                    method: 'DELETE',
                    headers: myHeaders,
                    redirect: 'follow'
                };
                break;
            case RequestType.Action:
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append("MSCRMCallerID", this.Token.userId as string);
                let rawForAction = JSON.stringify(entity);
                requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: rawForAction,
                    redirect: 'follow'
                };
                break;
            default:
                break;
        }
        return requestOptions;
    }
    private getRequestOptionsForFetch(fetchXmlQuery: string, entityName: string): RequestInit {

        var requestOptions = {} as RequestInit;
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + this.Token.access_token);
        myHeaders.append("Content-Type", "multipart/mixed;boundary=batch_fetchquery");
        requestOptions = {
            body:
                "--batch_fetchquery\n" +
                "Content-Type: application/http\n" +
                "Content-Transfer-Encoding: binary\n" +
                "\n" +
                "GET " +
                this.Token.apiUrl +
                "/api/data/v9.0/" + entityName + "?fetchXml=" +
                encodeURIComponent(fetchXmlQuery) +
                " HTTP/1.1\n" +
                'Prefer: odata.include-annotations="*"\n' +
                "\n" +
                "--batch_fetchquery--",
            headers: myHeaders,
            method: "POST"
        } as RequestInit;
        return requestOptions;
    }

    public async Retrieve(entityName: string, entityId: string, columnset: string[], getfromCache: boolean = true): Promise<any> {
        var request = new Request(this.Token.apiUrl + '/api/data/v9.1/' + entityName + '(' + entityId + ')?$select=' + columnset.join(','), this.getRequestOptions(RequestType.Retrieve, null));
        return await (await this.callfetch(request, getfromCache)).json();
    }

    public async RetrieveWithExpand(entityName: string, entityId: string, columnset: string[], expandData: ExpandData[], getfromCache: boolean = true): Promise<any> {

        let expandQuery = "&$expand=";

        for (let index = 0; index < expandData.length; index++) {
            const element = expandData[index];
            if (index != 0)
                expandQuery += ","
            expandQuery += element.entityName;
            expandQuery += "($select=" + element.columnset.join(",");
            if (element.filter != undefined)
                expandQuery += ";$filter=(" + element.filter + ")";
            expandQuery += ")";
        }

        var request = new Request(this.Token.apiUrl + '/api/data/v9.1/' + entityName + '(' + entityId + ')?$select=' + columnset.join(',') + expandQuery, this.getRequestOptions(RequestType.Retrieve, null));
        return await (await this.callfetch(request, getfromCache)).json();
    }

    public async Update(entityName: string, entityId: string, entity: any): Promise<Response> {
        return await fetch(this.Token.apiUrl + '/api/data/v9.1/' + entityName + '(' + entityId + ')', this.getRequestOptions(RequestType.Update, entity));
    }

    public async RetrieveMultiple(entityName: string, columnset: string[], filter: string, getfromCache: boolean = true): Promise<any[]> {
        var request = new Request(this.Token.apiUrl + '/api/data/v9.1/' + entityName + '?$select=' + columnset.join(',') + '&$filter=' + filter, this.getRequestOptions(RequestType.Retrieve, null))
        let response = await (await this.callfetch(request, getfromCache)).json();
        return response.value;
    }

    public async RetrieveMultipleWithExpand(entityName: string, columnset: string[], filter: string, expandData: ExpandData[], orderby: string, top: number = 0, getfromCache: boolean = true): Promise<any[]> {

        let expandQuery = "&$expand=";

        for (let index = 0; index < expandData.length; index++) {
            const element = expandData[index];
            if (index != 0)
                expandQuery += ","
            expandQuery += element.entityName;
            expandQuery += "($select=" + element.columnset.join(",");
            if (element.filter != undefined)
                expandQuery += ";$filter=(" + element.filter + ")";
            if(element.orderby != undefined)
                expandQuery += ";$orderby=" + element.orderby
            expandQuery += ")";
        }

        var request = new Request(this.Token.apiUrl + '/api/data/v9.1/' + entityName + '?$select=' + columnset.join(',') + expandQuery + '&$filter=' + filter + "&$orderby=" + orderby + "&$top=" + top, this.getRequestOptions(RequestType.Retrieve, null))
        let response = await (await this.callfetch(request, getfromCache)).json();
        return response.value;

    }

    public async RetrieveMultipleByOrder(entityName: string, columnset: string[], filter: string, orderby: string, getfromCache: boolean = true): Promise<any[]> {
        var request = new Request(this.Token.apiUrl + '/api/data/v9.1/' + entityName + '?$select=' + columnset.join(',') + '&$filter=' + filter + "&$orderby=" + orderby, this.getRequestOptions(RequestType.Retrieve, null));
        let response = await (await this.callfetch(request, getfromCache)).json();
        return response.value;
    }

    public async ExecuteFetch(entityName: string, fetchxml: string, getFromCache: boolean = true): Promise<any[]> {

        const request = new Request(this.Token.apiUrl + "/api/data/v9.1/$batch", this.getRequestOptionsForFetch(fetchxml, entityName));

        let cacheKey = await CacheManager.createCachePostRequest(request);

        let response = await CacheManager.getFromCache(cacheKey);
        var responseText = "";
        if (response == null || !getFromCache) {
            response = await fetch(this.Token.apiUrl + "/api/data/v9.1/$batch", this.getRequestOptionsForFetch(fetchxml, entityName));
            await CacheManager.putIntoCache(cacheKey, response);
        }

        responseText = await response.text();
        var responseObj = JSON.parse(
            responseText.substring(responseText.indexOf("{"), responseText.lastIndexOf("}") + 1));
        return responseObj.value;
    }

    public async Create(entityName: string, entity: any, columnset: string[]) {
        let response = await fetch(this.Token.apiUrl + '/api/data/v9.1/' + entityName + '?$select=' + columnset.join(','), this.getRequestOptions(RequestType.Create, entity));
        return await response.json();

    }

    public async Delete(entityName: string, entityId: string): Promise<Response> {
        return await fetch(this.Token.apiUrl + '/api/data/v9.1/' + entityName + '(' + entityId + ')', this.getRequestOptions(RequestType.Delete, null));
    }

    public async ExecuteBoundAction(targetEntity: string, targetEntityID: string, actionName: string, params: any) {
        return await (await fetch(this.Token.apiUrl + '/api/data/v9.1/' + targetEntity + "(" + targetEntityID + ")/" + actionName, this.getRequestOptions(RequestType.Action, params)));
    }

    async callfetch(request: Request, getfromCache: boolean) {

        let cachedResponse = await CacheManager.getFromCache(request);
        if (cachedResponse != null && getfromCache) {
            return cachedResponse;
        }
        var response = await fetch(request);
        await CacheManager.putIntoCache(request, response);
        return response;
    }

}