import { ContactModel } from "../Models/ContactModel";
import GlobalStateModel from "../Models/GlobalStateModel";
import { ProgrammeModel } from "../Models/ProgrammeModel";
import { ResultModel } from "../Models/ResultModel";
import TokenModel from "../Models/TokenModel";
import { TrainingPlanCommentModel } from "../Models/TrainingPlanCommentModel";
import TrainingPlanModel from "../Models/TrainingPlanModel";
import TrainingPlanUnitStandardModel from "../Models/TrainingPlanUnitStandardModel";
import { UnitStandardModel } from "../Models/UnitStandardModel";

const cacheName = "window";
export default class CacheManager {

    static async getFromCache(request: Request) {
        var cache = await caches.open(cacheName);
        var cachedResponse = await cache.match(request);
        if (cachedResponse != undefined) {
            if (CacheManager.isValid(cachedResponse)) {
                if (cachedResponse != null && cachedResponse != undefined)
                    return cachedResponse;
                else
                    return null;
            }
        }


        return null;
    }

    static async clearCache(globalState: GlobalStateModel) {

        let token = globalState.tokenModel as TokenModel;
        
        var unitStandardModel = new UnitStandardModel(token);
        var resultModel = new ResultModel(token);
        var tPUSModel = new TrainingPlanUnitStandardModel(token);
        var tPCommentModel = new TrainingPlanCommentModel(token);

        unitStandardModel.clearCache();
        resultModel.clearCache();
        tPUSModel.clearCache();
        tPCommentModel.clearCache();
        await caches.delete(cacheName);
    }

    static async initializeCache(globalState: GlobalStateModel) {

        let token = globalState.tokenModel as TokenModel;

        let programmeObj = globalState.programmeObj as ProgrammeModel;
        let contact = globalState.contact as ContactModel;
        let trainingPlanObj = globalState.trainingPlanObj as TrainingPlanModel;

        var unitStandardModel = new UnitStandardModel(token);
        var resultModel = new ResultModel(token);
        var tPUSModel = new TrainingPlanUnitStandardModel(token);
        var tPCommentModel = new TrainingPlanCommentModel(token);

        let usmProm = unitStandardModel.initCache(programmeObj.tims_programmeid as string, contact.contactid as string);
        let resultMProm = resultModel.initCache(contact.contactid as string, false);
        let tPUSModelProm = tPUSModel.initCache(trainingPlanObj.tims_trainingplanid as string);
        let tPCommentModelProm = tPCommentModel.initCache(trainingPlanObj.tims_trainingplanid as string, false);

        await Promise.all([usmProm, resultMProm, tPUSModelProm, tPCommentModelProm]);

    }

    static async createCachePostRequest(request: Request) {

        var textReq = await request.clone().text();
        const hash = await this.sha256(textReq);
        const cacheUrl = new URL(request.url)

        // Store the URL in cache by prepending the body's hash
        cacheUrl.pathname = "/posts" + cacheUrl.pathname + hash

        // Convert to a GET to be able to cache
        const cacheKey = new Request(cacheUrl.toString(), {
            headers: request.headers,
            method: "GET",
        });

        return cacheKey;
    }

    static async putIntoCache(request: Request, response: Response) {
        var cache = await caches.open(cacheName);
        let copy = response.clone();
        var headers = new Headers(copy.headers);
        headers.append('sw-fetched-on', new Date().getTime().toString());
        let body = await copy.blob();

        await cache.put(request, new Response(body, { status: copy.status, headers: headers, statusText: copy.statusText }));
    }

    private static async sha256(message: any) {
        // encode as UTF-8
        const msgBuffer = new TextEncoder().encode(message)

        // hash the message
        const hashBuffer = await crypto.subtle.digest("SHA-256", msgBuffer)

        // convert ArrayBuffer to Array
        const hashArray = Array.from(new Uint8Array(hashBuffer))

        // convert bytes to hex string
        const hashHex = hashArray.map(b => ("00" + b.toString(16)).slice(-2)).join("")
        return hashHex
    }

    private static isValid(response: Response) {
        if (!response) return false;
        var fetched = response.headers.get('sw-fetched-on');
        if (fetched && (parseFloat(fetched) + (1000 * 60 * 60 * 2)) > new Date().getTime()) return true;
        return false;
    }
}