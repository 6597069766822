import TokenModel from "../Models/TokenModel";
import { TrainingPlanCommentModel } from "../Models/TrainingPlanCommentModel";
import ComponentHelperBase from "./ComponentHelperBase";
import ProgrammeUnitStandard from "./ProgrammeUnitStandard";

export default class CommentForm {

    _parent: ComponentHelperBase;
    isVisible: boolean | undefined;
    isSelected: boolean | undefined;
    comment: string;
    _trainingPlanCommentModel: TrainingPlanCommentModel;
    /**
     *
     */
    constructor(parent: ComponentHelperBase) {
        this._parent = parent;
        this._trainingPlanCommentModel = new TrainingPlanCommentModel(parent._globalStateModel?.tokenModel as TokenModel);
        this.comment = parent.comment?.tims_trainingcomment as string;
    }

    get buttonText() {
        return this._parent.comment === null ? "Create" : "Update";
    };

    toggle() {
        this.isVisible = !this.isVisible;
        if (this.isVisible) {
            this.isSelected = true;
        }
    };

    // work out what kindof edit we are doing,
    // then we either update or create the result
    submit() {
        // we willeeither create the comment or updateit
        var comment = this._parent.comment;

        if (comment) {
            this.updateComment();
        } else {
            this.createComment();
        }
    };

    // edit the result then update the result entity on the programme unit standard
    async updateComment() {

        var self = this;
        let commentModel = undefined;
        var comment = this.comment;
        var commentId = this._parent.comment?.tims_trainingplancommentid;

        // just sync the comment back up
        let response = await this._trainingPlanCommentModel.updateComment(commentId as string, comment);
        if (response.ok)
            commentModel = await this._trainingPlanCommentModel.retrieveById(commentId as string, true);
        else
            commentModel = await response.json();
        

        this.isVisible = false;

        return commentModel;
    };

    // create the result then update the result on the programme unit standard
    async createComment() {
        // create the comment
        var trainingPlanUnitStandard = this._parent._trainingPlanUnitStandard?._tpuModel;
        var commentOptions = {
            trainingplanid: trainingPlanUnitStandard?._tims_trainingplanid_value,
            trainingplanunitstandardid: trainingPlanUnitStandard?.tims_trainingplanunitstandardid,
            trainingcomment: this.comment
        };

        var self = this;
        return await this._trainingPlanCommentModel.createComment(commentOptions);
    };

}