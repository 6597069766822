import { Serializable } from './Serializable';
import TokenModel from "./TokenModel"

const entityName = "tims_programmes"
export class ProgrammeModel extends Serializable {

    constructor(tokenModel: TokenModel) {
        super(tokenModel);
    }

    statuscode: number | undefined;
    tims_agitoprogrammenumber: string | undefined;
    tims_description: string | undefined;
    tims_hasadditionalmaunitstandards: number | undefined;
    tims_isalsoma: number | undefined;
    tims_isalsonza: number | undefined;
    tims_issimplema: number | undefined;
    tims_level: number | undefined;
    tims_minimumcredits: number | undefined;
    tims_minimumcreditsforadditionalunits: number | undefined;
    tims_minimumcreditsonlevel: number | undefined;
    tims_minimumlevel: number | undefined;
    tims_maximumlevel: number | undefined;
    tims_maximumcredits: number | undefined;
    tims_maximumcreditsonlevel:number | undefined;
    tims_nzqaoptionalstrand: string | undefined;
    tims_nzqaprogrammeidentifier: string | undefined;
    tims_programmeid: string | undefined;
    tims_ruledescription: string | undefined;
    tims_isallowedunitstandardresources: number | undefined;
    tims_name: string | undefined;
    tims_creditsexpectedpermonth: string | undefined;
    tims_pathway: number | undefined;
    tims_deliverytype: number | undefined;


    async retrieveProgramme(programmeId: string) {
        return await this.d365Helper.Retrieve(entityName, programmeId, this.createColumnArray(this));
    }

    async searchProgrammeByName(keyword: string) {
        var filter = `(contains(tims_name,'${keyword.trim()}') and statuscode ne 5)`;
        return await this.d365Helper.RetrieveMultiple(entityName, this.createColumnArray(this), filter);
    }

}