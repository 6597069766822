import { DefaultButton, Dropdown, IDropdownOption, IDropdownStyles, Stack } from "@fluentui/react";
import React, { useEffect, useState, useContext } from 'react';
import CacheManager from "../Common/CacheManager";
import { Events, RequestType } from "../Common/Enum";
import Emitter from "../Common/EventEmitter";
import TrainingPlanUnitStandardManager, { ItpuCreate } from "../Common/TrainingPlanUnitStandardManager";
import GlobalState from '../GlobalState';

import { ProgrammeTemplateModel } from '../Models/ProgrammeTemplateModel';
import { Serializable } from "../Models/Serializable";
import { TemplateUnitStandardModel } from "../Models/TemplateUnitStandardModel";
import TokenModel from "../Models/TokenModel";
import TrainingPlanUnitStandardModel from "../Models/TrainingPlanUnitStandardModel";
import { IMessagebarObject, MasterControlContext } from "./MasterControl";
import { PreviewControlContext } from "./PreviewControl";


export interface TemplateSelectorProps {
  programmeId: string,
  trainingPlanStatus: number
}

const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: { width: 300 },
};



export const TemplateSelector: React.FunctionComponent<TemplateSelectorProps> = ({ programmeId, trainingPlanStatus }) => {

  const globalState = useContext(GlobalState);

  const [options, setOptions] = useState<IDropdownOption[]>([]);
  const [showFullScreenLoader, setShowFullScreenLoader] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<IDropdownOption | null>(null);
  const masterControlDispatcher = React.useContext(MasterControlContext);
  const previewControlDispatcher = React.useContext(PreviewControlContext);


  const getDispatcher = () => {

    if (typeof masterControlDispatcher == "function")
      return masterControlDispatcher;
    else if (typeof previewControlDispatcher == "function")
      return previewControlDispatcher;
  }


  useEffect(() => {
    console.log('Component mounted');
    async function fetchData() {
      try {
        const programmeTemplateModel = new ProgrammeTemplateModel(globalState.tokenModel as TokenModel);
        const items = await programmeTemplateModel.retrieveProgrammeTemplates(programmeId);
        console.log(items.length);
        var ops = items.map((value, index) => {
          return {
            key: value.tims_programmetemplateid,
            text: value.tims_name,
          }
        });

        setOptions(ops);
      } catch (e) {
        console.error(e);
      }
    };
    fetchData();

    return () => {
      console.log('Component will be unmount');
    }
  }, [programmeId]);

  const onTemplateChange = async (option: IDropdownOption<any>) => {

    let dispatcher = getDispatcher();
    TrainingPlanUnitStandardManager.clear();
    setShowFullScreenLoader(true);
    const trainingPlanUnitStandardRepository = new TrainingPlanUnitStandardModel(globalState.tokenModel as TokenModel)

    const existingUnits = await trainingPlanUnitStandardRepository.retrieveCrossCreditTrainingPlanUnitStandards(globalState.trainingPlanObj?.tims_trainingplanid as string);

    const templateUnitRepository = new TemplateUnitStandardModel(globalState.tokenModel as TokenModel);
    const templateUnitStandards = await templateUnitRepository.getTemplateUnitStandards(option?.key as string);
    // get the ones which aren't in both to be deleted,
    // get the ones which are only the the new one, to be added

    const unitsToDelete = existingUnits.filter(filterElm => templateUnitStandards.find(findElm =>
      findElm._tims_unitstandardid_value === filterElm._tims_unitstandardid_value
      && findElm._tims_electivesectionid_value === filterElm._tims_electivesectionid_value) === undefined
    ) as TrainingPlanUnitStandardModel[];


    const unitsToAdd = templateUnitStandards.filter(filterElm => existingUnits.find(findElm =>
      findElm._tims_unitstandardid_value === filterElm._tims_unitstandardid_value
      && findElm._tims_electivesectionid_value === filterElm._tims_electivesectionid_value) === undefined
    );

    unitsToDelete.forEach(element => {
      TrainingPlanUnitStandardManager.deleteTrainingPlanUnitStandard(element);
    });

    unitsToAdd.forEach(element => {

      //Add
      // assessment type defaults to practical and assessor			
      var tpuCreate = {
        trainingplanid: globalState.trainingPlanObj?.tims_trainingplanid,
        unitstandardid: element._tims_unitstandardid_value,
        assessmenttype: 1,
        assessedby: 1,
        electivesectionid: element._tims_electivesectionid_value
      } as ItpuCreate;

      TrainingPlanUnitStandardManager.createCrossCreditTrainingPlanUnitStandard(tpuCreate);
    });

    let responseObj = await TrainingPlanUnitStandardManager.commit(globalState, true);

    if (responseObj.isSuccess) {
      await CacheManager.clearCache(globalState);
      await CacheManager.initializeCache(globalState);
      setShowFullScreenLoader(false);
      if (dispatcher != undefined)

        dispatcher({
          type: "POST_TEMPLATE", obj: { reloadControls: true }
        });

    }
    Emitter.emit(Events.SetMessageBar, { errors: responseObj.errors, isSuccess: responseObj.isSuccess, requestType: RequestType.APPLIED_TEMPLATE, showMessageBar: true } as IMessagebarObject);

    //We have to reload everything at this point..

  }


  const onCreateTemplate = async () => {

    var crossCreditUnitStandards,
      programmeTemplateId,
      newProgrammeTemplate,
      programmeId = globalState.programmeObj?.tims_programmeid,
      trainingPlanId = globalState.trainingPlanObj?.tims_trainingplanid,
      templateName = window.prompt("Enter Name of Plan Template"),
      errorMessages: string[] = [];
    if (!templateName) {
      return;
    }

    setShowFullScreenLoader(true);

    const trainingPlanUnitStandardRepository = new TrainingPlanUnitStandardModel(globalState.tokenModel as TokenModel)
    const programmeTemplateRepo = new ProgrammeTemplateModel(globalState.tokenModel as TokenModel);
    const templateUnitRepository = new TemplateUnitStandardModel(globalState.tokenModel as TokenModel);


    crossCreditUnitStandards = await trainingPlanUnitStandardRepository.retrieveCrossCreditTrainingPlanUnitStandards(globalState.trainingPlanObj?.tims_trainingplanid as string);


    var optionsToCreate = {
      defaulttemplate: false,
      name: templateName,
      programmeid: programmeId
    };


    var newTemplate = await programmeTemplateRepo.createProgrammeTemplate(optionsToCreate);

    console.log(newTemplate);

    if (newTemplate.error) {
      //Emitter.emit(Events.SaveTrainingPlan, {});
      errorMessages.push((newTemplate as Serializable).error?.message as string);
      setShowFullScreenLoader(false);
      Emitter.emit(Events.SetMessageBar, { errors: errorMessages, isSuccess: false, requestType: RequestType.CREATE_TEMPLATE, showMessageBar: true } as IMessagebarObject);
      return;
    }

    let unitError = 0;

    for await (const templateUnitStandard of crossCreditUnitStandards) {
      var createOptions = {
        electivesectionid: templateUnitStandard._tims_electivesectionid_value,
        programmeid: programmeId,
        programmetemplateid: newTemplate.tims_programmetemplateid,
        unitstandardid: templateUnitStandard._tims_unitstandardid_value
      };

      var newUnit = await templateUnitRepository.createTemplateUnitStandard(createOptions);

      console.log(newUnit);

      if (newUnit.error) {
        errorMessages.push((newUnit as Serializable).error?.message as string);
        unitError++;
      }

    }

    if (unitError > 0) {
      setShowFullScreenLoader(false);
    }
    else {
      let newOption = { key: newTemplate.tims_programmetemplateid, text: templateName } as IDropdownOption<any>;
      let newOptions = options.concat(newOption);
      setOptions(newOptions);
      setShowFullScreenLoader(false);
    }

    Emitter.emit(Events.SetMessageBar, { errors: errorMessages, isSuccess: unitError == 0, requestType: RequestType.CREATE_TEMPLATE, showMessageBar: true } as IMessagebarObject);

  }
  return (

    <Stack style={{ paddingRight: "5px", paddingTop: "1px" }} horizontal>
      {showFullScreenLoader ? <div className="loading">
        <div className='uil-ring-css' style={{ transform: "scale(0.79)" }}>
          <div></div>
        </div>
      </div> : ""}
      {options.length > 0 && trainingPlanStatus == 1 ?
        <Dropdown
          placeholder="Select template to apply"
          label="Plan Template"
          options={options}
          styles={dropdownStyles}
          onChange={async (event, option) => {
            await onTemplateChange(option as IDropdownOption<any>);
          }}
        /> : ""}
      <DefaultButton className="button-style" title="Create Template" iconProps={{ iconName: "SaveTemplate" }} style={{ marginTop: "31px", marginLeft: "5px" }}
        onClick={async () => {
          await onCreateTemplate();
        }}>
      </DefaultButton>
    </Stack>
  );
}


