import TokenModel from "../Models/TokenModel";
import { D365Helper } from "./D365Helper";

export default class Common {

  createEntityReference(entityName: string, entityID: string): any[] {
    return [];
  }

  static getQueryString(key: string) {

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    return params[key];
  }

  static getProgressBarStyle() {
    return { root: { height: 8 }, itemProgress: { height: 8, width:130 }, progressTrack: { height: 8, width:130, backgroundColor: '#FFFFFF' }, progressBar: { backgroundColor: '#95c27b', height: 8, width:130 } };
  }

  static async getUserRoles(token: TokenModel, userId: string) {
    let d365Help = new D365Helper(token);
    let query = `<fetch>
        <entity name="systemuser">
          <filter>
            <condition attribute="systemuserid" operator="eq" value="${userId}" />
          </filter>
          <link-entity name="systemuserroles" from="systemuserid" to="systemuserid" intersect="true">
            <link-entity name="role" from="roleid" to="roleid" alias="Role" intersect="true">
              <attribute name="name" />
              <attribute name="roleid" />
            </link-entity>
          </link-entity>
        </entity>
      </fetch>`;
    let userRoles = await d365Help.ExecuteFetch("systemusers", query, false);
    return userRoles;
  }

  static getToken = async (token: string) => {
    let tokenModelObj = new TokenModel();
    try {
      var myHeaders = new Headers();
      myHeaders.append("authorization-x", token);

      let response = await fetch("/api/GetD365Token", { method: 'POST', headers: myHeaders });
      if (response.ok)
        tokenModelObj = await response.json() as TokenModel;
      else {
        if (response.status == 401)
          tokenModelObj.error = "Key is not provided or expired.Unauthorized";
        else
          tokenModelObj.error = "Unkown error.Please contact administrator."
      }
    }
    catch (e) {
      //@ts-ignore
      tokenModelObj.error = e;
    }
    return tokenModelObj;

  }

  static getCleanshortDate(date: string) {
    var dateObj = new Date(date);
    return dateObj.getDate() + "/" + (dateObj.getMonth() + 1) + "/" + dateObj.getFullYear();
  }

  static getFormattedValue(obj: any, fieldName: string) {
    let formattedValue;
    if (obj == undefined || obj == null)
      return "";
    else
      formattedValue = obj[fieldName + "@OData.Community.Display.V1.FormattedValue"]
      return formattedValue ? formattedValue : obj[fieldName + "@OData"]  
  }

  static newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  static getDifferenceInDays(pastDate:Date, futureDate:Date){
    pastDate.setHours(0,0,0,0)
    const differenceInMs = futureDate?.getTime() - pastDate.getTime()
    const differenceInDays = differenceInMs / (1000 * 60 * 60 * 24);
    return Math.floor(differenceInDays)
}


}

