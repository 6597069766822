
import React from "react";
import { Stack, Checkbox, themeRulesStandardCreator, TooltipHost, ITooltipHostStyles, RefObject, IContextualMenuProps } from "@fluentui/react";
import { DefaultButton } from "@fluentui/react/lib/Button";
import GlobalState from "../GlobalState";
import GlobalStateModel, { DirtyMode } from "../Models/GlobalStateModel";
import CacheManager from "../Common/CacheManager";
import Emitter from "../Common/EventEmitter";
import { Events, RequestType, TrainingPlanStatuses, Views } from "../Common/Enum";
import TrainingPlanUnitStandardManager from "../Common/TrainingPlanUnitStandardManager";
import "../assets/css/OverlayLoader.css";
import TrainingPlanModel from "../Models/TrainingPlanModel";
import { IMessagebarObject } from "./MasterControl";
import { Serializable } from "../Models/Serializable";
import { TrainingProgrammeModel } from "../Models/TrainingProgrammeModel";
import Common from "../Common/Common";


interface IButtonPanelState {
    showFullScreenLoader: boolean,
    hasEditRights: boolean,
}

interface IButtonPanelProps {
    isDirty: DirtyMode,
    isCompliant: Boolean,
    isReorderModeDirty: boolean;
    isColumnsDirty:boolean;
    isCustomView:boolean;
    contentRef: React.MutableRefObject<HTMLDivElement | null>
    setPrinting:React.Dispatch<React.SetStateAction<boolean>>;
}


const calloutProps = { gapSpace: 0 };
// The TooltipHost root uses display: inline by default.
// If that's causing sizing issues or tooltip positioning issues, try overriding to inline-block.
const hostStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block' } };


export default class ButtonPanelControl extends React.Component<IButtonPanelProps, IButtonPanelState> {
    context: any;
    static contextType = GlobalState

    /**
     *
     */
    constructor(props: any) {
        super(props);

        this.state = {
            showFullScreenLoader: false
        } as IButtonPanelState

    }

    async componentDidMount() {
        if(this.context?.IsPortalMode === false){ 
            //Security role check
            let userRoles = await Common.getUserRoles(this.context.tokenModel, this.context.tokenModel.userId as string);
            if(userRoles !== undefined){
                let userRole = userRoles.find(item => item["Role.name"] == "3 - Enrolments" || item["Role.name"] == "System Administrator");
                this.setState({ showFullScreenLoader: false, hasEditRights: userRole != undefined });
            }
        }
    }

    clearDirty = () => {
        let globalContext = {
            ...this.context,
            isDirty: { isElecGrpSectionDirty: false, isPrgrmElecSec: false }
        } as GlobalStateModel;
        this.context.setGlobalState(globalContext);
    }

    async fetchAndUpdateTrainingPlan() {
        let tpModel = new TrainingPlanModel(this.context.tokenModel);
        let tp = this.context.trainingPlanObj as TrainingPlanModel;
        let jsonObj = await tpModel.retrieveTrainingPlan(tp.tims_trainingplanid as string, false);
        var trainingPlanObjUpd = jsonObj as TrainingPlanModel;
        let globalContext = { ...this.context, trainingPlanObj: trainingPlanObjUpd } as GlobalStateModel;
        this.context.setGlobalState(globalContext);
    }
    async fetchAndUpdateTrainingProgramme() {
        var trainingProgrammeModel = new TrainingProgrammeModel(this.context.tokenModel);
        let tp = this.context.trainingPlanObj as TrainingPlanModel;
        var trainingProgrammeObj = await trainingProgrammeModel.retrieveTrainingProgramme(tp._tims_trainingprogrammeid_value as string, false) as TrainingProgrammeModel;
        let globalContext = { ...this.context, trainingProgrammeObj: trainingProgrammeObj } as GlobalStateModel;
        this.context.setGlobalState(globalContext);
    }

    activateTrainingPlan = async () => {
        this.setState({ showFullScreenLoader: true });
        let tpModel = new TrainingPlanModel(this.context.tokenModel);
        let tp = this.context.trainingPlanObj as TrainingPlanModel;
        let resp = await tpModel.setStateActive(tp.tims_trainingplanid as string);
        if (resp.ok) {
            await this.fetchAndUpdateTrainingPlan();
            await this.fetchAndUpdateTrainingProgramme();
            Emitter.emit(Events.SetMessageBar, { errors: [], isSuccess: true, requestType: RequestType.ACTIVATE_TP, showMessageBar: true } as IMessagebarObject);
        } else {
            let responseError = await resp.json() as Serializable;
            Emitter.emit(Events.SetMessageBar, { errors: [responseError.error?.message], isSuccess: false, requestType: RequestType.ACTIVATE_TP, showMessageBar: true } as IMessagebarObject);
        }
        this.setState({ showFullScreenLoader: false });
    }

    isEditButtonDisabled = () => {
        if(!this.state.hasEditRights)
            return true;
        let tp = this.context.trainingPlanObj as TrainingPlanModel;
        if (tp.statuscode == TrainingPlanStatuses.Draft)// Do not check compliant if training plan is not active
            return !(this.props.isDirty.isElecGrpSectionDirty || this.props.isDirty.isPrgrmElecSec);

        return !((this.props.isDirty.isElecGrpSectionDirty || this.props.isDirty.isPrgrmElecSec) && this.props.isCompliant);
    }

    isActivateButtonVisible = () => {
        return (this.context.trainingPlanObj as TrainingPlanModel).statuscode == TrainingPlanStatuses.Draft && (this.context as GlobalStateModel).isEditMode
    }

    isActivateButtonDisabled = () => {
        return !((!this.props.isDirty.isElecGrpSectionDirty && !this.props.isDirty.isPrgrmElecSec) && this.props.isCompliant);
    }

    render() {
        return (<Stack style={{ paddingTop: "32px" }} horizontal>
            {this.state.showFullScreenLoader ? <div className="loading">
                <div className='uil-ring-css' style={{ transform: "scale(0.79)" }}>
                    <div></div>
                </div>
            </div> : ""}
            {
                (<TooltipHost
                    hidden={this.state.hasEditRights}
                    content="Changes to the Training Plan can be completed by Enrolments team only"
                    // This id is used on the tooltip itself, not the host
                    // (so an element with this id only exists when the tooltip is shown)
                    id={"SaveButtonTooltip"}
                    calloutProps={calloutProps}
                    styles={hostStyles}
                  ><DefaultButton aria-describedby="SaveButtonTooltip" className="button-style" title="Save" iconProps={{ iconName: "Save" }} type="button" onClick={async (e) => {
                    this.setState({ showFullScreenLoader: true });
                    TrainingPlanUnitStandardManager.clear();
                    Emitter.emit(Events.SaveTrainingPlan, {});
                    setTimeout(async () => {
                        let responseObj = await TrainingPlanUnitStandardManager.commit(this.context, false);

                        if (responseObj.isSuccess) {
                            this.clearDirty();
                            await this.fetchAndUpdateTrainingPlan();
                            await this.fetchAndUpdateTrainingProgramme();
                        }
                        Emitter.emit(Events.SetMessageBar, { errors: responseObj.errors, isSuccess: responseObj.isSuccess, requestType: RequestType.SAVE_TP, showMessageBar: true } as IMessagebarObject);
                        this.setState({ showFullScreenLoader: false });
                    }, 1000);

                }} disabled={this.isEditButtonDisabled()} hidden={!(this.context as GlobalStateModel).isEditMode}>
                </DefaultButton>&nbsp; </TooltipHost>)}
            {(this.context as GlobalStateModel).selectedView == Views.Reorder ||  (this.context as GlobalStateModel).selectedView == Views.Custom?
                <>
                    <DefaultButton iconProps={{ iconName: "Delete" }} className="button-style" hidden={!this.props.isCustomView} onClick={async (e) => {
                        Emitter.emit(Events.DeleteView, {});
                    }}></DefaultButton> 
                    <DefaultButton 
                        className="button-style" 
                        menuProps={{ items: [
                            {
                                key:"editColumns", 
                                text:'Edit columns',
                                onClick:() => { Emitter.emit(Events.OpenCreateViewModal, {})}
                            },
                            {
                                key: 'createView',
                                text: 'Save as new view', 
                                onClick: () => { Emitter.emit(Events.OpenSaveViewModal, {});}, 
                                disabled: !this.props.isColumnsDirty && !this.props.isCustomView 
                            },
                            {
                                key: 'saveAsView',
                                text: 'Save changes to current view',
                                disabled:!this.props.isColumnsDirty || !this.props.isCustomView,
                                onClick: () => { Emitter.emit(Events.SaveExistingView, {}); }
                            },
                            
                            ]}} 
                            style={{ marginLeft: "25px" }}>View Options</DefaultButton>
                            <DefaultButton className="button-style" style={{ marginRight: "2px", marginLeft:"2px" }} disabled={!this.props.isReorderModeDirty} onClick={async (e) => {
                                Emitter.emit(Events.SaveOrder, {});
                            }}>Save Order</DefaultButton>
                </>
                 : 
                <></>}
                
            <DefaultButton className="button-style" title="Print" iconProps={{ iconName: "Print" }} onClick={() => {
                window.scrollTo(0, 0);
                setTimeout(() => {
                        this.props.setPrinting(true)
                }, 500);
            }}></DefaultButton>
            {this.isActivateButtonVisible() ?
                <>
                    &nbsp;
                    <DefaultButton className="button-style" iconProps={{ iconName: "Activate" }} onClick={this.activateTrainingPlan} disabled={this.isActivateButtonDisabled()}>
                        Activate
                    </DefaultButton>
                </> : ""}
            &nbsp;
            <DefaultButton className="button-style" title="Refresh" iconProps={{ iconName: "Refresh" }} onClick={async () => { await CacheManager.clearCache(this.context); window.location.reload(); }}></DefaultButton>
            {/*Programme Template Div*/}
            <div>
            </div>
        </Stack >);
    }

}