import { D365Helper } from '../Common/D365Helper';
import { Serializable } from './Serializable';
import TokenModel from "./TokenModel"

const entityName = "tims_programmetemplates"

export class ProgrammeTemplateModel extends Serializable {
    constructor(tokenModel: TokenModel) {
        super(tokenModel);
        this._tokenModel = tokenModel;
        this.d365Helper = new D365Helper(this._tokenModel);
    }


    tims_defaulttemplate: string | undefined;
    tims_name: string | undefined;
    _tims_programmeid_value: string | undefined;
    tims_programmetemplateid: string | undefined;


    async retrieveProgrammeTemplates(programmeId: string) {

        return await this.d365Helper.RetrieveMultipleByOrder(entityName, this.createColumnArray(this), "_tims_programmeid_value eq '" + programmeId + "'", "tims_name asc", false);
    }

    async createProgrammeTemplate(options: any) {

        var programmeTemplate = {
            "tims_defaulttemplate": options.defaulttemplate,
            "tims_name": options.name,
            "tims_programmeid@odata.bind": "/tims_programmes(" + options.programmeid + ")"
        };
        return await this.d365Helper.Create(entityName, programmeTemplate, ["tims_programmetemplateid"]);
    }

}