import { ProgrammeUnitStandardModel } from '../Models/ProgrammeUnitStandardModel';
import TokenModel from '../Models/TokenModel';
import ProgrammeUnitStandard from '../ComponentHelpers/ProgrammeUnitStandard';
import GlobalStateModel from '../Models/GlobalStateModel';
import _ from 'underscore';
import ComponentHelperBase from './ComponentHelperBase';
import { Templates } from '../Common/Enum';

export default class CompulsarySectionHelper extends ComponentHelperBase {

    _programUnitStandardModel: ProgrammeUnitStandardModel;
    _tokenModel: TokenModel | undefined;
    programmeUnitStandards: ProgrammeUnitStandard[];
    _globalStateModel: GlobalStateModel;
    loadedCompletely: boolean;

    name: string | undefined;
    isEditMode: boolean | undefined;
    visible: boolean | undefined;
    _programmeId: string;
 
    /**
     *
     */
    constructor(programmeId: string, globalStateModel: GlobalStateModel) {
        super();
        this.loadedCompletely = false;
        var pus = [] as ProgrammeUnitStandard[];
        this.programmeUnitStandards = [];
        this._programUnitStandardModel = new ProgrammeUnitStandardModel(globalStateModel.tokenModel as TokenModel);
        this._globalStateModel = globalStateModel;
        this._programmeId = programmeId;
        this.isEditMode = true;
        this.isDisabled = true;
    }

    get isAllowedUnitStandardResources() {
        return this._globalStateModel.programmeObj?.tims_isallowedunitstandardresources as number;
    }

    async build() {

        var compulsoryUnitStandards = await this._programUnitStandardModel.retrieveNonElectiveProgrammeUnitStandards(this._programmeId) as ProgrammeUnitStandardModel[];
        let promiseArr = [];
        for await (const progUS of compulsoryUnitStandards) {
            progUS._tokenModel = this._globalStateModel.tokenModel as TokenModel;
            var obj = new ProgrammeUnitStandard(progUS, this._globalStateModel, true, undefined);

            if (!this._globalStateModel.isPreviewModeOnly)
                promiseArr.push(obj.build());
            else
                promiseArr.push(obj.previewBuild());

            this.programmeUnitStandards.push(obj);
        }

        await Promise.all(promiseArr);
        
        //if training plan has been opened first time and there are no training plan unitstandards then create
        if (!this._globalStateModel.isPreviewModeOnly) {
            promiseArr = [];
            let progUsWithNoTUP = this.programmeUnitStandards.filter(item => !item.isSelected);

            for await (const progUS of progUsWithNoTUP)
                promiseArr.push(progUS.createForCompulsary());

            await Promise.all(promiseArr);
            promiseArr = [];
            for await (const progUS of progUsWithNoTUP)
                promiseArr.push(progUS.initiateTPURetrieve());
            await Promise.all(promiseArr);
        }


        this.programmeUnitStandards.sort((item1, item2) => (item1.unitStandard?.tims_name as string) > (item2.unitStandard?.tims_name as string) ? 1 : -1);
        this.loadedCompletely = true;
    }


    templateName(isEditMode: boolean) {
        return isEditMode ? Templates.ItemSelectionEdit : Templates.ItemSelection;
    }
    _hasRows() {
        return this.programmeUnitStandards.length >= 1;
    }

    get rows() {
        return this.programmeUnitStandards;
    }

    get _hasSelectedRows() {
        return this.programmeUnitStandards.filter(item => item.isSelected).length > 0;
    };

    toggleVisible() {
        this.visible = !this.visible;
    };

    setEditMode(mode: boolean) {
        this.isEditMode = mode;
    };


    get hasProgrammeUnitStandards() {
        return this.programmeUnitStandards.length >= 1;
    };

    // Sums for credits
    get achievedCredits() {
        return _.reduce(this.programmeUnitStandards, (memo, pus) => {
            if ((pus.isChecked && pus.isAchieved) || pus.isExempt) {
                return memo + (pus.credits as number);
            }
            return memo;
        }, 0);
    };

    get selectedCredits() {
        return _.reduce(this.programmeUnitStandards, function (memo, pus) {
            if (pus.isChecked) {
                return memo + (pus.credits as number);
            }
            return memo;
        }, 0);
    };

    get _remainingCredits() {
        return this.selectedCredits - this.achievedCredits;
    };

    getCreditCountByLevel(level: number): number {
        return _.reduce(this.programmeUnitStandards, function (memo, pus) {
            if (pus.isChecked && pus.unitStandard?.tims_level == level) {
                return memo + (pus.credits as number);
            }
            return memo;
        }, 0);
    }

    // get achieved credits above level
    getAchievedCreditsAboveLevel(level: number) {
        var self = this;
        return _.reduce(this.programmeUnitStandards, function (memo, pus) {
            if (pus.isChecked && (pus.unitStandard?.tims_level as number) >= level && pus.isAchieved) {
                return memo + (pus.credits as number);;
            }
            return memo;
        }, 0);
    };

    // get selected credits above level
    getSelectedCreditsAboveLevel(level: number) {
        var self = this;
        return _.reduce(this.programmeUnitStandards, function (memo, pus) {
            if (pus.isChecked && (pus.unitStandard?.tims_level as number) >= level) {
                return memo + (pus.credits as number);;
            }
            return memo;
        }, 0);
    };
}