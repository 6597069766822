import { D365Helper } from "../Common/D365Helper";
import { Events, RequestType } from "../Common/Enum";
import Emitter from "../Common/EventEmitter";
import { AccountModel } from "../Models/AccountModel";
import GlobalStateModel from "../Models/GlobalStateModel";
import { Serializable } from "../Models/Serializable";
import TokenModel from "../Models/TokenModel";

const emailDescription = `Thank you for contributing to our recent meeting. Please find attached a copy of the Training Plan with our agreed goals captured in the goal box. Please let me know if this is not a true reflection of our conversation.`;

export default class SendEmailHelper {

    _globalState: GlobalStateModel;
    _isLearner: boolean;
    _pdfBase64: string;
    constructor(globalStateModel: GlobalStateModel, isLearner: boolean, pdfBase64: string) {
        this._globalState = globalStateModel;
        this._isLearner = isLearner;
        this._pdfBase64 = pdfBase64;
    }

    async initEmailProcess() {

        let contactId = await this.getContactId();
        let emailID = await this.createEmail(contactId as string);
        if (emailID != undefined) {
            await this.createAttachment(emailID, this._pdfBase64);
            //await sendEmail(emailID);
        }
        let left = (window.screen.width - 1000) / 2;
        let top = (window.screen.height - 1000) / 2;

        let emailRecordUrl = this._globalState.tokenModel?.apiUrl + "/main.aspx?pagetype=entityrecord&etn=email&id=" + emailID;
        window.open(emailRecordUrl, 'Email');
    }

    async getContactId() {
        let accountModel = new AccountModel(this._globalState.tokenModel as TokenModel);
        if (!this._isLearner) {
            if (this._globalState.trainingContractObj?._tims_accountworkplaceid_value != undefined) {
                let account = await accountModel.retrieveAccount(this._globalState.trainingContractObj?._tims_accountworkplaceid_value);
                if (account.length > 0)
                    return account[0].contact.contactid as string;
            }
        } else
            return this._globalState.contact?.contactid as string;

    }

    createAttachment = async (activityid: string, body: string) => {
        var entity = {} as any;
        entity.body = body.split(",")[1];
        entity["objectid_activitypointer@odata.bind"] = "/activitypointers(" + activityid + ")";
        entity.objecttypecode = 'email';
        entity.filename = "Training_Plan_" + this._globalState.contact?.fullname?.replace(" ", "_") + ".pdf";
        entity.mimetype = "application/pdf";

        let d365Helper = new D365Helper(this._globalState.tokenModel as TokenModel);
        let resp = await d365Helper.Create("activitymimeattachments", entity, ["activitymimeattachmentid"]) as Serializable;
        if (resp.error == undefined) {
            //success
        } else {
            Emitter.emit(Events.SetMessageBar, { errors: [resp.error?.message], isSuccess: false, requestType: RequestType.SEND_EMAIL, showMessageBar: true });
        }
    }

    createEmail = async (contactId: string) => {

        var data = {
            "description": emailDescription,   ///Email Body
            "subject": "Training Plan - " + this._globalState.contact?.fullname,
            "regardingobjectid_tims_trainingplan@odata.bind": "/tims_trainingplans(" + this._globalState.trainingPlanObj?.tims_trainingplanid + ")",
            "email_activity_parties": [
                {
                    "partyid_systemuser@odata.bind": "/systemusers(" + this._globalState.tokenModel?.userId + ")",
                    "participationtypemask": 1   ///From Email
                },
                {
                    "partyid_contact@odata.bind": "/contacts(" + contactId + ")",
                    "participationtypemask": 2 ///To Email
                }
            ]
        };

        let d365Helper = new D365Helper(this._globalState.tokenModel as TokenModel);
        let resp = await d365Helper.Create("emails", data, ["activityid"]);
        if (resp.error == undefined)
            return resp.activityid;
        else {
            Emitter.emit(Events.SetMessageBar, { errors: [(resp as Serializable).error?.message], isSuccess: false, requestType: RequestType.SEND_EMAIL, showMessageBar: true });
            return undefined;
        }

    }
}