import { Serializable } from './Serializable';
import TokenModel from "./TokenModel"

const entityName = "tims_results";

var cache = [] as any[];
export class ResultModel extends Serializable {

    constructor(tokenModel: TokenModel) {
        super(tokenModel);
    }

    _tims_unitstandardid_value: string | undefined;
    statuscode: number | undefined;
    _tims_originatingtrainingplanid_value: string | undefined;
    _tims_courseid_value: string | undefined;
    tims_datesenttonzqa: string | undefined;
    tims_dateofachievement: string | undefined;
    _tims_contacttraineeid_value: string | undefined;
    tims_resultid: string | undefined;
    tims_name: string | undefined;
    tims_ispriorresult: boolean | undefined;
    tims_isnzqaapproved: boolean | undefined;
    tims_isachievedthroughexemption: boolean | undefined;
    tims_datetheorypassed: string | undefined;
    tims_datepracticalpassed: string | undefined;
    _tims_contactassessorid_value: string | undefined;
    tims_assessmenttype: number | undefined;
    tims_assessedby: number | undefined;

    clearCache() {
        cache = [];
    }

    async initCache(traineeId: string, getFromCache: boolean = true) {

        var fetch = [
            "<fetch version='1.0' output-format='xml-platform' mapping='logical' distinct='false'>",
            "  <entity name='tims_result'>",
            "    <attribute name='tims_unitstandardid' />",
            "    <attribute name='statuscode' />",
            "    <attribute name='tims_originatingtrainingplanid' />",
            "    <attribute name='tims_courseid' />",
            "    <attribute name='tims_datesenttonzqa' />",
            "    <attribute name='tims_dateofachievement' />",
            "    <attribute name='tims_contacttraineeid' />",
            "    <attribute name='tims_resultid' />",
            "    <attribute name='tims_name' />",
            "    <attribute name='tims_ispriorresult' />",
            "    <attribute name='tims_isnzqaapproved' />",
            "    <attribute name='tims_isachievedthroughexemption' />",
            "    <attribute name='tims_datetheorypassed' />",
            "    <attribute name='tims_datepracticalpassed' />",
            "    <attribute name='tims_contactassessorid' />",
            "    <attribute name='tims_assessmenttype' />",
            "    <attribute name='tims_assessedby' />",
            "    <link-entity name='tims_unitstandard' from='tims_unitstandardid' to='tims_unitstandardid' alias='unitstandard'>",
            "       <attribute name='tims_version' />",
            "       <attribute name='tims_frameworkelementcode' />",
            "       <attribute name='tims_level' />",
            "       <attribute name='tims_credits' />",
            "       <attribute name='tims_frameworkelementid' />",
            "    </link-entity>",
            "    <filter type='and'>",
            "      <condition attribute='tims_contacttraineeid' operator='eq' value='#CONTACTID#' />",
            "    </filter>",
            "  </entity>",
            "</fetch>"
        ].join('').replace('#CONTACTID#', traineeId);

        cache = await this.d365Helper.ExecuteFetch(entityName, fetch, getFromCache);
    }

    retrieveResultsForUnitStandard(unitStandardId: string) {

        return cache.find(elem => elem.tims_unitstandardid == unitStandardId);
    }

    retrieveResultsForUnitStandardCode(unitStandardCode: string) {
        return cache.find(elem => elem["unitstandard.tims_frameworkelementcode"] == unitStandardCode);
    }

    retrieveAchievedResults() {
        return cache;
    }

    async retrieveResultsWithFrameworkElementFiltering(traineeId: string, potentialFrameworkElementsCriteria: string, getFromCache: boolean = true) {
        var fetch = [
            "<fetch version='1.0' output-format='xml-platform' mapping='logical' distinct='false'>",
            "  <entity name='tims_result'>",
            "    <attribute name='tims_unitstandardid' />",
            "    <attribute name='statuscode' />",
            "    <attribute name='tims_originatingtrainingplanid' />",
            "    <attribute name='tims_courseid' />",
            "    <attribute name='tims_datesenttonzqa' />",
            "    <attribute name='tims_dateofachievement' />",
            "    <attribute name='tims_contacttraineeid' />",
            "    <attribute name='tims_resultid' />",
            "    <attribute name='tims_name' />",
            "    <attribute name='tims_ispriorresult' />",
            "    <attribute name='tims_isnzqaapproved' />",
            "    <attribute name='tims_isachievedthroughexemption' />",
            "    <attribute name='tims_datetheorypassed' />",
            "    <attribute name='tims_datepracticalpassed' />",
            "    <attribute name='tims_contactassessorid' />",
            "    <attribute name='tims_assessmenttype' />",
            "    <attribute name='tims_assessedby' />",
            "    <link-entity name='tims_unitstandard' from='tims_unitstandardid' to='tims_unitstandardid' alias='unitstandard'>",
            "       <attribute name='tims_version' />",
            "       <attribute name='tims_frameworkelementcode' />",
            "       <attribute name='tims_level' />",
            "       <attribute name='tims_credits' />",
            "       <attribute name='tims_frameworkelementid' />",
            "       <link-entity name='tims_frameworkelement' from='tims_frameworkelementid' to='tims_frameworkelementid' alias='frameworkelement'>",
            "          <filter type='or'>",
            potentialFrameworkElementsCriteria,
            "          </filter>",
            "       </link-entity>",
            "    </link-entity>",
            "    <filter type='and'>",
            "      <condition attribute='tims_contacttraineeid' operator='eq' value='#CONTACTID#' />",
            "    </filter>",
            "  </entity>",
            "</fetch>"
        ].join('').replace('#CONTACTID#', traineeId);

        var results = [] as any[];
        if (potentialFrameworkElementsCriteria == '')
            return results;


        return await this.d365Helper.ExecuteFetch(entityName, fetch, getFromCache);
    }

    async createResult(options: any) {

        //var result = {} as any;

        var result = {} as any;
        result["tims_contacttraineeid@odata.bind"] = "/contacts(" + options.tims_contacttraineeid + ")";
        result["tims_unitstandardid@odata.bind"] = "/tims_unitstandards(" + options.tims_unitstandardid + ")";
        result.tims_assessmenttype = options.tims_assessmenttype;
        result.tims_assessedby = options.tims_assessedby;
        result["tims_contactassessorid@odata.bind"] = "/contacts(" + options.tims_contactassessorid + ")";
        result["tims_originatingtrainingplanid@odata.bind"] = "/tims_trainingplans(" + options.tims_trainingplanid + ")";

        if (options.tims_datepracticalpassed)
            result.tims_datepracticalpassed = options.tims_datepracticalpassed
        if (options.tims_datetheorypassed)
            result.tims_datetheorypassed = options.tims_datetheorypassed
        var resultResp = await this.d365Helper.Create(entityName, result, this.createColumnArray(this));
        return resultResp;
    }

    async updateResult(resultId: string, options: any) {

        var result = {} as any;

        result["tims_contactassessorid@odata.bind"] = "/contacts(" + options.tims_contactassessorid + ")";

        if (options.tims_datepracticalpassed)
            result.tims_datepracticalpassed = options.tims_datepracticalpassed
        if (options.tims_datetheorypassed)
            result.tims_datetheorypassed = options.tims_datetheorypassed

        return await this.d365Helper.Update(entityName, resultId, result);
    }

    retrieveCachedResult(resultId: string) {

        return cache.find(elem => elem.tims_resultid == resultId);
    }

    async retrieveResult(resultId: string, getfromCache: boolean = true) {

        var fetch = [
            "<fetch version='1.0' output-format='xml-platform' mapping='logical' distinct='false'>",
            "  <entity name='tims_result'>",
            "    <attribute name='tims_unitstandardid' />",
            "    <attribute name='statuscode' />",
            "    <attribute name='tims_originatingtrainingplanid' />",
            "    <attribute name='tims_courseid' />",
            "    <attribute name='tims_datesenttonzqa' />",
            "    <attribute name='tims_dateofachievement' />",
            "    <attribute name='tims_contacttraineeid' />",
            "    <attribute name='tims_resultid' />",
            "    <attribute name='tims_name' />",
            "    <attribute name='tims_ispriorresult' />",
            "    <attribute name='tims_isnzqaapproved' />",
            "    <attribute name='tims_isachievedthroughexemption' />",
            "    <attribute name='tims_datetheorypassed' />",
            "    <attribute name='tims_datepracticalpassed' />",
            "    <attribute name='tims_contactassessorid' />",
            "    <attribute name='tims_assessmenttype' />",
            "    <attribute name='tims_assessedby' />",
            "    <link-entity name='tims_unitstandard' from='tims_unitstandardid' to='tims_unitstandardid' alias='unitstandard'>",
            "       <attribute name='tims_version' />",
            "       <attribute name='tims_frameworkelementcode' />",
            "       <attribute name='tims_level' />",
            "       <attribute name='tims_credits' />",
            "       <attribute name='tims_frameworkelementid' />",
            "    </link-entity>",
            "    <filter type='and'>",
            "      <condition attribute='tims_resultid' operator='eq' value='#RESULTID#' />",
            "    </filter>",
            "  </entity>",
            "</fetch>"
        ].join('').replace('#RESULTID#', resultId);

        let response = await this.d365Helper.ExecuteFetch(entityName, fetch, getfromCache);
        let currelementIndex = cache.findIndex((val: ResultModel, index, arr) => {
            return val.tims_resultid == resultId;

        });
        if (currelementIndex >= 0)
            cache[currelementIndex] = response[0];
        else
            cache.push(response[0]);

        return response[0];
    }


}