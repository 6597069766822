import { ContactModel } from './ContactModel';
import { Serializable } from './Serializable';
import TokenModel from "./TokenModel"

const entityName = 'tims_trainingplans';
var attrs = {
    tims_name: "tims_name",
    tims_identifier: "tims_identifier",
    statuscode: "statuscode",
    tims_programmeid: "_tims_programmeid_value",
    tims_achievedcredits: "tims_achievedcredits",
    tims_mustrefreshunitstandards: "tims_mustrefreshunitstandards",
    tims_remainingcredits: "tims_remainingcredits",
    tims_requiredcredits: "tims_requiredcredits,",
    tims_selectedcredits: "tims_selectedcredits",
    tims_trainingprogrammeid: "_tims_trainingprogrammeid_value",
    tims_trainingplanid: "tims_trainingplanid",
    tims_defaultassessorid: "_tims_defaultcontactassessorid_value",
    tims_dateofitrenrolmentstart: "tims_dateofitrenrolmentstart",
    tims_datetrainingplanstarted: "tims_datetrainingplanstarted",
    fus_transferdate:"fus_transferdate",
    tims_itrregistrationstatus: "tims_itrregistrationstatus",
    tims_haveunitstandardschanged: "tims_haveunitstandardschanged",
    tims_programmecontextid: "_tims_programmecontextid_value",
    tims_programmecontextidname: "_tims_programmecontextid_value@OData.Community.Display.V1.FormattedValue",
    ownerId: "_ownerid_value"
};

export default class TrainingPlanModel extends Serializable {

    constructor(tokenModel: TokenModel) {
        super(tokenModel);
    }

    tims_name: string | undefined;
    tims_identifier: string | undefined;
    statuscode: number | undefined;
    _tims_programmeid_value: string | undefined;
    tims_achievedcredits: number | undefined;
    tims_mustrefreshunitstandards: string | undefined;
    tims_remainingcredits: number | undefined;
    tims_requiredcredits: number | undefined;
    tims_selectedcredits: number | undefined;
    _tims_trainingprogrammeid_value: string | undefined;
    tims_trainingplanid: string | undefined;
    _tims_defaultcontactassessorid_value: string | undefined;
    tims_dateofitrenrolmentstart: string | undefined;
    tims_datetrainingplanstarted: string | undefined;
    fus_transferdate:string | undefined;
    tims_itrregistrationstatus: number | undefined;
    tims_haveunitstandardschanged: boolean | undefined;
    _tims_programmecontextid_value: string | undefined;
    _ownerid_value: string | undefined;



    defaultAccessor: ContactModel | undefined;

    async retrieveTrainingPlan(trainingPlanId: string, getFromCache: boolean) {

        var columnSet = [
            attrs.tims_trainingplanid,
            attrs.tims_name,
            attrs.tims_identifier,
            attrs.statuscode,
            attrs.tims_programmeid,
            attrs.tims_trainingprogrammeid,
            attrs.tims_achievedcredits,
            attrs.tims_mustrefreshunitstandards,
            attrs.tims_remainingcredits,
            attrs.tims_selectedcredits,
            attrs.tims_defaultassessorid,
            attrs.tims_dateofitrenrolmentstart,
            attrs.fus_transferdate,
            attrs.tims_datetrainingplanstarted,
            attrs.tims_itrregistrationstatus,
            attrs.tims_programmecontextid,
            attrs.ownerId
        ];

        var trainingPlan = {} as any;
        trainingPlan = await this.d365Helper.Retrieve(entityName, trainingPlanId, columnSet, getFromCache);
        var tpModel = trainingPlan as TrainingPlanModel;

        return trainingPlan;
    }

    async setStateActive(trainingPlanId: string) {
        
        return await this.d365Helper.Update(entityName, trainingPlanId, {
            statuscode: 3
        } as any);
    }

    async setUpdateITR(trainingPlanId: string) {
        var update = {} as any;
        update[attrs.tims_haveunitstandardschanged] = true;

        return await this.d365Helper.Update(entityName, trainingPlanId, update);
    }

}

