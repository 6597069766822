import TokenModel from '../Models/TokenModel';
import { UnitStandardModel } from '../Models/UnitStandardModel';
import TrainingPlanUnitStandard from "./TrainingPlanUnitStandard";
import Result from './Result';
import CrossCreditSearchHelper from './CrossCreditSearchHelper';
import { ResultModel } from '../Models/ResultModel';
import { ProgrammeUnitStandardModel } from "../Models/ProgrammeUnitStandardModel"
import ElectiveSectionModel from '../Models/ElectiveSectionModel';
import CommentForm from "./CommentForm";
import ResultForm from "./ResultForm";
import { TrainingPlanCommentModel } from '../Models/TrainingPlanCommentModel';
import { Templates } from "../Common/Enum";
import GlobalStateModel from '../Models/GlobalStateModel';
import TrainingPlanUnitStandardModel from '../Models/TrainingPlanUnitStandardModel';
import { VisitModel } from '../Models/VisitModel';


export interface IUSCheckedEventArg {
    frameworkElementCode: string;
    electiveSectionId: string,
    checked: boolean
}
export default class ComponentHelperBase {

    unitStandard: UnitStandardModel | undefined;
    _trainingPlanUnitStandard: TrainingPlanUnitStandard | undefined;
    _result: ResultModel | undefined;
    _electiveSectionM: ElectiveSectionModel | undefined;
    _isChecked: boolean;
    _visit: VisitModel | undefined
    commentForm: CommentForm | undefined;
    resultForm: ResultForm | undefined;
    comment: TrainingPlanCommentModel | undefined;
    _progUnitStandard: ProgrammeUnitStandardModel | undefined;
    isEditMode: boolean | undefined;
    _globalStateModel: GlobalStateModel | undefined;
    _resultHelper: Result | undefined;
    trainingPlanUnitStandards: TrainingPlanUnitStandard[] | undefined;
    preReqUnitstandard: UnitStandardModel[] | undefined;
    isDisabled: boolean;

    _searchHelper: CrossCreditSearchHelper | undefined;
    /**
     *
     */
    constructor() {
        this._isChecked = false;
        this.isDisabled = false;
    }

    get resultHelper() {
        return new Result(this._result as ResultModel);
    }

    deselectFrameworkElementCode(option: IUSCheckedEventArg) {

    }

    selectFrameworkElementCode(option: IUSCheckedEventArg) {

    };
    get searchHelper() {

        if (!this._searchHelper) {
            this._searchHelper = new CrossCreditSearchHelper(this._electiveSectionM?.tims_electivesectionid as string, this._globalStateModel as GlobalStateModel, this._trainingPlanUnitStandard?._tpuModel as TrainingPlanUnitStandardModel);
        }

        return this._searchHelper;
    }

    async build() {

    }

    _trainingPlanUnitStandardCreated(tpus: TrainingPlanUnitStandardModel) {

    }

    sync() {

    }

    templateName(isEditMode: boolean): Templates {
        return isEditMode ? Templates.ItemSelectionEdit : Templates.ItemSelection;
    }

    onCheckboxChanged(isChecked: boolean) {
    }

    setEditMode(mode: boolean) {
    };

    
    setDisabled(mode: boolean) {
    };

    getCreditCountByLevel(level:number){
        return 0
    };


    get isITRLocked() {
        return false;
    }

    get isSelectedOtherSection() {
        return false;
    }

    get isCompliant() {
        return false;
    }

    get columns() {
        return [
        ] as string[];
    }

    get resourceStatus() {
        return 0;
    }

    get isAllowedUnitStandardResources() {
        return 0;
    }

    get dataMapper() {
        return [
        ] as string[];
    }

    get rows() {
        return [] as ComponentHelperBase[];
    }

    get isDirty() {
        return false;
    };

    get isChecked() {
        return false;
    }
    get assessmentType() {
        return "" as string | undefined;
    };

    get credits() {

        return 0 as number | undefined;
    };

    get isExempt() {

        return false;
    };

    get isExpiringThisYear(){
        return false
    }

    get hasResource() {
        return false;
    };

    get isSelected() {
        return false
    };

    get isAchieved() {
        return false;
    };

    get isAchievedPendingPreReq() {
        return false;
    };

    get isPartiallyAchieved() {
        return false as boolean | undefined;
    };

    get selectedCredits() {
        return 0;
    }

    get selectedMinimumCredits(){
        return 0;
    }

    get selectedMaximumCredits(){
        return 0;
    }

    get selectedUnits() {
        return 0;
    }

    get achievedCredits() {
        return 0;
    }

    get _remainingCredits() {
        return 0;
    }

    get excludeCredits() {
        return false;
    }

    get hasPrerequisites() {
        return false;
    }


}