import { Serializable } from './Serializable';
import TokenModel from "./TokenModel"
import { ContactRole } from "../Common/Enum";

const entityName = "contacts";
var cachedAssessor = {} as any;

export class ContactModel extends Serializable {


    public tims_nsnnumber: string | undefined;
    public contactid: string | undefined;
    public fullname: string | undefined;
    firstname:string | undefined;
    lastname:string | undefined
    tims_dateoflastvisit: string | undefined;
    tims_dateofnextvisit: string | undefined;
    tims_dateworkpermitexpires: string | undefined;
    emailaddress1: string | undefined;

    /**
     *
     */
    constructor(tokenModel: TokenModel) {
        super(tokenModel);
    }

    async retrieveContact(contactId: string, getFromCache: boolean = true) {
        return await this.d365Helper.Retrieve(entityName, contactId, this.createColumnArray(this), getFromCache);
    }

    async updateContactLastVisitDate(dateOfNextVsit: Date | null, contactId: string, dateOfLastVisit: string) {
        var entity = { tims_dateofnextvisit: dateOfNextVsit, tims_dateoflastvisit: dateOfLastVisit };
        return await this.d365Helper.Update(entityName, contactId, entity);
    }

    async retrieveAssessorEntityReference(trainingPlanId: string, getFromCache: boolean = true) {
        let trainingPlan = await this.d365Helper.Retrieve("tims_trainingplans", trainingPlanId, ["_tims_defaultcontactassessorid_value"], getFromCache);
        if (trainingPlan == null || !trainingPlan._tims_defaultcontactassessorid_value) {
            cachedAssessor[trainingPlanId] = null;
            return null;
        }
        var contactModel = await this.retrieveContact(trainingPlan._tims_defaultcontactassessorid_value, getFromCache) as ContactModel;
        cachedAssessor[trainingPlanId] = contactModel;
        return contactModel;
    }

    async retrieveContactByName(searchValue: string, contactRole: ContactRole) {

        let fieldName = "";
        let conditionClause = "";

        switch (contactRole) {
            case ContactRole.Assessor:
                conditionClause = `<filter type="or"> 
                                <condition attribute="tims_assessorstatus" operator="eq" value="100000001" /> 
                                <condition attribute="tims_assessorstatus" operator="eq" value="100000005" /> 
                                </filter>`;
                break;
            case ContactRole.Verifier:
                conditionClause = `<filter type="and"> 
                        <condition attribute="tims_verifier" operator="eq" value="1" /> 
                        <condition attribute="statecode" operator="eq" value="0" /> 
                            </filter>`;
                break;
        }

        let query =
            `<fetch top="50">
            <entity name="contact" >
                <attribute name="firstname" />
                    <attribute name="contactid" />
                        <attribute name="lastname" />
                            <filter type="or" >
                                <condition attribute="fullname" operator = "like" value = "%`+ searchValue + `%" />
                            </filter>
                            `+ conditionClause + `
                            <order attribute="firstname" />
                </entity>
            </fetch>`;

        let response = await this.d365Helper.ExecuteFetch(entityName, query, true);
        return response;
    }
}