import { FrameworkElementModel } from '../Models/FrameworkElementModel';
import GlobalStateModel from '../Models/GlobalStateModel';
import TokenModel from '../Models/TokenModel';
import { UnitStandardModel } from '../Models/UnitStandardModel';
import { SearchResultModel } from '../Models/SearchResultModel';
import TrainingPlanUnitStandardModel from '../Models/TrainingPlanUnitStandardModel';

const cache = new Map();

export default class CrossCreditSearchHelper {

    _tokenModel: TokenModel | undefined;
    _globalStateModel: GlobalStateModel;
    _electiveSectionId: string;
    _filter: string;
    _initializationPromise: Promise<void>|undefined;
    _unitStandard: UnitStandardModel;
    _trainingPlanUnitStandard: TrainingPlanUnitStandardModel;
    _query: string;

    constructor(electiveSectionId: string, globalStateModel: GlobalStateModel, trainingPlanUnitStandardModel: TrainingPlanUnitStandardModel | null) {
        this._electiveSectionId = electiveSectionId;
        this._globalStateModel = globalStateModel;
        this._tokenModel = this._globalStateModel.tokenModel as TokenModel;
        this._unitStandard = new UnitStandardModel(this._tokenModel as TokenModel);
        this._trainingPlanUnitStandard = trainingPlanUnitStandardModel ?? new TrainingPlanUnitStandardModel(this._tokenModel as TokenModel);
        this._filter = "";
        this._query = "";
    }
    
    async _doInitialize() {
        let frameworkElem = new FrameworkElementModel(this._tokenModel as TokenModel);
        this._filter = await frameworkElem.generateFrameworkElementFilter(this._electiveSectionId);
      }
    
      async _initialize() {
        // prevent concurrent calls firing initialization more than once
        if (!this._initializationPromise) {
          this._initializationPromise = this._doInitialize();
        }
        return this._initializationPromise;
      }

    async searchUnitStandard(query: string) {
      this._query = query;
      await this._initialize();

        try {
          let unitStandardModels = await  this._unitStandard.retrieveUnitStandardsFilteredFE(this._query,this._filter);
          cache.set(this._electiveSectionId,unitStandardModels.map( i=> new SearchResultModel(i)));      
          return cache.get(this._electiveSectionId);

        } catch (error) {
            return [] as SearchResultModel[];
        }
        
    }
  
    async retrieveUnitByCode(code: string) {

      await this._initialize();

        try {

          if(!cache.has(this._electiveSectionId))
          {
            let unitStandardModels = await  this._unitStandard.retrieveUnitStandardsFilteredFE(this._query,this._filter);

            cache.set(this._electiveSectionId,unitStandardModels.map( i=> new SearchResultModel(i)));
          }
          return cache.get(this._electiveSectionId).find( (x:SearchResultModel) => x.UnitStandard === code);

        } catch (error) {
            return [] as SearchResultModel[];
        }
        
    }
   
}