import * as React from "react";
import { Stack, StackItem } from "@fluentui/react";
import { DefaultPalette } from "@fluentui/react/lib/Styling";
import { IconButton } from "@fluentui/react/lib/Button";
import "../assets/css/GridControl.css";

export const TableHeader: React.FunctionComponent<TableHeaderProps> = (props) => {
    const [hover, setHover] = React.useState(false)

    const getChev = () => {
        if(!props.isSelected){
            return "Down"
        } else {
            return props.sortOrder ? "Up" : "Down"
        }
    }

    return (
        <th 
        onClick={props.onClick} 
        hidden={props.hidden}
        onMouseEnter={() => { setHover(true)} } 
        onMouseLeave={() => { setHover(false)}}
        style = {{cursor:'pointer', background: hover ? DefaultPalette.neutralLighter : "", textAlign:'center' }}
        >
       <div className="d-flex flex-row justify-content-center" style={{position:'relative'}}>
            <div className="d-flex col-sm align-items-center"></div>
                <div className="d-flex col-sm-6 align-items-center">      
                    {props.children}
                    <div className={"sort-icon" + (props.isSelected ? " selected" : "")} style = {{ visibility: (props.isSelected || hover) && props.sortable ? 'visible' : 'hidden'}}>
                        <IconButton iconProps={{ iconName: getChev() }} />
                    </div>
                </div>
            <div className="d-flex col-sm-2 align-items-center">
                {props.crossCreditButton ? props.crossCreditButton : ""}
            </div>
        </div>
      </th> 
    )
}

interface TableHeaderProps {
    isSelected?:boolean,
    onClick?: React.MouseEventHandler
    sortOrder?: Boolean,
    sortable: Boolean,
    children:React.ReactNode,
    crossCreditButton?:React.ReactElement,
    hidden?:boolean
}