import React, { Fragment, useState } from "react";
import GlobalState from "../GlobalState";
import { useBoolean, useId } from '@fluentui/react-hooks';
import { GridControl} from "./GridControl";

import { ReorderControlContext } from "../Contexts/ReorderControlContext";
import { Events, RequestType, SortReorderCode, Templates, Views } from "../Common/Enum";
import { cancelIcon, contentStyles, iconButtonStyles } from '../Common/ModalHelper';
import { ChoiceGroup, ContextualMenu, DefaultButton, Dialog, DialogFooter, DialogType, hiddenContentStyle, IChoiceGroupOption, mergeStyles, PrimaryButton, Stack, Modal, IconButton, StackItem, Checkbox, IStackTokens, TextField, IStackItemStyles, composeComponentAs, IDropdownOption, IDragOptions} from "@fluentui/react";
import Loader from "react-loader-spinner";
import ReorderHelper from '../ComponentHelpers/ReorderHelper';
import Emitter from "../Common/EventEmitter";
import GlobalStateModel from "../Models/GlobalStateModel";
import { IMessagebarObject, MasterControlContext, TrainingPlanViewsContext, ReorderContState } from "./MasterControl";
import TokenModel from "../Models/TokenModel";
import Common from "../Common/Common";
import { SummaryObject } from "./ElectiveGroupControl";
import { TrainingPlanViewColumns, TrainingPlanViewModel } from "../Models/TrainingPlanViewModel";
import { Serializable } from "../Models/Serializable";

const itemAlignmentsStackTokens: IStackTokens = { childrenGap: 5, padding: 5 };

const dialogStyles = { main: { maxWidth: 450 } };

const dialogContentProps = {
    type: DialogType.largeHeader,
    title: `Let's get it sorted`,
    closeButtonAriaLabel: 'Close',
    subText: "Units on this Training Plan have not been ordered yet. Please pick the initial sort order:",
};

const deleteViewDialogContentProps = {
    type: DialogType.normal,
    title: 'Confirm Deletion',
    closeButtonAriaLabel: 'Close',
    subText: `Are you sure you want to delete this saved view? This action cannot be undone.`,
};

const columnCheckboxOptions = [
    { key: 'tims_sequencenumber', label: 'Sequence Number', disabled:true },
    { key: 'tims_unitstandardname', label: 'Unit Standard Name', disabled:true },
    { key: 'tims_credits', label: 'Credits', disabled:true },
    { key: 'tims_level', label: 'Level', disabled:true },
    { key: 'tims_assessmenttype', label: 'Assessment Type', disabled:true },
    { key: 'tims_result', label: 'Result', disabled:true },
    { key: 'tims_course', label: 'Course' },
    { key: 'tims_coursestartdate', label: 'Course Start Date' },
    { key: 'tims_courseidandstartdate', label: 'Course code and start date' },
    { key: 'tims_assessmentstatus', label: 'Assessment Status' },
    { key: 'tims_assessmentstatusfinal', label: 'Resubmission Status' },
    { key: 'tims_assessor', label: 'Assessor' },
    { key: 'tims_verifier', label: 'Verifier' },
    { key: 'tims_comments', label: 'Comments' },
  ];

  let defaultColumns = {
    tims_sequencenumber: true, tims_unitstandardname: true, tims_credits: true,
    tims_level: true, tims_assessmentstatus: false, tims_assessmenttype: true,
    tims_assessmentstatusfinal: false, tims_courseidandstartdate: false, 
    tims_coursestartdate: false, tims_result: true, tims_course: false,
    tims_assessor: true, tims_verifier: true, tims_comments: true
  } as TrainingPlanViewColumns;


export const ReorderControl: React.FunctionComponent = () => {

    const globalState = React.useContext(GlobalState);
    const ref = React.useRef(true);

    const masterControlDispatcher = React.useContext(MasterControlContext);
    const trainingPlanViewContext = React.useContext(TrainingPlanViewsContext);

    const reorderControlContext = React.useContext(ReorderControlContext)

    const [hideDialog, { toggle: toggleHideDialog, setFalse: setHideDialogFalse, setTrue: sethideDialogTrue }] = useBoolean(true);

    const [isCreateViewModalOpen, { setFalse: hideCreateViewModal, setTrue: showCreateViewModal }] = useBoolean(false);
    const [isSaveViewModalOpen, { setFalse: hideSaveViewModal, setTrue: showSaveViewModal }] = useBoolean(false);
    const [isDeleteViewDialogOpen, { setFalse: hideDeleteViewDialog, setTrue: showDeleteViewDialog }] = useBoolean(false);
    const [isDeletingVisit, { toggle: toggleDeletingVisit }] = useBoolean(false);

    const [isDraggable, { toggle: toggleIsDraggable }] = useBoolean(false);
    const [sortCode, setSortCode] = useState<null | SortReorderCode>(SortReorderCode.ResultFirst);
    const [viewName, setViewName] = useState("")
    const [selectedColumns, setSelectedColumns] = useState<TrainingPlanViewColumns>(defaultColumns)
    const labelId: string = useId('dialogLabel');
    const subTextId: string = useId('subTextLabel');
    
    if (!reorderControlContext) {
        throw new Error('Reorder control context is not available');
    }

    const {reorderState, reorderDispatcher} = reorderControlContext

    const options: IChoiceGroupOption[] = [
        {
            key: 'AcheivedFirst',
            text: 'Achieved First',
        },
        {
            key: 'AcheivedLast',
            text: 'Achieved Last'
        },
        {
            key: 'ByUnitID',
            text: 'By Unit ID'
        },
    ];

    const modalProps = React.useMemo(
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: false,
            styles: dialogStyles,
            dragOptions: isDraggable ? dragOptions : undefined,
        }),
        [isDraggable, labelId, subTextId],
    );

    const dragOptions: IDragOptions = {
        moveMenuItemText: 'Move',
        closeMenuItemText: 'Close',
        menu: ContextualMenu,
        dragHandleSelector: '.ms-Modal-scrollableContent > div:first-child',
      };

    const retrieveColumns = async () => {
        let columns = defaultColumns
        if(globalState.selectedView == Views.Custom){
            let trainingPlanViewModel = new TrainingPlanViewModel(globalState.tokenModel as TokenModel)
            columns = await trainingPlanViewModel.retrieveTrainingPlanView(globalState.selectedCustomView as string)
        }
        return columns
    }


    React.useEffect(() => {
        const initializeDataLoad = async () => {
            let reorderHelper = new ReorderHelper(globalState);   
            
            await reorderHelper.build();

            reorderHelper.sortBySequenceNumber();

            let tpusWithoutSeqNumber = reorderHelper.rows.filter(item => item._tpuModel.fus_sequencenumber == undefined);
            if (tpusWithoutSeqNumber.length > 0 && !globalState.IsPortalMode)
                setHideDialogFalse();

            if (!ref.current)
                return;

            if (globalState.IsPortalMode) {
                if (masterControlDispatcher != undefined) {
                    if (typeof masterControlDispatcher == "function")
                        masterControlDispatcher({
                            type: "INIT_SUMMARY", obj: { selected: reorderHelper.selectedCredits, achieved: reorderHelper.achievedCredits, remaining: reorderHelper._remainingCredits, id: Common.newGuid() } as SummaryObject
                        });
                }
            }
            let columns = await retrieveColumns()
            reorderDispatcher({ type: "INIT", obj: { reorderHelp: reorderHelper, showLoader: false, trainingPlanView:columns } as ReorderContState });    
            Emitter.on(Events.SaveOrder, async () => {
                reorderDispatcher({ type: "SAVE_ORDER" })
            });

            Emitter.on(Events.OpenCreateViewModal, async () => {
                setViewName("")
                showCreateViewModal()
            })

            Emitter.on(Events.OpenSaveViewModal, async () => {
                showSaveViewModal()
            })

            Emitter.on(Events.DeleteView, async () => {
                showDeleteViewDialog()
            })

            ref.current = false;
        }
        initializeDataLoad();

        return () => {
            Emitter.off(Events.SaveOrder, () => { });
            Emitter.off(Events.OpenCreateViewModal, () => { });
            Emitter.off(Events.OpenSaveViewModal, () => { });
            Emitter.off(Events.DeleteView, () => { });

        };
    }, []);


    React.useEffect(() => {
        const updateSelectedColumns = async () => {
            let columns = await retrieveColumns()
            setSelectedColumns(columns)
            
            reorderDispatcher({type:"SAVE_COLUMNS", obj:{trainingPlanView:columns}})
        }

        let globalContext = {
            ...globalState,
            isColumnsDirty:false
        } as GlobalStateModel
        globalState.setGlobalState(globalContext)
        updateSelectedColumns()
        
    }, [globalState.selectedCustomView])


    const sortTPUS = () => {
        if (sortCode == SortReorderCode.ResultFirst || sortCode == SortReorderCode.ResultLast) {

            reorderState.reorderHelp.sortByResult(sortCode);
            reorderState.reorderHelp.setSequenceNumberForTPUS(false);
            let globalContext = {
                ...globalState,
                IsReorderDirty: true
            } as GlobalStateModel;
            globalState.setGlobalState(globalContext);
            reorderDispatcher({ type: "INIT", obj: { reorderHelp: reorderState.reorderHelp, showLoader: false, trainingPlanView: reorderState.trainingPlanView } as ReorderContState });
        }
        else if (sortCode == SortReorderCode.ByUnits) {
                reorderState.reorderHelp.sortByFrameworkElementCode();
                let globalContext = {
                    ...globalState,
                    IsReorderDirty: true
                } as GlobalStateModel;
                globalState.setGlobalState(globalContext);
                reorderState.reorderHelp.setSequenceNumberForTPUS(false);
                reorderDispatcher({ type: "INIT", obj: { reorderHelp: reorderState.reorderHelp, showLoader: false, trainingPlanView:reorderState.trainingPlanView } as ReorderContState });
        }
    }
    const checkForDirty = () => {
        let dirty = false
        for(let key in selectedColumns){
            if (selectedColumns[key as keyof TrainingPlanViewColumns]  !== defaultColumns[key as keyof TrainingPlanViewColumns]) {
                dirty = true
              }
        }
        let globalContext = {
            ...globalState,
            isColumnsDirty:dirty
        } as GlobalStateModel
        globalState.setGlobalState(globalContext)
    }

    const renderCreateViewModal = () => {
        return(
        <Modal isDarkOverlay={false}
            titleAriaId="Add Visit"
            isOpen={isCreateViewModalOpen}
            onDismiss={hideCreateViewModal}
            isBlocking={false}
            containerClassName={contentStyles.container}
            >
                <div className={contentStyles.header}>
                <span id="Create_View">Edit Columns</span>
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={cancelIcon}
                        ariaLabel="Close popup modal"
                        onClick={hideCreateViewModal}
                    />
                </div>
                <Stack className={contentStyles.body} tokens={itemAlignmentsStackTokens}>
                    {columnCheckboxOptions.map(c => 
                         <StackItem key={c.key}>
                            <Checkbox disabled = {c.disabled} label={c.label}
                            checked={selectedColumns[c.key as keyof TrainingPlanViewColumns]} 
                            onChange={(e, checked) => { 
                                if(checked != undefined) setSelectedColumns({...selectedColumns, [c.key]:checked})
                            }}   
                            />
                    </StackItem>
                    )}
                    <StackItem>
                        <DefaultButton style={{ float: "right", marginTop: "7%" }} className="button-style" onClick={async (e) => {
                            reorderDispatcher({type:"SAVE_COLUMNS", obj:{trainingPlanView:selectedColumns}})
                            checkForDirty()
                            hideCreateViewModal()
                        } }>
                            OK
                        </DefaultButton>
                    </StackItem>
                </Stack>
        </Modal>
        )
    }

    const renderDeleteViewModal = () => {
        return (
            <Dialog
            hidden={!isDeleteViewDialogOpen}
            onDismiss={hideDeleteViewDialog}
            dialogContentProps={deleteViewDialogContentProps}
            modalProps={modalProps}
        >
            <DialogFooter>
                {isDeletingVisit ? <div style={{ marginLeft: "10px", paddingTop: "13px" }} className="lds-dual-ring"></div> : ""}
                <PrimaryButton 
                disabled={isDeletingVisit} 
                onClick={async (e) => {
                    toggleDeletingVisit()
                    await deleteTrainingPlanView();
                    hideDeleteViewDialog();
                    toggleDeletingVisit()
                }} text="Yes" />
                <DefaultButton 
                disabled={isDeletingVisit} 
                onClick={hideDeleteViewDialog} text="No" />
            </DialogFooter>
        </Dialog>);
    }

    const renderSaveViewModal = () => {
        return (
            <Modal isDarkOverlay={false}
            titleAriaId="Save View"
            isOpen={isSaveViewModalOpen}
            onDismiss={hideSaveViewModal}
            isBlocking={false}
            containerClassName={contentStyles.container}>
                <div className={contentStyles.header}>
                    <span id="Save_View">Save View</span>
                </div>
                    <Stack className={contentStyles.body} tokens={itemAlignmentsStackTokens}>
                     <StackItem styles={{root: { marginBottom:15  }}}>
                        <TextField
                            placeholder="View Name"
                            value={viewName}
                            onChange={(e, text) => {
                              if (text != undefined) {
                                setViewName(text)
                              }
                            }}
                            style={{ marginLeft: "5px", width:'9vw'}}
                        />
                    </StackItem>
                    <StackItem>
                        <DefaultButton style={{ float: "right", marginTop: "7%" }} className="button-style" onClick={async (e) => {
                            saveTrainingPlanView()
                            hideSaveViewModal()
                        }}>
                            Save
                        </DefaultButton>
                    </StackItem>
                </Stack>
            </Modal>
        )
    }

    const showDialog = () => {
        return (<Dialog
            hidden={hideDialog}
            onDismiss={toggleHideDialog}
            dialogContentProps={dialogContentProps}
            modalProps={modalProps}
        >
            <ChoiceGroup label="Please select one:" defaultSelectedKey="AcheivedFirst" options={options} onChange={(e, option) => {
                switch (option?.key) {
                    case "AcheivedFirst":
                        setSortCode(SortReorderCode.ResultFirst);
                        break;
                    case "AcheivedLast":
                        setSortCode(SortReorderCode.ResultLast);
                        break;
                    case "ByUnitID":
                        setSortCode(SortReorderCode.ByUnits);
                        break;
                    default:
                        break;
                }
            }} />
            <DialogFooter>
                <PrimaryButton onClick={() => {
                    toggleHideDialog();
                    sortTPUS();
                }} text="Confirm" />
                <DefaultButton onClick={() => { toggleHideDialog(); }} text="Back" />
            </DialogFooter>
        </Dialog>);
    }

    const saveTrainingPlanView = async () => {
        let tpViewModal = new TrainingPlanViewModel(globalState.tokenModel as TokenModel);

        let response = await tpViewModal.createTrainingPlanView({...reorderState.trainingPlanView, tims_name:viewName, tims_user:globalState.tokenModel?.userId})
        if(response.error == undefined){
            const newView = {
                key: response.tims_trainingplanviewid as string,
                text: viewName as string,
              } as IDropdownOption;
        
            trainingPlanViewContext?.updateTrainingPlanViews([...trainingPlanViewContext.trainingPlanViews, newView])

            Emitter.emit(Events.SetMessageBar, { errors: [], isSuccess: true, requestType: RequestType.CREATE_VIEW, showMessageBar: true } as IMessagebarObject);
        }
        else {
            Emitter.emit(Events.SetMessageBar, { errors: [response.error?.message], isSuccess: false, requestType: RequestType.CREATE_VIEW, showMessageBar: true } as IMessagebarObject);
        }
    }

    
    const updateTrainingPlanView = async () => {
        let tpViewModal = new TrainingPlanViewModel(globalState.tokenModel as TokenModel);
        let response = await tpViewModal.updateTrainingPlanView({...reorderState.trainingPlanView, tims_user:globalState.tokenModel?.userId}, reorderState.trainingPlanView.tims_trainingplanviewid as string)
        
        if(response.ok){
            let globalContext = { ...globalState, isColumnsDirty:false } as GlobalStateModel
            globalState.setGlobalState(globalContext)

            Emitter.emit(Events.SetMessageBar, { errors: [], isSuccess: true, requestType: RequestType.UPDATE_VIEW, showMessageBar: true } as IMessagebarObject);
        } else {
            let error = await response.json() as Serializable;
            Emitter.emit(Events.SetMessageBar, { errors: [error.error?.message], isSuccess: false, requestType: RequestType.UPDATE_VIEW, showMessageBar: true } as IMessagebarObject);
        }
    }

    const deleteTrainingPlanView = async () => {
        let tpViewModal = new TrainingPlanViewModel(globalState.tokenModel as TokenModel);
        let response = await tpViewModal.deleteTrainingPlanView(reorderState.trainingPlanView.tims_trainingplanviewid as string)

        if(response.ok){   
            let tpViewsCopy = [...trainingPlanViewContext?.trainingPlanViews as IDropdownOption[]] 
            let index = tpViewsCopy.findIndex(item => item.key == reorderState.trainingPlanView.tims_trainingplanviewid)        

            tpViewsCopy.splice(index, 1)
            trainingPlanViewContext?.updateTrainingPlanViews(tpViewsCopy)

            Emitter.emit(Events.SetMessageBar, { errors: [], isSuccess: true, requestType: RequestType.DELETE_VIEW, showMessageBar: true } as IMessagebarObject);
        } else {
            let error = await response.json() as Serializable;
            Emitter.emit(Events.SetMessageBar, { errors: [error.error?.message], isSuccess: false, requestType: RequestType.DELETE_VIEW, showMessageBar: true } as IMessagebarObject);
        }

    }
    
    React.useEffect(() => {
        Emitter.on(Events.SaveExistingView, updateTrainingPlanView);

        return () => {
            Emitter.off(Events.SaveExistingView, updateTrainingPlanView);
        };
    }, [reorderState.trainingPlanView ])


    return (

        <Stack>
            {reorderState.showLoader ?
                <Stack style={{ display: "block", marginLeft: "auto", marginRight: "auto", textAlign: "center" }}>
                    <Loader
                        type="TailSpin"
                        color="#00BFFF"
                        height={100}
                        width={100}
                        timeout={30000} //3 secs
                    />
                </Stack> : <Fragment>
                    <GridControl
                        columns={reorderState.trainingPlanView} rows={reorderState.reorderHelp.rows} key={globalState.programmeObj?.tims_programmeid} componentHelper={reorderState.reorderHelp} template={Templates.Reorder}
                    ></GridControl>
                    {showDialog()}
                    {renderCreateViewModal()}
                    {renderSaveViewModal()}
                    {renderDeleteViewModal()}
                </Fragment>}
        </Stack>
    )
}
