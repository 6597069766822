import * as React from "react";
import {
  DefaultPalette,
  AnimationClassNames
} from "@fluentui/react/lib/Styling";
import {
  IconButton,
  initializeIcons,
  Stack,
  IStackItemStyles,
  IStackStyles
} from '@fluentui/react';
import "../assets/css/Accordion.css";

initializeIcons();

export class Accordion extends React.PureComponent<IAccordionProps, {content:any}> {
  private accordionHeader: IStackItemStyles = {
    root: {
      background: DefaultPalette.neutralLighter,
      padding: 5,
      cursor: "pointer"
    }
  };
  private accordion: IStackStyles = {
    root: {
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: DefaultPalette.neutralTertiary
    }
  };
  private accordionConent: IStackStyles = {
    root: {
      padding: 10
    }
  };

  constructor(props: IAccordionProps) {
    super(props);

    this.state = {
      content: true
    };
  }

  private showContent = () => {
    this.setState({
      content: !this.state.content
    });
  };

  public componentDidMount() {
    if (this.props.isSuperParentAccordion) {

    }

  }

  private getHeight() {
    if (this.props.isSuperParentAccordion) {
      let htmlElement = document.getElementById("topPanel");
      if (htmlElement != null)
        return (window.outerHeight - htmlElement.clientHeight - 140) + "px"
    }
    return "100%";
  }

  public render(): React.ReactElement<IAccordionProps> {

    return (
      <>
        <Stack id="accordionTop" horizontal={false} styles={this.accordion}>
          <Stack.Item styles={this.accordionHeader}>
            <Stack horizontal={true} onClick={this.showContent}>
              <Stack.Item>
                <IconButton
                  iconProps={{
                    iconName: this.state.content
                      ? "ChevronDown"
                      : "ChevronRight"
                  }}
                />
              </Stack.Item>

              {this.props.HeaderElem}

            </Stack>
          </Stack.Item>

          <Stack.Item style={{ padding: "10px" }}
            className={this.props.isSuperParentAccordion ? AnimationClassNames.slideDownIn20 + " top-accordion" : AnimationClassNames.slideDownIn20}
            styles={this.accordionConent}
            hidden={!this.state.content}
            id="accordionChild"
          >
            {this.props.children}
          </Stack.Item>

        </Stack>
      </>);
  }
}

interface IAccordionProps {
  HeaderElem: JSX.Element,
  isSuperParentAccordion: boolean,
  children?:React.ReactNode
}