import { useContext } from "react";
import GlobalState from "../GlobalState";
import { ContactModel } from "../Models/ContactModel";
import GlobalStateModel from "../Models/GlobalStateModel";
import { ResultModel } from "../Models/ResultModel";
import TokenModel from "../Models/TokenModel";
import { UnitStandardModel } from "../Models/UnitStandardModel";
import Result from "./Result";
import TrainingPlanUnitStandard from "./TrainingPlanUnitStandard";

export default class ResultForm {
    AssessmentType = {
        Practical: 1,
        Theory: 2,
        Combined: 3
    };

    AssessedBy = {
        Assessor: 1,
        TrainingProvider: 2
    };

    isVisible: boolean | undefined;

    dateTheoryPassed: Date | undefined;
    datePracticalPassed: Date | undefined;
    result: Result | undefined;
    trainingPlanUnitStandard: TrainingPlanUnitStandard | undefined;


    constructor(result: Result | undefined, trainingPlanUnitStandard: TrainingPlanUnitStandard | undefined) {
        this.result = result;
        this.trainingPlanUnitStandard = trainingPlanUnitStandard;
    };

    get _practicalEnabled() {
        var result = this.result;
        var tpus = this.trainingPlanUnitStandard;


        if (!tpus?._tpuModel)
            return false;

        var assessedBy = result != undefined && result._resultModel.tims_assessedby !== undefined ?
            result._resultModel.tims_assessedby :
            this.trainingPlanUnitStandard?._tpuModel.tims_assessedby;
        var assessmentType = result != undefined && result._resultModel.tims_assessmenttype !== undefined ?
            result._resultModel.tims_assessmenttype :
            this.trainingPlanUnitStandard?._tpuModel.tims_assessmenttype;

        return (assessmentType == this.AssessmentType.Practical || assessmentType == this.AssessmentType.Combined) &&
            (result === undefined || !result?._resultModel.tims_datepracticalpassed);
    };

    get _theoryEnabled() {
        var result = this.result;
        var tpus = this.trainingPlanUnitStandard;


        if (!tpus?._tpuModel)
            return false;

        var assessedBy = result != undefined && result._resultModel.tims_assessedby !== undefined ?
            result._resultModel.tims_assessedby :
            this.trainingPlanUnitStandard?._tpuModel.tims_assessedby;
        var assessmentType = result != undefined && result._resultModel.tims_assessmenttype !== undefined ?
            result._resultModel.tims_assessmenttype :
            this.trainingPlanUnitStandard?._tpuModel.tims_assessmenttype;

        return assessmentType == (this.AssessmentType.Theory || assessmentType == this.AssessmentType.Combined) &&
            (assessedBy == this.AssessedBy.Assessor) &&
            (result === undefined || !result?._resultModel.tims_datetheorypassed);
    };

    get _createEnabled() {
        var result = this.result;


        return (result === undefined);
    };

    get _updateEnabled() {

        var result = this.result;
        return (result !== undefined && !result?._resultModel.tims_datepracticalpassed);
    };

    _saveValid() {

        if (this._practicalEnabled && this._datePracticalVisible)
            return this.datePracticalPassed !== undefined;

        if (this._theoryEnabled && this._dateTheoryVisible)
            return this.dateTheoryPassed !== undefined;

        return false;
    };

    _assessorHasPermission(globalState: GlobalStateModel) {
        var tpus = this.trainingPlanUnitStandard;
        if (!tpus)
            return false;
        var unitStandardId = tpus._tpuModel._tims_unitstandardid_value;
        var unitStandardModel = new UnitStandardModel(globalState.tokenModel as TokenModel)
        return unitStandardModel.isAssessorHasAccessToUnitStandard(unitStandardId as string);
    };

    validateDateLessThanNow(date: string) {

        if (date === '')
            return;

        var splitDate = date.split("/");
        if (parseInt(splitDate[2]) < 1965) {
            alert("Invalid Date.");
            this.parseDate(new Date(Date.now()));
            return;
        }
        var resultDate = new Date(splitDate[2] + "-" + this.padd0Left(splitDate[1]) + "-" + this.padd0Left(splitDate[0]));

        if (resultDate.toString() == "Invalid Date") {
            alert("Invalid Date.");
            this.parseDate(new Date(Date.now()));
            return;
        }

        var today = new Date(Date.now());
        today.setHours(23);
        today.setMinutes(59);
        today.setMilliseconds(999);

        if (resultDate > today) {
            // set the date back	
            alert("Cannot set the pass date to a future date.");
            this.parseDate(new Date(Date.now()));
        }
    };

    parseDate(date: Date) {
        return "DD/MM/YYYY"
            .replace("YYYY", date.getFullYear().toString())
            .replace("MM", date.getMonth().toString() + 1)
            .replace("DD", date.getDate().toString());
    };

    containsDefaultAssessor(globalState: GlobalStateModel) {
        return globalState.trainingPlanObj?.defaultAccessor !== null && globalState.trainingPlanObj?.defaultAccessor;
    };

    get _dateTheoryVisible() {

        var tpus = this.trainingPlanUnitStandard as TrainingPlanUnitStandard;


        if (!tpus)
            return false;
        else if (tpus._tpuModel == null)
            return false;

        var type = tpus._tpuModel.tims_assessmenttype;

        return type == this.AssessmentType.Theory ||
            type == this.AssessmentType.Combined;
    };

    get _datePracticalVisible() {
        var tpus = this.trainingPlanUnitStandard as TrainingPlanUnitStandard;

        if (!tpus)
            return false;
        else if (tpus._tpuModel == null)
            return false;


        var type = tpus._tpuModel.tims_assessmenttype;

        return type == this.AssessmentType.Practical ||
            type == this.AssessmentType.Combined;
    };

    _buttonText() {
        return this.result === null ? "Create" : "Update";
    };

    toggle() {
        this.isVisible = !this.isVisible;
    };

    // edit the result then update the result entity on the programme unit standard
    async updateResult(globalState: GlobalStateModel) {
        var tpus = this.trainingPlanUnitStandard;
        var assessorId = globalState.trainingPlanObj?.defaultAccessor?.contactid;

        var resultOptions = {

            tims_contactassessorid: assessorId,
            tims_datepracticalpassed: undefined as Date | undefined,
            tims_datetheorypassed: undefined as Date | undefined
        };

        // parse the practival passed date
        if (this._practicalEnabled &&
            (tpus?._tpuModel.tims_assessmenttype == this.AssessmentType.Practical ||
                tpus?._tpuModel.tims_assessmenttype == this.AssessmentType.Combined) &&
            this.datePracticalPassed !== undefined) {
            //var practicalPassedDate = this.datePracticalPassed?.split("/") as string[];
            //var formattedDate = new Date(practicalPassedDate[0] + "-" + this.padd0Left(practicalPassedDate[1]) + "-" + this.padd0Left(practicalPassedDate[2]));
            resultOptions.tims_datepracticalpassed = this.datePracticalPassed;
        }

        // parse the theory passed date
        if (this._theoryEnabled &&
            (tpus?._tpuModel.tims_assessmenttype == this.AssessmentType.Theory ||
                tpus?._tpuModel.tims_assessmenttype == this.AssessmentType.Combined) &&
            this.dateTheoryPassed !== undefined &&
            tpus?._tpuModel.tims_assessedby == this.AssessedBy.Assessor) {
            //var theoryPassedDate = this.dateTheoryPassed?.split("/") as string[];
            //var theoryFormattedDate = new Date(theoryPassedDate[0] + "-" + this.padd0Left(theoryPassedDate[1]) + "-" + this.padd0Left(theoryPassedDate[2]));
            resultOptions.tims_datetheorypassed = this.dateTheoryPassed;
        }

        var self = this;
        var resultModel = new ResultModel(globalState.tokenModel as TokenModel);
        let response = await resultModel.updateResult(this.result?._resultModel.tims_resultid as string, resultOptions);
        let updatedResult = {} as ResultModel;
        if (response.ok)
            updatedResult = await resultModel.retrieveResult(this.result?._resultModel.tims_resultid as string, false) as ResultModel;
        else
            updatedResult.error = await response.json();


        return updatedResult;
    };

    // create the result then update the result on the programme unit standard
    async createResult(globalState: GlobalStateModel) {
        var tpus = this.trainingPlanUnitStandard;

        var assessorId = globalState.trainingPlanObj?.defaultAccessor?.contactid;
        var unitStandardId = tpus?._tpuModel._tims_unitstandardid_value;
        //this._parent.result._resultModel.tims_datetheorypassed
        var resultOptions = {
            tims_contactassessorid: assessorId,
            tims_contacttraineeid: globalState.contact?.contactid,
            tims_assessmenttype: tpus?._tpuModel.tims_assessmenttype,
            tims_assessedby: tpus?._tpuModel.tims_assessedby,
            tims_unitstandardid: unitStandardId,
            tims_trainingplanid: tpus?._tpuModel._tims_trainingplanid_value,
            tims_datepracticalpassed: undefined as Date | undefined,
            tims_datetheorypassed: undefined as Date | undefined
        };

        // parse the practival passed date
        if ((tpus?._tpuModel.tims_assessmenttype == this.AssessmentType.Practical ||
            tpus?._tpuModel.tims_assessmenttype == this.AssessmentType.Combined) &&
            this.datePracticalPassed !== undefined) {
            //var practicalPassedDate = this.datePracticalPassed?.split("/") as string[];
            //var formattedDate = new Date(practicalPassedDate[0] + "-" + this.padd0Left(practicalPassedDate[1]) + "-" + this.padd0Left(practicalPassedDate[2]));
            resultOptions.tims_datepracticalpassed = this.datePracticalPassed;
        }

        // parse the theory passed date
        if ((tpus?._tpuModel.tims_assessmenttype == this.AssessmentType.Theory ||
            tpus?._tpuModel.tims_assessmenttype == this.AssessmentType.Combined) &&
            this.dateTheoryPassed !== undefined) {
            //var theoryPassedDate = this.dateTheoryPassed?.split("/") as string[];
            //var theoryFormattedDate = new Date(theoryPassedDate[0] + "-" + this.padd0Left(theoryPassedDate[1]) + "-" + this.padd0Left(theoryPassedDate[2]));
            resultOptions.tims_datetheorypassed = this.dateTheoryPassed;
        }
        var resultModel = new ResultModel(globalState.tokenModel as TokenModel);
        var self = this;
        var result = await resultModel.createResult(resultOptions) as ResultModel;
        let createdResult = {} as ResultModel;
        if (result.error == undefined)
            createdResult = await resultModel.retrieveResult(result.tims_resultid as string, false) as ResultModel;
        else
            createdResult = result;

        return createdResult;
    };

    // pads to 2 digits, returns string
    padd0Left(num: string) {
        var parsedNum = parseInt(num);
        if (parsedNum <= 9) {
            return '0' + parsedNum;
        }

        return parsedNum.toString();
    }

}