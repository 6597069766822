import { Serializable } from './Serializable';
import TokenModel from "./TokenModel"
import { format } from 'date-fns'
import TrainingPlanUnitStandardModel from './TrainingPlanUnitStandardModel';
import { orderBy } from 'lodash';

const entityName = "fus_goals";

export interface IGoalsCreate {
    fus_learnerid: string | undefined;
    fus_learnervisitid: string | undefined;
    fus_trainingplanid: string | undefined;
    tims_trainingplanunitstandard:string | undefined;
    fus_duedate: Date | undefined;
    fus_description: string | undefined;
    tims_trainingadviserremindertype:number[];
    tims_learnerremindertype:number[];
    tims_trainingadviserreminderdate:Date | undefined;
    tims_learnerreminderdate:Date | undefined;
    tims_workplacereminderdate:Date | undefined;
    tims_goaltype:number |undefined
}


export class GoalModel extends Serializable {

    constructor(tokenModel: TokenModel) {
        super(tokenModel);

    }
    _fus_learnerid_value: string | undefined;
    _fus_learnervisitid_value: string | undefined;
    _fus_trainingplanid_value: string | undefined;
    _tims_trainingplanunitstandard_value: string | undefined;
    fus_duedate: string | undefined;
    fus_description: string | undefined;
    fus_goalid: string | undefined;
    fus_name: string | undefined;
    statuscode: number | undefined;
    tims_cancellationreason:string | undefined;
    tims_trainingadviserremindertype:string | undefined
    tims_learnerremindertype:string | undefined
    tims_trainingadviserreminderdate:string  |undefined;
    tims_learnerreminderdate:string |  undefined;        
    tims_workplacereminderdate:string |undefined;
    tims_goaltype:number | undefined;

    async retrieveGoals(trainingPlanId: string) {
        var criteria = "_tims_trainingplanid_value eq '" + trainingPlanId + "'";
        return await this.d365Helper.RetrieveMultiple(entityName, this.createColumnArray(this), criteria);
    }

    async retrieveGoal(goalId: string) {
        return await this.d365Helper.Retrieve(entityName, goalId, this.createColumnArray(this), false);
    }

    async createGoals(createGoalsData: IGoalsCreate) {
        var goal = {
            "tims_goaltype":createGoalsData.tims_goaltype,
            "fus_TrainingPlanId@odata.bind": "/tims_trainingplans(" + createGoalsData.fus_trainingplanid + ")",
            "fus_LearnerVisitId@odata.bind": "/tims_macvisits(" + createGoalsData.fus_learnervisitid + ")",
            "fus_LearnerId@odata.bind": "/contacts(" + createGoalsData.fus_learnerid + ")",
            "fus_description": createGoalsData.fus_description,
            "fus_duedate": format(createGoalsData.fus_duedate as Date, 'yyyy-MM-dd')
        } as any

        if(createGoalsData.tims_trainingplanunitstandard){
            goal["tims_trainingplanunitstandard@odata.bind"] = "/tims_trainingplanunitstandards(" + createGoalsData.tims_trainingplanunitstandard + ")"
        }
        if(createGoalsData.tims_learnerremindertype.length > 0) 
        {
            goal.tims_learnerreminderdate = format(createGoalsData.tims_learnerreminderdate as Date, 'yyyy-MM-dd')
            goal.tims_learnerremindertype = createGoalsData.tims_learnerremindertype.join(",")
        }
        if(createGoalsData.tims_trainingadviserremindertype.length > 0){
            goal.tims_trainingadviserreminderdate = format(createGoalsData.tims_trainingadviserreminderdate as Date, 'yyyy-MM-dd')
            goal.tims_trainingadviserremindertype = `${createGoalsData.tims_trainingadviserremindertype}`
        }
        if(createGoalsData.tims_workplacereminderdate){
            goal.tims_workplacereminderdate = format(createGoalsData.tims_workplacereminderdate as Date, 'yyyy-MM-dd')
        }

        let response = await this.d365Helper.Create(entityName, goal, this.createColumnArray(this));
        return response;
    }

    async deleteGoal(goalId: string) {
        let response = await this.d365Helper.Delete(entityName, goalId);
        return response;
    }

    async updateGoalStatus(goalId: string, statuscode: number, cancellationReason:string | undefined) {
        var goal = {
            "statuscode": statuscode,
            "tims_cancellationreason":cancellationReason,
            "statecode": 0,
        };
        if (statuscode == 2)
            goal.statecode = 1;

        let response = await this.d365Helper.Update(entityName, goalId, goal);
        return response;
    }

    async updateGoal(createGoalsData: IGoalsCreate, goalId: string) {
        const goal: any = {
            fus_description: createGoalsData.fus_description,
            fus_duedate: format(createGoalsData.fus_duedate as Date, 'yyyy-MM-dd'),
            tims_learnerreminderdate: createGoalsData.tims_learnerreminderdate !== undefined ? format(createGoalsData.tims_learnerreminderdate as Date, 'yyyy-MM-dd') : null,
            tims_learnerremindertype: createGoalsData.tims_learnerremindertype.length > 0 ? createGoalsData.tims_learnerremindertype.join(",") : null,
            tims_trainingadviserreminderdate: createGoalsData.tims_trainingadviserreminderdate !== undefined ? format(createGoalsData.tims_trainingadviserreminderdate as Date, 'yyyy-MM-dd') : null,
            tims_trainingadviserremindertype: createGoalsData.tims_trainingadviserremindertype.length > 0 ? createGoalsData.tims_trainingadviserremindertype.join(",") : null,
            tims_workplacereminderdate: createGoalsData.tims_workplacereminderdate !== undefined ? format(createGoalsData.tims_workplacereminderdate as Date, 'yyyy-MM-dd') : null,
        };
        
        let response = await this.d365Helper.Update(entityName, goalId, goal);
        return response;
    }

    async deactivateGoal(goalId: string) {
        var goal = { "statuscode": 2, "statecode": 1 };
        let response = await this.d365Helper.Update(entityName, goalId, goal);
        return response;
    }

}