import { Checkbox, ConstrainMode, ContextualMenu, DatePicker, DayOfWeek, DetailsList, DetailsListLayoutMode, IColumn, IDetailsListStyles, IDragOptions, Modal, SearchBox, Selection, SelectionMode, Stack, StackItem } from "@fluentui/react";
import { useBoolean, useId, useForceUpdate } from '@fluentui/react-hooks';
import { DefaultButton, IconButton, PrimaryButton } from "@fluentui/react/lib/Button";
import React, { useContext, useEffect, useMemo, useState } from 'react';
import "../assets/css/GridControl.css";
import "../assets/css/DataGrid.css"
import "../assets/css/default-ocean-blue.css";
import Common from '../Common/Common';
import { AssessmentStatus, Events, RequestType, Templates, TrainingPlanStatuses } from '../Common/Enum';
import { cancelIcon, contentStyles, iconButtonStyles } from '../Common/ModalHelper';
import { ItpuCreate, ItpuUpdate } from "../Common/TrainingPlanUnitStandardManager";
import CommentForm from '../ComponentHelpers/CommentForm';
import ComponentHelperBase from "../ComponentHelpers/ComponentHelperBase";
import ProgramElectiveSectionHelper from "../ComponentHelpers/ProgramElectiveSectionHelper";
import { ReorderControlContext } from "../Contexts/ReorderControlContext";
import TrainingPlanUnitStandard, { ResourceOrderStatus } from '../ComponentHelpers/TrainingPlanUnitStandard';
import ResultForm from '../ComponentHelpers/ResultForm';
import GlobalState from "../GlobalState";
import { ResultModel } from "../Models/ResultModel";
import { SearchResultModel } from "../Models/SearchResultModel";
import TokenModel from "../Models/TokenModel";
import TrainingPlanUnitStandardModel from "../Models/TrainingPlanUnitStandardModel";
import { compulsarySectionContext } from './CompulsorySection';
import { electiveGroupSectionContext } from './ElectiveGroupSection';
import { prgElecSecContext } from './ProgrammeElectiveSections';
import { Table } from 'react-bootstrap';
import CrossCreditProgramUnitStandardHelper from "../ComponentHelpers/CrossCreditProgramUnitStandardHelper";
import { TrainingPlanCommentModel } from "../Models/TrainingPlanCommentModel";
import Emitter from "../Common/EventEmitter";
import { IMessagebarObject, MasterControlContextWarnings } from "./MasterControl";
import { ContactModel } from "../Models/ContactModel";
import { Serializable } from "../Models/Serializable";
import { ContactRole } from "../Common/Enum";
import { Grid, GridCellProps, GridColumn as Column } from "@progress/kendo-react-grid";
import { DragAndDrop } from "@progress/kendo-react-common";
import { DraggableRow } from "./Draggable-row";
import { DragHandleCell } from "./Drag-Handle";
import TrainingPlanModel from "../Models/TrainingPlanModel";
import GlobalStateModel from "../Models/GlobalStateModel";
import { Link } from '@fluentui/react';
import { TooltipHost, ITooltipHostStyles } from '@fluentui/react/lib/Tooltip';
import { TableHeader } from "./TableHeader"
import _ from "underscore";
import { TrainingPlanViewModel } from "../Models/TrainingPlanViewModel";
import check from "../assets/images/check.png";
import error from "../assets/images/exclm_icon.png"
import pending from "../assets/image/pending.png"

export interface GridProps {
  rows: ComponentHelperBase[];
  componentHelper: ComponentHelperBase,
  template: Templates,
  columns?:TrainingPlanViewModel
}

type ContextProps = {
  reorder: (dataItem: ComponentHelperBase, direction: "before" | "after" | null) => void;
  dragStart: (dataItem: ComponentHelperBase) => void;
};

export const ReorderContext = React.createContext<ContextProps>({
  reorder: () => { },
  dragStart: () => { },
});

const dialogStyles = { main: { maxWidth: 450 } };

const tooltipCalloutProps = { gapSpace: 0 };
const tooltipHostStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block' } };

export const GridControl: React.FunctionComponent<GridProps> = (props) => {


  const [isResultModalOpen, { setTrue: showResultModal, setFalse: hideResultModal }] = useBoolean(false);
  const [isCommentModalOpen, { setTrue: showCommentModal, setFalse: hideCommentModal }] = useBoolean(false);
  const [isAddUnitModalOpen, { setTrue: showUnitsModal, setFalse: hideUnitsModal }] = useBoolean(false);
  const [isAddContactModalOpen, { setTrue: showContactsModal, setFalse: hideContactsModal }] = useBoolean(false);
  const labelId: string = useId('dialogLabel');
  const subTextId: string = useId('subTextLabel');

  const [isDraggable, { toggle: toggleIsDraggable }] = useBoolean(false);
  const [keepInBounds, { toggle: toggleKeepInBounds }] = useBoolean(false);
  const [isCommentCreating, { setTrue: showCommentLoader, setFalse: hideCommentLoader }] = useBoolean(false);
  const [isResultCreating, { setTrue: showResultLoader, setFalse: hideResultLoader }] = useBoolean(false);
  const [isUnitCreating, { setTrue: showUnitLoader, setFalse: hideUnitLoader }] = useBoolean(false);
  const [searchData, setSearchData] = useState<any[]>([]);
  const [searchDataContact, setSearchContactData] = useState<any[]>([]);
  const [contactModalMode, setContactModalMode] = useState<ContactRole>(1);

  const forceUpdate = useForceUpdate();
  const [activeItem, setActiveItem] = React.useState<any | null>(null);

  const selection = useMemo(
    () =>
      new Selection({
        onSelectionChanged: forceUpdate
      }
      ),
    [],
  );


  const globalState = useContext(GlobalState);
  const prgElecSecDispatcher = useContext(prgElecSecContext);
  const elecGrpSecDispatcher = useContext(electiveGroupSectionContext);
  const compSecDipatcher = useContext(compulsarySectionContext);
  const reorderControlContext = useContext(ReorderControlContext);
  const masterControlDispatcher = useContext(MasterControlContextWarnings);

  if(!reorderControlContext){
    throw new Error("Reorder control context is not available")
  }

  const {reorderState, reorderDispatcher} = reorderControlContext

  // Normally the drag options would be in a constant, but here the toggle can modify keepInBounds
  const dragOptions = React.useMemo(
    (): IDragOptions => ({
      moveMenuItemText: 'Move',
      closeMenuItemText: 'Close',
      menu: ContextualMenu,
      keepInBounds,
    }),
    [keepInBounds],
  );

  const [commentObj, setComment] = useState({ comment: "", index: 0 });
  const [dateTheoryPassedObj, setDateTheoryPassedObj] = useState<Date | undefined>(new Date());
  const [datePracticalPassedObj, setDatePracticalPassedObj] = useState<Date | undefined>(new Date());
  const [currentRowIndex, setCurrentRowIndex] = useState(0);
  const [isOrderResourcePressed, setisOrderResourcePressed] = useState(false);
  const [isCancelResourcePressed, setisCancelResourcePressed] = useState(false);
  const [isCommentUpdateMode, setisCommentUpdateMode] = useState(false);
  const [sortOrder, setSortOrder] = useState(true)
  const [sortOrderColumn, setSortOrderColumn] = useState("tims_name")
  const [unitStandardRows, setUnitStandardRows] = useState(props.rows)

  useEffect(() => {
    setUnitStandardRows(props.rows)
  }, [props.rows])

  useEffect(() => {
     let sortableTemplate = [Templates.ItemSelection, Templates.ItemSelectionEdit, Templates.CrossCreditElective, Templates.CrossCreditElectiveEdit]
     if(sortableTemplate.includes(props.template)){
        setUnitStandardRows(prevUnitStandardRows => {
          const newUnitStandardRows = prevUnitStandardRows.slice()
          let sorted = newUnitStandardRows.sort(function (left, right) {
            // @ts-ignore
            let a = left.unitStandard[sortOrderColumn]
            // @ts-ignore
            let b = right.unitStandard[sortOrderColumn]
            
            if (a == b)
            return 0;
            
            if(sortOrder){
              return a > b ? 1 : -1;
            } else {
              return a < b ? 1 : -1;
            }
            
            
          });
          return sorted
        })
        }
      }, [sortOrder, sortOrderColumn])

  const addResultOnChange = (index:number) => {
    setCurrentRowIndex(index)
    if (props.rows.length > 0) {      
      let resultForm = (unitStandardRows[index]).resultForm;
      if (resultForm?._createEnabled) {
      if (resultForm._theoryEnabled && resultForm._dateTheoryVisible)
        setDateTheoryPassedObj(new Date());
      else
        setDateTheoryPassedObj(undefined);

      if (resultForm._practicalEnabled && resultForm._datePracticalVisible)
        setDatePracticalPassedObj(new Date());
      else
        setDatePracticalPassedObj(undefined);
    }
    else {
      setDateTheoryPassedObj(undefined);
      setDatePracticalPassedObj(new Date());
    }

    let resultHelper = (unitStandardRows[index]).resultHelper;
    if (resultHelper != undefined) {
      if (resultHelper._resultModel != undefined) {
        if (resultHelper._resultModel.tims_datetheorypassed != undefined)
          setDateTheoryPassedObj(new Date(resultHelper._resultModel.tims_datetheorypassed));
        if (resultHelper._resultModel.tims_datepracticalpassed != undefined)
          setDatePracticalPassedObj(new Date(resultHelper._resultModel.tims_datepracticalpassed));
      }
    }
  }
  }

  const setCommentInState = (e: any, index: number) => {
    setComment({ comment: e.target.value, index: index });
  };

  const getHTMLByTemplate = (template: Templates) => {
    switch (template) {
      case Templates.CrossCreditElective:
        return crossCreditElectiveTemplate();
      case Templates.CrossCreditElectiveEdit:
        return crossCreditElectiveEditTemplate();
      case Templates.ItemSelection:
        return itemSelectionTemplate();
      case Templates.ItemSelectionEdit:
        return itemSelectionEditTemplate();
      case Templates.PickAListElective:
        return pickAListElectiveTemplate();
      case Templates.UnitStandardItem:
        return unitStandardItemTemplate();
      case Templates.Reorder:
        return reorderTemplate();

      default:
        return itemSelectionTemplate();
    }

  }

  const getDispatcher = () => {

    if (typeof prgElecSecDispatcher == "function")
      return prgElecSecDispatcher;
    else if (typeof elecGrpSecDispatcher == "function")
      return elecGrpSecDispatcher;
    else if (typeof compSecDipatcher == "function")
      return compSecDipatcher;
    else if (typeof reorderDispatcher == "function")
      return reorderDispatcher;
    return () => { console.error("No dispatcher found.Check code...") };
  }

  const getMasterControlDispatcher = () => {
    if (typeof masterControlDispatcher == "function")
      return masterControlDispatcher;

    return () => { console.error("No dispatcher found.Check code...") };
  }

  const searchUnits = async (newValue: string, index: number) => {
    showUnitLoader();
    try {
      const units = await unitStandardRows[index].searchHelper.searchUnitStandard(newValue);
      setSearchData(units);
    } catch (error) {
      console.log("Unit Search Error", error);

    }
    hideUnitLoader();
  }

  const searchContacts = async (newValue: string, index: number) => {
    showUnitLoader();
    let contactModel = new ContactModel(globalState.tokenModel as TokenModel);
    let contacts = await contactModel.retrieveContactByName(newValue, contactModalMode);
    setSearchContactData(contacts);
    hideUnitLoader();
  }

  // Toggle global loading indicator state in parent component
  const toggleFullScreenLoader = (showLoader: boolean) => {
    let globalContext = {
      ...globalState,
      IsFullScreenLoaderVisible: showLoader
    } as GlobalStateModel;
    globalState.setGlobalState(globalContext);
  };

  const FetchLatestTrainingPlanDefaultAssessor = async () => {
    toggleFullScreenLoader(true);

    // Fetch latest Training Plan from Web API (not from cache!)
    let tpModel = new TrainingPlanModel(globalState.tokenModel as TokenModel);
    let tp = globalState.trainingPlanObj as TrainingPlanModel;
    let jsonObj = await tpModel.retrieveTrainingPlan(tp.tims_trainingplanid as string, false);
    var trainingPlanObjUpd = jsonObj as TrainingPlanModel;

    if (trainingPlanObjUpd._tims_defaultcontactassessorid_value !== tp._tims_defaultcontactassessorid_value) {
      // Fetch latest default assesor contact (not from cache!)
      var contactModel = new ContactModel(globalState.tokenModel as TokenModel);
      var defaultAssessor = await contactModel.retrieveAssessorEntityReference(trainingPlanObjUpd.tims_trainingplanid as string, false);
      if (defaultAssessor != null) {
        trainingPlanObjUpd.defaultAccessor = defaultAssessor as ContactModel;
      }

      // Update global state
      let globalContext = { ...globalState, trainingPlanObj: trainingPlanObjUpd, IsFullScreenLoaderVisible: false } as GlobalStateModel;
      globalState.setGlobalState(globalContext);
    } else {
      toggleFullScreenLoader(false);
    }
  };

  const reorder = (dataItem: ComponentHelperBase, direction: "before" | "after" | null) => {
    if (activeItem === dataItem) {
      return;
    }
    let reorderedData = unitStandardRows.slice();
    let prevIndex = reorderedData.findIndex((p) => p === activeItem);
    let nextIndex = reorderedData.findIndex((p) => p === dataItem);

    let dispatcher = getDispatcher();
    dispatcher({
      type: "REORDER", obj: {
        oldIndex: prevIndex, newIndex: nextIndex
      }
    });
    setActiveItem(null)
    console.log(`old index ${prevIndex} new index ${nextIndex}`)

  };

  const dragStart = (dataItem: any) => {
    setActiveItem(dataItem);
  };
  const reorderTemplate = () => {
 
    return (<ReorderContext.Provider
      value={{
        reorder: reorder,
        dragStart: dragStart
      }}
    >
      <DragAndDrop>
        <Grid
          id={globalState.IsPortalMode ? "dragAndDropGridPortal" : "dragAndDropGrid"}
          className={"dragAndDropGrid"}
          data={props.rows}
          dataItemKey={"_tpuModel.tims_trainingplanunitstandardid"}
          scrollable={"none"}
          rowRender={(row, rowProps) => (
            <DraggableRow isDragging={activeItem !== null} elementProps={row.props} {...rowProps}
            />
          )}
        >
          {!globalState.IsPortalMode ? <Column title="" width="50px" headerClassName={"grid-button"} cell={DragHandleCell} /> : <></>}
          {props.columns?.tims_sequencenumber && <Column cell={seqNumCell} width="30px" title="Order" headerCell={() => { return <p className="mb-0"><b>Order</b></p>; }} /> }
          {props.columns?.tims_unitstandardname && <Column cell={unitStandardNameCell} title="Unit Standard Name" headerCell={() => { return <p className="mb-0"><b>Unit Standard Name</b></p>; }} />}
          {props.columns?.tims_credits && <Column cell={creditsCell} title="Credits" width="40px" headerCell={() => { return <p className="mb-0"><b>Credits</b></p>; }} />}
          {props.columns?.tims_level && <Column cell={levelCell} title="Level" width="40px" headerCell={() => { return <p className="mb-0"><b>Level</b></p>; }} />}
          {props.columns?.tims_assessmenttype && <Column cell={assesmentTypeCell} title="Type" width="50px" headerCell={() => { return <p className="mb-0"><b>Type</b></p>; }} /> }
          {props.columns?.tims_result && <Column cell={resultCell} title="Result" width="80px" headerCell={() => { return <p className="mb-0"><b>Result</b></p>; }} />}
          {props.columns?.tims_course && <Column cell={courseCodeCell} title="Off-job course" width="80px" headerCell={() => { return <p className="mb-0"><b>Off-job course</b></p>; }} />}
          {props.columns?.tims_courseidandstartdate && <Column cell={courseCodeAndStartDateCell} title="Course code and start date" width="80px" headerCell={() => { return <p className="mb-0"><b>Course / </b> <br/><b>Start Date</b></p>; }} />}
          {props.columns?.tims_coursestartdate && <Column cell={courseStartDateCell} title="Course start date" width="80px" headerCell={() => { return <p className="mb-0"><b>Course start date</b></p>; }} />}
          {props.columns?.tims_assessmentstatus && <Column cell={assessmentStatusCell} title="Assessment status" width="100px" headerCell={() => { return  <p className="mb-0"><b>Assessment</b><br/><b>Status</b></p>; }} /> }
          {props.columns?.tims_assessmentstatusfinal && <Column cell={assessmentStatusFinalCell} title="Assessment status final" width="100px" headerCell={() => { return <p className="mb-0"><b>Resubmission</b> <br/> <b>Status</b> </p>; }} />}
          {props.columns?.tims_assessor && <Column headerClassName="assessor" cell={assessorCell} title="Assessor" headerCell={() => { return <p className="mb-0"><b>Assessor</b></p>; }} /> }
          {props.columns?.tims_verifier && <Column headerClassName="verifier" cell={verifierCell} title="Verifier" headerCell={() => { return <p className="mb-0"><b>Verifier</b></p>; }} /> }
          {props.columns?.tims_comments && <Column cell={commentsCell} title="Comments" headerCell={() => { return <p className="mb-0"><b>Comments</b></p>; }} /> }
          {/* {(globalState.programmeObj?.tims_deliverytype === 100000000 || globalState.programmeObj?.tims_deliverytype === 100000002) &&  <Column headerClassName="lmsstatus" cell={lmsStatusCell} title="LMSStatus"  headerCell={() => { return <p className="mb-0"><b>Mahi Tahi</b></p>; }} />} */}
        </Grid>
      </DragAndDrop>
      {renderDialogResult()}
      {renderDialogComment()}
      {renderContactSearchTemplate()}
    </ReorderContext.Provider>
    )
  }

  const seqNumCell = (props: GridCellProps) => {
    let data = props.dataItem as ComponentHelperBase;
    return <td><p className="mb-0">{(data as TrainingPlanUnitStandard)._tpuModel.fus_sequencenumber}</p></td>
  }

  const unitStandardNameCell = (props: GridCellProps) => {
    let data = props.dataItem as ComponentHelperBase;
    return <td><p className="mb-0">{data.unitStandard?.tims_name}</p></td>
  }

  const creditsCell = (props: GridCellProps) => {
    let data = props.dataItem as ComponentHelperBase;
    return <td><p className="mb-0">{data.credits}</p></td>
  }

  const levelCell = (props: GridCellProps) => {
    let data = props.dataItem as ComponentHelperBase;
    return <td><p className="mb-0">{data.unitStandard?.tims_level}</p></td>
  }

  const assesmentTypeCell = (props: GridCellProps) => {
    let data = props.dataItem as ComponentHelperBase;
    return <td><p className="mb-0">{data.assessmentType}</p></td>
  }

  // const lmsStatusCell = (props: GridCellProps) => {
  //   let data = props.dataItem as ComponentHelperBase
  //   let tpuModel = (data as TrainingPlanUnitStandard)._tpuModel
  //   if(tpuModel.tims_lmsstatus == 100000001){
  //     return <td className="lmsstatus" style={{textAlign:"center"}}><img style = {{width:"20px", height:"20px"}} src={check}/></td>
  //   }
  //   else if(tpuModel.tims_lmsstatus == 100000002){
  //     return <td className="lmsstatus" style={{textAlign:"center"}}><img style = {{width:"20px", height:"20px"}} src={error}/></td>
  //   }
  //   else {
  //     return <td className="lmsstatus" style={{textAlign:"center"}}><p className="mb-0">...</p></td>
  //   }
  // }

  const courseCodeCell = (props: GridCellProps) => {
    let data = props.dataItem as ComponentHelperBase
    let tpuModel = (data as TrainingPlanUnitStandard)._tpuModel
    // Show course title if course code hasn't been generated yet (Is a potential course)
    return <td> <p className="mb-0">{tpuModel.course.statuscode ==  100000000 ? Common.getFormattedValue(tpuModel.course, "statuscode") : tpuModel.course.tims_identifier}</p></td>
  }
  
  const courseStartDateCell = (props: GridCellProps) => {
    let data = props.dataItem as ComponentHelperBase
    let startDate = (data as TrainingPlanUnitStandard)._tpuModel.course.tims_dateofstart
    return <td> <p className="mb-0">{startDate !== undefined ? Common.getCleanshortDate(startDate) : ""}</p></td>
  }

  const courseCodeAndStartDateCell = (props: GridCellProps) => {
    let data = props.dataItem as ComponentHelperBase
    let startDate = (data as TrainingPlanUnitStandard)._tpuModel.course.tims_dateofstart
    let tpuModel = (data as TrainingPlanUnitStandard)._tpuModel
    return <td> 
              <p className="mb-0">{tpuModel.course.statuscode ==  100000000  || tpuModel.course.statuscode == 100000001 ? Common.getFormattedValue(tpuModel.course, "statuscode") : tpuModel.course.tims_identifier} </p> 
              <p> {startDate !== undefined && tpuModel.course.statuscode !==  100000000 && tpuModel.course.statuscode !== 100000001 ? Common.getCleanshortDate(startDate) : ""} </p>
            </td>
  }

  const assessmentStatusCell = (props: GridCellProps) => {
    let data = props.dataItem as ComponentHelperBase
    let tpuModel = (data as TrainingPlanUnitStandard)._tpuModel
    let dateOfAchievement = (data as TrainingPlanUnitStandard)._resultHelper?._resultModel.tims_dateofachievement
        
    let statusValue = (Common.getFormattedValue(tpuModel, "tims_assessmentstatus") ?? "")

    switch(tpuModel.tims_assessmentstatus) {
      case AssessmentStatus.Overdue:
      case AssessmentStatus.ToBeSubmitted:
        if(tpuModel.tims_assessmentduedatereviewed)
          statusValue += ' - ' + Common.getFormattedValue((data as TrainingPlanUnitStandard)._tpuModel, "tims_assessmentduedatereviewed")
        break;
      case AssessmentStatus.SubmittedByLearner:
        statusValue = "Submitted by " + (globalState as GlobalStateModel).contact?.firstname 
        if(tpuModel.tims_datesubmitted)
          statusValue += ' - ' + Common.getCleanshortDate(tpuModel.tims_datesubmitted)
        break;
      case AssessmentStatus.Achieved:
        if(dateOfAchievement)
          statusValue += ' - ' + Common.getCleanshortDate(dateOfAchievement)
        break;
      case AssessmentStatus.SentBackForResubmission:
        if(tpuModel.tims_datesentbackforresubmission)
          statusValue  += ' - ' + Common.getCleanshortDate(tpuModel.tims_datesentbackforresubmission)
        break;
      default:
        break;
    }    
    
    return <td><p className="mb-0">{tpuModel._tims_courseid_value &&  tpuModel.course.statuscode !==  100000000 && tpuModel.course.statuscode != 100000001 ? statusValue : ''}</p></td> // Only show status if there is a course attached
  }

  const assessmentStatusFinalCell = (props: GridCellProps) => {
    let data = props.dataItem as ComponentHelperBase
    let tpuModel = (data as TrainingPlanUnitStandard)._tpuModel
    let dateOfAchievement = (data as TrainingPlanUnitStandard)._resultHelper?._resultModel.tims_dateofachievement
    let statusValue = (Common.getFormattedValue(tpuModel, "tims_assessmentstatusfinal") ?? "")

    switch(tpuModel.tims_assessmentstatusfinal) {
      case AssessmentStatus.Overdue:
      case AssessmentStatus.ToBeSubmitted:
        if(tpuModel.tims_assessmentduedatefinal)
          statusValue += ' - ' + Common.getFormattedValue((data as TrainingPlanUnitStandard)._tpuModel, "tims_assessmentduedatefinal")
        break;
      case AssessmentStatus.SubmittedByLearner:
        statusValue = "Submitted by " + (globalState as GlobalStateModel).contact?.firstname 
        if(tpuModel.tims_dateresubmitted)
          statusValue += ' - ' + Common.getCleanshortDate(tpuModel.tims_dateresubmitted)
        break;
      case AssessmentStatus.Achieved:
        if(dateOfAchievement)
          statusValue += ' - ' + Common.getCleanshortDate(dateOfAchievement)
        break;
      default:
        break;
    } 


    // Only show final assessment information if original assessment is "Sent back for resubmission" and course is attached
    return <td><p className="mb-0">{tpuModel.tims_assessmentstatus === AssessmentStatus.SentBackForResubmission && tpuModel._tims_courseid_value &&  tpuModel.course.statuscode !==  100000000  && tpuModel.course.statuscode !== 100000001 ? statusValue : ''}</p></td>

  }

  const resultCell = (props: GridCellProps) => {
    let data = props.dataItem as ComponentHelperBase;
    let index = props.dataIndex;
    return <td>
      <>
        <p className="mb-0" style={{ marginTop: "5px" }} hidden={!(data.isExempt)}>Exempt</p>
        {!globalState.IsPortalMode ?
          <p className="mb-0" style={{ marginTop: "5px" }} hidden={!(data.isAchieved || data.isAchievedPendingPreReq || data.isPartiallyAchieved)}>{data._resultHelper != undefined ?
            <a href="#" onClick={(e) => { addResultOnChange(index); showResultModal(); }}>{data._resultHelper.formattedText}</a> : ""}</p>
          :
          <p className="mb-0" style={{ marginTop: "5px" }} hidden={!(data.isAchieved || data.isAchievedPendingPreReq || data.isPartiallyAchieved)}>{data._resultHelper != undefined ?
            data._resultHelper.formattedText : ""}</p>
        }
        {(!globalState.IsPortalMode && globalState.trainingPlanObj?.statuscode == TrainingPlanStatuses.Active && !(data.isAchieved || data.isAchievedPendingPreReq || data.isPartiallyAchieved)
          && (data.resultForm?._practicalEnabled || data.resultForm?._theoryEnabled) && (data.resultForm?._assessorHasPermission(globalState)) && !data.isExempt) ?
          <DefaultButton style={{ marginTop: "5px" }} iconProps={{ iconName: "Add" }} className="grid-button button-style" onClick={(e) => {
            addResultOnChange(index);
            showResultModal();
          }}>
          </DefaultButton> : ""}
      </>
    </td>
  }

  const assessorCell = (props: GridCellProps) => {
    let data = props.dataItem as ComponentHelperBase;
    let index = props.dataIndex;
    const assessorTooltipId = _.uniqueId('assessorToolTip' + index);
    return <td className="grid-button assessor">
      <>
        <div style={{ marginTop: "3%" }}>
          {globalState.IsPortalMode ?
            <p className="mb-0">{Common.getFormattedValue((data as TrainingPlanUnitStandard)._tpuModel, "_tims_assessor_value")}</p> :
            <TooltipHost
              content={data.resultForm?.result !== undefined ? "You can't add assessor as the unit is already resulted" : ""}
              id={assessorTooltipId}
              calloutProps={tooltipCalloutProps}
              styles={tooltipHostStyles}
            >
              <Link style={{ textDecoration: "underline", color: "blue" }} disabled={data.resultForm?.result !== undefined} aria-describedby={data.resultForm?.result === undefined ? assessorTooltipId : undefined} href="#" onClick={(e) => {
                setCurrentRowIndex(index);
                showContactsModal();
                setContactModalMode(ContactRole.Assessor);
              }}> <p className="mb-0">{Common.getFormattedValue((data as TrainingPlanUnitStandard)._tpuModel, "_tims_assessor_value")}</p></Link>
              {(data as TrainingPlanUnitStandard)._tpuModel._tims_assessor_value == undefined ?
                <DefaultButton iconProps={{ iconName: "Add" }} className="grid-button button-style" disabled={data.resultForm?.result !== undefined} aria-describedby={data.resultForm?.result === undefined ? assessorTooltipId : undefined}
                  onClick={(e) => {
                    setCurrentRowIndex(index);
                    showContactsModal();
                    setContactModalMode(ContactRole.Assessor);
                  }}></DefaultButton> : <></>}
            </TooltipHost>}
        </div>
      </>
    </td>;
  }
  const verifierCell = (props: GridCellProps) => {
    let data = props.dataItem as ComponentHelperBase;
    let index = props.dataIndex;
    const verifierTooltipId = _.uniqueId('verifierToolTip' + index);

    return <td className="grid-button verifier">
      <>
        <div style={{ marginTop: "3%" }}>
          {globalState.IsPortalMode ?
            <p className="mb-0">{Common.getFormattedValue((data as TrainingPlanUnitStandard)._tpuModel, "_tims_verifier_value")}</p> :
            <TooltipHost
              content={data.resultForm?.result !== undefined ? "You can't add verifier as the unit is already resulted" : ""}
              id={verifierTooltipId}
              calloutProps={tooltipCalloutProps}
              styles={tooltipHostStyles}
            >
              <Link style={{ textDecoration: "underline", color: "blue" }} disabled={data.resultForm?.result !== undefined} aria-describedby={data.resultForm?.result === undefined ? verifierTooltipId : undefined} href="#" onClick={(e) => {
                setCurrentRowIndex(index);
                showContactsModal();
                setContactModalMode(ContactRole.Verifier);
              }}><p className="mb-0">{Common.getFormattedValue((data as TrainingPlanUnitStandard)._tpuModel, "_tims_verifier_value")}</p></Link>
              {(data as TrainingPlanUnitStandard)._tpuModel._tims_verifier_value == undefined ?
                <DefaultButton iconProps={{ iconName: "Add" }} className="grid-button button-style" disabled={data.resultForm?.result !== undefined} aria-describedby={data.resultForm?.result === undefined ? verifierTooltipId : undefined}
                  onClick={(e) => {
                    setCurrentRowIndex(index);
                    showContactsModal();
                    setContactModalMode(ContactRole.Verifier);
                  }}></DefaultButton> : <></>}
            </TooltipHost>}
        </div>
      </>
    </td>;
  }
  const commentsCell = (props: GridCellProps) => {
    let data = props.dataItem as ComponentHelperBase;
    let index = props.dataIndex;
    return <td>
      <>
        <p className="mb-0" style={{ float: "left", width: "60%" }} hidden={data.comment == null}>{data.comment?.tims_trainingcomment}</p>
        {!globalState.IsPortalMode ?
          <DefaultButton style={{ float: "right" }} iconProps={{ iconName: "CommentAdd" }} className="grid-button button-style" onClick={(e) => {
            if (data.comment == null) {
              setisCommentUpdateMode(false);
              setComment({ comment: "", index });
            } else {
              setisCommentUpdateMode(true);
              setComment({ comment: data.comment?.tims_trainingcomment as string, index });
            }

            setCurrentRowIndex(index);
            showCommentModal();
          }}></DefaultButton> : <></>}
      </>
    </td>;
  }

  const renderDialogComment = () => {
    const index = currentRowIndex;
    const titleId = "title-commentmodal-" + index;
    return (
      <Modal
        isDarkOverlay={false}
        titleAriaId={titleId}
        isOpen={isCommentModalOpen}
        onDismiss={hideCommentModal}
        isBlocking={false}
        containerClassName={contentStyles.container}
        dragOptions={isDraggable ? dragOptions : undefined}
      >
        <div className={contentStyles.header}>
          <span id={titleId}>Add Comment</span>
          {isCommentCreating ? <div style={{ marginLeft: "10px" }} className="lds-dual-ring"></div> : ""}
          <IconButton
            styles={iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={hideCommentModal}
          />
        </div>
        <Stack>
          <Stack className={contentStyles.body}>
            <StackItem><textarea style={{ width: "400px", height: "250px" }} rows={3} placeholder="Comment" value={commentObj.comment} onChange={(e) => { setCommentInState(e, index) }} ></textarea></StackItem>
          </Stack>
          <StackItem>
            <DefaultButton className="button-style" style={{ float: "right", marginRight: "15px", marginBottom: "15px" }} disabled={isCommentCreating} onClick={async (event) => {
              showCommentLoader();
              let dispatcher = getDispatcher();
              var progUnitStandard = unitStandardRows[index];

              if (props.template == Templates.Reorder) {
                if (progUnitStandard.commentForm == undefined) {
                  let compHelper = new ComponentHelperBase();
                  compHelper._globalStateModel = globalState;
                  compHelper.comment = progUnitStandard.comment;
                  compHelper._trainingPlanUnitStandard = progUnitStandard as TrainingPlanUnitStandard;
                  (progUnitStandard as TrainingPlanUnitStandard).commentForm = new CommentForm(compHelper);
                } else
                  progUnitStandard.commentForm._parent.comment = progUnitStandard.comment;

              } else {
                if (progUnitStandard.commentForm == undefined)
                  progUnitStandard.commentForm = new CommentForm(progUnitStandard);
              }



              var commentForm = progUnitStandard.commentForm as CommentForm
              commentForm.comment = commentObj.comment;
              let createdCommentObj = {} as TrainingPlanCommentModel;

              if (!isCommentUpdateMode)
                createdCommentObj = await commentForm.createComment();
              else
                createdCommentObj = await commentForm.updateComment() as TrainingPlanCommentModel;

              if (createdCommentObj.error != undefined)
                Emitter.emit(Events.SetMessageBar, { errors: [createdCommentObj.error.message], isSuccess: false, requestType: RequestType.ADD_COMMENT, showMessageBar: true } as IMessagebarObject);
              else {
                dispatcher({
                  type: "CREATE_COMMENT", obj: {
                    electiveSectionId: props.componentHelper._electiveSectionM?.tims_electivesectionid, rowIndex: index, unitStandardId: unitStandardRows[index].unitStandard?.tims_unitstandardid, commentObj: createdCommentObj
                  }
                });
              }

              hideCommentLoader();
              hideCommentModal();


            }}>{isCommentUpdateMode ? "Update" : "Create"}</DefaultButton>
          </StackItem>
        </Stack>
      </Modal >
    );
  }

  const renderDialogResult = () => {
    const index = currentRowIndex;
    const titleId = "title-resultmodal-" + index;
    let resultForm = (unitStandardRows[index])?.resultForm;
    return (
      <Modal
        isDarkOverlay={false}
        titleAriaId={titleId}
        isOpen={isResultModalOpen}
        onDismiss={hideResultModal}
        isBlocking={false}
        containerClassName={contentStyles.container}
        dragOptions={isDraggable ? dragOptions : undefined}
      >
        <div className={contentStyles.header}>
          <span id={titleId}>{resultForm?._updateEnabled ? "Result" : "Add Result"} </span>
          {isResultCreating ? <div style={{ marginLeft: "10px" }} className="lds-dual-ring"></div> : ""}
          <IconButton
            styles={iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={hideResultModal}
          />
        </div>
        <Stack>
          <Stack className={contentStyles.body} style={{ width: "400px" }}>
            {globalState.trainingPlanObj?.defaultAccessor == undefined &&
              <StackItem>
                <div className="uk-alert-warning">
                  Please add a default assessor, save and reload the form to add results
                </div>
              </StackItem>}
            <StackItem>
              <Stack horizontal>
                <StackItem style={{ width: "50%" }}>Unit Standard</StackItem>
                <StackItem style={{ width: "50%", marginBottom: "10px" }}>{resultForm?.trainingPlanUnitStandard?.unitStandard?.tims_frameworkelementcode}</StackItem>
              </Stack>
            </StackItem>
              <Stack horizontal>
                <StackItem style={{ width: "50%" }}>Default Assessor</StackItem>
                <StackItem style={{ width: "50%", marginBottom: "10px" }}>{globalState.trainingPlanObj?.defaultAccessor?.fullname}</StackItem>
              </Stack>
            <StackItem>
              {resultForm?._dateTheoryVisible &&
                <Stack horizontal>
                  <StackItem style={{ width: "50%" }}>Date Theory Passed</StackItem>
                  <StackItem style={{ width: "50%" }}>
                    <DatePicker disabled={!resultForm?._theoryEnabled} value={dateTheoryPassedObj} firstDayOfWeek={DayOfWeek.Sunday} maxDate={new Date()} onSelectDate={(date) => {
                      setCurrentRowIndex(index);
                      setDateTheoryPassedObj(date as Date);
                    }} placeholder={resultForm?._theoryEnabled ? "Select a date..." : undefined} ariaLabel="Select a date" />
                  </StackItem>
                </Stack>}
              {resultForm?._datePracticalVisible &&
                <Stack horizontal>
                  <StackItem style={{ width: "50%" }}>Date On Job Passed</StackItem>
                  <StackItem style={{ width: "50%" }}>
                    <DatePicker disabled={!resultForm?._practicalEnabled} value={datePracticalPassedObj} firstDayOfWeek={DayOfWeek.Sunday} maxDate={new Date()} onSelectDate={(date) => {
                      setCurrentRowIndex(index);
                      setDatePracticalPassedObj(date as Date);
                    }} placeholder={resultForm?._practicalEnabled ? "Select a date..." : undefined} ariaLabel="Select a date" />
                  </StackItem>
                </Stack>}
            </StackItem>
            {((resultForm?._updateEnabled || resultForm?._createEnabled) && (resultForm?._practicalEnabled || resultForm?._theoryEnabled)) &&
              <StackItem>
                <DefaultButton className="button-style" style={{ float: "right", marginTop: "80px" }} disabled={isResultCreating || globalState.trainingPlanObj?.defaultAccessor == undefined} onClick={async (e) => {
                  var progUnitStandard = unitStandardRows[index];
                  showResultLoader();
                  let dispatcher = getDispatcher();

                  var resultForm = progUnitStandard.resultForm as ResultForm
                  if (resultForm._datePracticalVisible) {
                    if (datePracticalPassedObj != undefined)
                      resultForm.datePracticalPassed = datePracticalPassedObj;
                  }

                  if (resultForm._dateTheoryVisible) {
                    if (dateTheoryPassedObj != undefined)
                      resultForm.dateTheoryPassed = dateTheoryPassedObj;
                  }


                  if (resultForm._createEnabled) {
                    let createdResult = await resultForm.createResult(globalState) as ResultModel;
                    if (createdResult.error != undefined)
                      Emitter.emit(Events.SetMessageBar, { errors: [createdResult.error.message], isSuccess: false, requestType: RequestType.ADD_RESULT, showMessageBar: true } as IMessagebarObject);
                    else {
                      if (createdResult.tims_datetheorypassed != undefined)
                        setDateTheoryPassedObj(new Date(createdResult.tims_datetheorypassed));
                      if (createdResult.tims_datepracticalpassed != undefined)
                        setDatePracticalPassedObj(new Date(createdResult.tims_datepracticalpassed));
                      dispatcher({
                        type: "CREATE_RESULT", obj: {
                          electiveSectionId: props.componentHelper._electiveSectionM?.tims_electivesectionid, unitStandardId: unitStandardRows[index].unitStandard?.tims_unitstandardid, createdResult: createdResult
                        }
                      });

                      var dispatcherMaster = getMasterControlDispatcher();

                      dispatcherMaster({
                        type: "REMOVE_WARNINGS_AFTER_RESULT", obj: { frameworkelementcode: progUnitStandard.unitStandard?.tims_frameworkelementcode 
                        }
                      });
                    }

                  } else if (resultForm._updateEnabled) {
                    let updatedResult = await resultForm.updateResult(globalState);
                    if (updatedResult.error == undefined)
                      dispatcher({
                        type: "UPDATE_RESULT", obj: {
                          electiveSectionId: props.componentHelper._electiveSectionM?.tims_electivesectionid, rowIndex: index, unitStandardId: unitStandardRows[index].unitStandard?.tims_unitstandardid, updatedResult: updatedResult
                        }
                      });
                    else {
                      let error = updatedResult.error;
                      Emitter.emit(Events.SetMessageBar, { errors: [error.message], isSuccess: false, requestType: RequestType.UPDATE_RESULT, showMessageBar: true } as IMessagebarObject);
                    }
                  }

                  hideResultLoader();
                  hideResultModal();

                }}>{resultForm?._updateEnabled ? "Update" : "Create"}</DefaultButton>
              </StackItem>}
          </Stack>
        </Stack>
      </Modal>
    );
  }

  const changeOrder = (columnName:string) => {
      if(columnName != sortOrderColumn){
        setSortOrder(false)
        setSortOrderColumn(columnName)
      } else{
        setSortOrderColumn(columnName)
        setSortOrder(!sortOrder)
      }
    }

  const itemSelectionTemplate = () => {
   
    return (
    <Table id={Common.newGuid()} style={{ textAlign: "center"  }} bordered hover size="sm">
      <thead>
        <tr>
          <TableHeader isSelected = {sortOrderColumn == "tims_name"} onClick = { () => changeOrder("tims_name")} sortable = {true} sortOrder = {sortOrder}>
            <p style = {{userSelect:'none'}} className="mb-0">Unit Standard Name</p>
          </TableHeader> 
          <TableHeader isSelected = {sortOrderColumn == "tims_credits"} onClick = { () => changeOrder("tims_credits")} sortable = {true} sortOrder = {sortOrder}>
            <p style = {{userSelect:'none'}} className="mb-0">Credits</p>
          </TableHeader>
          <TableHeader isSelected = {sortOrderColumn == "tims_level"} onClick = { () => changeOrder("tims_level")} sortable = {true} sortOrder = {sortOrder}>
            <p style = {{userSelect:'none'}} className="mb-0">Level</p>
          </TableHeader>
          <TableHeader sortable = {false}>
            <p style = {{userSelect:'none'}} className="mb-0">Type</p>
          </TableHeader>
          <th hidden={props.componentHelper.isAllowedUnitStandardResources == 0}><p className="mb-0">Resource</p></th>

          <TableHeader sortable = {false}>
            <p style = {{userSelect:'none'}} className="mb-0">Result</p>
          </TableHeader>
          
          <TableHeader sortable = {false}>
            <p style = {{userSelect:'none'}} className="mb-0">Comment</p>
          </TableHeader>
        </tr>
      </thead>
      <tbody>
        {unitStandardItemTemplate()}
      </tbody>
    </Table>);
  }

  const unitStandardItemTemplate = () => {

    return (
      <React.Fragment>
        {unitStandardRows.map((data: ComponentHelperBase, index) => (
          <tr key={index} hidden={globalState.isPreviewModeOnly ? false : !data.isSelected}>
            <td className="pb-0">
              <div><p className="unitstandard-div mb-0">{data.unitStandard?.tims_name}</p></div>
            </td>
            <td className="pb-0">
              <p className="mb-0">
                <s style={{ color: "red" }} hidden={!data.excludeCredits}>{data.unitStandard?.tims_credits}</s>
                <span hidden={data.excludeCredits}>{data.unitStandard?.tims_credits}</span>
              </p></td>
            <td className="pb-0"><p className="mb-0">{data.unitStandard?.tims_level}</p></td>
            <td className="pb-0"><p className="mb-0">{data.assessmentType}</p></td>
            <td className="pb-0" hidden={props.componentHelper.isAllowedUnitStandardResources == 0}>
              <p className="mb-0" hidden={!(data.resourceStatus == 100000002)}>Cancelled</p>
              <p className="mb-0" hidden={!(data.resourceStatus == 100000001)}>Ordered</p>
              {(data.hasResource && data.resourceStatus == -1) ? <DefaultButton disabled={isOrderResourcePressed} className="grid-button button-style" onClick={async (e) => {
                setisOrderResourcePressed(true);
                let dispatcher = getDispatcher();
                await data._trainingPlanUnitStandard?.orderResource();
                dispatcher({
                  type: "UPDATE_RESOURCE", obj: {
                    electiveSectionId: props.componentHelper._electiveSectionM?.tims_electivesectionid, status: ResourceOrderStatus.Pending, unitStandardId: data.unitStandard?.tims_unitstandardid
                  }
                });
                setisOrderResourcePressed(false);
              }}>
                Order
              </DefaultButton> : ""}

              {(data.resourceStatus == 100000000) ? <DefaultButton disabled={isCancelResourcePressed} className="grid-button button-style" onClick={async (e) => {
                setisCancelResourcePressed(true);
                let dispatcher = getDispatcher();
                await data._trainingPlanUnitStandard?.cancelResource();
                dispatcher({
                  type: "UPDATE_RESOURCE", obj: {
                    electiveSectionId: props.componentHelper._electiveSectionM?.tims_electivesectionid, status: ResourceOrderStatus.Cancelled, unitStandardId: data.unitStandard?.tims_unitstandardid
                  }
                });
                setisCancelResourcePressed(false);
              }}>
                Cancel
              </DefaultButton> : ""}
            </td>
            <td className="pb-0">
              <p className="mb-0" hidden={!(data.isExempt)}>Exempt</p>
              <p className="mb-0" hidden={!(data.isAchieved || data.isAchievedPendingPreReq || data.isPartiallyAchieved)}>{data.resultHelper != undefined ?
                <a href="#" onClick={(e) => {
                  setCurrentRowIndex(index);
                  showResultModal();
                }
                }>{data.resultHelper.formattedText}</a> : ""}
              </p>

              {(globalState.trainingPlanObj?.statuscode == TrainingPlanStatuses.Active && !(data.isAchieved || data.isAchievedPendingPreReq || data.isPartiallyAchieved)
                && (data.resultForm?._practicalEnabled || data.resultForm?._theoryEnabled) && (data.resultForm?._assessorHasPermission(globalState)) && !data.isExempt) ?
                <DefaultButton title="Add Result" iconProps={{ iconName: "Add" }} className="grid-button button-style" onClick={async (e) => {
                  setCurrentRowIndex(index);
                  await FetchLatestTrainingPlanDefaultAssessor();
                  showResultModal();
                }}>
                </DefaultButton> : ""}
            </td>
            <td className="pb-0">
              <p className="unitstandard-div mb-0" hidden={data.comment == null}>
                {data.comment?.tims_trainingcomment} < DefaultButton className="grid-button button-style" title="Edit Comment" style={{ marginLeft: "7px", float: "right" }} iconProps={{ iconName: "CommentAdd" }} onClick={(e) => {
                  setisCommentUpdateMode(true);
                  setComment({ comment: data.comment?.tims_trainingcomment as string, index });
                  setCurrentRowIndex(index);
                  showCommentModal();
                }}></DefaultButton></p>
              {(data.comment == undefined) ? <DefaultButton title="Add Comment" style={{ float: "right" }} iconProps={{ iconName: "CommentAdd" }} className="grid-button button-style" onClick={(e) => {
                setisCommentUpdateMode(false);
                setComment({ comment: "", index });
                setCurrentRowIndex(index);
                showCommentModal();
              }}></DefaultButton> : ""}
            </td>
          </tr>
        ))
        }

        {renderDialogResult()}
        {renderDialogComment()}
      </React.Fragment >
    );

  }

  const crossCreditElectiveTemplate = () => {
    return (<Table
      id={Common.newGuid()} style={{ textAlign: "center" }} bordered hover size="sm">
      <thead>
        <tr>
        <TableHeader isSelected = {sortOrderColumn == "tims_name"} onClick = { () => changeOrder("tims_name")} sortable = {true} sortOrder = {sortOrder}>
          <p style = {{userSelect:'none'}} className="mb-0">Unit Standard Name</p>
        </TableHeader>
        <TableHeader isSelected = {sortOrderColumn == "tims_credits"} onClick = { () => changeOrder("tims_credits")} sortable = {true} sortOrder = {sortOrder}>
          <p style = {{userSelect:'none'}} className="mb-0">Credits</p>
        </TableHeader>
        <TableHeader isSelected = {sortOrderColumn == "tims_level"} onClick = { () => changeOrder("tims_level")} sortable = {true} sortOrder = {sortOrder}>
          <p style = {{userSelect:'none'}} className="mb-0">Level</p>
        </TableHeader>
        <TableHeader sortable = {false}>
          <p style = {{userSelect:'none', alignSelf:'center'}} className="mb-0">Type</p>
        </TableHeader>
        <TableHeader hidden={props.componentHelper.isAllowedUnitStandardResources == 0} sortable = {false}>
          <p style = {{userSelect:'none', alignSelf:'center'}} className="mb-0">Resource</p>
        </TableHeader>
        <TableHeader sortable = {false}>
          <p style = {{userSelect:'none', alignSelf:'center'}} className="mb-0">Result</p>
        </TableHeader>
        <TableHeader sortable = {false}>
          <p style = {{userSelect:'none', alignSelf:'center'}} className="mb-0">Comment</p>
        </TableHeader>
        </tr>
      </thead>
      <tbody>
        {unitStandardItemTemplate()}
      </tbody>
    </Table>);
  }
  const itemSelectionEditTemplate = () => {

    return (<Table
      id={Common.newGuid()} style={{ textAlign: "center" }} bordered hover size="sm">
      <thead>
        <tr>
        <TableHeader sortable = {false}>
          <p style = {{userSelect:'none', alignSelf:'center'}} className="mb-0">Selected</p>
        </TableHeader>

        <TableHeader isSelected = {sortOrderColumn == "tims_name"} onClick = { () => changeOrder("tims_name")} sortable = {true} sortOrder = {sortOrder}>
          <p style = {{userSelect:'none'}} className="mb-0">Unit Standard Name</p>
        </TableHeader>

        <TableHeader isSelected = {sortOrderColumn == "tims_credits"} onClick = { () => changeOrder("tims_credits")} sortable = {true} sortOrder = {sortOrder}>
          <p style = {{userSelect:'none'}} className="mb-0">Credits</p>
        </TableHeader>
            
        <TableHeader isSelected = {sortOrderColumn == "tims_level"} onClick = { () => changeOrder("tims_level")} sortable = {true} sortOrder = {sortOrder}>
          <p style = {{userSelect:'none'}} className="mb-0">Level</p>
        </TableHeader>

        <TableHeader sortable = {false}>
          <p style = {{userSelect:'none', alignSelf:'center'}} className="mb-0">Type</p>
        </TableHeader>

        {globalState.contact != undefined ? 
        <TableHeader sortable = {false}>
          <p style = {{userSelect:'none', alignSelf:'center'}} className="mb-0">Result</p>
        </TableHeader> : ""}
        </tr>
      </thead>
      <tbody>

        {unitStandardRows.map((data: ComponentHelperBase, index) => (

          <tr key={data.unitStandard?.tims_unitstandardid} className={data.isDirty ? 'pito-tr-dirty' : undefined}>
            <td className="pb-2">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Checkbox disabled={data.isDisabled || data.isITRLocked || props.componentHelper.isDisabled } checked={data.isSelected} onChange={(e, checked) => {
                  let dispatcher = getDispatcher();
                  dispatcher({
                    type: "CHECKBOX_CHANGED", obj: {
                      electiveSectionId: props.componentHelper._electiveSectionM?.tims_electivesectionid, checked: checked, unitstandardId: data.unitStandard?.tims_unitstandardid,
                      frameworkElementCode: data.unitStandard?.tims_frameworkelementcode
                    }
                  });
                }} />
                <span className="mb-0" hidden={!data.isSelectedOtherSection}>*</span>
              </div>
            </td>
            <td className="pb-2">
              <p className="unitstandard-div mb-0">{data.unitStandard?.tims_name}</p>
            </td>

            <td className="pb-2">
              <p className="mb-0">
                <s style={{ color: "red" }} hidden={!data.excludeCredits}>{data.credits}</s>
                <span hidden={data.excludeCredits}>{data.credits}</span>
              </p>
            </td>
            <td className="pb-2"><p className="mb-0">{data.unitStandard?.tims_level}</p></td>
            <td className="pb-2"><p className="mb-0">{data.assessmentType}</p></td>
            {globalState.contact != undefined ?
              <td className="pb-2">
                <p className="mb-0" hidden={!(data.isExempt)}>Exempt</p>
                <p className="mb-0" hidden={!(data.isAchieved || data.isAchievedPendingPreReq || data.isPartiallyAchieved)}>{data.resultHelper != undefined ?
                  <a href="#" onClick={(e) => { setCurrentRowIndex(index); showResultModal(); }}>{data.resultHelper.formattedText}</a> : ""}</p>
              </td> : ""}
            {renderDialogResult()}
          </tr>
        ))}

      </tbody>
    </Table>);

  }

  const pickAListElectiveTemplate = () => {

    const pickAListColumnDataSource = [
      { text: "A", value: "islista" },
      { text: "B", value: "islistb" },
      { text: "C", value: "islistc" },
      { text: "D", value: "islistd" },
      { text: "E", value: "isliste" },
      { text: "F", value: "islistf" }
    ];

    return (
      <React.Fragment>
        <div style={{ float: "right", marginRight: "6px", paddingTop: "5px" }}><p>{props.componentHelper._electiveSectionM?.tims_listitemdefinition}</p></div>
        <Table id={Common.newGuid()} style={{ textAlign: "center" }} bordered hover size="sm">
          <thead>
            <tr>
              <th><p className="mb-0">Selected</p></th>
              <th><p className="mb-0">Unit Standard Name</p></th>
              <th><p className="mb-0">Credits</p></th>
              <th><p className="mb-0">Level</p></th>
              <th><p className="mb-0">Type</p></th>
              {globalState.contact != undefined ? <th><p className="autoParaStyle6">Result</p></th> : ""}
              {pickAListColumnDataSource.map((obj, index) => (
                <th key={props.componentHelper._electiveSectionM?.tims_electivesectionid + obj.text}><p className="autoParaStyle6">{obj.text}</p><input disabled = {props.componentHelper.isDisabled} name="pickalistSelect" value={obj.value} type="radio" defaultChecked={(props.componentHelper as ProgramElectiveSectionHelper).isCheckedPickAList(obj.value)} onChange={(e) => {
                  let dispatcher = getDispatcher();
                  dispatcher({
                    type: "PICKALIST_SELECTION_CHANGED", obj: {
                      electiveSectionId: props.componentHelper._electiveSectionM?.tims_electivesectionid, value: e.target.value
                    }
                  });
                }} /></th>
              ))}
            </tr>
          </thead>
          <tbody>

            {props.componentHelper.rows.map((data: ComponentHelperBase, index) => (
              <tr key={data.unitStandard?.tims_unitstandardid} className={data.isDirty ? 'pito-tr-dirty' : undefined} >
                <td className="pb-2">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Checkbox disabled={true} checked={data.isChecked} />
                  </div>
                </td>
                <td className="pb-2"><p className="unitstandard-div mb-0">{data.unitStandard?.tims_name}</p></td>
                <td className="pb-2"><p className="mb-0">{data.unitStandard?.tims_credits}</p></td>
                <td className="pb-2"><p className="mb-0">{data.unitStandard?.tims_level}</p></td>
                <td className="pb-2"><p className="mb-0">{data.assessmentType}</p></td>
                {globalState.contact != undefined ?
                  <td className="pb-2">
                    <p className="mb-0" hidden={!(data.isExempt)}>Exempt</p>
                    <p className="mb-0" hidden={!(data.isAchieved || data.isAchievedPendingPreReq || data.isPartiallyAchieved)}>{data.resultHelper != undefined ?
                      <a href="#" onClick={(e) => { setCurrentRowIndex(index); showResultModal(); }}>{data.resultHelper.formattedText}</a> : ""}</p>
                    {renderDialogResult()}
                  </td> : ""}
                <td className="pb-2"><p className="mb-0" hidden={!data._progUnitStandard?.tims_islista}>&#10003;</p></td>
                <td className="pb-2"><p className="mb-0" hidden={!data._progUnitStandard?.tims_islistb}>&#10003;</p></td>
                <td className="pb-2"><p className="mb-0" hidden={!data._progUnitStandard?.tims_islistc}>&#10003;</p></td>
                <td className="pb-2"><p className="mb-0" hidden={!data._progUnitStandard?.tims_islistd}>&#10003;</p></td>
                <td className="pb-2"><p className="mb-0" hidden={!data._progUnitStandard?.tims_isliste}>&#10003;</p></td>
                <td className="pb-2"><p className="mb-0" hidden={!data._progUnitStandard?.tims_islistf}>&#10003;</p></td>
              </tr>
            ))}
          </tbody>
        </Table>
      </React.Fragment>);
  }
  const crossCreditElectiveEditTemplate = () => {
    
    const crossCreditButton = !globalState.isPreviewModeOnly ? <DefaultButton disabled = {props.componentHelper.isDisabled} className="grid-button button-style" style={{ float: "left", marginInline: "1%", marginTop: "1%" }} onClick={async () => {
      await searchUnits("", 0)
      showUnitsModal();
    }}>Add Unit Standard</DefaultButton> : undefined

    return (<Table
      id={Common.newGuid()} style={{ textAlign: "center" }} bordered hover size="sm">
      <thead>
        <tr>
        <TableHeader sortable = {false}>
          <p style = {{userSelect:'none', alignSelf:'center'}} className="mb-0">Selected</p>
        </TableHeader>
        <TableHeader crossCreditButton = {crossCreditButton} isSelected = {sortOrderColumn == "tims_name"} onClick = { () => changeOrder("tims_name")} sortable = {true} sortOrder = {sortOrder}>
            <p className="mb-0">Unit Standard Name</p>
            
            {renderCrossCreditSearchTemplate()}
          </TableHeader>
            <TableHeader isSelected = {sortOrderColumn == "tims_credits"} onClick = { () => changeOrder("tims_credits")} sortable = {true} sortOrder = {sortOrder}>
              <p style = {{userSelect:'none'}} className="mb-0">Credits</p>
            </TableHeader>
            <TableHeader isSelected = {sortOrderColumn == "tims_level"} onClick = { () => changeOrder("tims_level")} sortable = {true} sortOrder = {sortOrder}>
              <p style = {{userSelect:'none'}} className="mb-0">Level</p>
            </TableHeader>
            <TableHeader sortable = {false}>
              <p style = {{userSelect:'none', alignSelf:'center'}} className="mb-0">Type</p>
            </TableHeader>
            
             {globalState.contact != undefined ?        
             <TableHeader sortable = {false}>
              <p style = {{userSelect:'none', alignSelf:'center'}} className="mb-0">Result</p>
            </TableHeader> : ""}
        </tr>
      </thead>
      <tbody>

        {unitStandardRows.map((data: ComponentHelperBase, index) => (
          <tr key={data.unitStandard?.tims_unitstandardid} className={data.isDirty ? 'pito-tr-dirty' : undefined}>
            <td className="pb-2">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Checkbox disabled={data.isITRLocked || props.componentHelper.isDisabled} checked={data.isSelected} onChange={(e, checked) => {
                  let dispatcher = getDispatcher();
                  dispatcher({
                    type: "CHECKBOX_CHANGED", obj: {
                      electiveSectionId: props.componentHelper._electiveSectionM?.tims_electivesectionid, checked: checked, unitstandardId: data.unitStandard?.tims_unitstandardid,
                      frameworkElementCode: data.unitStandard?.tims_frameworkelementcode
                    }
                  });
                }} />
                <span hidden={!data.isSelectedOtherSection}>*</span>
              </div>
            </td>
            <td className="pb-2">
              <p className="unitstandard-div mb-0">{data.unitStandard?.tims_name}</p>
            </td>

            <td className="pb-2">
              <p className="mb-0">
                <s style={{ color: "red" }} hidden={!data.excludeCredits}>{data.credits}</s>
                <span hidden={data.excludeCredits}>{data.credits}</span></p>
            </td>
            <td className="pb-2"><p className="mb-0">{data.unitStandard?.tims_level}</p></td>
            <td className="pb-2"><p className="mb-0">{data.assessmentType}</p></td>
            {globalState.contact != undefined ?
              <td className="pb-2">
                <p className="mb-0" hidden={!(data.isExempt)}>Exempt</p>
                <p className="mb-0" hidden={!(data.isAchieved || data.isAchievedPendingPreReq || data.isPartiallyAchieved)}>{data.resultHelper != undefined ?
                  <a href="#" onClick={(e) => { setCurrentRowIndex(index); showResultModal(); }}>{data.resultHelper.formattedText}</a> : ""}</p>
              </td> : ""}
            {renderDialogResult()}
          </tr>
        ))}

      </tbody>
    </Table>);

  }

  const _columns: IColumn[] = [
    {
      key: 'UnitStandard',
      name: 'Unit Standard',
      fieldName: 'UnitStandard',
      minWidth: 200,
      maxWidth: 400,
      isResizable: true,
      ariaLabel: 'Unit Standard'
    },
    {
      key: 'Version',
      name: 'Version',
      fieldName: 'Version',
      minWidth: 20,
      maxWidth: 50,
      isResizable: false,
      ariaLabel: 'Version'
    }];

  const _contactColumns: IColumn[] = [
    {
      key: 'firstname',
      name: 'First Name',
      fieldName: 'firstname',
      minWidth: 200,
      maxWidth: 400,
      isResizable: true,
      ariaLabel: 'First Name'
    },
    {
      key: 'lastname',
      name: 'Last Name',
      fieldName: 'lastname',
      minWidth: 20,
      maxWidth: 50,
      isResizable: false,
      ariaLabel: 'Last Name',
    }
  ];

  const renderCrossCreditSearchTemplate = () => {
    const index = currentRowIndex;
    const titleId = "title-unitmodal-" + index;

    return (
      <Modal
        isDarkOverlay={false}
        titleAriaId={titleId}
        isOpen={isAddUnitModalOpen}
        onDismiss={hideUnitsModal}
        isBlocking={false}
        containerClassName={contentStyles.container}
        dragOptions={isDraggable ? dragOptions : undefined}
      >
        <div className={contentStyles.header}>
          <span id={titleId}>Unit Standard Search</span>
          {isUnitCreating ? <div style={{ marginLeft: "10px" }} className="lds-dual-ring"></div> : ""}
          <IconButton
            styles={iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={hideUnitsModal}
          />
        </div>
        <Stack>
          <Stack className={contentStyles.body} style={{ width: "800px" }}>
            <StackItem>
              <Stack horizontal>
                <SearchBox
                  placeholder="Search"
                  onEscape={ev => {
                    console.log('Custom onEscape Called');
                  }}
                  onClear={ev => {
                    console.log('Custom onClear Called');
                  }}
                  onChange={(_, newValue) => console.log('SearchBox onChange fired: ' + newValue)}
                  onSearch={async newValue => {
                    await searchUnits(newValue, index);
                  }}
                />
              </Stack>
            </StackItem>
            <StackItem >
              <DetailsList
                columns={_columns}
                // checkboxVisibility={CheckboxVisibility.always}  
                styles={gridStyles}
                layoutMode={DetailsListLayoutMode.justified}
                constrainMode={ConstrainMode.horizontalConstrained}
                selection={selection}
                items={searchData} />
            </StackItem>

            <StackItem>
              <DefaultButton className="button-style" style={{ float: "right", marginTop: "10px" }} disabled={isResultCreating} text="Add" onClick={async (e) => {

                showUnitLoader();
                var helper = unitStandardRows[index].searchHelper;


                var tpunitStandardRepo = new TrainingPlanUnitStandardModel(helper._globalStateModel?.tokenModel as TokenModel);
                var selectedItems = selection.getSelection();
                let errorArray: string[] = [];

                for (const element of selectedItems) {
                  var model = element as SearchResultModel;
                  var code = model.UnitStandard ?? "";
                  var version = model.Version ?? "";
                  var unitStandard = await helper.retrieveUnitByCode(code);
                  var tpunitStandard = await tpunitStandardRepo.retrieveByUnitStandardCode(code.substring(0, code.indexOf("-")));
                  let dispatcher = getDispatcher();

                  if (tpunitStandard) {
                    let response = await tpunitStandardRepo.deleteTPUS(tpunitStandard.tims_trainingplanunitstandardid as string);
                    dispatcher({
                      type: "ROW_REMOVED", obj: {
                        electiveSectionId: props.componentHelper._electiveSectionM?.tims_electivesectionid,
                        tpusId: tpunitStandard.tims_trainingplanunitstandardid
                      }
                    });

                  }
                  //Add
                  // assessment type defaults to practical and assessor			
                  var tpuCreate = {
                    trainingplanid: helper._globalStateModel.trainingPlanObj?.tims_trainingplanid,
                    unitstandardid: unitStandard.Id,
                    assessmenttype: 1,
                    assessedby: 1,
                    electivesectionid: helper?._electiveSectionId,
                  } as ItpuCreate;

                  const createResult = await tpunitStandardRepo.create(tpuCreate) as TrainingPlanUnitStandardModel;

                  if (createResult.error != undefined) {
                    console.error("No  tpunit created");
                    errorArray.push(createResult.error.message);
                    continue;

                  }
                  //Now get result..
                  let resultModel = new ResultModel(helper._globalStateModel?.tokenModel as TokenModel);
                  let result;
                  if (createResult._tims_resultid_value) {
                    result = resultModel.retrieveResult(createResult._tims_resultid_value, false);
                  }
                  else {
                    result = resultModel.retrieveResultsForUnitStandardCode(code.substring(0, code.indexOf("-")));
                  }
                  let electiveSectionId = props.componentHelper._electiveSectionM?.tims_electivesectionid as string;
                  let newCrossCreditProgramUnitStandardHelper = new CrossCreditProgramUnitStandardHelper(result ?? resultModel, createResult, electiveSectionId, globalState);
                  await newCrossCreditProgramUnitStandardHelper.build();
                  dispatcher({
                    type: "ROW_ADDED", obj: { newCrossCreditUSHelper: newCrossCreditProgramUnitStandardHelper, electiveSectionId: electiveSectionId, newUnit: createResult, }
                  });

                }
                Emitter.emit(Events.SetMessageBar, { errors: errorArray, isSuccess: errorArray.length == 0, requestType: RequestType.ADD_US, showMessageBar: true } as IMessagebarObject);

                hideUnitLoader();
                hideUnitsModal();
                setSearchData([]);
              }}></DefaultButton>
            </StackItem>
          </Stack>
        </Stack>
      </Modal>
    );
  }

  const renderContactSearchTemplate = () => {
    const index = currentRowIndex;
    const titleId = "title-unitmodal-" + index;

    return (
      <Modal
        isDarkOverlay={false}
        titleAriaId={titleId}
        isOpen={isAddContactModalOpen}
        onDismiss={hideContactsModal}
        isBlocking={false}
        containerClassName={contentStyles.container}
        dragOptions={isDraggable ? dragOptions : undefined}
        onDismissed={() => {
          setSearchContactData([]);
        }}
      >
        <div className={contentStyles.header}>
          <span id={titleId}>Contact Search</span>
          {isUnitCreating ? <div style={{ marginLeft: "10px" }} className="lds-dual-ring"></div> : ""}
          <IconButton
            styles={iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={hideContactsModal}
          />
        </div>
        <Stack>
          <Stack className={contentStyles.body} style={{ width: "800px" }}>
            <StackItem>
              <Stack horizontal>
                <SearchBox
                  placeholder="Search"
                  onEscape={ev => {
                    console.log('Custom onEscape Called');
                  }}
                  onClear={ev => {
                    console.log('Custom onClear Called');
                  }}
                  onChange={(_, newValue) => console.log('SearchBox onChange fired: ' + newValue)}
                  onSearch={async newValue => {
                    await searchContacts(newValue, index);
                  }}
                />
              </Stack>
            </StackItem>
            <StackItem >
              <DetailsList
                columns={_contactColumns}
                styles={gridStyles}
                layoutMode={DetailsListLayoutMode.justified}
                constrainMode={ConstrainMode.horizontalConstrained}
                selection={selection}
                selectionMode={SelectionMode.single}
                items={searchDataContact} />
            </StackItem>

            <StackItem>
              <DefaultButton className="button-style" style={{ float: "right", marginTop: "10px" }} disabled={isResultCreating} text="Add" onClick={async (e) => {
                showUnitLoader();
                let errorObj = {} as Serializable;
                var tpunitStandardRepo = new TrainingPlanUnitStandardModel(globalState.tokenModel as TokenModel);
                var selectedItems = selection.getSelection();
                if (selectedItems.length == 0) {
                  hideUnitLoader();
                  return;
                }
                let selectedContact = selectedItems[0] as ContactModel;
                let row = props.rows[index] as TrainingPlanUnitStandard;
                let updateTPU = {
                  assessorId: contactModalMode == ContactRole.Assessor ? selectedContact.contactid : undefined,
                  electivesectionid: undefined,
                  programmeunitstandardid: undefined,
                  resourceorderstatus: undefined,
                  unitstandardid: undefined,
                  verifierId: contactModalMode == ContactRole.Verifier ? selectedContact.contactid : undefined
                } as ItpuUpdate;

                let response = await tpunitStandardRepo.updateTPUS(row._tpuModel.tims_trainingplanunitstandardid as string, updateTPU);
                if (response.ok) {
                  let dispatcher = getDispatcher();
                  let updatedTPU = await tpunitStandardRepo.retrieveTPUSFromId(row._tpuModel.tims_trainingplanunitstandardid as string, false);
                  dispatcher({
                    type: "ADD_ASSESSOR_VERIFIER", obj: { updatedTPU: updatedTPU, rowIndex: index }
                  });
                }
                else
                  errorObj = await response.json() as Serializable;

                Emitter.emit(Events.SetMessageBar, {
                  errors: [errorObj != undefined ? errorObj.error != undefined ? errorObj.error.message : "" : ""], isSuccess: response.ok,
                  requestType: contactModalMode == ContactRole.Assessor ? RequestType.ADD_ASSESSOR : RequestType.ADD_VERIFIER, showMessageBar: true
                } as IMessagebarObject);
                hideUnitLoader();
                hideContactsModal();
                setSearchContactData([]);
              }}></DefaultButton>
            </StackItem>
          </Stack>
        </Stack>
      </Modal>
    );
  }


  const gridStyles: Partial<IDetailsListStyles> = {
    root: {
      overflowX: 'scroll',
      selectors: {
        '& [role=grid]': {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          height: '60vh',
        },
      },
    },
    headerWrapper: {
      flex: '0 0 auto',
    },
    contentWrapper: {
      flex: '1 1 auto',
      overflowY: 'auto',
      overflowX: 'hidden',
    },
  };

  return getHTMLByTemplate(props.template);

}