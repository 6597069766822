import _ from 'underscore';
import { SortReorderCode } from '../Common/Enum';
import { ItpuUpdate } from '../Common/TrainingPlanUnitStandardManager';
import GlobalStateModel from '../Models/GlobalStateModel';
import TokenModel from '../Models/TokenModel';
import TrainingPlanUnitStandardModel from '../Models/TrainingPlanUnitStandardModel';
import ComponentHelperBase from './ComponentHelperBase';
import TrainingPlanUnitStandard from './TrainingPlanUnitStandard';

export default class ReorderHelper extends ComponentHelperBase {

    trainingPlanUnitStandards: TrainingPlanUnitStandard[];
    _globalStateModel: GlobalStateModel;

    constructor(globalStateModel: GlobalStateModel) {
        super();
        this._globalStateModel = globalStateModel;          
        this.trainingPlanUnitStandards = [];
    }

    


    async build() {
        let tpUS = new TrainingPlanUnitStandardModel(this._globalStateModel.tokenModel as TokenModel);
        tpUS.needsRefresh = true;
        tpUS.trainingPlanId = this._globalStateModel.trainingPlanObj?.tims_trainingplanid;
        let tpus = await tpUS.retrieveAll();

        for (let index = 0; index < tpus.length; index++) {
            const element = tpus[index];
            element._tokenModel = this._globalStateModel.tokenModel as TokenModel;
            let tpu = new TrainingPlanUnitStandard(element);
            tpu.GlobalState = this._globalStateModel as GlobalStateModel;
            await tpu.build()
            if(tpu._tpuModel.statecode == 0) this.trainingPlanUnitStandards.push(tpu);

        }
    }

    async saveSeqNum() {
        let tpusModel = new TrainingPlanUnitStandardModel(this._globalStateModel.tokenModel as TokenModel);
        let promiseArr = [];

        for (let index = 0; index < this.trainingPlanUnitStandards.length; index++) {
            const element = this.trainingPlanUnitStandards[index];
            if (element.isDirty) {
                let resp = tpusModel.updateTPUS(element._tpuModel.tims_trainingplanunitstandardid as string, { sequencenumber: element.newSequenceNumber } as ItpuUpdate);
                promiseArr.push(resp);
            }
        }

        let responses = await Promise.all(promiseArr);
        return responses;
    }

    setSequenceNumberForTPUS(isForAll: boolean) {
        for (let index = 0; index < this.trainingPlanUnitStandards.length; index++) {
            const element = this.trainingPlanUnitStandards[index];
            if (element.sequenceNumber == undefined || isForAll)
                element.newSequenceNumber = index + 1;
        }   
    }

    sortBySequenceNumber() {
        let sortedArray = this.trainingPlanUnitStandards.sort((tp1: TrainingPlanUnitStandard, tp2: TrainingPlanUnitStandard) => {
            if (tp1._tpuModel.fus_sequencenumber != undefined && tp2._tpuModel.fus_sequencenumber != undefined) {
                let tp1SeqNum = tp1._tpuModel.fus_sequencenumber;
                let tp2SeqNum = tp2._tpuModel.fus_sequencenumber;

                if (tp1SeqNum < tp2SeqNum)
                    return -1;
                else if (tp1SeqNum > tp2SeqNum)
                    return 1;
                else
                    return 0;
            } else if (tp1._tpuModel.fus_sequencenumber != undefined)
                return 1;
            else if (tp2._tpuModel.fus_sequencenumber != undefined)
                return -1;
            else
                return 0;
        });

        this.trainingPlanUnitStandards = sortedArray;
    }

    sortByFrameworkElementCode() {
        let sortedArray = this.trainingPlanUnitStandards.sort((tp1: TrainingPlanUnitStandard, tp2: TrainingPlanUnitStandard) => {
            if (tp1._tpuModel.fus_sequencenumber != undefined)
                return 0;

            if (tp1.unitStandard != undefined && tp2.unitStandard != undefined) {
                let tp1FrameworkElementCode = parseInt(tp1.unitStandard.tims_frameworkelementcode as string);
                let tp2FrameworkElementCode = parseInt(tp2.unitStandard.tims_frameworkelementcode as string);

                if (tp1FrameworkElementCode < tp2FrameworkElementCode)
                    return -1;
                else if (tp1FrameworkElementCode > tp2FrameworkElementCode)
                    return 1;
                else
                    return 0;
            } else if (tp1.unitStandard != undefined)
                return 1;
            else if (tp2.unitStandard != undefined)
                return -1;
            else
                return 0;
        });

        this.trainingPlanUnitStandards = sortedArray;
    }

    get achievedCredits() {
        return _.reduce(this.trainingPlanUnitStandards, function (memo, pus) {
            if ((pus.isAchieved) || pus.isExempt) {
                return memo + (pus.credits as number);
            }
            return memo;
        }, 0);
    };

    get selectedCredits() {
        return _.reduce(this.trainingPlanUnitStandards, function (memo, pus) {
            return memo + (pus.credits as number);


        }, 0);
    };

    get _remainingCredits() {
        return this.selectedCredits - this.achievedCredits;
    };

    sortByResult(sortorder: SortReorderCode) {
        let sortedArray = this.trainingPlanUnitStandards.sort((tp1: TrainingPlanUnitStandard, tp2: TrainingPlanUnitStandard) => {
            if (tp1._tpuModel.fus_sequencenumber != undefined)
                return 0;

            if (tp1._resultHelper != undefined && tp2._resultHelper != undefined) {
                let dateOfAcheiveTp1 = new Date(tp1._resultHelper._resultModel.tims_dateofachievement as string);
                let dateOfAcheiveTp2 = new Date(tp2._resultHelper._resultModel.tims_dateofachievement as string);
                if (dateOfAcheiveTp1 < dateOfAcheiveTp2)
                    return -1;
                else if (dateOfAcheiveTp1 > dateOfAcheiveTp2)
                    return 1;
                else
                    return 0;
            } else if (tp1._resultHelper != undefined)
                return sortorder == SortReorderCode.ResultFirst ? -1 : 1;
            else if (tp2._resultHelper != undefined)
                return sortorder == SortReorderCode.ResultFirst ? 1 : -1;
            else
                return 0;
        });

        this.trainingPlanUnitStandards = sortedArray;
    }

    get rows() {
        return this.trainingPlanUnitStandards
    }
}
