import { Serializable } from "./Serializable";
import TokenModel from "./TokenModel"

const entityName = "tims_unitstandardrelationships";

export class UnitStandardRelationshipModel extends Serializable {
    constructor(tokenModel: TokenModel) {
        super(tokenModel);
    }  

    async retrieveExemptRelationship(programmeId:string, unitStandardCode:string){
        var fetch = [
            "<fetch version='1.0' output-format='xml-platform' mapping='logical' distinct='false'>",
            "  <entity name='tims_unitstandardrelationship'>",
            "    <attribute name='tims_relationshiptype' />",
            "    <attribute name='tims_rule' />",
            "    <filter>",
            "      <condition attribute='tims_relationshiptype' operator='eq' value='1' />",
            "      <condition attribute='tims_programmeid' operator='eq' value='#PROGRAMMEID#' />",
            "      <condition attribute='statecode' operator='eq' value='0' />",
            "    </filter>",
            "    <link-entity name='tims_unitstdrelationship_tims_unitstandard' from='tims_unitstandardrelationshipid' to='tims_unitstandardrelationshipid' intersect='true'>",
            "      <link-entity name='tims_unitstandard' from='tims_unitstandardid' to='tims_unitstandardid' alias='unit_standard' intersect='true'>",
            "        <attribute name='tims_frameworkelementcode' />",
            "      </link-entity>",
            "    </link-entity>",
            "    <link-entity name='tims_unitstandard' from='tims_unitstandardid' to='tims_unitstandardid' alias='TP_US'>",
            "      <filter>",
            "        <condition attribute='tims_frameworkelementcode' operator='eq' value='#UNITSTANDARDCODE#' />",
            "      </filter>",
            "    </link-entity>",
            "  </entity>",
            "</fetch>"
            ].join('').replace("#PROGRAMMEID#", programmeId).replace('#UNITSTANDARDCODE#', unitStandardCode);
            
            return await this.d365Helper.ExecuteFetch(entityName, fetch, false)
    }

}