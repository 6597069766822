import { Checkbox, Dropdown, IDropdownOption } from "@fluentui/react";
import { Col, Row } from 'react-bootstrap';
import { Fragment, useEffect, useState } from "react";
import Common from '../Common/Common';

export const GoalReminder: React.FunctionComponent<GoalReminderProps> = (props) => {
    const { reminderName, reminderDate, isChecked, dueDate, reminderTypes, reminderTypeOptions, setReminderDate, setIsChecked, setReminderTypes } = props;
    
    const [reminderDays, setReminderDays] = useState<IDropdownOption | undefined>()

    const [reminderDateOptions, setReminderDateOptions] = useState(
        [ {key:7, text:"7", disabled:false}, {key:14, text:"14", disabled:false}, {key:21, text:"21", disabled:false}])

    const goalRemindersDisabled = !dueDate || Common.getDifferenceInDays(new Date(), dueDate) < 7

    const checkOnChangeHandler = (checked:Boolean) => {
        if(checked && dueDate){
            setIsChecked(true)
            setReminderDays({key:7, text:'7'})
            setReminderTypes([1])
        } else {
            setReminderDays(undefined)
            setReminderDate(undefined)
            setReminderTypes([])
            setIsChecked(false)
        }
    }

    // Setting reminder date when reminderDays or dueDate is changed
    
    useEffect(() => {
        if (reminderDays && dueDate) {
            const newDate = new Date(dueDate);
            newDate.setDate(newDate.getDate() - Number(reminderDays.key));
            setReminderDate(newDate);
        }

    }, [dueDate, reminderDays])
    

    // Disable reminder options if the number of days before the due date is less than the option selected
    useEffect(() => {
      if (dueDate) {
          const daysBeforeDueDate = Common.getDifferenceInDays(new Date(), dueDate);
          let reminderDateOptionsCopy = [...reminderDateOptions]
          if(daysBeforeDueDate < 7) setIsChecked(false) 
          
          for(let i = 0; i < reminderDateOptionsCopy.length; i++){
              reminderDateOptionsCopy[i].disabled = daysBeforeDueDate< reminderDateOptionsCopy[i].key
          }
          setReminderDateOptions(reminderDateOptionsCopy)
      }
    }, [dueDate]);
      
    // If reminder date has already beens set, update the days before due date to the number of days remaining
    useEffect(() => {
        if(isChecked && reminderDate && dueDate){
            let currentDays = Common.getDifferenceInDays(reminderDate as Date, dueDate as Date) 
            
            let reminderDateOptionsCopy = [...reminderDateOptions]
            let index = reminderDateOptions.length

            if(currentDays != 7 && currentDays != 14 && currentDays != 21){
                for(let i = 0; i < reminderDateOptions.length; i ++){
                    if(currentDays < reminderDateOptions[i].key){
                        index = i
                        break
                    }
                }
                reminderDateOptionsCopy.splice(index, 0, { key:currentDays, text:currentDays.toString(), disabled:false})
                setReminderDateOptions(reminderDateOptionsCopy)
            }
            setReminderDays({key:currentDays, text:currentDays.toString()})
        }
    }, [])

    return (
        <Fragment>
            <Checkbox 
                disabled = {!isChecked && goalRemindersDisabled} 
                label={reminderName}
                checked={isChecked} onChange={(e, checked) => { 
                    if(checked != undefined){
                        checkOnChangeHandler(checked)
                    }
                }}/>
            { isChecked && 
            <Fragment>
                <Row className="m-2 ml-5">
                    <Col className="col-6">
                        <p>Reminder type:</p>
                    </Col>
                    <Col className="col-6">
                        <Dropdown 
                        placeholder="Select an option"
                        selectedKeys={reminderTypes}
                        multiSelect 
                        options = {reminderTypeOptions}
                        onChange={(e, item) => {
                            if(item){
                                setReminderTypes(
                                    item.selected ? [...reminderTypes, item.key as number] : reminderTypes.filter(key => key !== item.key)
                                )
                            }
                        }}/>
                    </Col>
                </Row>
                <Row className="m-2 ml-5">
                    <Col className="col-6">
                        <p>Days before due date:</p>
                    </Col>
                    <Col className="col-3">
                        <Dropdown 
                        disabled={goalRemindersDisabled}
                        selectedKey={reminderDays ? reminderDays.key : undefined}
                        onChange={(e, item) => {
                            if(item){
                                setReminderDays(item)
                            }
                        }}
                        options = {reminderDateOptions}
                            />
                    </Col>
                </Row>
            </Fragment> 
            }
        </Fragment>
    )
}
interface GoalReminderProps {
    reminderName:string,
    reminderDate?:Date,
    isChecked:boolean,
    dueDate?:Date,
    reminderTypes:number[],
    reminderTypeOptions:IDropdownOption[]
    setIsChecked:React.Dispatch<React.SetStateAction<boolean>>;
    setReminderTypes:React.Dispatch<React.SetStateAction<number[]>>;
    setReminderDate:React.Dispatch<React.SetStateAction<Date | undefined>>;
}
