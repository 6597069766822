import { Serializable } from './Serializable';
import  TokenModel  from "./TokenModel"

const entityName = "tims_electivegroups"
export default class ElectiveGroupModel extends Serializable {


    statuscode: number | undefined;
    tims_electivegroupid: string | undefined;
    tims_level: number | undefined;
    tims_minimumcredits: number | undefined;
    tims_maximumcredits: number | undefined;
    tims_minimumcreditsonlevel: number | undefined;
    tims_maximumcreditsonlevel: number | undefined;
    tims_maximumlevel:number | undefined;
    tims_name: string | undefined;
    _tims_parentelectivegroupid_value: string | undefined;
    tims_programmeid: string | undefined;
    tims_ruledescription: string | undefined;
    tims_sortorder: number | undefined;
    tims_type: number | undefined;
    tims_minimumelectivegroups: number | undefined;
    tims_maximumelectivegroups: number | undefined;
    tims_minimumelectivesections: number | undefined;
    tims_maximumelectivesections: number | undefined;

    constructor(tokeModel: TokenModel) {
        super(tokeModel);

    }

    async retrieveParentElectiveGroups(programmeId: string) {
        var criteria = "_tims_programmeid_value eq '" + programmeId + "' and _tims_parentelectivegroupid_value eq null";
        return await this.d365Helper.RetrieveMultipleByOrder(entityName, this.createColumnArray(this), criteria, "tims_sortorder asc");
    }

    async retrieveChildElectiveGroups(parentGroupId: string) {
    
        var criteria = "_tims_parentelectivegroupid_value eq '" + parentGroupId + "'";

        return await this.d365Helper.RetrieveMultipleByOrder(entityName, this.createColumnArray(this), criteria, "tims_sortorder asc");
    }
}