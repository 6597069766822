import * as React from "react";
import ElectiveSectionModel from "../Models/ElectiveSectionModel";
import GlobalState from "../GlobalState";
import { ElectiveSectionControl } from "./ElectiveSectionControl";
import { ProgrammeModel } from "../Models/ProgrammeModel";
import Loader from "react-loader-spinner";
import { Stack } from "@fluentui/react";
import ComponentHelperBase, { IUSCheckedEventArg } from "../ComponentHelpers/ComponentHelperBase";
import ProgramElectiveSectionHelper from "../ComponentHelpers/ProgramElectiveSectionHelper";
import CrossCreditElectiveSectionHelper from "../ComponentHelpers/CrossCreditElectiveSectionHelper";
import TokenModel from "../Models/TokenModel";
import Emitter from "../Common/EventEmitter";
import { Events } from "../Common/Enum";
import Result from "../ComponentHelpers/Result";
import { PostSaveResponse } from "../Common/TrainingPlanUnitStandardManager";
import TrainingPlanUnitStandard from "../ComponentHelpers/TrainingPlanUnitStandard";
import "../assets/css/PreviewControl.css";
import GlobalStateModel from "../Models/GlobalStateModel";
import CrossCreditProgramUnitStandardHelper from "../ComponentHelpers/CrossCreditProgramUnitStandardHelper";

interface ElecSecState {
    electiveSections: ElectiveSectionModel[],
    electiveSecHelp: ComponentHelperBase[]
    showLoader: boolean,
}

export const prgElecSecContext = React.createContext({});


export const ProgrammeElectiveSections: React.FunctionComponent<{}> = () => {
    const globalContext = React.useContext(GlobalState);
    const ref = React.useRef(true);

    const setDirtyAndCompliantInContext = (isDirty: Boolean, isCompliant: boolean) => {
        let globalContextTmp = {
            ...globalContext,
            isCompliant: { ...globalContext.isCompliant, isPrgrmElecSecCompliant: isCompliant },
            isDirty: {
                isElecGrpSectionDirty: globalContext.isDirty?.isElecGrpSectionDirty, isPrgrmElecSec: isDirty
            }
        } as GlobalStateModel;
        globalContext.setGlobalState(globalContextTmp);
    }

    const checkForDirty = (state: ElecSecState) => {
        let isDirty = false
        for (let index = 0; index < state.electiveSecHelp.length; index++) {
            const element = state.electiveSecHelp[index];
            isDirty = element.rows.some(item => item.isDirty);
            if (isDirty)
                return isDirty;
        }
        return isDirty;
    }

    const checkForCompliant = (state: ElecSecState) => {
        let isCompliant = true
        for (let index = 0; index < state.electiveSecHelp.length; index++) {
            const element = state.electiveSecHelp[index];
            isCompliant = element.isCompliant;
            if (!isCompliant)
                return isCompliant;
        }
        return isCompliant;
    }



    const prgElecSectionReducer = (state: ElecSecState, action: any) => {
        switch (action.type) {
            case "CHECKBOX_CHANGED":

                let elecSec = state.electiveSecHelp.find((innerItem) => { return innerItem._electiveSectionM?.tims_electivesectionid == action.obj.electiveSectionId });
                if (elecSec != undefined) {
                    let elecSecRow = elecSec.rows.find((innerItem) => {
                        return innerItem.unitStandard?.tims_unitstandardid == action.obj.unitstandardId;
                    });
                    if (elecSecRow != undefined) {
                        elecSecRow.onCheckboxChanged(action.obj.checked);
                        if (elecSecRow.isSelectedOtherSection) {
                            refreshOtherRows(state, action);
                            setTimeout(() => { Emitter.emit(Events.UnitStandardSelectionChangedInPrgElecSec, action.obj); }, 100);
                        }
                        let isDirty = checkForDirty(state);
                        let isCompliant = checkForCompliant(state);
                        if (globalContext.isDirty?.isPrgrmElecSec != isDirty || globalContext.isCompliant?.isPrgrmElecSecCompliant != isCompliant)
                            setDirtyAndCompliantInContext(isDirty, isCompliant);
                    }
                }
                return { electiveSections: state.electiveSections, electiveSecHelp: state.electiveSecHelp, showLoader: false } as ElecSecState;
            case "PICKALIST_SELECTION_CHANGED":
                {
                    let elecSec = state.electiveSecHelp.find(item => item._electiveSectionM?.tims_electivesectionid == action.obj.electiveSectionId);
                    if (elecSec != undefined) {
                        if (elecSec != undefined) {
                            if (elecSec instanceof ProgramElectiveSectionHelper) {
                                let prgElecSecHelper = elecSec as ProgramElectiveSectionHelper;
                                prgElecSecHelper.selectList(action.obj.value);
                            }
                        }
                    }

                    let isDirty = checkForDirty(state);
                    let isCompliant = checkForCompliant(state);
                    if (globalContext.isDirty?.isPrgrmElecSec != isDirty || globalContext.isCompliant?.isPrgrmElecSecCompliant != isCompliant)
                        setDirtyAndCompliantInContext(isDirty, isCompliant);

                    return { electiveSections: state.electiveSections, electiveSecHelp: state.electiveSecHelp, showLoader: false } as ElecSecState;
                }
            case "CHECKBOX_CHANGED_ELEC_GRP":
                refreshOtherRows(state, action);
                return { electiveSections: state.electiveSections, electiveSecHelp: state.electiveSecHelp, showLoader: false } as ElecSecState;
            case "CREATE_RESULT":
                let elecSecForResult = state.electiveSecHelp.find(item => item._electiveSectionM?.tims_electivesectionid == action.obj.electiveSectionId);
                if (elecSecForResult != undefined) {
                    let row = elecSecForResult.rows.find(e => e.unitStandard?.tims_unitstandardid == action.obj.unitStandardId)
                    if(row){
                        row._resultHelper = new Result(action.obj.createdResult);
                        if (row.resultForm != undefined)
                        row.resultForm.result = row._resultHelper;
                    }
                }
                return { electiveSections: state.electiveSections, electiveSecHelp: state.electiveSecHelp, showLoader: false } as ElecSecState;
            case "UPDATE_RESULT":
                {
                    let elecSecForResult = state.electiveSecHelp.find(item => item._electiveSectionM?.tims_electivesectionid == action.obj.electiveSectionId);
                    if (elecSecForResult != undefined) {
                        let row = elecSecForResult.rows.find(e => e.unitStandard?.tims_unitstandardid == action.obj.unitStandardId)
                        if(row){
                            row._resultHelper = new Result(action.obj.updatedResult);
                            if (row.resultForm != undefined)
                            row.resultForm.result = row._resultHelper;
                        }

                    }
                    return { electiveSections: state.electiveSections, electiveSecHelp: state.electiveSecHelp, showLoader: false } as ElecSecState;
                }
            case "CREATE_COMMENT":
                let elecSecCmmnt = state.electiveSecHelp.find(item => item._electiveSectionM?.tims_electivesectionid == action.obj.electiveSectionId);
                if (elecSecCmmnt != undefined) {
                    let row = elecSecCmmnt.rows.find(e => e.unitStandard?.tims_unitstandardid == action.obj.unitStandardId)
                    if(row) row.comment = action.obj.commentObj;
                }
                return { electiveSections: state.electiveSections, electiveSecHelp: state.electiveSecHelp, showLoader: false } as ElecSecState;
            case "ROW_ADDED":
                let elecSecAddRowAdded = state.electiveSecHelp.find(item => item._electiveSectionM?.tims_electivesectionid == action.obj.electiveSectionId);
                if (elecSecAddRowAdded != undefined) {

                    //Get the result
                    const { newCrossCreditUSHelper, electiveSectionId, newUnit } = action.obj;
                    let newCrossCreditUSHelperRow = newCrossCreditUSHelper as CrossCreditProgramUnitStandardHelper;
                    //All this state is impure so this is a hack to fix the double invokation 
                    //We check it the TPUS has already been pushed onto the array.
                    const found = elecSecAddRowAdded.rows.filter(elm => elm._trainingPlanUnitStandard?._tpuModel != null).find(elm => elm._trainingPlanUnitStandard?._tpuModel.tims_trainingplanunitstandardid === newUnit.tims_trainingplanunitstandardid)
                    if (!found) {
                        elecSecAddRowAdded.rows.push(newCrossCreditUSHelperRow);
                    }
                    return { electiveSections: state.electiveSections, electiveSecHelp: state.electiveSecHelp, showLoader: false } as ElecSecState;
                }
                break;
            case "ROW_REMOVED":
                var newState = { ...state };
                let elecSecRemove = state.electiveSecHelp.find(item => item._electiveSectionM?.tims_electivesectionid == action.obj.electiveSectionId);
                if (elecSecRemove != undefined) {
                    const { tpusId, electiveSectionId } = action.obj;
                    var found = elecSecRemove.rows.filter(elm => elm._trainingPlanUnitStandard?._tpuModel != null).find(elm => elm._trainingPlanUnitStandard?._tpuModel.tims_trainingplanunitstandardid === tpusId);
                    if (found) {
                        elecSecRemove.rows.splice(elecSecRemove.rows.indexOf(found), 1);
                    }
                    return newState as ElecSecState;
                }
                break;
            case "POST_SAVE":
                let response = action.obj as PostSaveResponse[];
                postSave(state, response);
                return { electiveSections: state.electiveSections, electiveSecHelp: state.electiveSecHelp, showLoader: false } as ElecSecState;

            case "SAVE_TP":
                preSave(state);
                return state;

            case "UPDATE_RESOURCE":
                let elecSecRes = state.electiveSecHelp.find(item => item._electiveSectionM?.tims_electivesectionid == action.obj.electiveSectionId);
                if (elecSecRes != undefined) {
                    let row = elecSecRes.rows.find(e => e.unitStandard?.tims_unitstandardid == action.obj.unitStandardId)
                    if(row){
                        if (row._trainingPlanUnitStandard != undefined)
                        row._trainingPlanUnitStandard._tpuModel.tims_resourceorderstatus = action.obj.status;
                    }
                }
                return { electiveSections: state.electiveSections, electiveSecHelp: state.electiveSecHelp, showLoader: false } as ElecSecState;

            case "INIT":
                let isDirty = checkForDirty(state);
                let isCompliant = checkForCompliant(state);
                if (globalContext.isDirty?.isPrgrmElecSec != isDirty || globalContext.isCompliant?.isPrgrmElecSecCompliant != isCompliant)
                    setDirtyAndCompliantInContext(isDirty, isCompliant);
                
                return { electiveSections: action.obj.electiveSections, electiveSecHelp: action.obj.electiveSecHelp, showLoader: false } as ElecSecState;
            default:
                break;
        }
        return state;
    }

    const preSave = (state: ElecSecState) => {
        for (let index = 0; index < state.electiveSecHelp.length; index++) {
            const element = state.electiveSecHelp[index];
            element.rows.forEach(item => item.sync());
        }
    }
    const postSave = (state: ElecSecState, response: PostSaveResponse[]) => {

        for (let innerIndex = 0; innerIndex < state.electiveSecHelp.length; innerIndex++) {
            const innerElement = state.electiveSecHelp[innerIndex];

            let rowsToUnselect = innerElement.rows.filter(item => item.isDirty && !item.isChecked);
            rowsToUnselect.forEach(item => item._trainingPlanUnitStandard = undefined);


            let respForElecSec = response.filter(resp => resp.electivesectionid == innerElement._electiveSectionM?.tims_electivesectionid);
            for (let index = 0; index < respForElecSec.length; index++) {
                const element = respForElecSec[index];
                let row = innerElement.rows.find(row => row.unitStandard?.tims_unitstandardid == element.unitstandardid && row.isDirty && row.isChecked);
                if (row != undefined) {
                    row._trainingPlanUnitStandard = new TrainingPlanUnitStandard(element.trainingPlanUnitStandard)
                }
            }
        }
    }



    const refreshOtherRows = (state: ElecSecState, action: any) => {
        state.electiveSecHelp.forEach((item) => {
            item.rows.forEach(row => {
                if (!row.isSelectedOtherSection) {
                    if (action.obj.checked)
                        row.deselectFrameworkElementCode({ electiveSectionId: action.obj.electiveSectionId, frameworkElementCode: action.obj.frameworkElementCode, checked: action.obj.checked });
                    else
                        row.selectFrameworkElementCode({ electiveSectionId: action.obj.electiveSectionId, frameworkElementCode: action.obj.frameworkElementCode, checked: action.obj.checked });
                }
            });
        });
    }

    const [prgElecSecState, dispatch] = React.useReducer(prgElecSectionReducer, { electiveSecHelp: [], electiveSections: [], showLoader: true } as ElecSecState);

    React.useEffect(() => {

        const initializeDataLoad = async () => {
            let tokenModel = globalContext.tokenModel as TokenModel;
            var electiveSecModel = new ElectiveSectionModel(tokenModel);
            let programmeId = (globalContext.programmeObj as ProgrammeModel).tims_programmeid as string;
            let electiveSectionModels = await electiveSecModel.retrieveElectiveSectionsForProgramme(programmeId) as ElectiveSectionModel[];
            let promiseArr = [];
            let componentHelpers = [] as ComponentHelperBase[];
            for (let index = 0; index < electiveSectionModels.length; index++) {
                let electiveSection = electiveSectionModels[index];

                if (electiveSection.tims_ruletype != 3) {
                    //ProgramElectiveSection
                    let programElectiveSectionHelper = new ProgramElectiveSectionHelper(globalContext, electiveSection);
                    programElectiveSectionHelper.setEditMode(globalContext.isEditMode as boolean);
                    promiseArr.push(programElectiveSectionHelper.build());
                    componentHelpers.push(programElectiveSectionHelper);

                } else {
                    //CrossCreditElectiveSection
                    let crossCreditElectiveSectionHelper = new CrossCreditElectiveSectionHelper(globalContext, electiveSection);
                    crossCreditElectiveSectionHelper.setEditMode(globalContext.isEditMode as boolean);
                    if (globalContext.isPreviewModeOnly)
                        promiseArr.push(crossCreditElectiveSectionHelper.previewBuild());
                    else
                        promiseArr.push(crossCreditElectiveSectionHelper.build());

                    componentHelpers.push(crossCreditElectiveSectionHelper);
                }
            }
            await Promise.all(promiseArr);

            if (!ref.current)
                return;

            Emitter.on(Events.UnitStandardSelectionChangedInElecGrps, (arg: IUSCheckedEventArg) => {
                dispatch({ type: "CHECKBOX_CHANGED_ELEC_GRP", obj: arg });
            });

            Emitter.on(Events.SaveTrainingPlan, () => {
                dispatch({ type: "SAVE_TP" });
            });
            Emitter.on(Events.POSTSaveTrainingPlan, (arg: PostSaveResponse[]) => {
                dispatch({ type: "POST_SAVE", obj: arg });
            });
            dispatch({ type: "INIT", obj: { electiveSections: electiveSectionModels, electiveSecHelp: componentHelpers } as ElecSecState });
            ref.current = false;
        };

        initializeDataLoad();

        return () => {
            Emitter.off(Events.UnitStandardSelectionChangedInElecGrps, () => { })
            Emitter.off(Events.SaveTrainingPlan, () => { });
            Emitter.off(Events.POSTSaveTrainingPlan, () => { });
        };
    }, []);


    return (
        <Stack>
            {prgElecSecState.showLoader ?
                <Stack style={{ display: "block", marginLeft: "auto", marginRight: "auto", textAlign: "center" }}>
                    <Loader
                        type="TailSpin"
                        color="#00BFFF"
                        height={100}
                        width={100}
                        timeout={60000} //3 secs 
                    />
                </Stack> :
                <prgElecSecContext.Provider value={dispatch}>
                    {prgElecSecState.electiveSections.map((data, index) => (
                        <ElectiveSectionControl key={data.tims_electivesectionid} electiveSection={data} isUnderElectiveGroup={false} electiveSecHelp={prgElecSecState.electiveSecHelp[index]}></ElectiveSectionControl>
                    ))}
                    {prgElecSecState.electiveSections.length == 0 ?
                        <div  className="emptyMessageDiv">
                            <b>No Elective sections found for programme</b>
                        </div> : ""}
                </prgElecSecContext.Provider>
            }
        </Stack>
    );
}