import { ContactModel } from './ContactModel';
import { Serializable } from './Serializable';
import TokenModel from "./TokenModel"

const entityName = "tims_frameworkelements"
var cache = {} as any;
export class FrameworkElementModel extends Serializable {

    constructor(tokeModel: TokenModel) {
        super(tokeModel);
    }


    async initCache(electiveSectionId: string) {

        var potentialFrameworkElementsCriteria = await this.generateFrameworkElementFilter(electiveSectionId);

        var frameworkElementsFetch = [
            "<fetch version='1.0' output-format='xml-platform' mapping='logical' distinct='true'>",
            "  <entity name='tims_frameworkelement'>",
            "    <attribute name='tims_type' />",
            "    <attribute name='tims_frameworkelementcode' />",
            "    <attribute name='tims_frameworkelementid' />",
            "    <filter type='and'>",
            "      <filter type='or'>",
            potentialFrameworkElementsCriteria,
            "      </filter>",
            "      <condition attribute='tims_type' operator='eq' value='4' />",
            "    </filter>",
            "  </entity>",
            "</fetch>"
        ].join('');

        cache[electiveSectionId] = await this.d365Helper.ExecuteFetch(entityName, frameworkElementsFetch, true);
    }

    async generateFrameworkElementFilter(electiveSectionId: string) {

        // retrieve the framework element id's
        // then depending on the level get all the framework elements that are related
        var parentElementsFetch = [
            "<fetch version='1.0' output-format='xml-platform' mapping='logical' distinct='true'>",
            "  <entity name='tims_frameworkelement'>",
            "    <attribute name='tims_type' />",
            "    <attribute name='tims_frameworkelementcode' />",
            "    <attribute name='tims_frameworkelementid' />",
            "    <order attribute='tims_frameworkelementcode' descending='false' />",
            "    <link-entity name='tims_electivesection_tims_frameworkelement' from='tims_frameworkelementid' to='tims_frameworkelementid' visible='false' intersect='true'>",
            "      <link-entity name='tims_electivesection' from='tims_electivesectionid' to='tims_electivesectionid' alias='ag'>",
            "        <filter type='and'>",
            "          <condition attribute='tims_electivesectionid' operator='eq' value='#ELECTIVESECTIONID#' />",
            "        </filter>",
            "      </link-entity>",
            "    </link-entity>",
            "  </entity>",
            "</fetch>"
        ].join('').replace("#ELECTIVESECTIONID#", electiveSectionId);

        let parentElements = [] as any[];
        let parentElem = await this.d365Helper.ExecuteFetch(entityName, parentElementsFetch, true);
        parentElem.forEach(element => {
            parentElements.push({ type: element.tims_type, code: element.tims_frameworkelementcode });
        });
        // generate the fetchxml query to retrieve all the related unit standards
        var typeMap = {
            1: "tims_fieldcode",
            2: "tims_subfieldcode",
            3: "tims_domaincode",
            4: "tims_unitstandardcode"
        } as any;
        let conditionQuery = '<condition attribute="#FIELD#" operator="eq" value="#VALUE#" />';
        let potentialFrameworkElementsCriteria = "";
        parentElements.forEach(fe => {
            potentialFrameworkElementsCriteria += conditionQuery.replace("#FIELD#", typeMap[fe.type])
                .replace("#VALUE#", fe.code);
        });

        return potentialFrameworkElementsCriteria;
    }


    async getPotentialCriteriaForAssessor(assessorId: any, type: number, typeMap: any) {
        // retrieve the framework element id's
        // then depending on the level get all the framework elements that are related
        var parentElementsFetch = [
            "<fetch version='1.0' output-format='xml-platform' mapping='logical' distinct='true'>",
            "  <entity name='tims_frameworkelement'>",
            "    <attribute name='tims_type' />",
            "    <attribute name='tims_frameworkelementcode' />",
            "    <attribute name='tims_frameworkelementid' />",
            "    <order attribute='tims_frameworkelementcode' descending='false' />",
            "   <filter type='and'>",
            "          <condition attribute='tims_type' operator='eq' value='" + type + "' />",
            "      </filter>",
            "    <link-entity name='tims_assessorframeworklevel' from='tims_frameworkelementid' to='tims_frameworkelementid' visible='false' intersect='true'>",
            "        <filter type='and'>",
            "          <condition attribute='tims_contactassessorid' operator='eq' value='#ASSESSORID#' />",
            "          <condition attribute='statecode' operator='eq' value='0' />",
            "        </filter>",
            "    </link-entity>",
            "  </entity>",
            "</fetch>"
        ].join('').replace("#ASSESSORID#", assessorId);

        var parentElements = [] as any[];

        let response = await this.d365Helper.ExecuteFetch(entityName, parentElementsFetch, true);
        response.forEach(element => {
            parentElements.push({
                type: element.tims_type,
                code: element.tims_frameworkelementcode
            });
        });
        let conditionExpression = '<condition attribute="#FIELD#" operator="eq" value="#VALUE#" />';

        let potentialFrameworkElementsCriteria = "";
        parentElements.forEach(fe => {
            potentialFrameworkElementsCriteria += conditionExpression.replace("#FIELD#", typeMap[fe.type]).replace("#VALUE#", fe.code);
        });

        return potentialFrameworkElementsCriteria;
    }

    async generateFrameworkElementFilterForAssessor(assessor: ContactModel) {


        // generate the fetchxml query to retrieve all the related unit standards
        var typeMap = {
            3: "tims_domaincode",
            4: "tims_frameworkelementcode"
        } as any;
        var potentialFrameworkElementsCriteria = [];
        if (assessor.contactid != null && assessor.contactid != "") {
            potentialFrameworkElementsCriteria.push(await this.getPotentialCriteriaForAssessor(assessor.contactid, 3, typeMap));
            potentialFrameworkElementsCriteria.push(await this.getPotentialCriteriaForAssessor(assessor.contactid, 4, typeMap));
        }
        return potentialFrameworkElementsCriteria;
    }


    retrieveForElectiveSection(electiveSectionId: string) {
        return cache[electiveSectionId];
    }

}