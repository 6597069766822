import React, { useState } from "react";
import Common from "./Common/Common";
import { MasterControl } from "./Controls/MasterControl";
import GlobalState from "./GlobalState";
import GlobalStateModel from "./Models/GlobalStateModel";
import { PreviewControl } from "./Controls/PreviewControl";
import TokenModel from "./Models/TokenModel";
import { DefaultButton, Stack, StackItem } from "@fluentui/react";
import trellisLogo from "../src/assets/images/Trellis logo.png";
import { Events, Views } from "./Common/Enum";


export const App: React.FunctionComponent = () => {

  //Let's declare our main state here, it would be global, and can be shared!


  React.useEffect(() => {

    const initializeDataLoad = async () => {
      let token = await Common.getToken(getQueryString("key"));
      if (token.error != undefined)
        setIsUnauthorized(true);
      else
      {
        setToken(token);
      }
       

      setRenderControl(true);
    }
    initializeDataLoad();
    return () => {

    };


  }, []);

  const [isUnauthorized, setIsUnauthorized] = useState(false);
  const [token, setToken] = useState({} as TokenModel);
  const [renderControl, setRenderControl] = useState(false);

  const setGlobalAppState = (globalContext: GlobalStateModel) => {
    setAppState({
      tokenModel: globalContext.tokenModel,
      contact: globalContext.contact,
      programmeObj: globalContext.programmeObj,
      trainingPlanObj: globalContext.trainingPlanObj,
      trainingProgrammeObj: globalContext.trainingProgrammeObj,
      trainingContractObj: globalContext.trainingContractObj,
      isEditMode: globalContext.isEditMode,
      isPreviewModeOnly: globalContext.isPreviewModeOnly,
      isDirty: globalContext.isDirty,
      isCompliant: globalContext.isCompliant,
      showSectionRules:globalContext.showSectionRules,
      selectedView: globalContext.selectedView,
      selectedCustomView: globalContext.selectedCustomView,
      IsReorderDirty: globalContext.IsReorderDirty,
      isColumnsDirty: globalContext.isColumnsDirty,
      IsFullScreenLoaderVisible: globalContext.IsFullScreenLoaderVisible,
      IsPortalMode : globalContext.IsPortalMode,
      setGlobalState: setGlobalAppState
    } as GlobalStateModel);
  };
  const [appState, setAppState] = useState({
    setGlobalState: setGlobalAppState,
    tokenModel: {},
    contact: {},
    programmeObj: {},
    trainingPlanObj: {},
    trainingProgrammeObj: {},
    isEditMode: false,
    isPreviewModeOnly: false,
    isDirty: { isPrgrmElecSec: false, isElecGrpSectionDirty: false },
    isCompliant: { isElecGrpCompliant: true, isPrgrmElecSecCompliant: true },
    selectedView: Views.Readonly,
    selectedCustomView:'',
    IsReorderDirty: false,  
    IsFullScreenLoaderVisible: false,
    IsPortalMode : false,
  } as GlobalStateModel);



  const getQueryString = (key: string) => {
    let data = Common.getQueryString(key)
    if (data == undefined)
      return "";
    else
      return data;
  }

  //This is the method to set the context data.

  return (
    <GlobalState.Provider value={appState}>
      {renderControl ?
        <React.Fragment>
          {isUnauthorized ?
            <Stack style={{ padding: "30px", textAlign: "center", background: "#f5f5f6", alignContent: "center" }} className="centered">
              <StackItem style={{ marginBottom: "20px" }}>
                <img style={{ width: "150px" }} src={trellisLogo}></img>
              </StackItem>
              <StackItem>
                <p><b>This link has expired</b></p>
              </StackItem>
              <StackItem>
                <p>Training plan links are set to expire for security reasons.</p>
              </StackItem>
              <StackItem>
                <p>To bring up this plan again, go to Learner's record and select <b>View Plan.</b></p>
              </StackItem>
              <StackItem>
                <DefaultButton style={{ width: "104px" }} type="button" onClick={(e) => { window.close(); }}>Close tab</DefaultButton>
              </StackItem>
            </Stack> :
            <React.Fragment>
              {getQueryString("trainingplanid") != "" ? <MasterControl trainingPlanId={getQueryString("trainingplanid")} token={token} /> :
                <PreviewControl contactId={getQueryString("contactid")} programmeId={getQueryString("programmeid")} token={token} />}
            </React.Fragment>}
        </React.Fragment> :
        ""}
    </GlobalState.Provider>
  );

}

