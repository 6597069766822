import { ProgrammeUnitStandardModel } from '../Models/ProgrammeUnitStandardModel';
import TokenModel from '../Models/TokenModel';
import ProgrammeUnitStandard from '../ComponentHelpers/ProgrammeUnitStandard';
import GlobalStateModel from '../Models/GlobalStateModel';
import ElectiveGroupModel from '../Models/ElectiveGroupModel';
import ElectiveSectionModel from '../Models/ElectiveSectionModel';
import _ from 'underscore';
import ComponentHelperBase from './ComponentHelperBase';
import { Templates } from '../Common/Enum';


export default class ProgramElectiveSectionHelper extends ComponentHelperBase {

    _tokenModel: TokenModel | undefined;
    _globalStateModel: GlobalStateModel;
    loadedCompletely: boolean;
    electiveGroups: ElectiveGroupModel[];
    programmeUnitStandards: ProgrammeUnitStandard[];
    visible: boolean | undefined;
    isEditMode: boolean | undefined;
    _electiveSectionM: ElectiveSectionModel | undefined;
    _selectedLetter: string | null;
    // isDisabled: boolean;


    constructor(globalStateModel: GlobalStateModel, electiveSection: ElectiveSectionModel) {
        super();
        this.loadedCompletely = false;
        var pus = [] as ProgrammeUnitStandard[];
        this._globalStateModel = globalStateModel;
        this._electiveSectionM = electiveSection;
        this.programmeUnitStandards = [];
        this.electiveGroups = [];
        this.isEditMode = true;
        this._selectedLetter = null;
        // this.isDisabled = false
    }

    async build() {

        let progUnitStandardM = new ProgrammeUnitStandardModel(this._globalStateModel.tokenModel as TokenModel);
        var progUnitStandards = await progUnitStandardM.retrieveProgrammeUnitStandardsForElectiveSection(this._electiveSectionM?.tims_electivesectionid as string) as ProgrammeUnitStandardModel[];
        let promiseArr = [] as any[];
        for (let index = 0; index < progUnitStandards.length; index++) {
            let item = progUnitStandards[index];
            item._tokenModel = this._globalStateModel.tokenModel as TokenModel;
            let progUS = new ProgrammeUnitStandard(item, this._globalStateModel, false, this._electiveSectionM?.tims_electivesectionid);
            if (!this._globalStateModel.isPreviewModeOnly)
                promiseArr.push(progUS.build());
            else
                promiseArr.push(progUS.previewBuild());

            this.programmeUnitStandards.push(progUS);

        }


        await Promise.all(promiseArr);

        if (this._electiveSectionM?.tims_ruletype === 2) {
            // check if there has been a selected one
            var selectedList = this.findSelectedList();
            this._selectedLetter = selectedList;
        }
    }

    isCheckedPickAList(value: string) {
        return value == this._selectedLetter;
    }

    get isAllowedUnitStandardResources() {
        return this._globalStateModel.programmeObj?.tims_isallowedunitstandardresources as number;
    }

    get rows() {
        return this.programmeUnitStandards;
    }
    templateName(isEditMode: boolean) {
        if (isEditMode) {
            return {
                1: Templates.ItemSelectionEdit,
                2: Templates.PickAListElective,
                3: Templates.CrossCreditElective
            }[this._electiveSectionM?.tims_ruletype as number] as Templates;
        }

        return {
            1: Templates.ItemSelection,
            2: Templates.ItemSelection,
            3: Templates.CrossCreditElective
        }[this._electiveSectionM?.tims_ruletype as number] as Templates;
    }

    sortUnitStandards() {
        this.programmeUnitStandards.sort(function (left, right) {
            if (left.unitStandard?.tims_name == right.unitStandard?.tims_name)
                return 0;
            //@ts-ignore
            return left.unitStandard?.tims_name > right.unitStandard?.tims_name ? 1 : -1;
        });
    };

    getCreditCountByLevel(level: number){
        return _.reduce(this.programmeUnitStandards, function (memo, pus) {
            if (pus.isChecked && !pus.excludeCredits && pus.unitStandard?.tims_level == level) {
                return memo + (pus.credits as number);
            }
            return memo;
        }, 0);
    }

    hasRows() {
        return this.programmeUnitStandards.length >= 1;
    };

    hasSelectedRows() {
        return _.find(this.programmeUnitStandards, function (pus) {
            return pus.isSelected;
        });
    };

    toggleVisible() {
        this.visible = !this.visible;
    };

    setEditMode(mode: boolean) {
        this.isEditMode = mode;
    };

    setDisabled(disabled:boolean){
        this.isDisabled = disabled;
    }

    get isCompliant() {
        let minUnits = this._electiveSectionM?.tims_minimumitems ?? 0
        let maxUnits = this._electiveSectionM?.tims_maximumitems ?? 1000

        let minCredits = this._electiveSectionM?.tims_minimumcredits ?? 0
        let maxCredits = this._electiveSectionM?.tims_maximumcredits ?? 1000

        let minCreditsAtLevel = this._electiveSectionM?.tims_minimumcreditsonlevel ?? 0
        let maxCreditsAtLevel = this._electiveSectionM?.tims_maximumcreditsonlevel ?? 1000


        let isUnitsCompliant = this.selectedUnits >= minUnits && this.selectedUnits <= maxUnits
        let isCreditsCompliant =  this.selectedCredits >= minCredits && this.selectedCredits <= maxCredits
        let isLevelsCompliant = this.selectedMinimumCredits >= minCreditsAtLevel && this.selectedMaximumCredits <= maxCreditsAtLevel

        return (isUnitsCompliant && isCreditsCompliant && isLevelsCompliant) || this.isDisabled;
    };


    get selectedMinimumCredits(){
        let minLevel = this._electiveSectionM?.tims_level ?? 0
        return this.getCreditCountByLevel(minLevel)

    }

    get selectedMaximumCredits(){   
        let maxLevel = this._electiveSectionM?.tims_maximumlevel ?? 0
        return this.getCreditCountByLevel(maxLevel)
    }

    // // Sums for credits
    get achievedCredits() {
        return _.reduce(this.programmeUnitStandards, (memo, pus) => {
            if (pus.isChecked && ((pus.isAchieved && !pus.excludeCredits) || pus.isExempt)) {
                return memo + (pus.credits as number);
            }
            return memo;
        }, 0);
    };


    get selectedCredits() {
        return _.reduce(this.programmeUnitStandards, function (memo, pus) {
            if (pus.isChecked && !pus.excludeCredits) {
                return memo + (pus.credits as number);
            }
            return memo;
        }, 0);
    };
    get _remainingCredits() {
        return this.selectedCredits - this.achievedCredits;
    };
    // // Sums for units
    get achievedUnits() {
        return _.reduce(this.programmeUnitStandards, function (memo, pus) {
            if (pus.isChecked && ((pus.isAchieved && !pus.excludeCredits) || pus.isExempt)) {
                return memo + 1;
            }
            return memo;
        }, 0);
    };

    get selectedUnits() {
        return _.reduce(this.programmeUnitStandards, function (memo, pus) {
            if (pus.isChecked && !pus.excludeCredits) {
                return memo + 1;
            }
            return memo;
        }, 0);
    };

    get remainingUnits() {
        return this.selectedUnits - this.achievedUnits;
    };

    get isDirty() {

        var isDirty = false;
        _.each(this.programmeUnitStandards, function (pus) {
            if (pus.isDirty) {
                isDirty = true;
            }
        });

        return isDirty;
    };

    get isSelected(){ // Return true if at least one Programme unit standard is selected in section
        return this.programmeUnitStandards.find(({ isSelected }) => isSelected)?.isSelected ? true : false
    } 

    // // select list click event
    selectList(value: string) {

        // uncheck everything then check list selectedList
        this._selectedLetter = value;

        _.each(this.programmeUnitStandards, function (pus) {

            let isList = false;

            switch (value) {
                case "islista":
                    isList = pus._progUnitStandard.tims_islista as boolean;
                    break;
                case "islistb":
                    isList = pus._progUnitStandard.tims_islistb as boolean;
                    break;
                case "islistc":
                    isList = pus._progUnitStandard.tims_islistc as boolean;
                    break;
                case "islistd":
                    isList = pus._progUnitStandard.tims_islistd as boolean;
                    break;
                case "isliste":
                    isList = pus._progUnitStandard.tims_isliste as boolean;
                    break;
                case "islistf":
                    isList = pus._progUnitStandard.tims_islistf as boolean;
                    break;

            }
            pus.onCheckboxChanged(isList);
        });

        return true;
    };

    // // returns the selected list
    findSelectedList() {


        let selectedProgUS = this.programmeUnitStandards.filter(item => item.isSelected);
        let unSelectedProgUS = this.programmeUnitStandards.filter(item => !item.isSelected);

        if (selectedProgUS.length == 0)
            return null;

        if (selectedProgUS.every(item => item._progUnitStandard.tims_islista) && unSelectedProgUS.every(item => !item._progUnitStandard.tims_islista))
            return 'islista';
        else if (selectedProgUS.every(item => item._progUnitStandard.tims_islistb) && unSelectedProgUS.every(item => !item._progUnitStandard.tims_islistb))
            return 'islistb';
        else if (selectedProgUS.every(item => item._progUnitStandard.tims_islistc) && unSelectedProgUS.every(item => !item._progUnitStandard.tims_islistc))
            return 'islistc';
        else if (selectedProgUS.every(item => item._progUnitStandard.tims_islistd) && unSelectedProgUS.every(item => !item._progUnitStandard.tims_islistd))
            return 'islistd';
        else if (selectedProgUS.every(item => item._progUnitStandard.tims_isliste) && unSelectedProgUS.every(item => !item._progUnitStandard.tims_isliste))
            return 'isliste';
        else if (selectedProgUS.every(item => item._progUnitStandard.tims_islistf) && unSelectedProgUS.every(item => !item._progUnitStandard.tims_islistf))
            return 'islistf';
        else
            return null;

    };
}


