import * as React from 'react';
import { Stack } from '@fluentui/react';
import '../Common/Common';
import GlobalState from "../GlobalState";
import GlobalStateModel from '../Models/GlobalStateModel';
import Common from "../Common/Common";
import { Col, Container, Row } from 'react-bootstrap';
import TrainingPlanModel from '../Models/TrainingPlanModel';
import { TrainingProgrammeModel } from '../Models/TrainingProgrammeModel';
import exclmIcon from "../assets/images/exclm_icon.png";
import "../assets/css/DetailsControl.css";
import { ContactModel } from '../Models/ContactModel';

const detailSection1 = {
    background: "#f5f5f6",
    /* padding: 32px; */
    marginRight: "1%",
    paddingLeft: "0.6%",
    paddingTop: "0.8%",
    width: "50%"
}

const rowStyle = {
    paddingBottom: "1px"
}

const middleDetailSection = {
    marginRight: "1%",
    width: "25%",
    Child1: {
        background: "#f5f5f6",
        marginBottom: "1%",
        paddingLeft: "4%",
        /* width: 387px; */
        paddingTop: "17px"
    },
    Child2: {
        background: "#f5f5f6",
        paddingTop: "17px",
        paddingLeft: "4%"
    },
    Child1Custom: {
        background: "#f5f5f6",
        marginBottom: "1%",
        paddingLeft: "4%",
        paddingTop: "17px"
    },
    Child2Custom: {
        background: "#f5f5f6",
        paddingTop: "17px",
        paddingLeft: "4%"
    },
}

interface IDetailsControlProps {
    trainingPlan: TrainingPlanModel
    trainingProgramme: TrainingProgrammeModel
    contact: ContactModel
}

export default class DetailsControl extends React.Component<IDetailsControlProps, {}>{
    context: any;
    static contextType = GlobalState;
    constructor(props: any) {
        super(props);
    }

    componentDidMount() {
    }

    getUrlForCRM = (entityName: string, entityId: string) => {
        return this.context.tokenModel?.apiUrl + `/main.aspx?etn=${entityName}&id=${entityId}&pagetype=entityrecord&forceUCI=1`;
    }

    checkCriteria(value: string) {

        switch (value) {
            case "ontrack":
                return (this.props.trainingProgramme?.tims_requiredcreditspermonthtocomplete as number) >= 0 && (this.props.trainingProgramme?.tims_requiredcreditspermonthtocomplete as number) <= 6;
            case "fallingbehind":
                return (this.props.trainingProgramme?.tims_requiredcreditspermonthtocomplete as number) >= 7 && (this.props.trainingProgramme?.tims_requiredcreditspermonthtocomplete as number) <= 14;
            case "atrisk":
                return (this.props.trainingProgramme?.tims_requiredcreditspermonthtocomplete as number) >= 15;
        }
        return false;

    }

    getCreditsRequiredPerMonthValue = (value: string) => {

        if (this.props.trainingProgramme?.tims_requiredcreditspermonthtocomplete == undefined)
            return <></>;

        if (this.checkCriteria(value))
            return <b>[{this.props.trainingProgramme?.tims_requiredcreditspermonthtocomplete}]</b>;

        return <></>
    }

    showExclamationIcon = () => {
        if ((this.context as GlobalStateModel).contact?.tims_dateworkpermitexpires == undefined || this.props.trainingProgramme.tims_datetrainingexpires == undefined)
            return false;

        let expirydate = new Date((this.context as GlobalStateModel).contact?.tims_dateworkpermitexpires as string);
        let expectedEndDate = new Date(this.props.trainingProgramme.tims_datetrainingexpires as string);

        return expirydate < expectedEndDate;
    }

    render() {

        return (<Stack id="DetailsSectionDiv" horizontal style={{ marginBottom: "15px", fontSize: "14px" }}>
            <Stack style={detailSection1}>
                <Container style={{ marginLeft: 0 }}>
                    <Row style={rowStyle}>
                        <Col className="col-3">
                            <p><strong>Learner:</strong></p>
                        </Col>
                        <Col>
                            {!(this.context as GlobalStateModel).IsPortalMode ? 
                                <p> <a className='anchor-style' href={this.getUrlForCRM("contact", this.context.contact?.contactid)} target="_blank">{this.context.contact?.fullname}</a></p>
                                :
                                <p>{this.context.contact?.fullname}</p>
                            }
                        </Col>
                    </Row>
                    <Row style={rowStyle}>
                        <Col className="col-3">
                            <p><strong>NSN:</strong></p>
                        </Col>
                        <Col>
                            <p>{this.context.contact?.tims_nsnnumber}</p>
                        </Col>
                    </Row>
                    <Row style={rowStyle}>
                        <Col className="col-3">
                            <p><strong>Programme:</strong></p>
                        </Col>
                        <Col>
                            {!(this.context as GlobalStateModel).IsPortalMode ? 
                                <p><a className='anchor-style' href={this.getUrlForCRM("tims_programme", this.context.programmeObj?.tims_programmeid)} target="_blank">{this.context.programmeObj?.tims_name} </a></p>
                                :
                                <p>{this.context.programmeObj?.tims_name}</p>
                            }
                         </Col>
                    </Row>
                    {this.props.trainingPlan._tims_programmecontextid_value !== null &&
                        <Row style={rowStyle}>
                            <Col className="col-3">
                                <p><strong>Context:</strong></p>
                            </Col>
                            <Col>
                                <p>{Common.getFormattedValue(this.props.trainingPlan, "_tims_programmecontextid_value")}</p>
                            </Col>
                        </Row>
                    }
                    <Row style={rowStyle}>
                        <Col className="col-3">
                            <p><strong>Workplace:</strong></p>
                        </Col>
                        <Col>
                            <p>{Common.getFormattedValue(this.context.trainingContractObj, "_tims_accountworkplaceid_value")}</p>
                        </Col>
                    </Row>
                </Container>
            </Stack>
            <Stack style={middleDetailSection}>
                <Container style={middleDetailSection.Child1}>
                    <Row style={rowStyle}>
                        <p><b>Training Programme dates</b></p>
                    </Row>
                    <Row style={rowStyle}>
                        <Col className="col-7">
                            <p><strong>Start date:</strong></p>
                        </Col>
                        <Col>
                            <p>
                                { this.props.trainingPlan.tims_datetrainingplanstarted ? 
                                Common.getFormattedValue(this.props.trainingPlan, "tims_datetrainingplanstarted") : 
                                Common.getFormattedValue(this.props.trainingPlan, "fus_transferdate") }
                            </p>
                        </Col>
                    </Row>
                    <Row style={rowStyle}>
                        <Col className="col-7">
                            <p><strong>Expect end date:</strong></p>
                        </Col>
                        <Col>
                            <p> {Common.getFormattedValue(this.props.trainingProgramme, "tims_datetrainingexpires")}</p>
                        </Col>
                    </Row>
                    <Row style={rowStyle}>
                        <Col className="col-7">
                            <p><strong>Visa expiry:</strong></p>
                        </Col>
                        <Col>
                            <p>{Common.getFormattedValue((this.context as GlobalStateModel).contact, "tims_dateworkpermitexpires")} {this.showExclamationIcon() ? <img style={{ marginLeft: "3px", width: "15px" }} src={exclmIcon}></img> : <></>}</p>
                        </Col>
                    </Row>
                </Container>
                <Container style={middleDetailSection.Child2}>
                    <Row style={rowStyle}>
                        <Col className="col-7">
                            <p> <strong>Agreement status:</strong></p>
                        </Col>
                        <Col>
                            <p>{Common.getFormattedValue((this.context as GlobalStateModel).trainingContractObj, "statuscode")}</p>
                        </Col>
                    </Row>
                    <Row style={rowStyle}>
                        <Col className="col-7">
                            <p><strong>Plan status:</strong></p>
                        </Col>
                        <Col>
                            <p>{Common.getFormattedValue(this.props.trainingPlan, "statuscode")}</p>
                        </Col>
                    </Row>
                </Container>
            </Stack>
            <Stack style={middleDetailSection}>
                <Container style={middleDetailSection.Child1Custom}>
                    <Row style={rowStyle}>
                        <p><b>Credits required per month</b></p>
                    </Row>
                    <Row style={rowStyle}>
                        <Col className="col">
                            <p style={this.checkCriteria("ontrack") ? { textDecoration: "underline", textDecorationColor: "#95c27b", textDecorationThickness: "3px" } : undefined}><strong>On track:</strong></p>
                        </Col>
                        <Col>
                            <p>0-6 credits {this.getCreditsRequiredPerMonthValue("ontrack")}</p>
                        </Col>
                    </Row>
                    <Row style={rowStyle}>
                        <Col className="col">
                            <p style={this.checkCriteria("fallingbehind") ? { textDecoration: "underline", textDecorationColor: "#f6b039", textDecorationThickness: "3px" } : undefined}><strong>Falling behind:</strong></p>
                        </Col>
                        <Col>
                            <p> 7-14 credits {this.getCreditsRequiredPerMonthValue("fallingbehind")}</p>
                        </Col>
                    </Row>
                    <Row style={rowStyle}>
                        <Col className="col">
                            <p style={this.checkCriteria("atrisk") ? { textDecoration: "underline", textDecorationColor: "#e9741f", textDecorationThickness: "3px" } : undefined}><strong>At Risk:</strong></p>
                        </Col>
                        <Col>
                            <p> 15+ credits {this.getCreditsRequiredPerMonthValue("atrisk")}</p>
                        </Col>
                    </Row>
                </Container>
                <Container style={middleDetailSection.Child2Custom}>
                    <Row style={rowStyle}>
                        <Col className="col">
                            <p><strong>Last visit:</strong></p>
                        </Col>
                        <Col>
                            <p>{Common.getFormattedValue(this.props.contact, "tims_dateoflastvisit")}</p>
                        </Col>
                    </Row>
                    <Row style={rowStyle}>
                        <Col className="col">
                            <p><strong>Visit due:</strong></p>
                        </Col>
                        <Col>
                            <p> {Common.getFormattedValue(this.props.contact, "tims_dateofnextvisit")}</p>
                        </Col>
                    </Row>

                </Container>
            </Stack>
        </Stack >);
    }
}