import React from "react";
import { useContext, useState } from "react";
import { Col, Row } from 'react-bootstrap';
import { DefaultButton, PrimaryButton } from "@fluentui/react/lib/Button";
import { Modal, Dialog, DialogType, DialogFooter, IDragOptions, ContextualMenu } from '@fluentui/react';
import { useBoolean, useId } from '@fluentui/react-hooks';
import { contentStyles } from '../Common/ModalHelper';

import GlobalState from "../GlobalState";
import Emitter from "../Common/EventEmitter";
import { GoalModel } from "../Models/GoalModel";
import { VisitModel } from "../Models/VisitModel";
import { Events, RequestType } from '../Common/Enum';
import { IMessagebarObject } from "./MasterControl";
import { GoalStatusControl } from "./GoalStatusControl";
import GoalsHelper from "../ComponentHelpers/GoalsHelper";
import GoalsAndVisits from "../ComponentHelpers/GoalsAndVisits";
import { AddGoalControl } from "./AddGoalControl";
import { Serializable } from "../Models/Serializable";
import { createPortal } from "react-dom";
import { goalsControlContext } from "./GoalsControl";
import { GoalsCreationMode, GoalType } from "../Common/Enum";

import Common from "../Common/Common";


const dialogContentProps = {
    type: DialogType.normal,
    title: 'Confirm',
    closeButtonAriaLabel: 'Close',
    subText: 'Do you want to delete this goal?',
};

const dragOptions: IDragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
  dragHandleSelector: '.ms-Modal-scrollableContent > div:first-child',
};

export const GoalRowControl: React.FunctionComponent<GoalRowControlProps> = (props) => {
    const globalState = useContext(GlobalState);
    const goalsControlDispatcher = useContext(goalsControlContext);

    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const [goalsCreationMode, setGoalsCreationMode] = useState<GoalsCreationMode>(GoalsCreationMode.Create);
    const [isAddGoalsModalOpen, { setTrue: showGoalsModal, setFalse: hideGoalsModal }] = useBoolean(false);
    
    const labelId: string = useId('dialogLabel');
    const subTextId: string = useId('subTextLabel');
    const dialogStyles = { main: { maxWidth: 450 } };

    const modalProps = React.useMemo(() => ({
      titleAriaId: labelId,
      subtitleAriaId: subTextId,
      isBlocking: false,
      styles: dialogStyles,
    }),
    [labelId, subTextId]);


    const renderDialogAddGoals = () => {
        const visitModel = props.data._visit;
        return (
          <Modal isDarkOverlay={false}
            titleAriaId="Add Goals"
            isOpen={isAddGoalsModalOpen}
            onDismiss={hideGoalsModal}
            isBlocking={false}
            containerClassName={contentStyles.container}
            isModeless={true}
            dragOptions={dragOptions}
            >
              <AddGoalControl
                  goalsCreationMode={goalsCreationMode} 
                  visitModel={visitModel}
                  goalModel={props.goal}
                  goalsHelper={props.goalsHelper as GoalsHelper}
                  hideGoalsModal={hideGoalsModal}
                  units={props.goalsHelper.availableTpus}
              >
              </AddGoalControl>
          </Modal >
        )
    }

    const renderDeleteConfirmationDialog = () => {
        return (
          <Dialog
            hidden={hideDialog}
            onDismiss={toggleHideDialog}
            dialogContentProps={dialogContentProps}
            modalProps={modalProps}
          >
            <DialogFooter>
              <PrimaryButton onClick={async (e) => {
                await deleteGoal();
                toggleHideDialog();
              }} text="Yes" />
              <DefaultButton onClick={toggleHideDialog} text="No" />
            </DialogFooter>
          </Dialog>
        );
      }

    const deleteGoal = async () => {
        let response = await (props.goalsHelper as GoalsHelper).deactivateGoal(props.goal.fus_goalid as string);
        if (response.ok) {
          if(typeof(goalsControlDispatcher) == "function"){
                goalsControlDispatcher({ type: "POST_GOALS_DELETE", obj: { goalId: props.goal.fus_goalid, visitId: props.data._visit.tims_macvisitid } });
                Emitter.emit(Events.SetMessageBar, { errors: [], isSuccess: true, requestType: RequestType.DELETE_GOAL, showMessageBar: true } as IMessagebarObject);
            }
        }
        else {
          let respObj = await response.json() as Serializable;
          Emitter.emit(Events.SetMessageBar, { errors: [respObj.error?.message], isSuccess: false, requestType: RequestType.DELETE_GOAL, showMessageBar: true } as IMessagebarObject);
        }
      }

    return (
        <>
            <Row className="goals-row">
                <Col className="goals-col col-6">
                    <div style={{ float: "left", width: "85%" }}>
                        {props.goal.tims_goaltype == GoalType.Learning && <p style={{ whiteSpace: "pre-line", fontWeight:"bold"  }} className="mb-0">Learning</p>}
                        {props.goal.tims_goaltype == GoalType.PastoralCare && <p style={{ whiteSpace: "pre-line", fontWeight:"bold"  }} className="mb-0">Pastoral Care</p>}
                        {props.goal.tims_goaltype == GoalType.Milestone && <p style={{ whiteSpace: "pre-line", fontWeight:"bold"  }} className="mb-0">Milestone</p>}
                        <p style={{ whiteSpace: "pre-line" }} className="mb-0">{props.goal.fus_description}</p>
                        <p style={{ whiteSpace: "pre-line", paddingTop:"15px" }} className="mb-0">{Common.getFormattedValue(props.goal, "_tims_trainingplanunitstandard_value")}</p>

                    </div>
                    {!globalState.IsPortalMode && (props.data as GoalsAndVisits).isVisitPending ?
                        <div style={{ float: "right", width: "15%" }}>
                            <div className="grid-button" style={{ float: "left", marginRight: "3%" }}>
                                <a onClick={async (e) => {
                                    e.preventDefault()
                                    setGoalsCreationMode(GoalsCreationMode.Update);
                                    showGoalsModal();
                                }} href="#">Edit Goal</a>
                            </div>
                            <div className="grid-button" style={{ float: "right" }}>
                                <a onClick={async (e) => {
                                    e.preventDefault()
                                    toggleHideDialog();
                                }} href="#">Delete</a>
                            </div>
                        </div> : <></>}
                </Col>
                <Col className="goals-col col-6">
                    <GoalStatusControl setGoalsCreationMode={setGoalsCreationMode} showGoalsModal = {showGoalsModal} goal={props.goal} visit = {props.data._visit as VisitModel}/>
                </Col>
            </Row>
            <>
            {createPortal(renderDeleteConfirmationDialog(), document.body)}
            {createPortal(renderDialogAddGoals(), document.body)}

            </>
        </>
      
    )
}

interface GoalRowControlProps {
    goal:GoalModel,
    visit:VisitModel,
    data:GoalsAndVisits,
    goalsHelper:GoalsHelper
}