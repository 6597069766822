import { Serializable } from './Serializable';
import TokenModel from "./TokenModel"
import { GoalModel } from "./GoalModel";

const entityName = "tims_macvisits";

export enum VisitsStatusCode {
    Active = 1,
    Pending = 498400000,
    InActive = 2,
    ReadyToFinalize = 498400001
}

export interface IVisitCreate {
    tims_dateofvisit: Date | undefined;
    trainingprogrammeId: string | undefined;
    tims_visittype: number | undefined;
    statuscode: number | undefined;
    tims_learner: string | undefined;
    tims_macagreementId: string | undefined;
    tims_visittypeothertext: string | undefined;
    tims_trainingplanId: string | undefined;
}

export class VisitModel extends Serializable {

    constructor(tokenModel: TokenModel) {
        super(tokenModel);

    }
    _tims_learner_value: string | undefined;
    _tims_macagreementid_value: string | undefined;
    _tims_trainingprogrammeid_value: string | undefined;
    tims_dateofvisit: string | undefined;
    tims_nextvisitdate: string | undefined;
    tims_itrregistrationstatus: number | undefined;
    tims_visittype: number | undefined;
    tims_deletemacvisit: string | undefined;
    tims_name: string | undefined;
    tims_visitnotes: string | undefined;
    statuscode: number | undefined;
    tims_macvisitid: string | undefined;
    tims_visittypeothertext: string | undefined;

    get goals() {
        //@ts-ignore
        if (this["fus_Goal_LearnerVisitId_tims_MacVisit"] == undefined) {
            //@ts-ignore
            this["fus_Goal_LearnerVisitId_tims_MacVisit"] = [] as GoalModel[];

        }
        //@ts-ignore
        return this["fus_Goal_LearnerVisitId_tims_MacVisit"] as GoalModel[];
    }

    set goals(goals: GoalModel[]) {
        //@ts-ignore
        this["fus_Goal_LearnerVisitId_tims_MacVisit"] = goals;
    }
    async retrieveVisits(trainingprogrammeId: string) {
        var criteria = "_tims_trainingprogrammeid_value eq '" + trainingprogrammeId + "'";
        return await this.d365Helper.RetrieveMultiple(entityName, this.createColumnArray(this), criteria);
    }

    async retrieveVisitsById(visitId: string, getFromCache: boolean) {
        let goalModel = new GoalModel(this._tokenModel);
        let visit = await this.d365Helper.RetrieveWithExpand(entityName, visitId, this.createColumnArray(this), [{ entityName: "fus_Goal_LearnerVisitId_tims_MacVisit", columnset: this.createColumnArray(goalModel), filter: "statuscode ne 2", orderby:"fus_duedate asc" }], getFromCache) as VisitModel;
        if (visit.error == undefined)
            return this.createVisitsModel([visit])[0];
        else
            return visit;
    }



    async retrieveVisitsWithGoals(trainingprogrammeId: string, visitTypes: number[], topVisits: number, goalStatus:number[]) {

        var criteria = "(_tims_trainingprogrammeid_value eq '" + trainingprogrammeId + "' and statuscode ne 2 and Microsoft.Dynamics.CRM.In(PropertyName='tims_visittype',PropertyValues=['" + visitTypes.join("','") + "']) or ((statuscode eq 498400000 or statuscode eq 498400001) and _tims_trainingprogrammeid_value eq '" + trainingprogrammeId + "'))";
        let goalModel = new GoalModel(this._tokenModel);
        let visits = await this.d365Helper.RetrieveMultipleWithExpand(entityName, this.createColumnArray(this), criteria, [{ entityName: "fus_Goal_LearnerVisitId_tims_MacVisit", columnset: this.createColumnArray(goalModel), filter: "Microsoft.Dynamics.CRM.In(PropertyName='statuscode',PropertyValues=['" + goalStatus.join("','") + "']) and statuscode ne 2", orderby:"fus_duedate asc" } ], "tims_dateofvisit desc", topVisits, false);

        return this.createVisitsModel(visits);
    }

    async updateVisit(visitCreate: IVisitCreate, visitId: string) {
        var visit = {} as any;
        if (visitCreate.statuscode != undefined)
            visit.statuscode = visitCreate.statuscode;
        if (visitCreate.tims_dateofvisit != undefined)
            visit.tims_dateofvisit = visitCreate.tims_dateofvisit;
        if (visitCreate.tims_visittype != undefined)
            visit.tims_visittype = visitCreate.tims_visittype;
        if (visitCreate.tims_visittypeothertext != undefined)
            visit.tims_visittypeothertext = visitCreate.tims_visittypeothertext;


        let response = await this.d365Helper.Update(entityName, visitId, visit);
        return response;
    }

    async createVisit(visitCreate: IVisitCreate) {

        var visit = {
            "tims_trainingprogrammeid@odata.bind": "/tims_trainingprogrammes(" + visitCreate.trainingprogrammeId + ")",
            "tims_dateofvisit": visitCreate.tims_dateofvisit,
            "tims_visittype": visitCreate.tims_visittype,
            "tims_Learner@odata.bind": "/contacts(" + visitCreate.tims_learner + ")",
            "statuscode": VisitsStatusCode.Pending,
            "tims_TrainingPlan@odata.bind": "/tims_trainingplans(" + visitCreate.tims_trainingplanId + ")",
        }

        if (visitCreate.tims_macagreementId != undefined) {
            let visitFinal = {
                ...visit,
                "tims_macagreementid@odata.bind": "/tims_macagreements(" + visitCreate.tims_macagreementId + ")"
            };
            visit = visitFinal;
        }
        if (visitCreate.tims_visittypeothertext != undefined) {
            let visitFinal = {
                ...visit,
                "tims_visittypeothertext": visitCreate.tims_visittypeothertext
            };
            visit = visitFinal;
        }

        let response = await this.d365Helper.Create(entityName, visit, this.createColumnArray(this));
        return response;
    }


    createVisitsModel(visits: any[]) {
        let visitArr = [] as VisitModel[];
        if (visits != undefined) {

            for (let index = 0; index < visits.length; index++) {
                const element = visits[index];
                let visit = new VisitModel(this._tokenModel);
                visit.fillFromJSON(JSON.stringify(element));
                visitArr.push(visit);
            }
        }
        return visitArr;
    }

}