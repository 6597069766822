export default class TokenModel {

    token_type: string | undefined;
    expires_in: number | undefined;
    ext_expires_in: number | undefined;
    access_token: string | undefined;
    apiUrl: string | undefined;
    error: string | undefined;
    userId: string | undefined;
    contactId: string | undefined;
    loginType: string | undefined;
}