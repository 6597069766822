import TrainingPlanUnitStandardModel from '../Models/TrainingPlanUnitStandardModel';
import { ItpuUpdate } from "../Common/TrainingPlanUnitStandardManager";
import Result from './Result';
import ComponentHelperBase from './ComponentHelperBase';
import { UnitStandardModel } from '../Models/UnitStandardModel';
import GlobalStateModel from '../Models/GlobalStateModel';
import { ResultModel } from '../Models/ResultModel';
import { TrainingPlanCommentModel } from '../Models/TrainingPlanCommentModel';
import ResultForm from './ResultForm';

export const ResourceOrderStatus = {
    Pending: 100000000,
    Ordered: 100000001,
    Cancelled: 100000002
};

export default class TrainingPlanUnitStandard extends ComponentHelperBase {

    _tpuModel: TrainingPlanUnitStandardModel;
    _resultHelper: Result | undefined;
    unitStandard: UnitStandardModel | undefined;
    comment: TrainingPlanCommentModel | undefined;
    private _newSequenceNumber: number | undefined;

    get Id() {
        return this._tpuModel.tims_trainingplanunitstandardid
    }

    public set tpuModel(tpuModel:TrainingPlanUnitStandardModel){
        this._tpuModel = tpuModel
    }

    public set GlobalState(globalState: GlobalStateModel) {
        this._globalStateModel = globalState;
    }


    constructor(tpUsModel: TrainingPlanUnitStandardModel) {
        super();
        this._tpuModel = tpUsModel;
        if (this._tpuModel != null) {
            this.unitStandard = this._tpuModel.unitStandard;
            this.newSequenceNumber = this._tpuModel.fus_sequencenumber;
        }


    }

    get resourceorderstatus() {
        if (this._tpuModel != null)
            return this._tpuModel.tims_resourceorderstatus as number;
        else
            return -1;
    }

    get isDirty(): boolean {
        return this.sequenceNumber != this.newSequenceNumber;
    }

    set sequenceNumber(sequenceNumber: number | undefined) {
        this._tpuModel.fus_sequencenumber = sequenceNumber;
    }

    get sequenceNumber() {
        return this._tpuModel.fus_sequencenumber;
    }

    get newSequenceNumber() {
        return this._newSequenceNumber;
    }

    set newSequenceNumber(sequenceNumber: number | undefined) {
        this._newSequenceNumber = sequenceNumber;
    }

    async build() {

        var resultModel = new ResultModel(this._tpuModel._tokenModel);
        var commentModel = new TrainingPlanCommentModel(this._tpuModel._tokenModel);
        var result = await resultModel.retrieveCachedResult(this._tpuModel._tims_resultid_value as string);
        if (result != undefined)
            this._resultHelper = new Result(result);


        this.resultForm = new ResultForm(this._resultHelper, this);

        this.comment = await commentModel.retrieveByTrainingPlanUnitStandardId(this._tpuModel.tims_trainingplanunitstandardid as string);

    }

    get assessmentType() {
        if (this._tpuModel != null)
            if (this._tpuModel.tims_trainingplanunitstandardid) {
                return {
                    1: "Practical",
                    2: "Theory",
                    3: "Combined"
                }[this._tpuModel.tims_assessmenttype as number];
            }

        return "Practical";
    };

    get credits() {
        return this.unitStandard?.tims_credits as number;
    };


    get isAchieved() {
        var result = this._resultHelper;
        return result !== null && result !== undefined && (result?.isAchieved());
    };

    get isAchievedPendingPreReq() {
        var result = this._resultHelper;
        if (result != undefined)
            return result?.isAchievedPendingPreReq();
        else
            return false;
    };

    get isPartiallyAchieved() {
        var result = this._resultHelper;
        return result !== undefined && (result?.isPartiallyAchieved());
    };

    get isExempt() {

        var tpus = this._trainingPlanUnitStandard?._tpuModel ?? this._tpuModel;
        if (tpus?.tims_isexempt) {
            return true;
        } else {
            return false;
        }
    };

    get isExpiringThisYear(){
        let expiringDate = new Date(this.unitStandard?.tims_dateofexpiry as string) 
        let today = new Date() 
        return expiringDate.getFullYear() === today.getFullYear()
    }

    //resourceorderstatus: number | undefined;

    async orderResource() {

        var self = this,
            options = {
                resourceorderstatus: ResourceOrderStatus.Pending,
                electivesectionid: undefined,
                programmeunitstandardid: undefined,
                unitstandardid: undefined,
                goalId:undefined
            } as ItpuUpdate;

        var resp = await this._tpuModel.updateTPUS(this._tpuModel.tims_trainingplanunitstandardid as string, options);

    };

    async cancelResource() {

        var self = this,
            options = {
                resourceorderstatus: ResourceOrderStatus.Cancelled,
                electivesectionid: undefined,
                programmeunitstandardid: undefined,
                unitstandardid: undefined,
                assessorId: undefined,
                verifierId: undefined,
                assessmentmethod:undefined,
                sequencenumber: undefined,
                goalId:undefined,
                lmsstatus:undefined
            };
        await this._tpuModel.updateTPUS(this._tpuModel.tims_trainingplanunitstandardid as string, options);
    };
}