import * as React from "react";
import { Table } from "react-bootstrap";
import { ProgressIndicator } from "@fluentui/react";
import Common from "../Common/Common";
import check from "../assets/images/check.png";
import cross from "../assets/images/cross.png";
import disabled from "../assets/images/disabled.svg"
import "../assets/css/Header.css";

export const Header: React.FunctionComponent<HeaderProps> = (props) => {
    const getProgressBarValue = () => {
        let percentage = props.progress.achieved / props.progress.remaining;
        let isNan = Number.isNaN(percentage)
        if (isNan)
            return 0;
        return percentage;
    }

    // Get compliance for each individual rule to show text in red 

    const isMinCreditsCompliant = () => ((props.credits.selected ?? 0) >= (props.credits.minAllowed ?? 0)) || props.isDisabled
    const isMaxCreditsCompliant = () => ((props.credits.selected ?? 0) <= (props.credits.maxAllowed ?? 1000)) || props.isDisabled

    const isMinUnitsCompliant = () => (((props.units?.selected ?? 0) >= (props.units?.minAllowed ?? 0))) || props.isDisabled
    const isMaxUnitsCompliant = () => ((props.units?.selected ?? 0) <= (props.units?.maxAllowed ?? 1000)) || props.isDisabled

    const isMinLevelCreditsCompliant = () => ((props.levels?.selectedAtMinLevel ?? 0) >= (props.levels?.minAllowed ?? 0)) || props.isDisabled
    const isMaxLevelCreditsCompliant = () => ((props.levels?.selectedAtMaxLevel ?? 0) <= (props.levels?.maxAllowed ?? 1000)) || props.isDisabled

    // Do not need to check for Max sections as remaining sections are disabled
    const isMinSectionsComplant = () => ((props.sections?.selected ?? 0) >= (props.sections?.minAllowed ?? 0)) || props.isDisabled

    // Get image to display in section - Compliant, Non-compliant or disabled.
    const getStatusImage = () => { 
        let complianceImage = props.isCompliant ? check : cross 
        return props.isDisabled ? disabled : complianceImage
    }

    let selectedAtLevelA = props.levels?.selectedAtMinLevel
    let selectedAtLevelB = props.levels?.maxAllowedLevel ? props.levels?.selectedAtMaxLevel : undefined
    
    let levelLimitA = props.levels?.minAllowedLevel ?? props.levels?.maxAllowedLevel 
    
    let selectedLevelCreditsColorA = isMinLevelCreditsCompliant() ? "" : "red"
    let selectedLevelCreditsColorB = isMaxLevelCreditsCompliant() ? "" : "red"

    let isMinAndMaxEqual = props.levels?.minAllowedLevel == props.levels?.maxAllowedLevel
    let noMinLevel = !props.levels?.minAllowedLevel && props.levels?.maxAllowedLevel

    // Do not show extra column if Min and Max level are the same, or either has not been populated
    if(isMinAndMaxEqual || noMinLevel){
        selectedAtLevelA = selectedAtLevelB
        selectedAtLevelB = undefined
        if (noMinLevel) selectedLevelCreditsColorA = selectedLevelCreditsColorB
        if(isMinAndMaxEqual) selectedLevelCreditsColorA = (isMinLevelCreditsCompliant() && isMaxLevelCreditsCompliant()) ? "" : "red"
    }

    return (
        <div className="pageItem group header-section"
        style={{
            left: "56px !important",
            top: "381px !important",
            width: "60%",
            marginTop:"5px",
            opacity: props.isDisabled ? 0.5 : 1
        }}
        id="item12501"
        >   
            <div style={{ float: "left", marginRight: "10px" }}>
                <img style = {{width:"20ox", height:"20px"}} src={getStatusImage()}/>
            </div>
            <div id="item12503" className="pageItem singleline align-vert">
                <div className="valign-center" style={{marginTop:"2px"}}>
                    <span className="tabbed-standard">
                        <p><b>{props.name}</b></p>
                    </span>
                </div>
            </div>    
            
            <Table size = "sm">
                <tbody>
                    <tr>
                        <td width="20%">
                            <b>Progress</b>
                        </td>
                        <td width="20%">
                            <ProgressIndicator styles={Common.getProgressBarStyle()} percentComplete={getProgressBarValue()}/>
                        </td>
                        <td width="20%">
                            Achieved: {props.progress.achieved}
                        </td>
                        <td width="20%" colSpan={selectedAtLevelB != undefined ? 2 : 1}>
                            Remaining: {props.progress.remaining < 0 ? 0 : props.progress.remaining}
                        </td>
                    </tr>
                    {props.showRules && <tr>
                        <td width="20%"><b>Credits</b></td>
                        <td width="20%" style = {{color: isMinCreditsCompliant() ? "" : "red"}}>
                            Min Allowed: {props.credits.minAllowed ?? "N/A"}
                        </td>
                        <td width="20%" style = {{color: isMaxCreditsCompliant() ? "" : "red"}}>
                            Max Allowed: {props.credits.maxAllowed ?? "N/A"}
                        </td>
                        <td width="20%" style={{color: (isMinCreditsCompliant() && isMaxCreditsCompliant()) ? "" : "red"}} colSpan={selectedAtLevelB != undefined ? 2 : 1}>
                            Selected: {props.credits.selected}
                        </td>
                    </tr> }
                    {(props.units && props.showRules) && <tr>
                        <td width="20%"><b>Units</b></td>
                        <td width="20%" style = {{color: isMinUnitsCompliant() ? "" : "red"}}>
                            Min Allowed: {props.units?.minAllowed ?? "N/A"}
                        </td>
                        <td width="20%" style = {{color: isMaxUnitsCompliant() ? "" : "red"}}>
                            Max Allowed: {props.units?.maxAllowed ?? "N/A"}
                        </td>
                        <td width="20%" style = {{color: (isMinUnitsCompliant() && isMaxUnitsCompliant()) ? "" : "red"}} colSpan={selectedAtLevelB != undefined ? 2 : 1}>
                            Selected: {props.units?.selected ?? "N/A"}
                        </td >
                    </tr> }
                    { (props.sections && props.showRules) && 
                    <tr>
                        <td width="20%">
                            <b>Sections</b>
                        </td>
                        <td width="20%" style = {{color: isMinSectionsComplant() ? "" : "red"}}>
                            Min elective { props.sections.isParent ? "groups" : "sections" }: { props.sections.minAllowed ?? "N/A" }
                        </td>
                        <td width="20%">
                            Max elective { props.sections.isParent ? "groups" : "sections" }: { props.sections.maxAllowed ?? "N/A"}
                        </td>
                        <td width="20%" style = {{color: isMinSectionsComplant() ? "" : "red"}} colSpan={selectedAtLevelB != undefined ? 2 : 1}>
                            Selected: {props.sections.selected}
                        </td>
                    </tr> }
                    {(props.levels && props.showRules) &&
                    <tr>
                        <td width="20%">
                            <b>Levels</b>
                        </td>
                        <td width="20%" style = {{color: isMinLevelCreditsCompliant() ? "" : "red"}}>
                            {"Min allowed at level" + (props.levels?.minAllowedLevel ? " " + props.levels?.minAllowedLevel : "") }: {props.levels?.minAllowed ?? "N/A"}
                        </td>
                        <td width="20%" style = {{color: isMaxLevelCreditsCompliant() ? "" : "red"}}>
                            {"Max allowed at level" + (props.levels?.maxAllowedLevel ? " " + props.levels?.maxAllowedLevel : "" ) }: {props.levels?.maxAllowed ?? "N/A"}
                        </td>
                        <td width="20%" style = {{color: selectedLevelCreditsColorA}}>
                            <span>{"Selected at Level" + (levelLimitA ? " " + levelLimitA : "")}: </span> 
                            <span style = {{width:50}}>{ levelLimitA ? selectedAtLevelA : "N/A"  }</span>
                        </td>
                        {selectedAtLevelB != undefined ? 
                        <td width="20%" style = {{color:selectedLevelCreditsColorB}}>
                            Selected at Level { props.levels?.maxAllowedLevel }: { selectedAtLevelB }
                        </td>: <></>}
                    </tr> }
                    </tbody> 
                </Table>
        </div>
    )
}

interface HeaderProps {
    children:React.ReactNode,
    name:String,
    showRules:boolean,
    isCompliant: boolean,
    isDisabled: boolean,
    progress:Progress,
    credits:Credits,
    sections?:Sections,
    levels?:Levels,
    units?:Units
  }

interface Credits {
    minAllowed: number | undefined,
    maxAllowed: number | undefined,
    selected: number | undefined
}

interface Sections {
    isParent: boolean,
    minAllowed: number | undefined,
    maxAllowed: number | undefined,
    selected: number
}

interface Levels {
    minAllowedLevel: number | undefined,
    maxAllowedLevel: number | undefined,
    minAllowed: number | undefined,
    maxAllowed: number | undefined,
    selectedAtMinLevel:number,
    selectedAtMaxLevel:number   
}

interface Units {
    minAllowed: number | undefined,
    maxAllowed: number | undefined,
    selected: number
}

interface Progress {
    achieved: number,
    remaining: number
}