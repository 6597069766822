import React, { createContext, useReducer, ReactNode } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import arrayMove from "array-move";
import GlobalState from "../GlobalState";

import Emitter from "../Common/EventEmitter";
import { Events, RequestType } from "../Common/Enum";
import { IMessagebarObject } from '../Controls/MasterControl';

import ReorderHelper from '../ComponentHelpers/ReorderHelper';
import ResultForm from "../ComponentHelpers/ResultForm";
import Result from "../ComponentHelpers/Result";

import { Serializable } from "../Models/Serializable";
import TokenModel from "../Models/TokenModel";
import GlobalStateModel from "../Models/GlobalStateModel";
import TrainingPlanUnitStandardModel from '../Models/TrainingPlanUnitStandardModel';
import { TrainingPlanViewModel } from "../Models/TrainingPlanViewModel";

export const ReorderControlContext = createContext<ReorderContContextType | undefined>(undefined);

export const ReorderControlProvider = ({children}: ReorderControlProviderProps) => {
    
    const saveOrder = async (reorderStateb: ReorderContState) => {
        toggleFullScreenLoader(true);
        let updateResponses = await reorderStateb.reorderHelp.saveSeqNum();
        let errorArr = [];
        for (let index = 0; index < updateResponses.length; index++) {
            const element = updateResponses[index];
            if (!element.ok) {
                let errorObj = await element.json() as Serializable;
                errorArr.push(errorObj.error?.message);
            }
        }
        Emitter.emit(Events.SetMessageBar, {
            errors: errorArr, isSuccess: updateResponses.every(item => item.ok),
            requestType: RequestType.SAVE_ORDER, showMessageBar: true
        } as IMessagebarObject);

        let tpuRep = new TrainingPlanUnitStandardModel(globalAppContext.tokenModel as TokenModel);
        await tpuRep.refreshSeqNumber(globalAppContext.trainingPlanObj?.tims_trainingplanid as string);

        reorderDispatcher({ type: "POST_SAVE", obj: { updateResponses: updateResponses } });
    }

    const toggleFullScreenLoader = (showLoader: boolean) => {
        let globalContext = {
            ...globalAppContext,
            IsFullScreenLoaderVisible: showLoader
        } as GlobalStateModel;
        globalAppContext.setGlobalState(globalContext);
    }
    const globalAppContext = React.useContext(GlobalState);

    const reorderControlReducer = (state: ReorderContState, action: any): ReorderContState => {

        switch (action.type) {
            case "SAVE_COLUMNS":
                return { reorderHelp: state.reorderHelp, showLoader: state.showLoader, trainingPlanView:action.obj.trainingPlanView } as ReorderContState;
    
            case "OPEN_CREATE_VIEW_MODAL":
                return { reorderHelp: state.reorderHelp, showLoader: false, trainingPlanView:state.trainingPlanView } as ReorderContState;
    
            case "CREATE_RESULT":
                {
                    let row = state.reorderHelp.rows.find(e => e.unitStandard?.tims_unitstandardid == action.obj.unitStandardId)
                    if(row){
                        row._resultHelper = new Result(action.obj.createdResult);
                        row.resultForm = new ResultForm(row._resultHelper, row);
                    }
                    return { reorderHelp: state.reorderHelp, showLoader: false, trainingPlanView:state.trainingPlanView } as ReorderContState;
                }
            case "CREATE_COMMENT":
                {
                    let row = state.reorderHelp.rows.find(e => e.unitStandard?.tims_unitstandardid == action.obj.unitStandardId)
                    if(row){
                        row.comment = action.obj.commentObj;
                    }
                    return { reorderHelp: state.reorderHelp, showLoader: false, trainingPlanView:state.trainingPlanView } as ReorderContState;
                }
            case "REORDER": {
                let clone = cloneDeep(state.reorderHelp);
                clone.trainingPlanUnitStandards = arrayMove(clone.trainingPlanUnitStandards, action.obj.oldIndex, action.obj.newIndex);
                clone.setSequenceNumberForTPUS(true);
    
                let globalContext = {
                    ...globalAppContext,
                    IsReorderDirty: clone.trainingPlanUnitStandards.find(item => item.isDirty) != undefined
                } as GlobalStateModel;
                globalAppContext.setGlobalState(globalContext);
    
                return { reorderHelp: clone, showLoader: false, trainingPlanView:state.trainingPlanView } as ReorderContState;
            }
    
            case "TPUS_UPDATED": {
                let clone = cloneDeep(state.reorderHelp);
                let row = clone.rows.find(e => e._tpuModel.tims_trainingplanunitstandardid == action.obj.updatedTPU.tims_trainingplanunitstandardid) 
                if(row){
                    row._tpuModel = action.obj.updatedTPU;
                }
                return { reorderHelp: clone, showLoader: false, trainingPlanView:state.trainingPlanView } as ReorderContState;
            }
    
            case "ADD_ASSESSOR_VERIFIER": {
                let clone = cloneDeep(state.reorderHelp);
                let row = clone.rows[action.obj.rowIndex];
                row._tpuModel = action.obj.updatedTPU;
                return { reorderHelp: clone, showLoader: false, trainingPlanView:state.trainingPlanView } as ReorderContState;
            }
            case "POST_SAVE":
                {
                    let clone = cloneDeep(state.reorderHelp);
                    let updateResponses = action.obj.updateResponses as Response[];
                    if (updateResponses.every(item => item.ok)) {
                        for (let index = 0; index < clone.trainingPlanUnitStandards.length; index++) {
                            const element = clone.trainingPlanUnitStandards[index];
                            if (element.isDirty)
                                element.sequenceNumber = element.newSequenceNumber;
                        }
                    }
                    let globalContext = {
                        ...globalAppContext,
                        IsReorderDirty: !updateResponses.every(item => item.ok),
                        IsFullScreenLoaderVisible: false
                    } as GlobalStateModel;
                    globalAppContext.setGlobalState(globalContext);
                    return { reorderHelp: clone, showLoader: false, trainingPlanView:state.trainingPlanView }
                }
                break;
            case "SAVE_ORDER":
                saveOrder(state);   
                break;
            case "INIT":
                return { reorderHelp: action.obj.reorderHelp, showLoader: false, trainingPlanView:action.obj.trainingPlanView } as ReorderContState;
            default:
                break;
        }
    
        return { reorderHelp: state.reorderHelp, trainingPlanView:state.trainingPlanView, showLoader: false } as ReorderContState;
    }

    const [reorderState, reorderDispatcher] = useReducer(reorderControlReducer, { reorderHelp: {}, showLoader: true, trainingPlanView:{}} as ReorderContState);

    return ( 
        
        <ReorderControlContext.Provider value={{reorderState, reorderDispatcher}}>
            {children}
        </ReorderControlContext.Provider>
    );
}

interface ReorderContContextType {
    reorderState: ReorderContState;
    reorderDispatcher: React.Dispatch<any>;
}

interface ReorderControlProviderProps {
    children: ReactNode;
}

export interface ReorderContState {
    reorderHelp: ReorderHelper,
    showLoader: boolean,
    trainingPlanView:TrainingPlanViewModel
  }