import { Serializable } from "./Serializable";
import { UnitStandardModel } from "./UnitStandardModel";


export class SearchResultModel {
    UnitStandard: string | undefined;  
    Version: number | undefined;
    Id: string | undefined;  
  
    constructor(unitStandardModel: UnitStandardModel) {
        
        this.UnitStandard = `${unitStandardModel.tims_frameworkelementcode}-${unitStandardModel.tims_unitstandardtitle} v${unitStandardModel.tims_version}`;
        this.Version = unitStandardModel.tims_version;
        this.Id = unitStandardModel.tims_unitstandardid;
    }

   
}