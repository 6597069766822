import { D365Helper } from '../Common/D365Helper';
import TokenModel from './TokenModel';

interface IError {
    code: string;
    message: string
}
export class Serializable {

    public _tokenModel: TokenModel;
    public d365Helper: D365Helper;
    public error: IError | undefined
    /**
     *
     */
    constructor(tokenModel: TokenModel) {
        this._tokenModel = tokenModel;
        this.d365Helper = new D365Helper(this._tokenModel);
    }
    fillFromJSON(json: string) {
        var jsonObj = JSON.parse(json);
        for (var propName in jsonObj) {
            //@ts-ignore
            if (typeof this[propName] === 'object' && this[propName] !== null) {
                //@ts-ignore
                var serial = this[propName] as Serializable;
                serial.fillFromJSON(JSON.stringify(jsonObj[propName]));
            } else
                //@ts-ignore
                this[propName] = jsonObj[propName];
        }
    }

    createColumnArray(jsonObj: any): string[] {
        let columnset = [];
        for (var propName in jsonObj) {
            if (propName !== "_tokenModel" && propName !== "d365Helper" && propName !== "error")
                columnset.push(propName.toLowerCase());
        }
        return columnset;
    }
}