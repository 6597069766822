import { Serializable } from './Serializable';
import TokenModel from "./TokenModel"

const entityName = "tims_trainingprogrammes";

var attrs = {
    tims_contacttraineeid: "_tims_contacttraineeid_value",
    tims_name: "tims_name",
    tims_datetrainingexpires: "tims_datetrainingexpires",
    tims_requiredcreditspermonthtocomplete: "tims_requiredcreditspermonthtocomplete",
    tims_trainingcontractid: "_tims_trainingcontractid_value",
    fus_transferpending: "fus_transferpending",
    tims_trainingprogrammeid: "tims_trainingprogrammeid",
    tims_programmeid: "_tims_programmeid_value"
} as any;

export class TrainingProgrammeModel extends Serializable {

    constructor(tokenModel: TokenModel) {
        super(tokenModel);
    }

    _tims_contacttraineeid_value: string | undefined;
    tims_datetrainingexpires: string | undefined;
    tims_requiredcreditspermonthtocomplete: number | undefined;
    _tims_trainingcontractid_value: string | undefined;
    fus_transferpending: boolean | undefined;
    tims_trainingprogrammeid: string | undefined;
    _tims_programmeid_value: string | undefined;



    async retrieveTrainingProgramme(trainingProgrammeId: string, getFromCache: boolean) {

        var columnSet = [
            attrs.tims_contacttraineeid,
            attrs.tims_datetrainingexpires,
            attrs.tims_requiredcreditspermonthtocomplete,
            attrs.tims_trainingcontractid,
            attrs.fus_transferpending,
            attrs.tims_trainingprogrammeid,
            attrs.tims_programmeid
        ];

        var trainingProgramme;
        trainingProgramme = await this.d365Helper.Retrieve(entityName, trainingProgrammeId, columnSet, getFromCache);
        return trainingProgramme;
    }

    async getNZAAgreementForTP(trainingProgrammeId: string) {
        var criteria = "(_tims_trainingprogrammeid_value eq '" + trainingProgrammeId + "' and statuscode eq 3)";
        let macAgreement = await this.d365Helper.RetrieveMultiple("tims_macagreements", ["tims_macagreementid"], criteria, false);
        return macAgreement;
    }
}