import { ProgrammeUnitStandardModel } from '../Models/ProgrammeUnitStandardModel';
import { FrameworkElementModel } from '../Models/FrameworkElementModel';
import { ResultModel } from '../Models/ResultModel';
import TokenModel from '../Models/TokenModel';
import TrainingPlanUnitStandardModel from '../Models/TrainingPlanUnitStandardModel';
import ProgrammeUnitStandard from '../ComponentHelpers/ProgrammeUnitStandard';
import GlobalStateModel from '../Models/GlobalStateModel';
import ElectiveSectionModel from '../Models/ElectiveSectionModel';
import CrossCreditProgramUnitStandardHelper from '../ComponentHelpers/CrossCreditProgramUnitStandardHelper';
import ComponentHelperBase from '../ComponentHelpers/ComponentHelperBase';
import _ from 'underscore';
import { Templates } from '../Common/Enum';

export default class CrossCreditElectiveSectionHelper extends ComponentHelperBase {

    _tokenModel: TokenModel | undefined;
    crossCreditProgrammeUnitStandards: ComponentHelperBase[];
    electiveSectionId: string;
    _traineeId: string;
    _trainingPlanId: string;
    _globalStateModel: GlobalStateModel;
    _electiveSectionM: ElectiveSectionModel | undefined;
    _iselectedInOtherSection: boolean;

    masterPromiseArr: any[];

    _trainingPlanUnitStandardM: TrainingPlanUnitStandardModel;
    constructor(globalStateModel: GlobalStateModel, electiveSection: ElectiveSectionModel) {
        super();
        this._tokenModel = globalStateModel.tokenModel;
        this.crossCreditProgrammeUnitStandards = [];
        this._globalStateModel = globalStateModel;
        this.electiveSectionId = electiveSection.tims_electivesectionid as string;
        this._electiveSectionM = electiveSection;
        this._trainingPlanUnitStandardM = new TrainingPlanUnitStandardModel(this._tokenModel as TokenModel);
        this._traineeId = "";
        this._trainingPlanId = "";
        this._iselectedInOtherSection = false;
        this.isEditMode = true;
        this.masterPromiseArr = []
    }

    async previewBuild() {

        let progUnitStandardM = new ProgrammeUnitStandardModel(this._tokenModel as TokenModel);
        var progUnitStandards = await progUnitStandardM.retrieveProgrammeUnitStandardsForElectiveSection(this.electiveSectionId) as ProgrammeUnitStandardModel[];
        let frameworkElem = new FrameworkElementModel(this._tokenModel as TokenModel);
        let resultModel = new ResultModel(this._tokenModel as TokenModel);

        for (let index = 0; index < progUnitStandards.length; index++) {
            let item = progUnitStandards[index];
            let progUS = new ProgrammeUnitStandard(item, this._globalStateModel, false, this.electiveSectionId);
            //await progUS.build();
            this.masterPromiseArr.push(progUS.previewBuild());
            this.crossCreditProgrammeUnitStandards.push(progUS);

        }
        // Add the potential framework element unit standards
        var elementsFilter = await frameworkElem.generateFrameworkElementFilter(this.electiveSectionId);
        let contactId = this._globalStateModel.contact?.contactid;
        var relatedResults = [] as ResultModel[];

        if (contactId != undefined)
            relatedResults = await resultModel.retrieveResultsWithFrameworkElementFiltering(contactId, elementsFilter) as ResultModel[];

        for (let index = 0; index < relatedResults.length; index++) {
            let result = relatedResults[index];
            let ccPrgUs = new CrossCreditProgramUnitStandardHelper(result, null, this.electiveSectionId, this._globalStateModel);
            this.masterPromiseArr.push(ccPrgUs.build());
            this.crossCreditProgrammeUnitStandards.push(ccPrgUs);
        }
        await Promise.all(this.masterPromiseArr);
    }

    async build() {
        this._tokenModel = this._globalStateModel.tokenModel as TokenModel;
        let progUnitStandardM = new ProgrammeUnitStandardModel(this._tokenModel as TokenModel);
        var progUnitStandards = await progUnitStandardM.retrieveProgrammeUnitStandardsForElectiveSection(this.electiveSectionId) as ProgrammeUnitStandardModel[];
        this._traineeId = this._globalStateModel.trainingProgrammeObj?._tims_contacttraineeid_value as string;

        if (progUnitStandards != undefined)
            for (let index = 0; index < progUnitStandards.length; index++) {
                let item = progUnitStandards[index];
                let progUS = new ProgrammeUnitStandard(item, this._globalStateModel, false, this.electiveSectionId);
                //await progUS.build();
                this.masterPromiseArr.push(progUS.build());
                this.crossCreditProgrammeUnitStandards.push(progUS);

            }

        let frameworkElem = new FrameworkElementModel(this._tokenModel as TokenModel);
        let resultModel = new ResultModel(this._tokenModel as TokenModel);

        let elementsFilter = await frameworkElem.generateFrameworkElementFilter(this.electiveSectionId);
        let relatedResults = await resultModel.retrieveResultsWithFrameworkElementFiltering(this._traineeId, elementsFilter, false) as ResultModel[];

        if (relatedResults != undefined)
            for (let index = 0; index < relatedResults.length; index++) {
                let result = relatedResults[index];
                //@ts-ignore
                var code = result["unitstandard.tims_frameworkelementcode"] as string;
                var matchingTPUS = await this._trainingPlanUnitStandardM.retrieveByUnitStandardCode(code);

                var hasMatchingTPUS = (matchingTPUS !== null &&
                    matchingTPUS._tims_electivesectionid_value &&
                    matchingTPUS._tims_electivesectionid_value === this.electiveSectionId);

                var hasNonMatchingTPUS = (matchingTPUS !== null &&
                    matchingTPUS._tims_electivesectionid_value &&
                    matchingTPUS._tims_electivesectionid_value !== this.electiveSectionId);

                var hasCompulsoryTPUS = (matchingTPUS !== null &&
                    !matchingTPUS._tims_electivesectionid_value);

                if (matchingTPUS === null || (hasMatchingTPUS && !matchingTPUS._tims_programmeunitstandardid_value)) {
                    let ccPrgUs = new CrossCreditProgramUnitStandardHelper(result, matchingTPUS as TrainingPlanUnitStandardModel, this.electiveSectionId, this._globalStateModel);
                    this.masterPromiseArr.push(ccPrgUs.build());
                    this.crossCreditProgrammeUnitStandards.push(ccPrgUs);
                } else if (hasCompulsoryTPUS || hasNonMatchingTPUS) {
                    let ccPrgUs = new CrossCreditProgramUnitStandardHelper(result, null, this.electiveSectionId, this._globalStateModel);
                    this.masterPromiseArr.push(ccPrgUs.build());
                    this.crossCreditProgrammeUnitStandards.push(ccPrgUs);
                }

            }

        var trainingPlanUnitStandards = await this._trainingPlanUnitStandardM.retrieveNonResultForCrossCreditElective(this.electiveSectionId);
        var self = this;

        if (trainingPlanUnitStandards != undefined)
            for (let index = 0; index < trainingPlanUnitStandards.length; index++) {
                let tpus = trainingPlanUnitStandards[index];

                if (tpus._tims_programmeunitstandardid_value == undefined) {
                    let ccPrgUS = new CrossCreditProgramUnitStandardHelper({} as ResultModel, tpus, self.electiveSectionId, self._globalStateModel);
                    this.masterPromiseArr.push(ccPrgUS.build());
                    this.crossCreditProgrammeUnitStandards.push(ccPrgUS);
                }

            }

        await Promise.all(this.masterPromiseArr);
    }

    get isAllowedUnitStandardResources() {
        return this._globalStateModel.programmeObj?.tims_isallowedunitstandardresources as number;
    }

    get rows() {
        return this.crossCreditProgrammeUnitStandards;
    }

    get isCompliant() {
        let minUnits = this._electiveSectionM?.tims_minimumitems ?? 0
        let maxUnits = this._electiveSectionM?.tims_maximumitems ?? 1000

        let minCredits = this._electiveSectionM?.tims_minimumcredits ?? 0
        let maxCredits = this._electiveSectionM?.tims_maximumcredits ?? 1000

        let minCreditsAtLevel = this._electiveSectionM?.tims_minimumcreditsonlevel ?? 0
        let maxCreditsAtLevel = this._electiveSectionM?.tims_maximumcreditsonlevel ?? 1000


        let isUnitsCompliant = this.selectedUnits >= minUnits && this.selectedUnits <= maxUnits
        let isCreditsCompliant =  this.selectedCredits >= minCredits && this.selectedCredits <= maxCredits
        let isLevelsCompliant = this.selectedMinimumCredits >= minCreditsAtLevel && this.selectedMaximumCredits <= maxCreditsAtLevel

        return (isUnitsCompliant && isCreditsCompliant && isLevelsCompliant) || this.isDisabled;
    };

    get selectedMinimumCredits() {
        let minLevel = this._electiveSectionM?.tims_level ?? 0
        return this.getCreditCountByLevel(minLevel)

    };

    get selectedMaximumCredits() {
        let maxLevel = this._electiveSectionM?.tims_maximumlevel ?? 0
        return this.getCreditCountByLevel(maxLevel)

    };

    getCreditCountByLevel(level: number): number {

        return _.reduce(this.crossCreditProgrammeUnitStandards, function (memo, pus) {
            if (pus.isChecked && pus.unitStandard?.tims_level == level) {
                if (pus instanceof ProgrammeUnitStandard) {
                    if ((pus as ProgrammeUnitStandard).excludeCredits)
                        return memo;
                    else
                        return memo + (pus.credits as number);
                }
                return memo + (pus.credits as number);
            }
            return memo;
        }, 0);
        
    }

    setDisabled(disabled:boolean){
        this.isDisabled = disabled;
    }


    isEditMode: boolean | undefined;
    visible: boolean | undefined;

    sortUnitStandards() {
        this.crossCreditProgrammeUnitStandards.sort(function (left, right) {

            if (!left.isSelected && right.isSelected)
                return 1;

            if (left.unitStandard?.tims_name == right.unitStandard?.tims_name)
                return 0;

            return (left.unitStandard?.tims_name as string) > (right.unitStandard?.tims_name as string) ? 1 : -1;
        });
    };

    get isSelected(){ // Return true if at least one Programme unit standard is selected in section
        return this.crossCreditProgrammeUnitStandards.find(({ isSelected }) => isSelected)?.isSelected ? true : false
    } 

    get hasRows() {
        return this.crossCreditProgrammeUnitStandards.length >= 1;
    }

    get isSelectedOtherSection() {
        return this._iselectedInOtherSection;
    }

    get hasSelectedRows() {
        return _.find(this.crossCreditProgrammeUnitStandards, function (pus) {
            return pus.isSelected;
        });
    };

    toggleVisible() {
        this.visible = !this.visible;
    };

    setEditMode(mode: boolean) {
        this.isEditMode = mode;
    };


    // // Sums for credits
    get achievedCredits() {
        return _.reduce(this.crossCreditProgrammeUnitStandards, function (memo, pus) {
            if ((pus.isChecked && pus.isAchieved && !pus.excludeCredits) || pus.isExempt) {
                return memo + (pus.credits as number);
            }
            return memo;
        }, 0);
    };

    get selectedCredits() {
        return _.reduce(this.crossCreditProgrammeUnitStandards, function (memo, pus) {
            if (pus.isChecked) {
                if (pus instanceof ProgrammeUnitStandard) {
                    if ((pus as ProgrammeUnitStandard).excludeCredits)
                        return memo;
                    else
                        return memo + (pus.credits as number);
                }
                return memo + (pus.credits as number);
            }
            return memo;
        }, 0);
    };

    get _remainingCredits() {
        return this.selectedCredits - this.achievedCredits;
    };

    // // Sums for units
    get achievedUnits() {
        return _.reduce(this.crossCreditProgrammeUnitStandards, function (memo, pus) {
            if ((pus.isChecked && pus.isAchieved && !pus.excludeCredits) || pus.isExempt) {
                return memo + 1;
            }
            return memo;
        }, 0);
    };

    get selectedUnits() {
        return _.reduce(this.crossCreditProgrammeUnitStandards, function (memo, pus) {
            if (pus.isChecked) {
                if (pus instanceof ProgrammeUnitStandard) {
                    if ((pus as ProgrammeUnitStandard).excludeCredits)
                        return memo;
                    else
                        return memo + 1;
                }
            }
            return memo;
        }, 0);
    };

    get remainingUnits() {
        return this.selectedUnits - this.achievedUnits;
    };

    // // check the programme unit standards if is dirty
    get isDirty() {
        var isDirty = false;
        _.each(this.crossCreditProgrammeUnitStandards, function (pus) {
            if (pus.isDirty) {
                isDirty = true;
            }
        });

        return isDirty;
    };

    templateName(isEditMode: boolean) {
        if (isEditMode) {
            return Templates.CrossCreditElectiveEdit;
        }
        return Templates.CrossCreditElective;
    }
}