import { ResultModel } from '../Models/ResultModel';

export default class Result {

    _resultModel: ResultModel;
    constructor(resModel: ResultModel) {
        this._resultModel = resModel
    }

    resultStatus = {
        PartiallyAchieved: 1,
        FullAchievedPendingPreReq: 4,
        FullyAchieved: 3
    };

    get formattedText() {

        if (this._resultModel == undefined)
            return "";
        var dateAchieved = this._resultModel.tims_dateofachievement !== null ? new Date((this._resultModel.tims_dateofachievement as string)) : new Date();
        var formattedDate = "DD/MM/YYYY"
            .replace("DD", this.padd0Left(dateAchieved.getDate()))
            .replace("MM", this.padd0Left(dateAchieved.getMonth() + 1))
            .replace("YYYY", dateAchieved.getFullYear().toString());

        if (this.isAchieved()) {
            return formattedDate;
        }

        if (this.isAchievedPendingPreReq()) {
            return formattedDate + "*";

        }

        if (this.isPartiallyAchieved()) {
            if (this._resultModel.tims_datepracticalpassed !== undefined)
                return 'Practical';
            if (this._resultModel.tims_datetheorypassed !== undefined)
                return 'Theory';
        }

        return '';
    };

    // is achieved
    isAchieved() {
        var statusCode = this._resultModel.statuscode;
        return statusCode == this.resultStatus.FullyAchieved;
    };

    // is achievedPendingPreReq
    isAchievedPendingPreReq() {
        var statusCode = this._resultModel.statuscode;
        return statusCode == this.resultStatus.FullAchievedPendingPreReq;
    };

    // is achievedPendingPreReq
    isPartiallyAchieved() {
        var statusCode = this._resultModel.statuscode;
        return statusCode == this.resultStatus.PartiallyAchieved;
    };

    // pads to 2 digits, returns string
    padd0Left(num: number) {

        if (num <= 9) {
            return '0' + num;
        }

        return num.toString();
    }
}