import { Serializable } from './Serializable';
import TokenModel from "./TokenModel"

const entityName = "tims_trainingplanviews";

export interface ITrainingPlanViewCreate {
    tims_user:string | undefined;
    tims_name: string | undefined;
    tims_sequencenumber?: boolean | undefined;
    tims_unitstandardname: boolean | undefined;
    tims_credits: boolean | undefined;
    tims_level: boolean | undefined;
    tims_assessmentstatus: boolean | undefined;
    tims_assessmenttype: boolean | undefined;
    tims_assessmentstatusfinal:boolean | undefined;
    tims_courseidandstartdate:boolean | undefined;
    tims_coursestartdate:boolean | undefined;
    tims_result: boolean | undefined;
    tims_course: boolean | undefined;
    tims_assessor: boolean | undefined;
    tims_verifier: boolean | undefined;
    tims_comments: boolean | undefined;
}

export interface TrainingPlanViewColumns {
    tims_sequencenumber: boolean | undefined;
    tims_unitstandardname: boolean | undefined;
    tims_credits: boolean | undefined;
    tims_level: boolean | undefined;
    tims_assessmentstatus: boolean | undefined;
    tims_assessmenttype: boolean | undefined;
    tims_assessmentstatusfinal:boolean | undefined;
    tims_courseidandstartdate:boolean | undefined;
    tims_coursestartdate:boolean | undefined;
    tims_result: boolean | undefined;
    tims_course: boolean | undefined;
    tims_assessor: boolean | undefined;
    tims_verifier: boolean | undefined;
    tims_comments: boolean | undefined;
}

export class TrainingPlanViewModel extends Serializable {
    _tims_user_value:string | undefined;
    tims_trainingplanviewid:string | undefined;
    tims_name: string | undefined;
    tims_sequencenumber: boolean | undefined;
    tims_unitstandardname: boolean | undefined;
    tims_credits: boolean | undefined;
    tims_level: boolean | undefined;
    tims_assessmentstatus: boolean | undefined;
    tims_assessmenttype: boolean | undefined;
    tims_assessmentstatusfinal:boolean | undefined;
    tims_courseidandstartdate:boolean | undefined;
    tims_coursestartdate:boolean | undefined;
    tims_result: boolean | undefined;
    tims_course: boolean | undefined;
    tims_assessor: boolean | undefined;
    tims_verifier: boolean | undefined;
    tims_comments: boolean | undefined;

    constructor(tokenModel: TokenModel) {
        super(tokenModel);
    }

    async retrieveTrainingPlanViewsForUser(userID:string) {
        return await this.d365Helper.RetrieveMultipleByOrder(entityName, this.createColumnArray(this), "_tims_user_value eq '" + userID + "'", "tims_name asc", false);
    }

    async retrieveTrainingPlanView(trainingPlanViewId:string) {
        var trainingPlanView = await this.d365Helper.Retrieve(entityName, trainingPlanViewId, this.createColumnArray(this))
        return trainingPlanView
    }
    async createTrainingPlanView(createTrainingPlanViewData:ITrainingPlanViewCreate){
        let tpView = {
            "tims_User@odata.bind": "/systemusers(" + createTrainingPlanViewData.tims_user + ")",
            tims_name: createTrainingPlanViewData.tims_name,
            tims_sequencenumber: createTrainingPlanViewData.tims_sequencenumber,
            tims_unitstandardname: createTrainingPlanViewData.tims_unitstandardname,
            tims_credits: createTrainingPlanViewData.tims_credits,
            tims_level: createTrainingPlanViewData.tims_level,
            tims_assessmentstatus: createTrainingPlanViewData.tims_assessmentstatus,
            tims_assessmenttype: createTrainingPlanViewData.tims_assessmenttype,
            tims_assessmentstatusfinal: createTrainingPlanViewData.tims_assessmentstatusfinal,
            tims_courseidandstartdate: createTrainingPlanViewData.tims_courseidandstartdate,
            tims_coursestartdate: createTrainingPlanViewData.tims_coursestartdate,
            tims_result: createTrainingPlanViewData.tims_result,
            tims_course: createTrainingPlanViewData.tims_course,
            tims_assessor: createTrainingPlanViewData.tims_assessor,    
            tims_verifier: createTrainingPlanViewData.tims_verifier,
            tims_comments: createTrainingPlanViewData.tims_comments
        }
        return await this.d365Helper.Create(entityName, tpView, this.createColumnArray(this))
    }

    async updateTrainingPlanView(createTrainingPlanViewData:ITrainingPlanViewCreate, trainingPlanViewId:string){
        let tpView = {
            "tims_User@odata.bind": "/systemusers(" + createTrainingPlanViewData.tims_user + ")",
            tims_name: createTrainingPlanViewData.tims_name,
            tims_sequencenumber: createTrainingPlanViewData.tims_sequencenumber,
            tims_unitstandardname: createTrainingPlanViewData.tims_unitstandardname,
            tims_credits: createTrainingPlanViewData.tims_credits,
            tims_level: createTrainingPlanViewData.tims_level,
            tims_assessmentstatus: createTrainingPlanViewData.tims_assessmentstatus,
            tims_assessmenttype: createTrainingPlanViewData.tims_assessmenttype,
            tims_assessmentstatusfinal: createTrainingPlanViewData.tims_assessmentstatusfinal,
            tims_courseidandstartdate: createTrainingPlanViewData.tims_courseidandstartdate,
            tims_coursestartdate: createTrainingPlanViewData.tims_coursestartdate,
            tims_result: createTrainingPlanViewData.tims_result,
            tims_course: createTrainingPlanViewData.tims_course,
            tims_assessor: createTrainingPlanViewData.tims_assessor,
            tims_verifier: createTrainingPlanViewData.tims_verifier,
            tims_comments: createTrainingPlanViewData.tims_comments
        }
        let response = await this.d365Helper.Update(entityName, trainingPlanViewId, tpView)
        return response
    }

    async deleteTrainingPlanView(trainingPlanViewId:string){
        return await this.d365Helper.Delete(entityName, trainingPlanViewId)
    }
}