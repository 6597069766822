import { Serializable } from './Serializable';
import TokenModel from "./TokenModel"
import { UnitStandardModel } from './UnitStandardModel';
import { ItpuCreate, ItpuUpdate } from "../Common/TrainingPlanUnitStandardManager";

const entityName = "tims_trainingplanunitstandards";
var cache = [] as TrainingPlanUnitStandardModel[];
interface usp {
    uspunitstandardid: string
}

interface course {
    tims_title: string | undefined;
    tims_name: string | undefined;
    courseid: string | undefined;
    tims_identifier: string | undefined;
    tims_dateofstart: string | undefined;
    statuscode: number | undefined;
}
export default class TrainingPlanUnitStandardModel extends Serializable {


    trainingPlanId: string | undefined;
    needsRefresh = false;

    statecode: number | undefined;
    statuscode: number | undefined;
    tims_assessedby: number | undefined;
    tims_assessmenttype: number | undefined;
    _tims_electivesectionid_value: string | undefined;
    tims_isexempt: boolean | undefined;
    tims_name: string | undefined;
    _tims_programmeunitstandardid_value: string | undefined;
    _tims_resultid_value: string | undefined;
    _tims_trainingplanid_value: string | undefined;
    tims_trainingplanunitstandardid: string | undefined;
    _tims_unitstandardid_value: string | undefined;
    tims_resourceorderstatus: number | undefined;
    _tims_assessor_value: string | undefined;
    _tims_verifier_value: string | undefined;
    _tims_courseid_value: string | undefined;
    uspunitstandardid: string | undefined;
    usp: usp | undefined;
    fus_sequencenumber: number | undefined;
    tims_prerequisite: boolean | undefined;
    tims_assessmentstatus: number | undefined;
    tims_assessmentstatusfinal: number | undefined;
    tims_assessmentduedatereviewed: string | undefined;
    tims_assessmentduedatefinal: string | undefined;
    tims_datesubmitted: string | undefined;
    tims_dateresubmitted: string | undefined;
    tims_datesentbackforresubmission: string | undefined;
    // tims_assessmentmethod:number | undefined;
    // tims_lmsstatus:number | undefined;
    _tims_goal_value:string | undefined;

    constructor(tokenModel: TokenModel) {
        super(tokenModel);
    }

    clearCache() {
        cache = [];
    }

    get course() {

        let course = {} as course;

        for (const key in this) {
            
            if (Object.prototype.hasOwnProperty.call(this, key)) {
                if (key.includes("course.")) {
                    let propName = key.split(".")[1];
                    //@ts-ignore
                    course[propName] = this[key];
                }

            }
        }
        return course;

    }

    get unitStandard() {
        let unitStandard = new UnitStandardModel(this._tokenModel);
        unitStandard.tims_unitstandardid = this.uspunitstandardid;
        for (const key in this) {
            if (Object.prototype.hasOwnProperty.call(this, key)) {
                if (key.includes("unitstandard.")) {
                    let propName = key.split(".")[1];
                    //@ts-ignore
                    unitStandard[propName] = this[key];
                }

            }
        }
        return unitStandard;
    }

    async initCache(_trainingPlanId: string) {

        this.trainingPlanId = _trainingPlanId;
        var fetch = ["<fetch version='1.0' output-format='xml-platform' mapping='logical' distinct='true' >",
            "  <entity name='tims_trainingplanunitstandard'>",
            "    <attribute name='statecode' />",
            "  <attribute name='statuscode' />",
            "  <attribute name='tims_assessedby' />",
            "  <attribute name='tims_assessmenttype' />",
            "  <attribute name='tims_electivesectionid' />",
            "  <attribute name='tims_isexempt' />",
            "  <attribute name='tims_name' />",
            "  <attribute name='tims_programmeunitstandardid' />",
            "  <attribute name='tims_resultid' />",
            "  <attribute name='tims_trainingplanid' />",
            "  <attribute name='tims_trainingplanunitstandardid' />",
            "  <attribute name='tims_unitstandardid' />",
            "  <attribute name='tims_resourceorderstatus' />",
            "  <attribute name='tims_assessor' />",
            "  <attribute name='tims_verifier' />",
            "  <attribute name='tims_courseid' />",
            "  <attribute name='tims_assessmentstatus' />",
            "  <attribute name='tims_assessmentstatusfinal' />",
            "  <attribute name='tims_assessmentduedatereviewed' />",
            "  <attribute name='tims_assessmentduedatefinal' />",
            "  <attribute name='tims_datesubmitted' />",
            "  <attribute name='tims_dateresubmitted' />",
            "  <attribute name='tims_datesentbackforresubmission' />",
            // "  <attribute name='tims_assessmentmethod' />",
            // "  <attribute name='tims_lmsstatus' />",
            "  <attribute name='tims_goal' />",
            "  <attribute name='fus_sequencenumber' />",
            "  <attribute name='tims_prerequisite' />", 
            "    <filter type='and'>",
            "      <condition attribute='tims_trainingplanid' operator='eq' value='#TRAININGPLANID#' />",
            "    </filter>",
            "  <link-entity name='tims_course' from='tims_courseid' to='tims_courseid' link-type='outer' alias='course'>",
            "      <attribute name='tims_title' />",
            "      <attribute name='tims_name' />",
            "      <attribute name='tims_dateofstart' />",
            "      <attribute name='tims_identifier' />",
            "      <attribute name='statuscode' />",
            "   </link-entity>",
            "   <link-entity name='tims_unitstandard' from='tims_unitstandardid' to='tims_unitstandardid' visible='false' alias='unitstandard'>",
            "      <attribute name='tims_frameworkelementcode' />",
            "      <attribute name='tims_level' />",
            "      <attribute name='tims_name' />",
            "      <attribute name='tims_unitstandardid' />",
            "      <attribute name='tims_credits' />",
            "      <attribute name='tims_dateofexpiry' />",
            "      <link-entity name='tims_unitstandard_product' from='tims_unitstandardid' to='tims_unitstandardid' link-type='outer' alias='usp' >",
            "           <attribute name='tims_unitstandardid'  alias='uspunitstandardid'/>",
            "      </link-entity>",
            "    </link-entity>",
            "  </entity>",
            "</fetch>"
        ].join('').replace('#TRAININGPLANID#', this.trainingPlanId);

        cache = this.createTPUModel(await this.d365Helper.ExecuteFetch(entityName, fetch, false));
        this.needsRefresh = false;
    }

    async refreshSeqNumber(_trainingPlanId: string) {
        let query = `<fetch>
        <entity name="tims_trainingplanunitstandard">
          <attribute name="fus_sequencenumber" />
          <attribute name="tims_trainingplanunitstandardid" />
          <filter>
            <condition attribute="tims_trainingplanid" operator="eq" value="`+ _trainingPlanId + `" />
          </filter>
        </entity>
      </fetch>`;
        let tpus = await this.d365Helper.ExecuteFetch(entityName, query, false)
        for (let index = 0; index < cache.length; index++) {
            const element = cache[index]

            let tpuWithNewSeqNumber = tpus.find((item: TrainingPlanUnitStandardModel) => item.tims_trainingplanunitstandardid == element.tims_trainingplanunitstandardid) as TrainingPlanUnitStandardModel;
            if (tpuWithNewSeqNumber != undefined)
                cache[index].fus_sequencenumber = tpuWithNewSeqNumber.fus_sequencenumber;
        }

    }

    async retrieveAll() {
        if (!cache) {
            throw "TrainingPlanUnitStandardRepo cache has not been initialised";
        }

        if (this.needsRefresh) {
            await this.initCache(this.trainingPlanId as string);
        }

        return cache;
    }

    getMaxSeqNum() {
        if (cache.length > 0) {
            //TPUS selected on the plan, so sort and return the latest sequence number
            let sortedArray = cache.sort((tp1, tp2) => {
                if (tp1.fus_sequencenumber != undefined && tp2.fus_sequencenumber != undefined) {
                    let tp1SeqNum = tp1.fus_sequencenumber;
                    let tp2SeqNum = tp2.fus_sequencenumber;

                    if (tp1SeqNum < tp2SeqNum)
                        return -1;
                    else if (tp1SeqNum > tp2SeqNum)
                        return 1;
                    else
                        return 0;
                } else if (tp1.fus_sequencenumber != undefined)
                    return 1;
                else if (tp2.fus_sequencenumber != undefined)
                    return -1;
                else
                    return 0;
            });

            return sortedArray[sortedArray.length - 1].fus_sequencenumber;
        } else {
            //No TPUS selected on the plan yet, so don't return a sequence number
            return undefined;
        }
    }

    async retrieveTPUSFromProgrammeUnitStandard(progUnitStandardId: string) {

        if (!cache) {
            throw "TrainingPlanUnitStandardRepo cache has not been initialised";
        }

        if (this.needsRefresh) {
            await this.initCache(this.trainingPlanId as string);
        }
        let tpus = cache.find(elem => elem._tims_programmeunitstandardid_value == progUnitStandardId && elem.statecode == 0);

        if (tpus) {
            return tpus;
        }

        return null;
    }

    async retrieveByUnitStandard(unitStandardId: string) {

        if (!cache) {
            throw "TrainingPlanUnitStandardRepo cache has not been initialised";
        }

        if (this.needsRefresh) {
            await this.initCache(this.trainingPlanId as string);
        }
        let tpus = cache.find(elem => elem._tims_unitstandardid_value == unitStandardId);

        if (tpus) {
            return tpus;
        }

        return null;
    }

    async retrieveByUnitStandardCode(unitStandardCode: string) {

        if (!cache) {
            throw "TrainingPlanUnitStandardRepo cache has not been initialised";
        }

        if (this.needsRefresh) {
            await this.initCache(this.trainingPlanId as string);
        }
        //@ts-ignore
        let tpus = cache.find(elem => elem["unitstandard.tims_frameworkelementcode"] == unitStandardCode);

        if (tpus) {
            return tpus;
        }

        return null;
    }

    async retrieveNonResultForCrossCreditElective(electiveSectionId: string) {
        if (!cache) {
            throw "TrainingPlanUnitStandardRepo cache has not been initialised";
        }

        if (this.needsRefresh) {
            await this.initCache(this.trainingPlanId as string);
        }
        return cache.filter(elem => elem._tims_electivesectionid_value &&
            elem._tims_electivesectionid_value == electiveSectionId &&
            !elem._tims_resultid_value);
    }

    async retrieveCrossCreditTrainingPlanUnitStandards(trainingPlanId: string) {

        if (this.needsRefresh) {
            this.initCache(trainingPlanId);
        }

        var fetch = ["<fetch version='1.0' output-format='xml-platform' mapping='logical' distinct='false'>",
            "  <entity name='tims_trainingplanunitstandard'>",
            "  <attribute name='statecode' />",
            "  <attribute name='statuscode' />",
            "  <attribute name='tims_assessedby' />",
            "  <attribute name='tims_assessmenttype' />",
            "  <attribute name='tims_electivesectionid' />",
            "  <attribute name='tims_isexempt' />",
            "  <attribute name='tims_name' />",
            "  <attribute name='tims_programmeunitstandardid' />",
            "  <attribute name='tims_resultid' />",
            "  <attribute name='tims_trainingplanid' />",
            "  <attribute name='tims_trainingplanunitstandardid' />",
            "  <attribute name='tims_unitstandardid' />",
            "  <attribute name='fus_sequencenumber' />",
            "  <attribute name='tims_resourceorderstatus' />",
            "  <attribute name='tims_prerequisite' />",
            "    <filter type='and'>",
            "      <condition attribute='tims_trainingplanid' operator='eq' value='#TRAININGPLANID#' />",
            "      <condition attribute='tims_electivesectionid' operator='not-null' />",
            "    </filter>",
            "    <link-entity name='tims_unitstandard' from='tims_unitstandardid' to='tims_unitstandardid' visible='false' link-type='outer' alias='unitstandard'>",
            "      <attribute name='tims_frameworkelementcode' />",
            "      <attribute name='tims_level' />",
            "      <attribute name='tims_credits' />",
            "    </link-entity>",
            "  </entity>",
            "</fetch>"
        ].join('').replace('#TRAININGPLANID#', trainingPlanId);

        return this.createTPUModel(await this.d365Helper.ExecuteFetch(entityName, fetch, true));
    }

    createTPUModel(tpus: any[]) {
        let tpusArr = [] as TrainingPlanUnitStandardModel[];
        if (tpus != undefined) {

            for (let index = 0; index < tpus.length; index++) {
                const element = tpus[index];
                let tpu = new TrainingPlanUnitStandardModel(this._tokenModel);
                tpu.fillFromJSON(JSON.stringify(element));
                tpusArr.push(tpu);
            }
        }
        return tpusArr;
    }

    async retrieveTPUSFromId(trainingPlanUnitStandardId: string, useCache: boolean = true) {

        if (!cache) {
            throw "TrainingPlanUnitStandardRepo cache has not been initialised";
        }

        if (useCache) {
            var tpus = cache.find(elem => elem.tims_trainingplanunitstandardid == trainingPlanUnitStandardId);

            if (tpus) {
                console.log("training plan unit standard cache hit");
                return tpus;
            }
        }

        var fetch = ["<fetch version='1.0' output-format='xml-platform' mapping='logical' distinct='false'>",
            "  <entity name='tims_trainingplanunitstandard'>",
            "    <attribute name='statecode' />",
            "    <attribute name='statuscode' />",
            "    <attribute name='tims_assessedby' />",
            "    <attribute name='tims_assessmenttype' />",
            "    <attribute name='tims_electivesectionid' />",
            "    <attribute name='tims_isexempt' />",
            "    <attribute name='tims_name' />",
            "    <attribute name='tims_programmeunitstandardid' />",
            "    <attribute name='tims_resultid' />",
            "    <attribute name='tims_trainingplanid' />",
            "    <attribute name='tims_trainingplanunitstandardid' />",
            "    <attribute name='tims_unitstandardid' />",
            "    <attribute name='tims_resourceorderstatus' />",
//            "    <attribute name='tims_assessmentmethod' />",
            "    <attribute name='tims_goal' />",
            // "    <attribute name='tims_lmsstatus' />",
            "    <attribute name='tims_verifier' />",
            "    <attribute name='tims_assessor' />",
            "    <attribute name='tims_courseid' />",
            "    <attribute name='fus_sequencenumber' />",
            "    <attribute name='tims_prerequisite' />",
            "  <link-entity name='tims_course' from='tims_courseid' to='tims_courseid' link-type='outer' alias='course'>",
            "  <attribute name='tims_title' />",
            "</link-entity>",
            "    <filter type='and'>",
            "      <condition attribute='tims_trainingplanunitstandardid' operator='eq' value='#TRAININGPLANUNITSTANDARDID#' />",
            "    </filter>",
            "    <link-entity name='tims_unitstandard' from='tims_unitstandardid' to='tims_unitstandardid' visible='false' link-type='outer' alias='unitstandard'>",
            "      <attribute name='tims_frameworkelementcode' />",
            "      <attribute name='tims_level' />",
            "      <attribute name='tims_credits' />",
            "    </link-entity>",
            "  </entity>",
            "</fetch>"
        ].join('').replace('#TRAININGPLANUNITSTANDARDID#', trainingPlanUnitStandardId);

        let resp = await this.d365Helper.ExecuteFetch(entityName, fetch, useCache);

        let res = this.createTPUModel(resp);

        if (res.length == 1) {
            cache.push(res[0]);
            tpus = res[0];
            return tpus;
        }

        return tpus;
    }

    async create(options: ItpuCreate) {
        this.needsRefresh = true;

        // let existingTPUS = cache.find(elem => elem._tims_unitstandardid_value == options.unitstandardid)

        // if(existingTPUS != undefined){
        //     this.reactivateTPUS(existingTPUS.tims_trainingplanunitstandardid as string)
        //     return existingTPUS
        // }
        var tpus = {
            "tims_trainingplanid@odata.bind": "/tims_trainingplans(" + options.trainingplanid + ")",
            "tims_unitstandardid@odata.bind": "/tims_unitstandards(" + options.unitstandardid + ")",
            "tims_assessedby": options.assessedby,
            "tims_assessmenttype": options.assessmenttype
        } as any;

        if (options.programmeunitstandardid)
            tpus["tims_programmeunitstandardid@odata.bind"] = "/tims_programmeunitstandards(" + options.programmeunitstandardid + ")";

        if (options.electivesectionid)
            tpus["tims_electivesectionid@odata.bind"] = "/tims_electivesections(" + options.electivesectionid + ")";

        if (options.sequenceNumber)
            tpus.fus_sequencenumber = options.sequenceNumber;

        let resp = await this.d365Helper.Create(entityName, tpus, ["tims_trainingplanunitstandardid",
            "tims_electivesectionid",
            "tims_name",
            "tims_programmeunitstandardid", "tims_assessedby", "tims_assessmenttype",
            "tims_resourceorderstatus", "tims_unitstandardid",
            "tims_resultid", "tims_isexempt", "statuscode", "statecode"]);

        if (resp.tims_trainingplanunitstandardid != undefined) {
            let resp2 = await this.retrieveTPUSFromId(resp.tims_trainingplanunitstandardid);

            if (resp2 != null && resp2 != undefined)
                cache.push(resp2);

            return resp2;
        } else
            return resp;
    }

    async deleteTPUS(trainingPlanUnitStandardId: string) {

        let response = await this.d365Helper.Delete(entityName, trainingPlanUnitStandardId);

        try {
            if (response.status === 204) {

                if (response != null) {
                    cache = cache.filter(tpus => {
                        return tpus.tims_trainingplanunitstandardid !== trainingPlanUnitStandardId
                    });
                }
                return { isSuccess: true, exception: {} as Serializable };;

            } else {
                return { isSuccess: false, exception: await response.json() as Serializable };
            }

        } catch (error) {
            alert('An exception occured while deleting TrainingPlan unitstandard, please contact your system administrator' + error);
            console.log(error);
            return { isSuccess: false, exception: { error: { message: error } } as Serializable };
        }
    }

    async deactivateTPUS(trainingPlanUnitStandardId: string) {
        var request = { "statuscode": 2, "statecode": 1 };
        let response = await this.d365Helper.Update(entityName, trainingPlanUnitStandardId, request);

        try {
            if (response.status === 204) {

                if (response != null) {
                    let index = cache.findIndex(e => e.tims_trainingplanunitstandardid == trainingPlanUnitStandardId)
                    cache[index].statecode = 1
                    cache[index].statuscode = 2
                }
                return { isSuccess: true, exception: {} as Serializable };;

            } else {
                return { isSuccess: false, exception: await response.json() as Serializable };
            }

        } catch (error) {
            alert('An exception occured while deleting TrainingPlan unitstandard, please contact your system administrator' + error);
            console.log(error);
            return { isSuccess: false, exception: { error: { message: error } } as Serializable };
        }
    }

    async reactivateTPUS(trainingPlanUnitStandardId:string){
        let request = {"statuscode": 1, "statecode":0 }
        let response = await this.d365Helper.Update(entityName, trainingPlanUnitStandardId, request);
        return response
    }

    async updateTPUS(id: string, fields: ItpuUpdate) {

        var tpus = {} as any;
        if (fields.resourceorderstatus !== undefined) {
            if (fields.resourceorderstatus === null)
                tpus.tims_ResourceOrderStatus = null;
            else
                tpus.tims_resourceorderstatus = fields.resourceorderstatus;
        }

        if (fields.electivesectionid !== undefined) {
            if (fields.electivesectionid === null)
                tpus["tims_electivesectionid@odata.bind"] = null;
            else
                tpus["tims_electivesectionid@odata.bind"] = "/tims_electivesections(" + fields.electivesectionid + ")";
        }

        if (fields.programmeunitstandardid !== undefined) {
            if (fields.programmeunitstandardid === null)
                tpus["tims_programmeunitstandardid@odata.bind"] = null;
            else
                tpus["tims_programmeunitstandardid@odata.bind"] = "/tims_programmeunitstandards(" + fields.programmeunitstandardid + ")";

        }

        if (fields.assessorId !== undefined) {
            if (fields.assessorId === null)
                tpus["tims_Assessor@odata.bind"] = null;
            else
                tpus["tims_Assessor@odata.bind"] = "/contacts(" + fields.assessorId + ")";
        }

        if (fields.verifierId !== undefined) {
            if (fields.verifierId === null)
                tpus["tims_Verifier@odata.bind"] = null;
            else
                tpus["tims_Verifier@odata.bind"] = "/contacts(" + fields.verifierId + ")";
        }

        if(fields.goalId !== undefined){
            tpus["tims_goal@odata.bind"] = "/fus_goals(" + fields.goalId + ")";
        }

        if (fields.sequencenumber != undefined)
            tpus.fus_sequencenumber = fields.sequencenumber;
        
        // if (fields.assessmentmethod != undefined)
        //     tpus.tims_assessmentmethod = fields.assessmentmethod
        // if(fields.lmsstatus != undefined)   
        //     tpus.tims_lmsstatus = fields.lmsstatus

        return await this.d365Helper.Update(entityName, id, tpus);
    }

    clone(obj: any) {
        return JSON.parse(JSON.stringify(obj));
    }

    async executeActionForTpComplete(entityId: string) {

        var parameters = {} as any;
        var selectedtrainingplan1 = {} as any;
        selectedtrainingplan1.tims_trainingplanid = entityId; //Delete if creating new record 
        selectedtrainingplan1["@odata.type"] = "Microsoft.Dynamics.CRM.tims_trainingplan";
        parameters.SelectedTrainingPlan = [selectedtrainingplan1];

        let response = await this.d365Helper.ExecuteBoundAction("tims_trainingplans", entityId, "Microsoft.Dynamics.CRM.tims_CompleteTrainingPlan", parameters);
        try {
            if (response.status === 200) {
                return true;
            } else {
                return false;
            }

        } catch (error) {
            alert('An exception occured, please contact your system administrator' + error);
            console.log(error);
            return false;
        }
    }
}