import { Serializable } from './Serializable';
import TokenModel from "./TokenModel"

const entityName = 'tims_trainingplancomments';
var cache = [] as TrainingPlanCommentModel[];
export class TrainingPlanCommentModel extends Serializable {

    constructor(tokenModel: TokenModel) {
        super(tokenModel);
    }

    _tims_learningoutcomeid_value: string | undefined;
    _tims_moduleid_value: string | undefined;
    tims_name: string | undefined;
    tims_trainingcomment: string | undefined;
    tims_trainingplancommentid: string | undefined;
    _tims_trainingplanid_value: string | undefined;
    _tims_trainingplanunitstandardid_value: string | undefined;

    clearCache() {
        cache = [];
    }

    retrieveByTrainingPlanUnitStandardId(trainingPlanUnitStandardId: string) {
        return cache.find(elem => elem._tims_trainingplanunitstandardid_value == trainingPlanUnitStandardId);
    }

    // create the new comment
    async createComment(commentOptions: any) {
        // create the new comment from the options
        var comment = {} as any;
        comment.tims_trainingcomment = commentOptions.trainingcomment;
        comment["tims_trainingplanid@odata.bind"] = "/tims_trainingplans(" + commentOptions.trainingplanid + ")";
        comment["tims_trainingplanunitstandardid@odata.bind"] = "/tims_trainingplanunitstandards(" + commentOptions.trainingplanunitstandardid + ")";
        var commentObj = await this.d365Helper.Create(entityName, comment, this.createColumnArray(this));
        if ((commentObj as Serializable).error != undefined)
            cache.push(commentObj);
        return commentObj;
    }

    // update the comment
    async updateComment(commentId: string, commentText: string) {
        var comment = {} as any;
        comment.tims_trainingcomment = commentText;
        return await this.d365Helper.Update(entityName, commentId, comment);
    }

    async retrieveById(commentId: string, noCache: boolean) {

        if (!cache) {
            throw "Training plan unit standard cache not initialised";
        }

        // find the items within the cached items
        var item = cache.find(elem => elem.tims_trainingplancommentid == commentId);

        if (item && !noCache) {
            return item;
        }
        else {
            let commentModel = await this.d365Helper.Retrieve(entityName, commentId, this.createColumnArray(this), false) as TrainingPlanCommentModel;
            if (item != undefined)
                cache[cache.indexOf(item)] = commentModel;

            return commentModel;
        }
    }

    // populates the cache with all the comments to reduce the
    // impact of the initial load
    async initCache(trainingPlanId: string, getfromCache: boolean = true) {
        var criteria = "_tims_trainingplanid_value eq '" + trainingPlanId + "'";
        cache = await this.d365Helper.RetrieveMultiple(entityName, this.createColumnArray(this), criteria, getfromCache);
    }

}