import TokenModel from '../Models/TokenModel';
import ProgrammeUnitStandard from '../ComponentHelpers/ProgrammeUnitStandard';
import GlobalStateModel from '../Models/GlobalStateModel';
import ElectiveGroupModel from '../Models/ElectiveGroupModel';
import _ from 'underscore';
import ComponentHelperBase from './ComponentHelperBase';

export default class ElectiveGroupHelper extends ComponentHelperBase{

    _tokenModel: TokenModel | undefined;
    _globalStateModel: GlobalStateModel;
    loadedCompletely: boolean;
    electiveGroups: ElectiveGroupModel[];
    _electiveGroup: ElectiveGroupModel

    childElectiveGroupHelpers:ElectiveGroupHelper[];
    childElectiveSectionHelpers:ComponentHelperBase[];

    programmeUnitStandards: ProgrammeUnitStandard[];

    constructor(globalStateModel: GlobalStateModel, electiveGroup: ElectiveGroupModel, childElectiveGroupHelpers: ElectiveGroupHelper[], childElectiveSectionHelpers:ComponentHelperBase[]) {
        super();
        this.loadedCompletely = false;
        this._globalStateModel = globalStateModel;
        this._electiveGroup = electiveGroup;
        this.programmeUnitStandards = [];
        this.electiveGroups = [];
        this.isEditMode = true;
        this.childElectiveGroupHelpers = childElectiveGroupHelpers
        this.childElectiveSectionHelpers = childElectiveSectionHelpers
    }

    setDisabled(disabled:boolean){
        this.isDisabled = disabled;
    }

    getCreditCountByLevel(level: number): number {
        let selectedInElectiveSections:number = this.childElectiveSectionHelpers.reduce((prev, currObj) => prev + currObj.getCreditCountByLevel(level), 0)
        let selectedInElectiveGroups:number = this.childElectiveGroupHelpers.reduce((prev,currObj) => prev + currObj.getCreditCountByLevel(level), 0)

        return selectedInElectiveSections + selectedInElectiveGroups 
    }

    get isParent(){
        return this._electiveGroup.tims_type == 1
    }

    get selectedElectiveSections(){
        return this.childElectiveSectionHelpers.reduce((prev, currentObj) => (currentObj.isSelected ? prev + 1: prev), 0)
    }

    get selectedElectiveGroups(){
        return this.childElectiveGroupHelpers.reduce((prev, currentObj) => (currentObj.isSelected ? prev + 1: prev), 0)
    }
    
    get selectedCredits(){
        let selectedInElectiveSections:number = this.childElectiveSectionHelpers.reduce((prev, currObj) => prev + currObj.selectedCredits, 0)
        let selectedInElectiveGroups:number = this.childElectiveGroupHelpers.reduce((prev, currObj) => prev + currObj.selectedCredits, 0)

        return selectedInElectiveGroups + selectedInElectiveSections
    }

    get selectedMinCredits(){
        let minLevel = this._electiveGroup.tims_level ?? 0
        return this.getCreditCountByLevel(minLevel)   
    }

    get selectedMaxCredits(){
        let maxLevel = this._electiveGroup.tims_maximumlevel ?? 0
        return this.getCreditCountByLevel(maxLevel)
    }

    get isSelected(): boolean{
        
        let selectedElectiveSections:boolean = this.childElectiveSectionHelpers.find(({ isSelected }) => isSelected)?.isSelected ?? false
        let selectedElectiveGroups:boolean = this.childElectiveGroupHelpers.find(({ isSelected }) => isSelected)?.isSelected ?? false
        
        return selectedElectiveSections || selectedElectiveGroups
    }

    get isCompliant(){   
        let minCreditsAtLevel = this._electiveGroup.tims_minimumcreditsonlevel ?? 0     
        let maxCreditsAtLevel = this._electiveGroup.tims_maximumcreditsonlevel ?? 1000

        let minSections = (this.isParent ? this._electiveGroup.tims_minimumelectivegroups : this._electiveGroup.tims_minimumelectivesections) ?? 0
        let selectedSections = (this.isParent ? this.selectedElectiveGroups : this.selectedElectiveSections)

        let minCredits = this._electiveGroup?.tims_minimumcredits ?? 0
        let maxCredits = this._electiveGroup?.tims_maximumcredits ?? 1000

        let levelCheck = this.selectedMaxCredits <= maxCreditsAtLevel && this.selectedMinCredits >= minCreditsAtLevel
        let creditCheck = this.selectedCredits >= minCredits && this.selectedCredits <= maxCredits
        let sectionCheck = selectedSections >= minSections

        return (levelCheck && sectionCheck && creditCheck) || this.isDisabled
    }

    get isSectionLimitReached(){
        let maxSections = this.isParent ? this._electiveGroup.tims_maximumelectivegroups : this._electiveGroup.tims_maximumelectivesections
        let selectedSections = this.isParent ? this.selectedElectiveGroups : this.selectedElectiveSections
        return selectedSections == maxSections 
    }


    async build() {

    }
}