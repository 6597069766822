
import ComponentHelperBase from "./ComponentHelperBase";
import { VisitModel, VisitsStatusCode } from "../Models/VisitModel";
import GlobalStateModel from "../Models/GlobalStateModel";
import TokenModel from "../Models/TokenModel";



export default class GoalsAndVisits extends ComponentHelperBase {

    _visit: VisitModel;

    constructor(visit: VisitModel) {
        super();
        this._visit = visit;
    }

    get isVisitPending() {
        return this._visit.statuscode == VisitsStatusCode.Pending || this._visit.statuscode == VisitsStatusCode.ReadyToFinalize;
    }
}