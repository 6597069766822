import { Serializable } from './Serializable';
import TokenModel from "./TokenModel"

const entityName = "tims_programmeunitstandards";

export class ProgrammeUnitStandardModel extends Serializable {


    constructor(tokenModel: TokenModel) {
        super(tokenModel);
    }


    statuscode: number | undefined;
    tims_assessedby: number | undefined;
    tims_assessmenttype: number | undefined;
    _tims_electivesectionid_value: string | undefined;
    tims_isallowedassessmenttypechange: any | undefined;
    tims_islista: boolean | undefined;
    tims_islistb: boolean | undefined;
    tims_islistc: boolean | undefined;
    tims_islistd: boolean | undefined;
    tims_isliste: boolean | undefined;
    tims_islistf: boolean | undefined;
    tims_isshownonlyifachieved: any | undefined;
    _tims_moduleid_value: string | undefined;
    tims_name: string | undefined;
    _tims_programmeid_value: string | undefined;
    tims_programmeunitstandardid: string | undefined;
    _tims_unitstandardid_value: string | undefined;
    tims_excludecredits: boolean | undefined;
    _tims_relationship_value: string | undefined;


    async retrieveNonElectiveProgrammeUnitStandards(programmeid: string) {

        var criteria = "_tims_programmeid_value eq '" + programmeid + "' and _tims_electivesectionid_value eq null";
        var columnSet = [
            "tims_programmeunitstandardid",
            "tims_name",
            "_tims_programmeid_value",
            "_tims_unitstandardid_value",
            "tims_islista",
            "tims_islistb",
            "tims_islistc",
            "tims_islistd",
            "tims_isliste",
            "tims_islistf",
            "tims_assessedby",
            "tims_assessmenttype",
            "tims_excludecredits",
            "_tims_relationship_value"
        ];

        return await this.d365Helper.RetrieveMultiple(entityName, columnSet, criteria);
    }

    async findPreReqForProgrammeUnitstandard(progUnitStandardId: string) {
        var fetch = `<fetch>
        <entity name="tims_programmeunitstandard">
          <filter>
            <condition attribute="tims_programmeunitstandardid" operator="eq" value="`+ progUnitStandardId + `" />
          </filter>
          <link-entity name="tims_unitstandardrelationship" from="tims_unitstandardrelationshipid" to="tims_relationship" alias="usrelation">
            <link-entity name="tims_unitstdrelationship_tims_unitstandard" from="tims_unitstandardrelationshipid" to="tims_unitstandardrelationshipid" alias="MtoM" intersect="true">
              <link-entity name="tims_unitstandard" from="tims_unitstandardid" to="tims_unitstandardid" alias="prereqUS" intersect="true">
                <attribute name="tims_name" />
                <attribute name="tims_frameworkelementcode" />
                <attribute name="tims_unitstandardid" />
              </link-entity>
            </link-entity>
          </link-entity>
        </entity>
      </fetch>`;

        let progUS = await this.d365Helper.ExecuteFetch(entityName, fetch, false);
        return progUS;
    }

    async retrieveProgrammeUnitStandardsForElectiveSection(electiveSectionId: string) {

        var criteria = "_tims_electivesectionid_value eq '" + electiveSectionId + "'";
        var columnSet = [
            "tims_programmeunitstandardid",
            "tims_name",
            "_tims_programmeid_value",
            "_tims_unitstandardid_value",
            "tims_islista",
            "tims_islistb",
            "tims_islistc",
            "tims_islistd",
            "tims_isliste",
            "tims_islistf",
            "tims_assessedby",
            "tims_assessmenttype",
            "tims_electivesectionid",
            "tims_excludecredits",
            "_tims_relationship_value"
        ];

        return await this.d365Helper.RetrieveMultiple(entityName, columnSet, criteria);
    }

}